const Constants = {
    Addons: {
        CUSTOMISE_JOB_REQUEST_FIELDS: 1,
        AUTOMATE_JOB_REQUEST: 3,
        ASSET_MANAGEMENT: 4,
        INVENTORY_MANAGEMENT: 5,
        QUOTES: 6,
        MULTI_OPERATOR: 7,
        MYOB_ACCOUNTING_RIGHT_LIVE: 8,
        MYOB_ESSENTIALS: 9,
        JOB_ATTENDANCE_TRACKING: 10,
        SITE_ATTENDANCE_TRACKING: 11,
        OPERATOR_EMPLOYEE_TASK: 12,
        ADDITIONAL_INFORMATION: 13,
        CLIENT_EMPLOYEE_TASK: 14,
        SITE_TRACKER: 15,
    },
    Roles: {
        RoleSuperAdmin: "ROLE_SUPER_ADMIN",
        RoleAdmin: "ROLE_ADMIN",
        RoleAdministrator: "ROLE_ADMINISTRATOR",
        RoleOperator: "ROLE_OPERATOR",
        RoleClient: "ROLE_CLIENT"
    },
    SearchType: {
        JOB_REQUEST_CREATED: 0,
        JOB_REQUEST_ASSIGNED_TO_OTHER: 1,
        RECENTLY_COMPLETED_JOB_REQUEST: 2,
        ADVANCED_SEARCH: 3
    },
    RequestType: {
        "Pending": "Pending",
        "Accepted": "Open",
        "Declined": "Declined",
        "Completed": "Completed",
        "DueToday": "Due Today",
        "Recurring": "Recurring"
    },
    RequestStatus: {
        "Pending": "Pending",
        "Accepted": "Accepted",
        "Declined": "Declined",
        "Completed": "Completed"
    },
    MapsErrorResult: [
        'ZERO_RESULTS',
        'REQUEST_DENIED'
    ],
    Days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    AssignStatus: [
        { value: "Pending", title: "Pending" },
        { value: "Accepted", title: "Open" },
        { value: "Completed", title: "Completed" },
        { value: "Declined", title: "Decliend" },
        { value: "Rejected", title: "Rejected" },
    ],
    Priorities: ["Critical", "High", "Medium", "Low"]
}

export default Constants
