import React, {useEffect, useState} from "react";
import Select from "react-select";
import {UNIT_ITEMS} from "../../../resorces/strings";
import {SelectItem} from "../../../models/SelectItem";
import {SelectBaseError} from "./SelectBaseError";

interface SelectUnitProps {
    value?: string;
    onSelect?: (unitName: string) => void;
    isError?: boolean
}

export const SelectUnit: React.FunctionComponent<SelectUnitProps> = ({
    value,
    onSelect,
    isError = false
}): JSX.Element => {

    const [selectedItem, setSelectedItem] = useState<SelectItem | undefined | null>();

    useEffect(() => {
        _updateSelectedItem(value);
    }, [value]);

    const _updateSelectedItem = (item?: string) => {
        if (item) {
            const found = UNIT_ITEMS.find(i => i.value === item);
            setSelectedItem(found ?? null);
        } else setSelectedItem(undefined);
    }

    return (
        <Select
            value={selectedItem}
            options={UNIT_ITEMS}
            onChange={(item) => {
                if (onSelect && item) {
                    onSelect(item.value);
                }
            }}
            styles={SelectBaseError(isError)}
        />
    )
}
