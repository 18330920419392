import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import Constants from "../../../resorces/Constants";
import {ReduxBaseState} from "../../../models/ReduxBaseState";
import {
    JRW01DetailModel,
    JRW02ClientModel,
    JRW03OperatorModel,
    JRW04AdditionalModel
} from "../../../app/pages/JobRequests/JobRequestWizard";

type IsValidated = { isValidated: boolean };

export type JobRequestReduxState = {
    requestStatus: ReduxBaseState,
    step01: JRW01DetailModel,
    step02: JRW02ClientModel & IsValidated,
    step03: {
        data: JRW03OperatorModel[],
        isValidated: boolean,
    },
    step04: JRW04AdditionalModel & IsValidated,
}

const initialState: JobRequestReduxState = {
    requestStatus: {
        status: "init",
        errorMessage: "",
    },
    step01: {
        data: [],
        isValidated: false,
    },
    step02: {
        clientId: '',
        propertyId: '',
        address: '',
        clientNotification: [],
        usePreferredOperator: false,
        isValidated: false,
    },
    step03: {
        data: [{
            assets: [],
            inventories: [],
            operator: {
                id: '',
            },
            service: { id: '' },
            status: {
                value: Constants.RequestStatus.Pending,
            },
            customFields: [],
            isValidated: false,
        }],
        isValidated: false,
    },
    step04: {
        files: [],
        editFile: [],
        checklists: [{ text: "", isChecked: false }],
        administratorIds: [],
        isValidated: false,
    }
}

export const createJobRequestRedux = createSlice({
    name: 'tempJobRequest',
    initialState,
    reducers: {
        updateRequestStatus: (state, payload: PayloadAction<ReduxBaseState>) => {
            state.requestStatus = payload.payload;
        },
        updateStep01Data: (state, payload: PayloadAction<JRW01DetailModel>) => {
            state.step01 = payload.payload;
        },
        updateStep01ValidationStatus: (state, payload: PayloadAction<boolean>) => {
            state.step01.isValidated = payload.payload;
        },
        updateStep02Data: (state, payload: PayloadAction<JRW02ClientModel & IsValidated>) => {
            state.step02 = payload.payload;
        },
        updateStep03Data: (state, payload: PayloadAction<JRW03OperatorModel[]>) => {
            state.step03.data = payload.payload;
        },
        updateStep04Data: (state, payload: PayloadAction<JRW04AdditionalModel & IsValidated>) => {
            state.step04 = payload.payload;
        },
        updateStep03ValidationStatus: (state, payload: PayloadAction<boolean>) => {
            state.step03.isValidated = payload.payload;
        },
        clearCreateJobRequest: () => initialState
    }
})

export const {
    updateRequestStatus,
    updateStep01Data,
    updateStep01ValidationStatus,
    updateStep02Data,
    updateStep03Data,
    updateStep04Data,
    updateStep03ValidationStatus,
    clearCreateJobRequest
} = createJobRequestRedux.actions