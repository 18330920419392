import {ReduxBaseState} from "../../../models/ReduxBaseState";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface InventoryCategoryReduxState extends ReduxBaseState {
    totalPage: number | string;
    currentPage: number | string;
    totalData: number | string;
    limit: number | string;
    date: any;
    isLoading: boolean;
    searchKeyword: string | any;
}

const initialState: InventoryCategoryReduxState = {
    status: 'init',
    errorMessage: undefined,
    
    totalPage: 0, // default
    currentPage: 0, // default
    totalData: 0, // default
    limit: 0, // default
    date: new Date(),
    isLoading: false,
    searchKeyword: "", // default
}

export const settingsInventoryCategorySlice = createSlice({
    initialState,
    name: 'settingsInventoryCategory',
    reducers: {
        setCurrentPage: (state, action: PayloadAction<{newCurrentPage: number | string}>) => {
            state.currentPage = action.payload.newCurrentPage;
        },
        setIsLoading: (state, action: PayloadAction<{isLoading: boolean}>) => {
            state.isLoading = action.payload.isLoading;
        },
        setSuccessResponseInventoryCategory: (state, action: PayloadAction<{totalData: number | string, limit: number | string}>) => {
            state.totalData = action.payload.totalData;
            state.isLoading = false;
            state.limit = action.payload.limit;
        },
        setDefaultInventoryCategory: (state) => {
            state.totalPage = 0;
            state.currentPage = 0;
            state.totalData = 0;
            state.limit = 0;
            state.searchKeyword = "";
        },
        setSearchKeyword: (state, action: PayloadAction<{searchKeyword: string | any}>) => {
            state.searchKeyword = action.payload.searchKeyword;
        },
        refresh: (state) => {
            state.isLoading = true;
            state.date = new Date();
        },
        clear: () => initialState
    }
})

export const {
    setCurrentPage,
    setIsLoading,
    setSuccessResponseInventoryCategory,
    setDefaultInventoryCategory,
    setSearchKeyword,
    refresh,
    clear
} = settingsInventoryCategorySlice.actions