import React from 'react'
import JobRequestTimeTracker from "../JobRequestTimeTracker";
import {TimeTrackerHelper} from "../../pages/JobRequests/Helpers/TimeTrackerHelper";
import {useRoleHelper} from "../../../resorces/RoleHelper";
import {IJobRequestExtendedModel} from "../../../models/IJobRequestResponse";

interface IJobRequestDropdownDetailTimeTracker {
    jobRequestInfo: IJobRequestExtendedModel,
    isLoading?: boolean,
    onReload?: () => void,
    onStopClick?: () => void
}
export const JobRequestDropdownDetailTimeTracker: React.VFC<IJobRequestDropdownDetailTimeTracker> = (props) => {
    const {isOperator} = useRoleHelper()

    const {
        jobRequestInfo,
        onReload,
        onStopClick,
        isLoading = false
    } = props

    return (
        <JobRequestTimeTracker
            operatorName={jobRequestInfo.assign_to?.fullname_operator ?? ""}
            isLoading={isLoading}
            trackTime={jobRequestInfo.track_time}
            onStartClick={() => TimeTrackerHelper.startTrackTime(
                jobRequestInfo.id,
                "start",
                onReload
            )}
            onPauseClick={() => TimeTrackerHelper.startTrackTime(
                jobRequestInfo.id,
                "pause",
                onReload
            )}
            onStopClick={onStopClick}
        />
    )
}
