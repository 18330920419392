import {ReduxBaseState} from "../../models/ReduxBaseState";
import {Newses} from "../../models/NewsFeed";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface NewsState extends ReduxBaseState {
    newses: Newses[]
}

const initialState: NewsState = {
    status: "init",
    errorMessage: undefined,
    newses: []
};

export const newsSlice = createSlice({
    name: "newses",
    initialState,
    reducers: {
        newsAddAll: (state, action: PayloadAction<Newses[]>) => {
            state.newses = action.payload;
            state.status = "success";
            state.errorMessage = "";
        },

        newsAdd: (state, action: PayloadAction<Newses>) => {
            state.newses = [...state.newses, action.payload];
            state.status = "success";
            state.errorMessage = "";
        },

        newsLoading: (state) => {
            state.status = "loading";
            state.errorMessage = "";
        },

        newsError: (state, action: PayloadAction<{errorMessage: string}>) => {
            state.status = "loading";
            state.errorMessage = action.payload.errorMessage;
        },

        clear: () => initialState,
    }
});

export const {
    newsAdd,
    newsAddAll,
    newsError,
    newsLoading,
    clear
} = newsSlice.actions;

export {};