import { Dispatch } from "redux";
import {
  errorLogin,
  login,
  processLogin,
  register,
} from "../reducers/authRedux";
import * as loginApi from "../../apis/AuthApi";
import { makeErrorMessage } from "../../utils/Helpers";

export const loginAuth =
  (username: string, password: string) => async (dispatch: Dispatch) => {
    dispatch(processLogin());

    try {
      const { data, status } = await loginApi.login(username, password);
      if (status === 200) {
        dispatch(
          login({
            access_token: data.access_token,
            expires_in: data.expires_in,
            refresh_token: data.refresh_token,
            scope: data.scope,
            token_type: data.token_type,
          })
        );
      } else {
        dispatch(
          errorLogin({
            errorMessage:
              "Please try again.. If you still get this message, please contact our Support.",
          })
        );
      }
    } catch (error: any) {
      let msg =
        "Please try again.. If you still get this message, please contact our Support.";
      if (error && error.response) {
        console.log("error.response.data", error.response.data);
        console.log("error.response.status", error.response.status);
        console.log("error.response.headers", error.response.headers);
        if (error.response.status === 400 || error.response.data) {
          msg = error.response.data.error_description;
        }
      }
      dispatch(
        errorLogin({
          errorMessage: msg,
        })
      );
    }
  };

export const loginWithPasscodeAction =
  (passcode: string) => async (dispatch: Dispatch) => {
    dispatch(
      errorLogin({
        errorMessage: "Not implemented",
      })
    );
    // dispatch(processLogin());

    // const [token, _] = await accountPasscodeToken()

    // const [data, error] = await loginWithPasscode(passcode, token!)
    // if (!error) {
    //     dispatch(login({
    //         access_token: data!.token.access_token,
    //         expires_in: data!.token.expires_in,
    //         refresh_token: data!.token.refresh_token,
    //         scope: null,
    //         token_type: data!.token.token_type
    //     }))
    // } else {
    //     dispatch(errorLogin({
    //         errorMessage: error
    //     }));
    // }
  };

export const registrationAction =
  (registerAccount: loginApi.IRegisterAccount) => (dispatch: Dispatch<any>) => {
    dispatch(processLogin());
    return loginApi
      .register(registerAccount)
      .then((_) => {
        // If success dispatch login
        dispatch(loginAuth(registerAccount.username, registerAccount.password));
        dispatch(register());
      })
      .catch((error) => {
        let msg =
          "Please try again.. If you still get this message, please contact our Support.";
        if (error.response) {
          if (error.response.status === 400) {
            msg = error.response.data.error_description;
          }
        }
        dispatch(errorLogin({ errorMessage: msg }));
      });
  };
