import React, { useState } from "react";
import { DropdownGroupItemToggler } from "../DropdownItem/DropdownGroupItemToggler";
import { Dropdown } from "react-bootstrap";
import { FilterToolbarFull } from "./FilterToolbarFull";
import { ToolbarClient } from "./ToolbarItem/ToolbarClient";
import { ToolbarDue } from "./ToolbarItem/ToolbarDue";
import { ToolbarSearch } from "./ToolbarItem/ToolbarSearch";
import FilterToolbarWrapper from "./FilterToolbarWrapper";
import { ToolbarOperator } from "./ToolbarItem/ToolbarOperator";
import { ToolbarProperty } from "./ToolbarItem/ToolbarProperty";
import { ToolbarServices } from "./ToolbarItem/ToolbarServices";

interface FilterToolbarProp {
  onSubmitSearch?: (args: string) => void;
  placeholder?: string;
  title?: string;
  showTitle?: boolean;
  children?: React.ReactNode;
}

type FilterToolbarSubComponent = {
  FullToolbar: typeof FilterToolbarFull;
  Client: typeof ToolbarClient;
  Operator: typeof ToolbarOperator;
  Property: typeof ToolbarProperty;
  Service: typeof ToolbarServices;
  Due: typeof ToolbarDue;
  Search: typeof ToolbarSearch;
  Wrapper: typeof FilterToolbarWrapper;
};

const FilterToolbar: React.FunctionComponent<FilterToolbarProp> &
  FilterToolbarSubComponent = ({
  children,
  onSubmitSearch,
  title = "Filter",
  placeholder = "Search",
  showTitle = false,
}): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showDropdownFilter, setShowDropdownFilter] = useState(false);

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (onSubmitSearch) onSubmitSearch(searchQuery);
  };

  return (
    <div className="mr-4">
      <div className="input-group">
        <form onSubmit={onSubmit}>
          <input
            type="text"
            className="form-control rounded-right-0"
            placeholder={placeholder}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
          />
        </form>

        <div className="input-group-append">
          <Dropdown show={showDropdownFilter}>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              as={DropdownGroupItemToggler}
              onClick={() => setShowDropdownFilter(!showDropdownFilter)}
            >
              Filter
            </Dropdown.Toggle>

            <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xxl">
              {showTitle ? (
                <>
                  <div className="p-8 border-bottom">
                    <h4 className="mb-0">
                      <span className="mr-4">
                        <i className="fas fa-filter" />
                      </span>
                      {title}
                    </h4>
                  </div>
                  {children}
                </>
              ) : (
                children
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

FilterToolbar.FullToolbar = FilterToolbarFull;
FilterToolbar.Search = ToolbarSearch;
FilterToolbar.Client = ToolbarClient;
FilterToolbar.Due = ToolbarDue;
FilterToolbar.Wrapper = FilterToolbarWrapper;
FilterToolbar.Operator = ToolbarOperator;
FilterToolbar.Property = ToolbarProperty;
FilterToolbar.Service = ToolbarServices;

export default FilterToolbar;
