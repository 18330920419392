import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {LoginWrapper} from "./components/LoginWrapper";
import IconFormField from "./components/IconFormField";
import {useIntl} from "react-intl";
import {IFormikType} from "../../../models/FormikType";
import {loginAuth, loginWithPasscodeAction} from "../../../redux/actions/authAction";
import {ApplicationState} from "../../../redux/rootReducer";
import {FormikMessage, Spinner} from "../../components";


interface ILoginForm {
    username: string,
    password: string,
    passcode: string
}

const initialValue: ILoginForm = {
    username: "",
    password: "",
    passcode: "",
}

function Login() {
    const {formatMessage: f} = useIntl();

    const [toggleSignText, setToggleSignText] = useState(true);
    const LoginSchema = Yup.object().shape({
        passcode: Yup.string()
            .min(3, "Minimum 3 characters"),
        username: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .when('passcode',
                (other: any, schema: any) => {
                    return (other) ? schema
                        : schema.required(
                            f({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })
                        )
                }
            ),
        password: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .when('passcode',
                (other: any, schema: any) => {
                    return (other) ? schema
                        : schema.required(
                            f({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })
                        )
                }
            ),
    });

    const getInputClasses = (fieldname: string) => {
        const touched = formik.touched as IFormikType;
        const errors = formik.errors as IFormikType;
        if (touched[fieldname] && errors[fieldname]) {
            return "is-invalid";
        }

        if (touched[fieldname] && !errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const { status, errorMessage } = useSelector((state: ApplicationState) => state.auth);
    const dispatch = useDispatch();

    const [csrfToken, setCsrfToken] = useState<string>("")

    const formik = useFormik<ILoginForm>({
        initialValues: initialValue,
        validationSchema: LoginSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            setStatus(null);
            if (values.passcode.trim() === "") {
                dispatch(loginAuth(values.username, values.password));
            } else {
                dispatch(loginWithPasscodeAction(values.passcode));
            }
        },
    });

    return (
        <LoginWrapper
            title="Login">
            <div className="login-form login-signin" id="kt_login_signin_form">
                <form onSubmit={formik.handleSubmit}>
                    { errorMessage !== "" && (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">{ errorMessage }</div>
                        </div>
                    )}

                    {formik.status ? (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">{formik.status}</div>
                        </div>
                    ) : (<></>) }


                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <IconFormField
                            iconName="fa fa-user">
                            <input
                                type="text"
                                className={`form-default ${getInputClasses("username")}`}
                                placeholder="Username"
                                onFocus={() => {
                                    formik.setFieldValue("passcode", "");
                                }}
                                {...formik.getFieldProps("username")}
                            />
                        </IconFormField>

                        <FormikMessage
                            errorMessage={formik.errors.username}
                            touchedField={formik.touched.username}
                        />

                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <IconFormField
                            iconName="fa fa-key">
                            <input
                                type="password"
                                className={`form-default ${getInputClasses("password")}`}
                                placeholder="Password"
                                onFocus={() => {
                                    formik.setFieldValue("passcode", "");
                                }}
                                {...formik.getFieldProps("password")}
                            />
                        </IconFormField>

                        <FormikMessage
                            errorMessage={formik.errors.password}
                            touchedField={formik.touched.password}
                        />
                    </div>

                    <div className="or-group w-100 position-relative d-flex justify-content-center mb-4">
                        <div className="or-divider position-absolute bg-dark w-100"/>
                        <div className="or-text-wrapper px-2">
                            <div className="or-text">OR</div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="username">Passcode</label>
                        <div className="form-icon-group d-flex align-items-center pl-4">
                            <i className="fa fa-lock" />
                            <div className="w-100 ml-4">
                                <input
                                    type="text"
                                    className={`form-default ${getInputClasses('passcode')}`}
                                    placeholder="XXXXX"
                                    name="passcode"
                                    value={formik.values.passcode}
                                    onFocus={() => {
                                        // When focusing, remove input value from field
                                        formik.setFieldValue("username", "");
                                        formik.setFieldValue("password", "");
                                    }}
                                    onChange={e => {
                                        formik.getFieldProps("passcode").onChange(e);
                                        if (e.target.value && e.target.value.length > 0) {
                                            setToggleSignText(false);
                                        } else {
                                            setToggleSignText(true);
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <FormikMessage
                            errorMessage={formik.errors.passcode}
                            touchedField={formik.touched.passcode}
                        />
                    </div>

                    <div className="d-flex align-items-center mb-6">
                        <input type="checkbox"/>
                        <label htmlFor="remember_me" className="mb-0 ml-2 font-weight-bold font-size-lg">REMEMBER
                            ME</label>
                    </div>

                    { status === "loading" ? (
                        <Spinner />
                    ) : (
                        <button
                            id="kt_login_signin_submit"
                            type="submit"
                            disabled={(status === 'success')}
                            className="bg-accent-color btn w-100 text-light font-weight-bold mb-6"
                        >
                                { toggleSignText ? "SIGN IN" : "SIGN IN USING PASSCODE"}
                        </button>
                    )}

                    <div className="dont-have-account text-center">
                        <p className="mb-0">Don't have account</p>
                        <div className="sign-up-text">
                            <Link to="/registration">SIGN UP</Link>
                        </div>
                    </div>
                </form>
            </div>
        </LoginWrapper>
    );
}

export default Login;
