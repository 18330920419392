import React from "react";
import DatePicker from "react-datepicker";

export interface CreateDateItem {
    value: string;
    label: string;
    as?: "within_the_next" | "within_the_last" | "between" | "on"
}

const _CREATED_DATE: CreateDateItem[] = [
    {value: "", label: "-- Created Date --"},
    {value: "within_the_next", label: "Within the next"},
    {value: "within_the_last", label: "Within the last"},
    {value: "between", label: "Between"},
    {value: "on", label: "On"},
];

export interface SearchToolbarCreatedDateData {
    type?: string;
    alias?: string;
    within?: { total?: string; type?: string };
    between?: { start?: Date; end?: Date };
    on?: Date;
}

export interface SearchToolbarDueDateData {
    type?: string;
    alias?: string;
    within?: { total?: string; type?: string };
    between?: { start?: Date; end?: Date };
    on?: Date;
}

interface SearchToolbarCreatedDateProps {
    /**
     * Override default implementation of Dropdown Created date value and label
     *
     * Default:
     * | Value | Label |
     * |-|-|
     * | none | -- Created Date -- |
     * | "within_the_next" | "Within the Next" |
     * | "within_the_last" | "Within the Last" |
     * | "between" | "Between" |
     * | "on" | "On" |
     * */
    createdDateSelect?: CreateDateItem[];
    value?: SearchToolbarCreatedDateData;
    onChange?: (data: SearchToolbarCreatedDateData) => void;

    label?: string;
}

export const SearchToolbarCreatedDate: React.FunctionComponent<SearchToolbarCreatedDateProps> = ({
    createdDateSelect = _CREATED_DATE,
    value,
    onChange,
    label = "Created Date"
}): JSX.Element => {
    
    const _onChangeSelected = (data: SearchToolbarCreatedDateData) => {
        // make an alias
        const filtered = createdDateSelect?.filter((v) => v.value === data.type)[0];

        const aliasType = filtered?.as ?? data.type;

        if (onChange) onChange({
            ...data,
            type: data.type,
            alias: aliasType
        });
    }

    return (
        <div className="form-group row">
            <label className="col-form-label col-md-3">
                {label}
            </label>

            <div className="col-md-3">
                <select
                    className="form-control"
                    name="created_date"
                    onChange={(e) => {
                        _onChangeSelected({
                            type: e.target.value,
                        })
                    }}
                    value={value?.type ?? ""}
                >
                    {createdDateSelect.map((value) => (
                        <option key={value.value} value={value.value}>{value.label}</option>
                    ))}
                </select>
            </div>

            {(
                value?.alias === "within_the_next"|| value?.alias === "within_the_last"
            ) && (
                <>
                    <div className="col-md-2">
                        <input
                            type="number"
                            className="form-control"
                            value={value?.within?.total ?? ""}
                            onChange={(e) => {
                                _onChangeSelected({
                                    ...value,
                                    within: {
                                        type: value.within?.type ?? "",
                                        total: e.target.value
                                    }
                                })
                            }}
                        />
                    </div>

                    <div className="col-md-3">
                        <select
                            name="within_next_type"
                            className="form-control"
                            onChange={(e) => {
                                _onChangeSelected({
                                    ...value,
                                    within: {
                                        type: e.target.value,
                                        total: value.within?.total ?? ""
                                    }
                                })
                            }}
                            value={value?.within?.type ?? ""}
                        >
                            <option value="" />
                            <option value="days">days</option>
                            <option value="weeks">weeks</option>
                            <option value="months">months</option>
                        </select>
                    </div>
                </>
            )}

            {value?.alias === "between" && (
                <>
                    <div className="col-md-3">
                        <DatePicker
                            selected={value?.between?.start}
                            onChange={(date: Date) => {
                                _onChangeSelected({
                                    ...value,
                                    between: {
                                        start: date,
                                        end: value.between?.end
                                    }
                                });
                            }}
                            className="form-control"
                            dateFormat="dd-MM-yyyy"
                        />
                    </div>

                    <div className="col-md-3">
                        <DatePicker
                            selected={value?.between?.end}
                            onChange={(date: Date) => {
                                _onChangeSelected({
                                    ...value,
                                    between: {
                                        start: value.between?.start,
                                        end: date
                                    }
                                });
                            }}
                            className="form-control"
                            dateFormat="dd-MM-yyyy"
                        />
                    </div>
                </>
            )}

            {value?.alias === "on" && (
                <div className="col-md-3">
                    <DatePicker
                        selected={value?.on}
                        onChange={(date: Date) => {
                            _onChangeSelected({
                                ...value,
                                on: date
                            });
                        }}
                        className="form-control"
                        dateFormat="dd-MM-yyyy"
                    />
                </div>
            )}
        </div>
    )
}

