import React from "react";

interface SymbolImageProps {
  size?: "30" | "35" | "40" | "45" | "50" | "55" | "60" | "65" | "70";
  className?: string;
  children: React.ReactNode;
}

export const SymbolImage: React.FunctionComponent<SymbolImageProps> = ({
  size = "45",
  className = "",
  children,
}): JSX.Element => {
  return (
    <div
      className={`symbol symbol-${size} symbol-light-gray ${className}`}
      style={{ width: `${size}px` }}
    >
      {children}
    </div>
  );
};
