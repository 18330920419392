/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import SVG from "react-inlinesvg";
import {checkIsActive, toAbsoluteUrl} from "../../../../_helpers";
import AsideMenuAdmin from "./Menus/AsideMenuAdmin";
import AsideMenuOperator from "./Menus/AsideMenuOperator";
import AsideMenuClient from "./Menus/AsideMenuClient";
import {Icons} from "../../../../../../resorces/images";
import React from "react";
import Api_config from "../../../../../../api_config";
import {useRoleHelper} from "../../../../../../resorces/RoleHelper";
import {AsideSuperAdministratorMenu} from "../../../../../pages/SuperAdministrator/layout/AsideSuperAdministratorMenu";


export function AsideMenuList({layoutProps}) {
    const {isSuperAdmin, isAdmin, isAdministrator, isOperator, isClient} = useRoleHelper()

    return (
        <>
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                <AsideMenuItemList
                    url={"/dashboard"}
                    name={"Dashboard"}
                    icon={Icons.layer}
                    iconType="svg"
                />

                {isSuperAdmin && (
                    <AsideSuperAdministratorMenu />
                )}

                {(isAdmin || isAdministrator) &&
                    <AsideMenuAdmin
                        isRoleAdmin={isAdmin || isAdministrator || isSuperAdmin}
                        isSuperAdmin={isSuperAdmin}
                    />
                }
                {(isOperator) && (
                    <AsideMenuOperator/>
                )}
                {(isClient) && (
                    <AsideMenuClient/>
                )}
                <li
                    className={`menu-item`}
                    aria-haspopup={true}
                >
                    <a className="menu-link" href="//support.my-psr.com/">
                        <AsideIcon
                            icon="question-circle"
                            iconType="fa"
                        />
                        <span className="menu-text">Support</span>

                    </a>
                </li>
            </ul>
        </>
    );
}

/**
 * Create Sidebar Icon
 * */
export const AsideIcon = ({icon, iconType}) => {
    return (
        <>
            {iconType === 'svg' && (
                <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl(icon)}/>
            </span>
            )}

            {iconType === 'fa' && (
                <span className="menu-icon">
                        <i className={`fas fa-${icon}`}/>
                    </span>
            )}

            {iconType === "bullet" && (
                <i className="menu-bullet menu-bullet-dot">
                    <span/>
                </i>
            )}

            {(icon === null || iconType === null) && (
                <></>
            )}
        </>
    )
}

/**
 * Sidebar Item Navigation
 * */
export const AsideMenuItemList = ({url, name, icon, iconType, appendedClass = "", children = null}) => {
    const isHasSubMenu = children !== null;


    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu &&
            "menu-item-active"} menu-item-open menu-item-not-hightlighted`
            : "";
    };

    if (isHasSubMenu) {
        return (
            <li
                className={`${appendedClass} menu-item menu-item-submenu ${getMenuItemActive(
                    "/error",
                    true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
            >
                <NavLink className="menu-link menu-toggle" to={url}>
                <span className="svg-icon menu-icon">
                  <AsideIcon
                      icon={icon}
                      iconType={iconType}
                  />
                </span>
                    <span className="menu-text">{name}</span>
                    <i className="menu-arrow"/>
                </NavLink>
                <div className="menu-submenu ">
                    <i className="menu-arrow"/>
                    <ul className="menu-subnav">
                        {children}
                    </ul>
                </div>
            </li>
        )
    } else {
        return (
            <li
                className={`menu-item ${appendedClass} ${getMenuItemActive(url, false)}`}
                aria-haspopup={true}
            >
                <NavLink className="menu-link" to={url}>
                    <AsideIcon
                        icon={icon}
                        iconType={iconType}
                    />
                    <span className="menu-text">{name}</span>
                </NavLink>
            </li>
        )
    }
}

export const MenuItemLink = ({icon, iconType, url, appendedClass, children, openNewPage = false}) => {
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu &&
            "menu-item-active"} menu-item-open menu-item-not-hightlighted`
            : "";
    }

    return (
        <li
            className={`menu-item ${appendedClass} ${getMenuItemActive(url, false)}`}
            aria-haspopup={true}
        >
            {!openNewPage ? (
                <NavLink className="menu-link" to={url}>
                    <AsideIcon
                        icon={icon}
                        iconType={iconType}
                    />
                    <span className="menu-text">{children}</span>
                </NavLink>
            ) : (
                <a href={`${Api_config.REACT_APP_API_URL}/${url}`} className="menu-link" target={'_blank'}>
                    <AsideIcon
                        icon={icon}
                        iconType={iconType}
                    />
                    <span className="menu-text">{children}</span>
                </a>
            )}

        </li>
    )
}
