import {Alert, Modal, Spinner} from "react-bootstrap";
import {useEffect, useState} from "react";
import {accountGeneratePasscode} from "../../../apis/AccountAPI";

interface PasscodeModalProps {
    show?: boolean;
    onClose?: () => void;
}

export function PasscodeModal({
    show,
    onClose
}: PasscodeModalProps): JSX.Element {

    const [showPasscodeInput, setShowPasscodeInput] = useState(false);
    const [ownPasscode, setOwnPasscode] = useState("");

    const [passcodeGenerated, setPasscodeGenerated] = useState("");
    const [generateLoading, setGenerateLoading] = useState(false);
    const [generateError, setGenerateError] = useState("");

    useEffect(() => {
        setShowPasscodeInput(false);
        setOwnPasscode("");
        setPasscodeGenerated("");
        setGenerateError("");
        setGenerateLoading(false);
    }, [show]);

    function _generatePasscode(passcode?: string) {
        setGenerateLoading(true);
        accountGeneratePasscode(passcode).then(({ data }) => {
            setOwnPasscode("");
            setPasscodeGenerated(data.passcode);
            setGenerateError("");
            setGenerateLoading(false);
            setTimeout(() => {
                if (onClose) onClose();
            }, 3000);
        }).catch(error => {
            setGenerateError(error.toString());
            setGenerateLoading(false);
        });
    }

    return (
        <Modal show={show} size="lg" onHide={ onClose }>
            <Modal.Header closeButton={true}>
                <Modal.Title>Passcode</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                {generateError && (
                    <div className="my-4 mx-6">
                        <Alert variant="danger">
                            <div className="font-weight-bolder">
                                <i className="fas fa-exclamation-triangle font-size-sm text-white mr-2"/>
                                Error
                            </div>
                            {generateError}
                        </Alert>
                    </div>
                )}

                {(passcodeGenerated && showPasscodeInput) && (
                    <div className="my-4 mx-6">
                        <Alert variant="success">
                            Passcode successfully created
                        </Alert>
                    </div>
                )}

                <div className="bg-gray-100 py-4 px-6">
                    <div className="font-size-h4 font-weight-bold mb-4">What is Passcode?</div>
                    <p>Passcode is a number you create (like a pin number) that allows you to login without having to enter your username and password. It's important that you protect this passcode just the same as you do with your pin.</p>
                </div>

                { showPasscodeInput && (
                    <div className="mx-6 my-4 p-4 border rounded bg-gray-50">
                        <label htmlFor="passcode">Enter your Pascode</label>
                        <input
                            type="text"
                            name="passcode"
                            className="form-control"
                            placeholder="XXXXX"
                            value={ownPasscode}
                            onChange={(e) => setOwnPasscode(e.target.value)}
                        />
                    </div>
                )}

                { generateLoading && (
                    <div className="text-center mx-6 my-4">
                        <Spinner animation="border" />
                    </div>
                )}

                {(passcodeGenerated && !showPasscodeInput) && (
                    <div className="text-center mx-6 my-4">
                        <div className="bg-light rounded d-inline-block px-6 py-2 border">
                            <div className="mb-1 text-black-50">Your passcode is</div>
                            <div className="font-weight-bolder font-size-h3">{passcodeGenerated}</div>
                        </div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                {!showPasscodeInput && (
                    <button
                        className="btn btn-mypsr-orange"
                        onClick={() => setShowPasscodeInput(true)}
                    >
                        <i className="fas fa-key mr-2 text-white"/>
                        Create my Own Passcode
                    </button>
                )}

                {showPasscodeInput && (
                    <button
                        className="btn btn-light"
                        onClick={() => setShowPasscodeInput(false)}
                    >
                        <i className="fas fa-arrow-left mr-2"/>
                        Generate Passcode Automatically
                    </button>
                )}

                {showPasscodeInput && (
                    <button
                        className="btn btn-mypsr-orange"
                        onClick={() => _generatePasscode(ownPasscode)}
                    >
                        <i className="fas fa-save mr-2 text-white"/>
                        Save my Own Passcode
                    </button>
                )}

                {!showPasscodeInput && (
                    <button
                        className="btn btn-primary"
                        onClick={() => _generatePasscode()}
                    >
                        <i className="fas fa-key mr-2 text-white"/>
                        Yes, Create New Passcode Automatically
                    </button>
                )}
            </Modal.Footer>
        </Modal>
    )
}