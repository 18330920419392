import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ICookieFilterState = { [key: string]: ICookieFilter };

export interface ICookieFilter {
  searchKeyword?: string;
  page?: number;
  order?: { column: string; by: "ASC" | "DESC" };
  other?: { [key: string]: any };
}

export interface IPayloadFilter {
  name: string;
  filter: ICookieFilter;
}

const initialState: ICookieFilterState = {};

export const cookieFilterRedux = createSlice({
  name: "cookie-filter",
  initialState: initialState,
  reducers: {
    updateFilter: (state, payload: PayloadAction<IPayloadFilter>) => {
      state[payload.payload.name] = payload.payload.filter;
    },
    resetFilter: (state, payload: PayloadAction<{ name: string }>) => {
      state[payload.payload.name] = {};
    },
    clearCookieFilter: () => initialState,
  },
});

export const { updateFilter, resetFilter, clearCookieFilter } =
  cookieFilterRedux.actions;
