import {useEffect, useState} from "react";
import {selectCategory} from "../../../apis/SelectAPI";
import AsyncSelect from "react-select/async";
import {SelectItem} from "../../../models/SelectItem";

type SelectGlobalCategoryType<IsMultiple extends boolean> = IsMultiple extends true ? string[] : string;

interface SelectGlobalCategoryProps<IsMultiple extends boolean = false> {
    categoryId?: SelectGlobalCategoryType<IsMultiple>;
    onSelected?: (operatorId: SelectGlobalCategoryType<IsMultiple>) => void;
    isMultiSelect?: IsMultiple;
    disabled?: boolean;
    arc?: number;
}


export function SelectGlobalCategory<Multiple extends boolean = false>({
    categoryId,
    onSelected,
    isMultiSelect,
    disabled = false,
    arc = 0
}: SelectGlobalCategoryProps<Multiple>): JSX.Element {

    const [categories, setCategories] = useState<SelectItem[]>([]);

    useEffect(() => {
        _valueChange(categoryId);
    }, [categoryId]);

    const _loadOptions = (inputValue: string): Promise<SelectItem[]> => {
        return new Promise<SelectItem[]>((resolve, reject) => {
            selectCategory().then(({ data }) => {
                const options = data.categories.map((category) => {
                    return { value: category.id.toString(), label: category.title }
                });

                setCategories(options);

                resolve(options);
            }).catch(e => {
                reject(e.toString());
            });
        })
    }

    const _valueChange = (inputValue?: SelectGlobalCategoryType<Multiple>): SelectItem[] => {
        let tempSelected: SelectItem[] = [];
        if (isMultiSelect && Array.isArray(inputValue)) {
            categories.forEach((value) => {
                inputValue.forEach((op) => {
                    if (value.value === op) tempSelected.push(value);
                })
            })
        } else {
            tempSelected = categories.filter((v) => v.value === categoryId);
        }
        return tempSelected;
    }

    return (
        <AsyncSelect
            key={arc}
            defaultOptions
            isMulti={isMultiSelect}
            loadOptions={_loadOptions}
            value={_valueChange(categoryId)}
            onChange={(item) => {
                if (onSelected && item) {
                    if (Array.isArray(item)) {
                        onSelected(item.map((value) => value.value) as SelectGlobalCategoryType<Multiple>);
                    } else {
                        const i = item as SelectItem;
                        onSelected(i.value as SelectGlobalCategoryType<Multiple>);
                    }
                }
            }}
            styles={{
                control: (styles) => {
                    return { ...styles, zIndex: 2 }
                },
                menu: (base) => {
                    return { ...base, zIndex: 3}
                }
            }}
        />
    )
}
