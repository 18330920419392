import React, {useMemo} from 'react'
import {useHtmlClassService} from "../../../_core/MetronicLayout";
import objectPath from "object-path";
import {Dropdown} from "react-bootstrap";
import {DropdownToggler} from "../../../../../pages/Dashboard/components/DropdownGroupItemToggler";
import {DropdownTopbarItemToggler} from "../../../../_partials/dropdowns";
import {useDispatch} from "react-redux";
import {
    clientSelectAction,
    propertyLocationSelectAction,
    propertySelectAction,
    serviceSelectAction
} from "../../../../../../redux/actions/selectActions";

const AdminSyncDropdown: React.VFC = () => {
    const dispatch = useDispatch()
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            offcanvas:
                objectPath.get(uiService.config, "extras.adminSync.layout") ===
                "offcanvas",
        };
    }, [uiService]);

    // TODO: Dispatch each menu

    return (
        <>
            {layoutProps.offcanvas && (
                <div className="topbar-item">
                    <div
                        className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
                        id="kt_quick_notifications_toggle"
                    >
                        <span className="svg-icon svg-icon-xl svg-icon-primary">
                          <i className="fas fa-sync"/>
                        </span>
                    </div>
                </div>
            )}
            {!layoutProps.offcanvas && (
                <Dropdown drop="down">
                    <Dropdown.Toggle
                        as={DropdownTopbarItemToggler}
                        id="kt_quick_notifications_toggle"
                    >
                        <div
                            className="btn btn-clean btn-lg mr-4"
                            id="kt_quick_notifications_toggle"
                        >
                            <i className="fas fa-sync icon-lg text-muted"/>
                            <span className="text-muted font-weight-bold py-1">Sync</span>
                        </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                        className="navi navi-hover py-4"
                    >
                        <Dropdown.Item
                            className="navi-item"
                            as={DropdownToggler}
                            onClick={() => {
                                // TODO: Add Service Listing dispatch later!
                            }}
                        >
                            <div className="navi-link">
                                <span style={{ width: '20px'}} className="symbol symbol-20 mr-3">
                                  <i className="fas fa-flag"/>
                                </span>
                                <span className="navi-text">
                                  Service Types
                                </span>
                            </div>
                        </Dropdown.Item>

                        <Dropdown.Item
                            className="navi-item"
                            as={DropdownToggler}
                            onClick={() => dispatch(serviceSelectAction())}
                        >
                            <div className="navi-link">
                                <span style={{ width: '20px'}} className="symbol symbol-20 mr-3">
                                  <i className="fas fa-flag-checkered"/>
                                </span>
                                <span className="navi-text">
                                  Service
                                </span>
                            </div>
                        </Dropdown.Item>

                        <Dropdown.Item
                            className="navi-item"
                            as={DropdownToggler}
                            onClick={() => dispatch(clientSelectAction())}
                        >
                            <div className="navi-link">
                                <span style={{ width: '20px'}} className="symbol symbol-20 mr-3">
                                  <i className="fas fa-users"/>
                                </span>
                                <span className="navi-text">
                                  Clients
                                </span>
                            </div>
                        </Dropdown.Item>

                        <Dropdown.Item
                            className="navi-item"
                            as={DropdownToggler}
                            onClick={() => dispatch(propertySelectAction())}
                        >
                            <div className="navi-link">
                                <span style={{ width: '20px'}} className="symbol symbol-20 mr-3">
                                  <i className="fas fa-home"/>
                                </span>
                                <span className="navi-text">
                                  Properties
                                </span>
                            </div>
                        </Dropdown.Item>

                        <Dropdown.Item
                            className="navi-item"
                            as={DropdownToggler}
                            onClick={() => {
                                dispatch(propertyLocationSelectAction())
                            }}
                        >
                            <div className="navi-link">
                                <span style={{ width: '20px'}} className="symbol symbol-20 mr-3">
                                  <i className="fas fa-map-marker-alt"/>
                                </span>
                                <span className="navi-text">
                                  Property Location
                                </span>
                            </div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </>
    )
}

export default AdminSyncDropdown