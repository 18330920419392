import React from "react";
import {SelectOperator} from "../../Selects";
import {FormGroupRow} from "../../Forms/FormGroupRow";

interface ToolbarOperatorProps {
    selectedOperator?: string;
    disable?: boolean;
    onOperatorSelected?: (operator: string) => void;
}

export const ToolbarOperator: React.FunctionComponent<ToolbarOperatorProps> = ({
    selectedOperator,
    onOperatorSelected,
    disable = false
}): JSX.Element => {

    return (
        <FormGroupRow label={"Operator"}>
            <SelectOperator
                value={selectedOperator}
                onChange={ onOperatorSelected }
            />
        </FormGroupRow>
    );
}
