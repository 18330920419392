import {ReduxBaseState} from "../../../models/ReduxBaseState";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {JobRequestAllFieldsData} from "../../../models/JobRequestAllFields";

export type JobRequestFieldReduxState = {
    fields: JobRequestAllFieldsData[]
} & ReduxBaseState;

const initialState: JobRequestFieldReduxState = {
    status: 'init',
    errorMessage: '',
    fields: [],
};

export const jobRequestFieldSlice = createSlice({
    name: 'jobRequestFields',
    initialState,
    reducers: {
        updateFieldItems: (state, payload: PayloadAction<JobRequestAllFieldsData[]>) => {
            state.fields = payload.payload;
            state.status = "success";
            state.errorMessage = "";
        },
        updateFieldItemsStatus: (state, payload: PayloadAction<ReduxBaseState>) => {
            state.status = payload.payload.status;
            state.errorMessage = payload.payload.errorMessage;
        },
        clearFieldState: () => initialState,
    },
});

export const {
    updateFieldItems,
    updateFieldItemsStatus,
    clearFieldState,
}  = jobRequestFieldSlice.actions;