/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {useEffect, useState} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Logout from "./pages/Auth/Logout";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

import {AuthPage} from './pages/Auth/AuthPage';
import {Layout} from "./layout/layout";
import {ApplicationState} from "../redux/rootReducer";
import Tour from 'reactour';
import {authSlice, disableTour} from "../redux/reducers/authRedux";

import {SearchModal, ShowSearchModalContext} from "./components/ShowSearchModalContext";

const BasePage = React.lazy(() => import("./BasePage"));

export function Routes() {
  const dispatch = useDispatch();

  const { isAuthorized, showTour, roles } = useSelector(
    ({ auth }: ApplicationState) => ({
      isAuthorized: auth?.token !== "",
      showTour: auth?.showTour,
      roles: auth?.user.role
    }),
    shallowEqual
  );

  // To Know when user logged in and have no roles
  // When there's no roles, force to Log in again
  const isHaveNoRole = roles.length === 0

  useEffect(() => {
    const isNotHaveRole = roles.length === 0
    if (isNotHaveRole || !isAuthorized) {
      dispatch(authSlice.actions.logout())
    }
  }, [roles])


  const [showModal, setShowModal] = useState(false);

  return (
    <Switch>
      {(!isAuthorized || (!isAuthorized && isHaveNoRole)) ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from="/login" to="/" />
      )}

      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from="/registration" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {(!isAuthorized) ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/login" />
      ) : (
          <>
            <Tour
              steps={ tourSteps }
              isOpen={showTour!}
              accentColor="#DF922F"
              onRequestClose={() => dispatch(disableTour())}
            />
            <ShowSearchModalContext.Provider value={{
              show: showModal,
              toggleShow: (show) => { setShowModal(show) }
            }}>
              <Layout>
                <BasePage />
              </Layout>
              <SearchModal />
            </ShowSearchModalContext.Provider>
          </>
      )}
    </Switch>
  );
}

const tourSteps = [
  {
      content: "Welcome to myPSR. Follow this quick tour to see how myPSR works."
  },
  {
    selector: ".__aside-menu",
    content: "This is the main menu and the one you will be using the most"
  },
  {
    selector: ".__tour-header",
    content: "This is the second menu which allows you to search, access your calendar, help and options"
  },
  {
    selector: ".__tour_client",
    content: "Your first step is to add clients, they can be commercial or private clients"
  },
  {
    selector: ".__tour_property",
    content: "Your second step is to add properties that are managed by your clients"
  },
  {
    selector: ".__tour_operator",
    content: "Now it's time to create your operators (contractors, tradies etc) these are the people doing the actual work"
  },
  {
    selector: ".__tour_service",
    content: "Add your services and service types"
  },
  {
    selector: ".__tour_job_request",
    content: "Now you can start creating your Job Requests"
  },
  {
    selector: ".__tour_admin_man",
    content: "You have the option of adding more admin accounts"
  },
  {
    selector: ".__tour_contact_us",
    content: "Click here to contact us if you have questions, issues or would like something added into myPSR"
  },
  {
    content: "We hope you love myPSR as much as we do!"
  }
]
