import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {call, put, takeLatest} from "redux-saga/effects";
import {getUsers} from "../../apis/AuthApi";
import {AxiosResponse} from "axios";
import {UserAdmin, UserInfoModel} from "../../models/UserInfoModel";
import {GetAccessToken} from "../../models/GetAccessTokenModel";
import {ReduxBaseState, ReduxBaseStateStatusType} from "../../models/ReduxBaseState";
import {newsGetAll} from "../../apis/NewsAPI";
import {NewsFeed} from "../../models/NewsFeed";
import {newsAddAll} from "./newsRedux";
import {ApiResponse, BaseApiResponse, BaseSearchParameterOrderBy} from "../../apis/BaseAPI";
import {adminPhoto} from "../../apis/AdminsAPI";
import {OperatorListingData} from "../../models/OperatorListing";
import {ClientLogData} from "../../models/ClientLogData";

export interface UserAuthState {
    userId: number;
    fullname: string;
    username: string;
    email: string;
    role: string[];
    adminId: number;
    admin?: UserAdmin;
    adminPhoto?: string;
    operator?: OperatorListingData
    clientlog?: ClientLogData,
}

export interface SanboxedAuthState {
    token: string;
    refreshToken: string,
    expire: number,
    user: UserAuthState,
}

export interface AuthState extends ReduxBaseState {
    token: string;
    refreshToken: string,
    expire: number,
    user: UserAuthState,
    showTour: boolean,
    adminFetchStatus: ReduxBaseStateStatusType
    adminFetchMessage: string,
    sandboxedAuth?: SanboxedAuthState,
    sandboxStatus?: ReduxBaseStateStatusType
}

const initialAuthState: AuthState = {
    token: "",
    refreshToken: "",
    expire: 0,
    user: {
        userId: 0,
        fullname: "",
        username: "",
        email: "",
        role: [],
        adminId: -1,
        admin: undefined,
        adminPhoto: "",
        operator: undefined,

    },
    sandboxedAuth: undefined,
    sandboxStatus: "init",
    showTour: false,
    status: "init",
    errorMessage: "",
    adminFetchStatus: "init",
    adminFetchMessage: ''
};

export const authSlice = createSlice({
    name: "auth",
    initialState: initialAuthState,
    reducers: {
        login: (state, action: PayloadAction<GetAccessToken>) => {
            state.token = action.payload.access_token;
            state.refreshToken = action.payload.refresh_token;
            state.expire = action.payload.expires_in;
            state.status = "success";
            state.errorMessage = "";
        },
        getUserInfo: (state, action: PayloadAction<UserAuthState>) => {
            state.user.username = action.payload.username;
            state.user.fullname = action.payload.fullname;
            state.user.email = action.payload.email;
            state.user.userId = action.payload.userId;
            state.user.role = action.payload.role;
            state.user.adminId = action.payload.adminId;
            state.user.admin = action.payload.admin;
            state.user.operator = action.payload.operator
            state.user.clientlog = action.payload.clientlog
        },
        refreshToken: (state, action: PayloadAction<{ newAccessToken: string }>) => {
            state.token = action.payload.newAccessToken;
            state.status = "success";
        },
        processLogin: (state) => {
            state.status = "loading";
            state.errorMessage = "";
        },
        errorLogin: (state, action: PayloadAction<{ errorMessage: string }>) => {
            state.status = "error";
            state.errorMessage = action.payload.errorMessage;
        },
        register: (state) => {
            state.showTour = true;
        },
        disableTour: (state) => {
            state.showTour = false;
        },
        updateAdminPhoto: (state, action: PayloadAction<{ base64Image: string }>) => {
            state.user.adminPhoto = action.payload.base64Image;
        },
        updateAdmin: (state, action: PayloadAction<UserAdmin>) => {
            state.user.admin = action.payload
        },
        updateAdminStatus: (state, action: PayloadAction<{status: ReduxBaseStateStatusType, message?: string}>) => {
            state.adminFetchStatus = action.payload.status
            state.adminFetchMessage = action.payload.message ?? ""
        },
        sandboxStatus: (state, action: PayloadAction<ReduxBaseStateStatusType>) => {
            state.sandboxStatus = action.payload
        },
        updateSandboxState: (state, action: PayloadAction<SanboxedAuthState>) => {
            state.sandboxedAuth = action.payload
        },
        clearSandboxState: (state) => {
            state.sandboxedAuth = undefined
            state.sandboxStatus = "init"
        },
        logout: () => {
            return initialAuthState;
        }
    }
});

export const {
    login,
    getUserInfo,
    processLogin,
    errorLogin,
    refreshToken,
    register,
    disableTour,
    logout,
    updateAdmin,
    updateAdminStatus,
    updateAdminPhoto,
    sandboxStatus,
    updateSandboxState,
    clearSandboxState,
} = authSlice.actions;

export function* saga() {
    yield takeLatest(login.type, function* (action: PayloadAction<GetAccessToken>) {
        const response: AxiosResponse<UserInfoModel> = yield call(getUsers, action.payload.access_token);
        const data = response.data;
        yield put(getUserInfo({
            fullname: data.user.username,
            email: data.user.email,
            username: data.user.username,
            userId: data.user.id,
            adminId: data.user.admin?.id ?? -1,
            role: data.user.roles,
            admin: data.user.admin,
            operator: data.user.operator,
            clientlog: data.user.clientlog
        }));
    });

    yield takeLatest(login.type, function* (_: PayloadAction<GetAccessToken>) {
        const [response,] = yield call(adminPhoto);
        yield put(updateAdminPhoto({ base64Image: response! }));
    });

    yield takeLatest(login.type, function*() {
        const searchParam: BaseSearchParameterOrderBy = {
            orderBy: [
                [
                    {"id": "id"},
                    {"asc": "false"}
                ],
                [
                    {"id": "title"},
                    {"asc": ""}
                ],
                [
                    {"id": "createdAt"},
                    {"asc": ""}
                ],
                [
                    {"id": "updatedAt"},
                    {"asc": ""}
                ],
                [
                    {"id": "isPublish"},
                    {"asc": ""}
                ]
            ]
        }

        const response: BaseApiResponse<NewsFeed> = yield call(newsGetAll, searchParam);
        const [data] = response
        yield put(newsAddAll(data!.newses));
    });
}
