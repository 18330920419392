import React from "react";
import { JobRequestDropdownDetailClient } from "./JobRequestDropdownDetailClient";
import { JobRequestDropdownDetailJobRequest } from "./JobRequestDropdownDetailJobRequest";
import { JobRequestDropdownDetailOperator } from "./JobRequestDropdownDetailOperator";
import { JobRequestDropdownDetailDetails } from "./JobRequestDropdownDetailDetails";
import { JobRequestDropdownDetailTimeTracker } from "./JobRequestDropdownDetailTimeTracker";
import { JobRequestDopdownDetailRecurringPattern } from "./JobRequestDopdownDetailRecurringPattern";
import {
  IJobRequestExtendedModel,
  IJobRequestModel,
} from "../../../models/IJobRequestResponse";

export interface IJobRequestDropdownDetail {
  jobRequestInfo?: IJobRequestExtendedModel;
}

export type IJobRequestDropdownDetailComponent = React.FC<{
  jobRequest?: IJobRequestExtendedModel;
  showDetails?: boolean;
  footer?: React.ReactNode;
  children?: React.ReactNode;
}> & {
  Client: typeof JobRequestDropdownDetailClient;
  JobRequest: typeof JobRequestDropdownDetailJobRequest;
  Operator: typeof JobRequestDropdownDetailOperator;
  TimeTracker: typeof JobRequestDropdownDetailTimeTracker;
  RecurringPattern: typeof JobRequestDopdownDetailRecurringPattern;
};

const JobRequestDropdownDetail: IJobRequestDropdownDetailComponent = (
  props
) => {
  const childWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      // @ts-ignore
      return React.cloneElement(child, { jobRequestInfo: props.jobRequest });
    }
    return child;
  });

  return (
    <>
      <div className="row mb-4">{childWithProps}</div>
      {props.showDetails && (
        <JobRequestDropdownDetailDetails
          details={props.jobRequest?.details ?? ""}
        />
      )}
      {props.footer}
    </>
  );
};

JobRequestDropdownDetail.Client = JobRequestDropdownDetailClient;
JobRequestDropdownDetail.JobRequest = JobRequestDropdownDetailJobRequest;
JobRequestDropdownDetail.Operator = JobRequestDropdownDetailOperator;
JobRequestDropdownDetail.TimeTracker = JobRequestDropdownDetailTimeTracker;
JobRequestDropdownDetail.RecurringPattern =
  JobRequestDopdownDetailRecurringPattern;

export default JobRequestDropdownDetail;
