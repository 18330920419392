import {configureStore} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import {reduxBatch} from "@manaflair/redux-batch";
import {persistStore} from "redux-persist";
import {reducers, rootSaga} from "./rootReducer";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  // reducer: persistReducers,
  reducer: reducers,
  middleware: (defaultMiddleware) => [
      ...defaultMiddleware({
          immutableCheck: false,
          serializableCheck: false,
          thunk: true
      }),
      sagaMiddleware,
  ],
  devTools: process.env.NODE_ENV !== "production",
  enhancers: [reduxBatch]
});

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store);
// export const persistor = persistReducer(rootPersistConfig, reducers);

sagaMiddleware.run(rootSaga);

export default store;
