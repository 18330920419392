const isProduction = true;

const ConfigDev = {
  REACT_APP_BASE_URL: "http://localhost:8080",
  REACT_APP_API_URL: "http://localhost:8080",
  REACT_APP_API_AUTH_URL: "/oauth/v2/token",
  REACT_APP_CLIENT_ID: "336_1z30nced2pc040gskg4s8go44sscgocwks0gkc084csskgk4so",
  REACT_APP_CLIENT_SECRET: "3b2a62l8bhmo0oscow8kgoos888c8os4gww0oc8g4oc8ckks4c",
  REACT_APP_GRANT_TYPE: "password",
  REACT_APP_TINY_MCE_KEY: "grk9606vo4g5ncohkn3uu24jfsx181fubmwthvnwfyvt6jfg",
  REACT_APP_GOOGLE_MAPS_KEY: "AIzaSyBckY_zDEnz6Rnu_gaotpUItzSp9Z5P_fo",
  REACT_APP_GOOGLE_MAPS_KEY_ALT: "AIzaSyB0DipBb9eWl9nz_M1TWe4ArJHvPwr6j3A",
};

const ConfigProd = {
  REACT_APP_BASE_URL: "https://staging.my-psr.com",
  REACT_APP_API_URL: "https://staging.my-psr.com",
  REACT_APP_API_AUTH_URL: "/oauth/v2/token",
  REACT_APP_CLIENT_ID: "336_1z30nced2pc040gskg4s8go44sscgocwks0gkc084csskgk4so",
  REACT_APP_CLIENT_SECRET: "3b2a62l8bhmo0oscow8kgoos888c8os4gww0oc8g4oc8ckks4c",
  REACT_APP_GRANT_TYPE: "password",
  REACT_APP_TINY_MCE_KEY: "grk9606vo4g5ncohkn3uu24jfsx181fubmwthvnwfyvt6jfg",
  REACT_APP_GOOGLE_MAPS_KEY: "AIzaSyBckY_zDEnz6Rnu_gaotpUItzSp9Z5P_fo",
  REACT_APP_GOOGLE_MAPS_KEY_ALT: "AIzaSyB0DipBb9eWl9nz_M1TWe4ArJHvPwr6j3A",
};

export const Configuration = {
  dev: {
    googleCalendar: {
      clientId:
        "918800085854-3r8u388liub7jev9e418argabi41nogo.apps.googleusercontent.com",
      apiKey: "AIzaSyAf6Sunh_7AB62Gc7Co1YVG7EYG2iUl0Co",
      scope: "https://www.googleapis.com/auth/calendar",
      discoveryDocs: [
        "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
      ],
    },
  },
  prod: {
    googleCalendar: {},
  },
};

export default isProduction ? ConfigProd : ConfigDev;
