import {Spinner} from "react-bootstrap";
import React from "react";

interface ButtonProps {
    isLoading?: boolean;
}

export const Button: React.FunctionComponent<ButtonProps & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>> = (
    {isLoading, children, ...rest}
): JSX.Element => {
    return (
        <button
            disabled={isLoading}
            {...rest}
        >
            {isLoading && (<span className="mr-2"><Spinner size="sm" animation="border"/></span>)}
            {children}
        </button>
    )
}