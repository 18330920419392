import {ActionCreator, Dispatch} from "redux";
import {ApplicationState} from "../rootReducer";
import {
    getClients,
    selectAdministrator,
    selectArchiveType,
    selectAttendancePropertyLocation,
    selectAttendancePropertyLocationAsset,
    selectGetProperty,
    selectInventory,
    SelectParam,
    selectService,
    selectServiceType,
    selectStockAsync,
    selectVendor
} from "../../apis/SelectAPI";
import {
    addAdministrator,
    addArchiveType,
    addAsset,
    addAttendanceTrackingProperty,
    addCertificate,
    addClient,
    addInventory,
    addOperator,
    addProperty,
    addPropertyLocation,
    addPropertyLocationAdmin,
    addService,
    updateArchiveType,
    addServiceType,
    addVendor
} from "../reducers/selectRedux";
import {attendanceTrackingPropertyLocationInventorySelect} from "../../apis/AttendanceAPI";
import {certificateListing} from "../../apis/CertificateAPI";
import {SelectItem} from "../../models/SelectItem";
import {operatorSelect, OperatorSelectParam} from "../../apis/OperatorAPI";

export {};

// Dispatching data first, when the data still available dont update the store

export const propertySelectAction = (clientId?: number, forceReload = false) => async (dispatch: Dispatch, getState: ActionCreator<ApplicationState>) => {
    const property = getState().select.property;

    // TODO: Create temp redux store for storing full items, so the property can be filtered

    // If the property select item is not empty or
    //      clientId is not undefined or
    //      clientId is not the same (the data is filtered with selected client, initial state is not have client id)
    //      Reload the Select Item Redux
    if (property.selectItems.length === 0 || clientId || (clientId !== property.selectedClientId) || forceReload) {
        dispatch(addProperty({
            status: "loading",
            selectItem: property.selectItems,
            tempSelectItem: property.selectItems
        }));

        const [selectItems, error] = await selectGetProperty(clientId);

        if (!error) {
            dispatch(addProperty({
                selectItem: selectItems!.options,
                tempSelectItem: clientId ? property.tempSelectItems : selectItems!.options,
                status: "success",
                selectedClientId: clientId
            }));
        } else {
            dispatch(addProperty({
                status: "error",
                selectItem: [...property.selectItems],
                tempSelectItem: property.selectItems,
                errorMessage: error
            }))
        }
    }
}

export const clientSelectAction = () => async (dispatch: Dispatch, getState: ActionCreator<ApplicationState>) => {
    const client = getState().select.client;

    if (client.selectItems.length === 0) {
        dispatch(addClient({
            status: "loading",
            selectItem: client.selectItems
        }));

        const [selectItems, error] = await getClients();
        if (!error) {
            dispatch(addClient({
                status: "success",
                selectItem: selectItems!
            }))
        } else {
            dispatch(addClient({
                status: "error",
                errorMessage: error,
                selectItem: [...client.selectItems]
            }))
        }
    }
}

export const administratorSelectAction = () => async (dispatch: Dispatch, getState: ActionCreator<ApplicationState>) => {
    const administrator = getState().select.administrator;

    if (administrator.selectItems.length === 0) {
        dispatch(addAdministrator({
            status: "loading",
            selectItem: administrator.selectItems
        }));

        const [selectItems, error] = await selectAdministrator();
        if (!error) {
            dispatch(addAdministrator({
                status: "success",
                selectItem: selectItems!
            }));
        } else {
            dispatch(addAdministrator({
                status: "error",
                selectItem: administrator.selectItems
            }))
        }
    }
}

export const serviceSelectAction = () => async (dispatch: Dispatch, getState: ActionCreator<ApplicationState>) => {
    const service = getState().select.service;

    if (service.selectItems.length === 0) {
        dispatch(addService({
            status: "loading",
            selectItem: service.selectItems
        }));

        const [selectItem, error] = await selectService();
        if (!error) {
            dispatch(addService({
                status: "success",
                selectItem: selectItem!
            }));
        } else {
            dispatch(addService({
                status: "error",
                selectItem: service.selectItems
            }))
        }
    }
}

export const serviceTypeSelectAction = () => async (dispatch: Dispatch, getState: ActionCreator<ApplicationState>) => {
    const service = getState().select.serviceType;

    if (service.selectItems.length === 0) {
        dispatch(addService({
            status: "loading",
            selectItem: service.selectItems
        }));

        const [selectItem, error] = await selectServiceType();
        if (!error) {
            dispatch(addServiceType({
                status: "success",
                selectItem: selectItem!
            }));
        } else {
            dispatch(addServiceType({
                status: "error",
                selectItem: service.selectItems
            }))
        }
    }
}

export const archiveTypeSelectAction = (adminId?: number) => async (dispatch: Dispatch, getState: ActionCreator<ApplicationState>) => {
    const archiveType = getState().select.archiveType;

    if (archiveType.selectItems.length === 0 || adminId || (adminId !== archiveType.selectedAdminId)) {
        dispatch(addArchiveType({
            status: "loading",
            selectItem: archiveType.selectItems
        }));

        const [selectItems, error] = await selectArchiveType({
            admin_id: adminId
        });
        if (!error) {
            dispatch(addArchiveType({
                status: "success",
                selectItem: selectItems!,
                selectedAdminId: adminId
            }));
        } else {
            dispatch(addArchiveType({
                status: "error",
                errorMessage: error,
                selectItem: archiveType.selectItems
            }));
        }
    }
}

export const archiveTypeSelectDeleteAction = (archiveTypeId: string) => async (dispatch: Dispatch, getState: ActionCreator<ApplicationState>) => {
    const archiveType = getState().select.archiveType;

    dispatch(
        updateArchiveType({
            selectItem: archiveType.selectItems.filter(item => item.value !== archiveTypeId)
        })
    )
}

export const assetSelectAction = (param?: SelectParam) => async (dispatch: Dispatch, getState: ActionCreator<ApplicationState>) => {
    const assets = getState().select.asset;

    if (assets.selectItems.length === 0) {
        dispatch(addAsset({
            status: "loading",
            selectItem: assets.selectItems
        }));

        const [selectItems, error] = await selectStockAsync(param);
        if (!error) {
            dispatch(addAsset({
                status: "success",
                selectItem: selectItems!
            }))
        } else {
            dispatch(addAsset({
                status: "error",
                selectItem: assets.selectItems,
                errorMessage: error
            }))
        }

    }
}

export const attendanceTrackingPropertySelectAction = (clientId?: string, forceReload = false) => async (dispatch: Dispatch, getState: ActionCreator<ApplicationState>) => {
    const atp = getState().select.attendanceTrackingProperty;

    if (atp.selectItems.length === 0 || clientId || clientId !== atp.selectedClientId || forceReload) {
        dispatch(addAttendanceTrackingProperty({
            status: "loading",
            selectItem: atp.selectItems
        }));

        const [selectItems, error] = await attendanceTrackingPropertyLocationInventorySelect({
            client_id: clientId,
            is_own_client: clientId ? 1 : 0,
        }, true);

        if (!error) {
            dispatch(addAttendanceTrackingProperty({
                status: "success",
                selectItem: selectItems!,
                selectedClientId: clientId
            }));
        } else {
            dispatch(addAttendanceTrackingProperty({
                status: "error",
                selectItem: atp.selectItems,
                errorMessage: error
            }))
        }
    }
}

export const certificateSelectAction = () => async (dispatch: Dispatch, getState: ActionCreator<ApplicationState>) => {
    const certificate = getState().select.certificate;

    if (certificate.selectItems.length === 0) {
        dispatch(addCertificate({
            status: "loading",
            selectItem: certificate.selectItems
        }));

        let totalPage = 1

        const [certificateListings, error] = await certificateListing();
        if (!error) {
            totalPage = Math.ceil(certificateListings!.total / certificateListings!.limit)

            let selectOptions: SelectItem[] = []

            selectOptions = certificateListings!.items.map(item => {
                return { value: item.id.toString(), label: item.name };
            });

            for (let i = 1; i < totalPage; i++) {
                try {
                    const result = await _fetchCertificate(i)
                    selectOptions = [...selectOptions, ...result]
                } catch (e) {
                    dispatch(addCertificate({
                        status: "error",
                        selectItem: certificate.selectItems,
                        errorMessage: error
                    }))
                }
            }

            dispatch(addCertificate({
                status: "success",
                selectItem: selectOptions
            }));
        } else {
            dispatch(addCertificate({
                status: "error",
                selectItem: certificate.selectItems,
                errorMessage: error
            }))
        }
    }
}

const _fetchCertificate = async (offset_page: number): Promise<SelectItem[]> => {
    const [certificateListings, error] = await certificateListing({offset_page});
    if (!error) {
        return certificateListings!.items.map(item => {
            return { value: item.id.toString(), label: item.name };
        });
    } else {
        throw new Error(error)
    }
}

export const certificateSelectActionAddSingle = (id: number, title: string) => (dispatch: Dispatch, getState: ActionCreator<ApplicationState>) => {
    const certificate = getState().select.certificate;
    dispatch(addCertificate({
        status: "success",
        selectItem: [{value: id.toString(), label: title}, ...certificate.selectItems]
    }));
}

export const inventorySelectAction = () => async (dispatch: Dispatch, getState: ActionCreator<ApplicationState>) => {
    const inventory = getState().select.inventory;

    if (inventory.selectItems.length === 0) {
        dispatch(addInventory({
            status: "loading",
            selectItem: inventory.selectItems
        }));

        const [selectItems, error] = await selectInventory();

        if (!error) {
            dispatch(addInventory({
                status: "success",
                selectItem: selectItems!,
            }))
        } else {
            dispatch(addInventory({
                status: "error",
                selectItem: inventory.selectItems,
                errorMessage: error
            }))
        }
    }
}

export const propertyLocationSelectAction = (propertyId?: number, clientId?: number, forceReload?: boolean) => async (dispatch: Dispatch, getState: ActionCreator<ApplicationState>) => {
    const propLocation = getState().select.propertyLocation;

    if (
        propLocation.selectItems.length === 0 ||
        propertyId ||
        clientId ||
        propertyId.toString() !== propLocation.selectedPropertyId ||
        clientId.toString() !== propLocation.selectedClientId ||
        forceReload
    ) {
        dispatch(addPropertyLocation({
            status: "loading",
            selectItem: propLocation.selectItems
        }));

        const [selectItems, error] = await selectAttendancePropertyLocation({
            client_id: clientId,
            property_id: propertyId
        });

        if (!error) {
            dispatch(addPropertyLocation({
                status: "success",
                selectItem: selectItems!,
            }))
        } else {
            dispatch(addPropertyLocation({
                status: "error",
                selectItem: propLocation.selectItems,
                errorMessage: error
            }))
        }
    }
}

export const propertyLocationAdminSelectAction = () => async (dispatch: Dispatch, getState: ActionCreator<ApplicationState>) => {
    const pLocationAdmin = getState().select.propertyLocationAdmin;

    if (pLocationAdmin.selectItems.length === 0) {
        dispatch(addPropertyLocationAdmin({
            status: "loading",
            selectItem: pLocationAdmin.selectItems
        }));

        const [selectItems, error] = await selectAttendancePropertyLocationAsset();
        if (!error) {
            dispatch(addPropertyLocationAdmin({
                status: "success",
                selectItem: selectItems!
            }))
        } else {
            dispatch(addPropertyLocationAdmin({
                status: "error",
                selectItem: pLocationAdmin.selectItems,
                errorMessage: error
            }))
        }
    }
}

export const vendorSelectAction = () => async (dispatch: Dispatch, getState: ActionCreator<ApplicationState>) => {
    const vendor = getState().select.vendor;

    if (vendor.selectItems.length === 0) {
        dispatch(addVendor({
            status: "loading",
            selectItem: vendor.selectItems
        }));

        const [selectItem, error] = await selectVendor();
        if (!error) {
            dispatch(addVendor({
                status: "success",
                selectItem: selectItem!
            }))
        } else {
            dispatch(addVendor({
                status: "error",
                selectItem: vendor.selectItems,
                errorMessage: error
            }))
        }
    }
}

/**
 * Add operator to Select Items redux
 */
export function operatorSelectAction(param?: OperatorSelectParam) {
    return async (dispatch: Dispatch, getState: ActionCreator<ApplicationState>) => {
        const oldOperator = getState().select.operator;

        const [operatorItem, error] = await operatorSelect(param);

        if (!error) {
            dispatch(addOperator({
                status: "success",
                selectItem: operatorItem!
            }))
        } else {
            dispatch(addOperator({
                status: "error",
                selectItem: oldOperator.selectItems,
                errorMessage: error
            }))
        }
    }
}
