/**
 * Certificate API
 * */
import {ApiResponse, APIResponse} from "./BaseAPI";
import {CertificateListing, Certificatetype} from "../models/CertificateListing";
import axios from "axios";
import {BaseResponse} from "../models/BaseModel/BaseResponse";
import {FormDataType, makeFormData, postToApi} from "../utils/APIHelper";

export interface CertificateListingFilter extends FormDataType {
    search_keyword?:     string;
    isSearch?:          boolean;
    offset_page?:       number;
}

export const certificateListing = (param?: CertificateListingFilter): ApiResponse<{ items: Certificatetype[], total: number, limit: number}> => {
    return postToApi<{ items: Certificatetype[], total: number, limit: number}, CertificateListing>({
        url: 'api/certificate/type/listings',
        data: param
    }, data => {
        return { items: data.certificatetypes, total: data.totals, limit: data.limit ?? 1 };
    })
}

export interface CertificateTypeNewParam extends FormDataType {
    name:       string;
    _token?:    string;
}

export const certificateCreateNew = (data: CertificateTypeNewParam, csrfToken: string, adminId?: string, idCertificate?: number): APIResponse<BaseResponse> => {
    const formData = makeFormData(data, "certificateType");
    formData.append("_csrf_token", csrfToken)
    formData.append('admin_id', adminId ?? "")

    const URL = idCertificate ? `api/certificate/type/${idCertificate}/edit` : `api/certificate/type/new`;

    return axios.post(URL, formData);
}

export const certificateDelete = (documentId: number): APIResponse<BaseResponse> =>
    axios.post(`/api/certificate/type/${documentId}/delete`, makeFormData({ id: documentId }));
