import axios, { AxiosResponse } from "axios";
import Config from "../api_config";
import { GetAccessToken } from "../models/GetAccessTokenModel";
import { LoginWithPasscode } from "../models/LoginWithPasscode";
import { APIResponse } from "./BaseAPI";
import { UserInfoModel } from "../models/UserInfoModel";
import { BaseResponse } from "../models/BaseModel/BaseResponse";
import { makeFormData } from "../utils/APIHelper";

const { REACT_APP_BASE_URL, REACT_APP_API_URL, REACT_APP_API_AUTH_URL, REACT_APP_CLIENT_ID, REACT_APP_CLIENT_SECRET, REACT_APP_GRANT_TYPE } = Config;

export function login(username: string, password: string): Promise<AxiosResponse<GetAccessToken>> {
	let params = {
		username: username,
		password: password,
		client_id: REACT_APP_CLIENT_ID,
		client_secret: REACT_APP_CLIENT_SECRET,
		grant_type: REACT_APP_GRANT_TYPE,
	};
	// Because some reason the local API is not using `/portal` sub-page
	// Consider to changing to `REACT_APP_API_URL` when pushing to staging/production!
	// TODO: Change later to `REACT_APP_API_URL`
	return axios.get(REACT_APP_API_URL + REACT_APP_API_AUTH_URL, {
		params,
	});
}

export function loginWithPasscode(passcode: string): Promise<AxiosResponse<LoginWithPasscode>> {
	return axios.post(REACT_APP_API_URL + "/api/users/passcode/mobile", {
		passcode,
	});
}

export function getUsers(accessToken: string): APIResponse<UserInfoModel> {
	return axios.post(
		"api/users/info",
		{},
		{
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		}
	);
}

export function forgotPassword(username: string) {
	return axios.post(REACT_APP_API_URL + "/api/resetting/send-email", {
		username,
	});
}

export function searchAccount(username: string) {
	let formData = new URLSearchParams();
	formData.append("search_account", username);
	formData.append("isSearch", "true");
	formData.append("offset_page", "0");

	return axios.post(/* REACT_APP_API_URL */ "https://newstaging.my-psr.com/portal/api/account/history", formData, {
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
		},
	});
}

export interface IRegisterAccount {
	firstName: string;
	lastName: string;
	email: string;
	username: string;
	password: string;
}

export function register(register: IRegisterAccount) {
	let data = new URLSearchParams();
	data.append("first_name", register.firstName);
	data.append("last_name", register.lastName);
	data.append("user_email", register.email);
	data.append("user_login", register.username);
	data.append("user_pass", register.password);

	return axios.post(REACT_APP_API_URL + "/api/register/free/plan", data, {
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
		},
	});
}

export function accountRecoverDeleted(accountId: number): APIResponse<BaseResponse> {
	return axios.post("api/account/delete/recover", makeFormData({ id: accountId }));
}
