import React from "react";

export const DropdownToggler = React.forwardRef((props, ref) => {
    return (
        <div
            ref={ref}
            className={ props.className }
            onClick={e => {
                e.preventDefault();
                props.onClick(e);
            }}
        >
            {props.children}
        </div>
    )
});

DropdownToggler.displayName = 'DropdownToggler';

export const DropdownGroupItemToggler = React.forwardRef((props, ref) => {
    return (
        <div
            ref={ref}
            className="btn btn-secondary dropdown-toggle"
            onClick={e => {
                e.preventDefault();
                props.onClick(e);
            }}
        >
            {props.children}
        </div>
    );
});

DropdownGroupItemToggler.displayName = 'DropdownGroupItemToggler';

export const DropdownGroupItemTogglerInside = React.forwardRef((props, ref) => {
    return (
        <div
            ref={ref}
            className="btn btn-secondary dropdown-toggle inside"
            onClick={e => {
                e.preventDefault();
                props.onClick(e);
            }}
        >
            {props.children}
        </div>
    );
});

DropdownGroupItemToggler.displayName = 'DropdownGroupItemTogglerInside';