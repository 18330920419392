import React from "react";

export interface IPagination2PageData {
	totalData: number;
	limit: number;
}

interface IPagination2Props {
	currentPage?: number;
	onPageClick?: (page: number) => void;

	pageData?: IPagination2PageData;
	isDisplayInformation?: boolean;
	isShowGoToFirstPage?: boolean;
	isShowGoToLastPage?: boolean;
	/** If set to `true` then when page change it will subtract with 1*/
	startFromZero?: boolean;
}

const MAX_PAGINATION_TO_DISPLAY = 5;

const Pagination2: React.FC<IPagination2Props> = ({ currentPage = 0, pageData = { totalData: 1, limit: 1 }, startFromZero = true, isDisplayInformation = true, isShowGoToFirstPage = true, isShowGoToLastPage = true, onPageClick }) => {
	const totalPage = Math.ceil(pageData.totalData / pageData.limit);

	// Return empty view when the total page is 0
	if (totalPage <= 1) {
		return <div />;
	}

	// Override default value when start from zero
	const realCurrentPage = startFromZero ? currentPage + 1 : currentPage;
	const realTotalPage = startFromZero ? totalPage - 1 : totalPage;

	const _onPageChange = (page: number) => {
		// Do nothing when page is the same
		if (page !== currentPage) {
			if (onPageClick) {
				onPageClick(page);
			}
		}
	};

	const _onPrevPage = (page: number) => {
		if (onPageClick) {
			if (page >= (startFromZero ? 0 : 1)) {
				onPageClick(page);
			}
		}
	};

	const _onNextPage = (page: number) => {
		if (page < totalPage && onPageClick) onPageClick(page);
	};

	let pages: number[] = [];
	let showStartEllipsis = false;
	let showEndEllipsis = false;

	if (realTotalPage > MAX_PAGINATION_TO_DISPLAY) {
		if (currentPage < MAX_PAGINATION_TO_DISPLAY - 1) {
			// When current page still at the start
			for (let i = startFromZero ? 0 : 1; i < MAX_PAGINATION_TO_DISPLAY; i++) {
				pages.push(i);
			}

			showEndEllipsis = true;
		} else if (currentPage + MAX_PAGINATION_TO_DISPLAY > realTotalPage) {
			// When current page almost at the end
			const start = realTotalPage - MAX_PAGINATION_TO_DISPLAY;

			for (let i = startFromZero ? start + 1 : start; i <= realTotalPage; i++) {
				pages.push(i);
			}
			showStartEllipsis = true;
		} else {
			// const center = Math.floor(currentPage / 2)
			let start = currentPage - 2;
			let end = currentPage + 2;

			for (let i = start; i < end; i++) {
				pages.push(i);
			}
			showStartEllipsis = true;
			showEndEllipsis = true;
		}
	} else {
		for (let i = startFromZero ? 0 : 1; i < totalPage; i++) {
			pages.push(i);
		}
	}

	return (
		<div className="d-lg-flex justify-content-between align-items-center">
			{isDisplayInformation && (
				<span>
					Data {currentPage * pageData?.limit + 1} - {currentPage * pageData?.limit + pageData!.limit} of {pageData!.totalData}, Page {realCurrentPage} of {totalPage}
				</span>
			)}
			<div className="d-flex justify-content-between align-items-center flex-wrap">
				<div className={`d-flex flex-wrap py-2 mr-3`}>
					{isShowGoToFirstPage && (
						<button type={"button"} onClick={() => _onPageChange(startFromZero ? 0 : 1)} className="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1">
							<i className="ki ki-bold-double-arrow-back icon-xs" />
						</button>
					)}
					<button type={"button"} onClick={() => _onPrevPage(currentPage - 1)} className="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1">
						<i className="ki ki-bold-arrow-back icon-xs" />
					</button>

					{showStartEllipsis && (
						<>
							{[0, 1].map((p) => (
								<button type={"button"} key={p} onClick={() => _onPageChange(p)} className={`btn btn-icon btn-sm border-0 btn-light ${currentPage === p ? " btn-hover-primary active" : ""} mr-2 my-1`}>
									{startFromZero ? p + 1 : p}
								</button>
							))}

							<button type={"button"} className="btn btn-icon btn-sm border-0 mr-2 my-1">
								...
							</button>
						</>
					)}

					{pages.map((p) => (
						<button type={"button"} key={p} onClick={() => _onPageChange(p)} className={`btn btn-icon btn-sm border-0 btn-light ${currentPage === p ? " btn-hover-primary active" : ""} mr-2 my-1`}>
							{startFromZero ? p + 1 : p}
						</button>
					))}
					{showEndEllipsis && (
						<>
							<button type={"button"} className="btn btn-icon btn-sm border-0 mr-2 my-1">
								...
							</button>

							{[realTotalPage - 1, realTotalPage].map((p) => (
								<button type={"button"} key={p} onClick={() => _onPageChange(p)} className={`btn btn-icon btn-sm border-0 btn-light ${currentPage === p ? " btn-hover-primary active" : ""} mr-2 my-1`}>
									{startFromZero ? p + 1 : p}
								</button>
							))}
						</>
					)}

					<button type={"button"} onClick={() => _onNextPage(currentPage + 1)} className="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1">
						<i className="ki ki-bold-arrow-next icon-xs"></i>
					</button>
					{isShowGoToLastPage && (
						<button type={"button"} onClick={() => _onPageChange(realTotalPage)} className="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1">
							<i className="ki ki-bold-double-arrow-next icon-xs"></i>
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default Pagination2;
