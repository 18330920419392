interface IStringPos {
    /**
     * Start word to slice
     */
    start: number,
    /**
     * End of sliced word position
     *
     * (Optional, when undefined it will choose end of word)
     */
    end?: number,

    /**
     * Count the end from the start word.
     */
    count?: number,
}

/**
 * Helper for used string function
 */
export const StringHelper = {
    /**
     * Performing slicing text by the word
     * @param text Text to sliced
     * @param range Range of start of end word need to be sliced
     * @param ellipsis Add ellipsis or not in the end of string
     *
     * @throws Error When `range.end` is less than start.
     * @throws Error when `range.end` is less than zero.
     * @throws Error When `range.count` is less than one.
     */
    sliceWord: (text: string, range: IStringPos, ellipsis = false): string => {
        if (range.end && range.count) {
            throw new Error("End and count cannot used at both time");
        }
        if ((range.end)  && (range.end < range.start)) {
            throw new Error("End cannot less than start");
        }
        if ((range.end) && (range.end < 1)) {
            throw new Error("End cannot less than zero");
        }
        if (range.count && ((range.count ?? 0) < 1)) {
            throw new Error("Range cannot less than one");
        }

        let end = text.length - 1;
        if (range.end) {
            end = range.end;
        }
        if (range.count) {
            end = range.start + range.count;
        }
        const exploded = text.split(" ");
        if (ellipsis && exploded.length < end) {
            return text;
        } else {
            const combinedText = exploded.slice(range.start, end).join(" ");
            return (ellipsis) ? combinedText + "..." : combinedText;
        }
    },

    /**
     * Make first letter uppercase.
     * @param text Text to uppercased
     */
    uppercaseFirst: (text: string): string => {
        return text.trim().charAt(0).toUpperCase() + text.slice(1);
    }
};
