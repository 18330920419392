import React, {useEffect} from 'react'
import {Dropdown} from "react-bootstrap";
import {DropdownToggler} from "../../../../../pages/Dashboard/components/DropdownGroupItemToggler";
import {DropdownTopbarItemToggler} from "../../../../_partials/dropdowns";
import {useHistory, useLocation} from "react-router-dom";
import {useUser} from "../../../../../Helpers/UserHelper";
import {Configuration} from "../../../../../../api_config";
import axios from "axios";

interface GoogleTokenResponse {
    access_token: string
    expires_in: number
    id_token: string
    refresh_token: string
    scope: string
    token_type: string
}

const OperatorCalendarSyncDropdown: React.VFC = () => {
    const history = useHistory()
    const {token} = useUser()
    const location = useLocation().search

    console.log(history.location.pathname, window.location.protocol)

    let code: string | undefined
    const googleOAuthResponse = new URLSearchParams(location)
    // state, code, scope, auth
    googleOAuthResponse.forEach((value, key) => {
        if (key === "code") code = value
    })

    useEffect(() => {
        if (code) {
            _listenForGoogleToken().then()
        }
    }, [code])

    const {clientId, scope} = Configuration.dev.googleCalendar

    // Stealing from `mypsr-mobile64`
    const _doSyncGoogleCalendar = async () => {
        const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;

        const options = {
            // TODO: Current URL
            redirect_uri: `${window.location.protocol}//${window.location.host}/calendar`,
            client_id: clientId,
            access_type: "offline",
            response_type: "code",
            prompt: "consent",
            scope: [
                "https://www.googleapis.com/auth/userinfo.profile",
                "https://www.googleapis.com/auth/userinfo.email",
            ].join(" "),
            state: "calendar",
        };

        console.log(options)

        const qs = new URLSearchParams(options);

        // return `${rootUrl}?${qs.toString()}`;
        window.open(`${rootUrl}?${qs.toString()}`, "_blank")
    }

    // Stealing from `mypsr-mobile64`
    const _doRemoveGoogleCalendar = () => {
        const url = `https://oauth2.googleapis.com/revoke?token=4/0AZEOvhUAuzPQ7vi5mNgM3RBH7Jii6tU2zSZyHH4KdB-_mLm7zqLWySkIGLh-J4yrCSx1Iw`
    }

    const _listenForGoogleToken = async () => {
        if (code) {
            let formData = new FormData()
            formData.append("code", code)
            formData.append("client_id", clientId)
            formData.append("client_secret", "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")
            formData.append("redirect_uri", `${window.location.protocol}//${window.location.host}${history.location.pathname}`)
            formData.append("grant_type", "authorization_code")

            const {status, data} = await axios.post<GoogleTokenResponse>(
                "https://oauth2.googleapis.com/token",
                formData,
                {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })
            if (status === 200) {
                console.log(data)
                // TODO: what should do after getting token?
            }
        }
    }

    return (
        <>
            <Dropdown drop="down">
                <Dropdown.Toggle
                    as={DropdownTopbarItemToggler}
                    id="aw_dropdown_calendar_sync"
                >
                    <div
                        className="btn btn-clean btn-lg px-4"
                        id="kt_quick_notifications_toggle"
                    >
                        <i className="fas fa-calendar icon-lg text-danger"/>
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu
                    className="navi navi-hover py-4"
                >
                    <Dropdown.Item
                        className="navi-item"
                        as={DropdownToggler}
                        onClick={() => history.push('/calendar')}
                    >
                        <div className="navi-link">
                            <span style={{ width: '20px'}} className="symbol symbol-20 mr-3">
                                <i className="fas fa-calendar text-primary"/>
                            </span>
                            <span className="navi-text">
                              View Calendar
                            </span>
                        </div>
                    </Dropdown.Item>

                    <Dropdown.Item
                        className="navi-item"
                        as={DropdownToggler}
                        onClick={() => _doSyncGoogleCalendar()}
                    >
                        <div className="navi-link">
                            <span style={{ width: '20px'}} className="symbol symbol-20 mr-3">
                                <i className="fas fa-sync text-success"/>
                            </span>
                            <span className="navi-text">
                              Sync to Google Calendar
                            </span>
                        </div>
                    </Dropdown.Item>

                    <Dropdown.Item
                        className="navi-item"
                        as={DropdownToggler}
                        onClick={() => _doRemoveGoogleCalendar()}
                    >
                        <div className="navi-link">
                            <span style={{ width: '20px'}} className="symbol symbol-20 mr-3">
                                <i className="fas fa-calendar-times text-danger"/>
                            </span>
                            <span className="navi-text">
                              Remove from Google Calendar
                            </span>
                        </div>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default OperatorCalendarSyncDropdown