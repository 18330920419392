import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./reducers/authRedux";
import * as operator from "./reducers/operatorRedux";
import * as dashboard from "./reducers/dashboardRedux";
import * as jobRequest from "./reducers/jobRedux";
import * as news from "./reducers/newsRedux";
import * as select from "./reducers/selectRedux";
import * as recurring from "./reducers/recurringRedux";
import * as jrField from "./reducers/jobRequestFieldRedux";
import * as temp from "./reducers/tempRedux";
import * as modules from "./reducers/moduleRedux";
import * as search from "./reducers/searchRedux";
import * as createJobRequestRedux from "./reducers/tempRedux/createJobRequestRedux";
import * as settingsPropertyLocation from "./reducers/settings/PropertyLocationRedux";
import * as settingsInventoryCategory from "./reducers/settings/InventoryCategoryRedux";
import * as settingsInventorySupplier from "./reducers/settings/InventorySupplierRedux";
import * as webConfig from "./reducers/webConfigRedux";
import * as jobRequestFields from "./reducers/tempRedux/jobRequestFieldRedux";
import * as cookieFilter from "../helpers/filtering/redux/cookieFilterRedux";

import * as sagas from "./sagas/saga";

import storage from "redux-persist/lib/storage";
import { createMigrate, persistReducer } from "redux-persist";
import { MigrationManifest, PersistConfig } from "redux-persist/es/types";

export function* rootSaga() {
  yield all([
    auth.saga(),
    dashboard.saga(),
    operator.saga(),
    jobRequest.saga(),

    sagas.saga(),
  ]);
}

const authPersistConfig = {
  key: "auth",
  version: 4,
  storage: storage,
};

const settingsReducers = combineReducers({
  propertyLocation:
    settingsPropertyLocation.settingsPropertyLocationSlice.reducer,
  inventoryCategory:
    settingsInventoryCategory.settingsInventoryCategorySlice.reducer,
  inventorySupplier:
    settingsInventorySupplier.settingsInventorySupplierSlice.reducer,
});
export interface ApplicationState {
  auth: auth.AuthState;
  operator: operator.OperatorState;
  dashboard: dashboard.DashboardState;
  job: jobRequest.JobRequestsState;
  news: news.NewsState;
  select: select.SelectState;
  jrRecurring: recurring.RecurringState;
  jrField: jrField.JobRequestFieldState;
  tempClient: temp.TempClient;
  tempProperty: temp.TempPropertyRedux;
  installedModules: modules.ModulesState;
  search: search.SelectReduxState;
  settings: any;
  tempCreateJobRequest: createJobRequestRedux.JobRequestReduxState;
  webConfig: webConfig.IWebConfigRedux;
  tempJobRequestFields: jobRequestFields.JobRequestFieldReduxState;
  cookieFilter: cookieFilter.ICookieFilterState;
}

const JobRequestMigration: MigrationManifest = {
  2: (state: any) => {
    return {
      ...state,
      overdue: {
        ...state.overdue,
        overdue: [],
      },
    };
  },
  3: (state: any) => {
    return {
      ...state,
      notInvoiced: {
        ...state.notInvoiced,
        notInvoice: [],
      },
    };
  },
  4: (state: any) => {
    return {
      ...state,
      dueToday: {
        ...state.dueTodays,
        dueTodays: [],
      },
      closedToday: {
        ...state.closedToday,
        closeToday: [],
      },
    };
  },
};

const JobRequestConfig: PersistConfig<jobRequest.JobRequestsState> = {
  key: "jobRequest",
  version: 4,
  storage,
  migrate: createMigrate(JobRequestMigration, { debug: true }),
};

export const reducers = combineReducers({
  auth: persistReducer(authPersistConfig, auth.authSlice.reducer),

  operator: persistReducer(
    {
      key: "operator",
      storage,
    },
    operator.operatorSlice.reducer
  ),

  dashboard: persistReducer(
    {
      key: "dashboard",
      storage,
    },
    dashboard.dashboardSlice.reducer
  ),

  job: persistReducer(JobRequestConfig, jobRequest.jobSlice.reducer),

  news: persistReducer({ key: "news", storage }, news.newsSlice.reducer),

  select: select.selectSlice.reducer,

  jrRecurring: recurring.recurringSlice.reducer,

  jrField: jrField.jobRequestFieldSlice.reducer,

  tempClient: temp.clientSlice.reducer,

  tempProperty: temp.propertySlice.reducer,

  installedModules: modules.moduleSlice.reducer,

  search: search.searchSlice.reducer,

  settings: settingsReducers,

  tempCreateJobRequest: createJobRequestRedux.createJobRequestRedux.reducer,

  webConfig: webConfig.webConfigSlice.reducer,

  tempJobRequestFields: jobRequestFields.jobRequestFieldSlice.reducer,

  cookieFilter: persistReducer(
    { key: "cookie-filter", storage },
    cookieFilter.cookieFilterRedux.reducer
  ),
});
