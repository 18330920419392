import React, {useState} from "react";
import {useFormik} from "formik";
import {Link} from "react-router-dom";
import * as Yup from "yup";
import {useIntl} from "react-intl";
import {LoginWrapper} from './components/LoginWrapper';
import IconFormField from "./components/IconFormField";
import BackButton from "./components/BackButton";
import {IFormikType} from "../../../models/FormikType";
import {forgotPassword} from "../../../apis/AuthApi";

const initialValues = {
    email: "",
};

export default function ForgotPassword() {
    const intl = useIntl();
    const [isRequested, setIsRequested] = useState(false);
    const ForgotPasswordSchema = Yup.object().shape({
        email: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
    });

    const getInputClasses = (fieldname: string) => {
        const touched = formik.touched as IFormikType;
        const errors = formik.errors as IFormikType;
        if (touched[fieldname] && errors[fieldname]) {
            return "is-invalid";
        }

        if (touched[fieldname] && !errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: ForgotPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            forgotPassword(values.email)
                .then(() => setIsRequested(true))
                .catch(error => {
                    let msg = "Please try again.. If you still get this message, please contact our Support.";
                    if (error.response)
                        if (error.response.status === 400) {
                            msg = error.response.data.error_description
                        }
                    setIsRequested(false);
                    setSubmitting(false);
                    setStatus(msg);
                });
        },
    });

    return (
        <LoginWrapper
            title="Forgot Password"
        >
            <BackButton />

            {isRequested && (
                <div className="mb-6 alert alert-custom alert-light-success alert-dismissible">
                    <div className="alert-text font-weight-bold">
                        Forogot Password Link already send to your email
                    </div>
                </div>
            )}

            <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            >
                {formik.status && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">
                            {formik.status}
                        </div>
                    </div>
                )}
                <div className="form-group fv-plugins-icon-container">
                    <label htmlFor="username_or_email">Username or Email</label>
                    <IconFormField
                        iconName="fa fa-user"
                    >
                        <input
                            type="email"
                            className={`form-default ${getInputClasses(
                                "email"
                            )}`}
                            {...formik.getFieldProps("email")}
                        />
                    </IconFormField>


                    {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.email}</div>
                        </div>
                    ) : null}
                </div>

                <div className="form-group d-flex flex-wrap flex-center">
                    <button
                        id="kt_login_forgot_submit"
                        type="submit"
                        className="bg-accent-color btn w-100 text-light font-weight-bold"
                        disabled={formik.isSubmitting}>
                        SUBMIT
                    </button>
                </div>

                <div className="dont-have-account text-center">
                    <p className="mb-0">Already have account</p>
                    <div className="sign-up-text">
                        <Link to="/login">SIGN IN</Link>
                    </div>
                </div>
            </form>
        </LoginWrapper>
    )
}
