import React from "react";

interface ToolbarSearchProp {
    title: string;
    name?: string;
    onValueChange?: (arg0: string) => void;
}

export const ToolbarSearch: React.FunctionComponent<ToolbarSearchProp> = ({
    title,
    name = "",
    onValueChange
}: ToolbarSearchProp): JSX.Element => {

    const _onValueChange = (e: any) => {
        if (onValueChange) {
            onValueChange(e.target.value);
        }
    }

    return (
        <div className="form-group row">
            <label htmlFor="search" className="col-3 col-form-label">{ title }</label>
            <div className="col-9">
                <input
                    type="text"
                    className="form-control"
                    name={name ?? title}
                    placeholder={ title }
                    onChange={ _onValueChange }
                />
            </div>
        </div>
    );
}
