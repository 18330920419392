import axios, {AxiosResponse} from "axios";
import {OperatorListing, OperatorListingData} from "../models/OperatorListing";
import {
    ApiResponse,
    APIResponse,
    BaseSearchParameterOrderBy,
    BaseSearchParameters,
    CreatedDateSearchParameter
} from "./BaseAPI";
import {OperatorSelect} from "../models/OperatorSelect";
import {BaseResponse, OperatorSaveResponse} from "../models/BaseModel/BaseResponse";
import {OperatorDocuments} from "../models/OperatorDocuments";
import {OperatorConfiguration} from "../models/OperatorConfiguration";
import {OperatorImportResponse} from "../models/OpeartorImportResponse";
import {FormDataType, makeFormData, postToApi} from "../utils/APIHelper";
import {SelectItemOperator} from "../models/SelectItem";
import {OperatorDetailResponse} from "../models/OperatorDetailResponse";
import {makeErrorMessage} from "../utils/Helpers";
import {ModulesModel} from "../models/ModulesModel";
import {OperatorCertificateTypeParam, OperatorCertificateTypeResponse} from "../models/OperatorCertificateTypeResponse";
import {
    OperatorDocumentDetailCertificate,
    OperatorDocumentDetailResponse
} from "../models/OperatorDocumentDetailResponse";


export interface OperatorSelectParam {
    offset_page?: number;
}

export function operatorSelect(param?: OperatorSelectParam): ApiResponse<SelectItemOperator[]> {
    return postToApi<SelectItemOperator[], OperatorSelect>({
        url: "api/operator/select",
        data: param
    }, (data) => {
        return data.operators.map((item) => {
            return {
                value: item.id.toString(),
                label: item.fullname_operator,
                propertyAccess: item.property_accesses
            }
        })
    })
}

export function operatorDetail(operatorId: number): ApiResponse<OperatorListingData> {
    return new Promise(resolve => {
        axios.get<OperatorDetailResponse>(`api/operator/${operatorId}/detail`).then(({ data }) => {
            if (data.success) resolve([ data.operator, undefined ]);
            else resolve([undefined, makeErrorMessage(data.messages)]);
        }).catch(err => resolve([undefined, err.toString()]));
    })
}

export interface OperatorListingParam extends BaseSearchParameterOrderBy {
    created_at?: CreatedDateSearchParameter;
}

export function getOperatorListing(searchParam?: OperatorListingParam): APIResponse<OperatorListing> {
    const params = {
        ...searchParam,
        orderBy: searchParam?.orderBy ?? ['Operator/updateAt/DESC']
    }
    return axios.post("api/operator/listings", makeFormData(params));
}

/// Begin create new operator
export interface OperatorCreateNewOperatorParam {
    operator: OperatorCreateNewOperatorDataParam;
    isPopUpNewOperator?: "0" | "1";
}

export interface OperatorCreateNewOperatorDataParam {
    firstname: string;
    lastname: string;
    email: string;
    address: string;
    suburb: string;
    postcode: string;
    state: string;
    country: string;
    phone: string;
    company: string;
    website: string;
    avatar?: {
        file?: File,
        delete?: number
    };
    category: string;
    username: string;
    password: string;
    token?: string;
}

export function operatorCreateNewOperator(data: OperatorCreateNewOperatorParam, csrfToken: string, operatorId?: number): APIResponse<OperatorSaveResponse> {
    const URL = operatorId ? `/api/operator/${operatorId}/edit/profile` : "api/operator/new";
    return axios.post(URL, makeFormData({...data, _csrf_token: csrfToken}));
}

export function operatorCreatePhotoUpload(operatorId: number, file: File): APIResponse<BaseResponse> {
    const formData = new FormData();
    formData.append("operator_id", operatorId.toString());
    formData.append("file", file);
    return axios.post("api/operator/photo/upload", formData);
}

export const operatorDocument = (operatorId: number, offset_page: number = 0, search_keyword: any, certificate_type_id: number): APIResponse<OperatorDocuments> => {
    const formData = new FormData();
    formData.append("offset_page", offset_page?.toString());
    if (search_keyword)
        formData.append("search_keyword", search_keyword?.toString());
    if (certificate_type_id)
        formData.append("certificate_type_id", certificate_type_id?.toString());

    return axios.post(`api/document/${operatorId}/operator`, formData);
}

export interface OperatorDocumentNewParam extends FormDataType {
    operator: string;
    certificatetypes: string;
    title: string;
    expire?: Date;
    avatar?: {
        file?: File,
        delete?: "1" | "0"
    };
}

export function operatorCreateDocument(param: OperatorDocumentNewParam, token: string, certificateId?: number): APIResponse<BaseResponse> {
    const formData = makeFormData(param, "certificate");
    formData.append('_csrf_token', token)
    formData.append('is_web', "1")
    const URL = certificateId ? `/api/document/${certificateId}/operator/edit` : `/api/document/operator/new`
    return axios.post(URL, formData);
}

export function operatorCreateCertificateType(certificateName: string, csrfToken: string): ApiResponse<OperatorCertificateTypeParam & {message?: string}> {
    return postToApi<OperatorCertificateTypeParam & { message?: string }, OperatorCertificateTypeResponse>({
        url: `/api/certificate/type/new.json`,
        data: {
            certificateType: { name: certificateName },
            _csrf_token: csrfToken,
            is_web: 1
        }
    }, (data, message) => {
        return {...data.param, message}
    })
}

export function operatorDocumentDetail(documentId: number): ApiResponse<OperatorDocumentDetailCertificate> {
    return postToApi<OperatorDocumentDetailCertificate, OperatorDocumentDetailResponse>({
        url: `/api/document/${documentId}/operator/detail.json`
    }, data => data.certificate)
}

// Operator Configuration
export function operatorConfiguration(operatorId: number): APIResponse<OperatorConfiguration> {
    return axios.get(`api/operator/${operatorId}/configuration`)
}

export function operatorConfigurationSave(operatorId: number, configurations: number[]): APIResponse<BaseResponse> {
    let formData = new FormData();
    configurations.forEach((config) => {
        formData.append("configurations[]", config.toString());
    })
    return axios.post(`api/operator/${operatorId}/configuration/save`, formData);
}

export function operatorDelete(operatorId: number): APIResponse<BaseResponse> {
    let formData = new FormData();
    formData.append("id", operatorId.toString());
    return axios.post(`operator/remove?id=${operatorId}`, formData);
}

export function operatorImportResponse(documentId: number): APIResponse<OperatorImportResponse> {
    let formData = new FormData();
    formData.append("fileId", documentId.toString());

    return axios.post("global/import/new/operator", formData);
}

export function operatorModules(filter?: BaseSearchParameterOrderBy): Promise<[ModulesModel?, string?]> {
    return new Promise<[ModulesModel?, string?]>((resolve) => {
        const data = makeFormData(filter as FormDataType);
        axios.post("api/modules/operator", data).then(({ data }: AxiosResponse<ModulesModel>) => {
            resolve([data, undefined]);
        }).catch((e) => resolve([undefined, e.toString()]));
    })
}

export interface IOperatorDocumentParams extends BaseSearchParameters {
    certificate_type_id?: string;
}

export function operatorDocuments(operatorId: number, params?: IOperatorDocumentParams): ApiResponse<OperatorDocuments> {
    return postToApi<OperatorDocuments, OperatorDocuments>({
        url: `/api/document/${operatorId}/operator.json`,
        data: params
    }, data => data)
}