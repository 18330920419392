import React, {ReactNode} from "react"
import {Spinner} from "../Spinner"

export interface CardDashboardHeaderProps {
    /** Title of the Card Wrapper */
    title: string,

    /** Card subtitle it will be placed bottom of title (optional) */
    subtitle?: string,

    /** Create bottom border for card header */
    withoutBorder?: boolean,

    /**
     * Display Toolbar placed on the right of card (inline with title)
     */
    toolbar?: NonNullable<ReactNode> | null,

    /** Show loading indicator */
    isLoading?: boolean,

    /**
     * Display badge number beside title (optional). __Default 0__
     */
     badgeNumber?: number,

    /**
     * Leading icon on the left side of title,
     *
     * <b>Use only icon name</b>
     *
     * Example:
     *      Font Awesome icon name: `fas fa-cog`.
     *      so, the parameter tobe passed is `cog`
     * */
    leadingIcon?: string,

    /** Hide only text on title */
    hideTitleText?: boolean;
}

export const CardDashboardHeader: React.FunctionComponent<CardDashboardHeaderProps> = ({
    title,
    subtitle,
    isLoading,
    toolbar,
    withoutBorder,
    leadingIcon,
    hideTitleText = false,
    badgeNumber = 0
}): JSX.Element => {
    return (
        <div className={`card-header ${ withoutBorder ? "border-0" : "" }`}>
            <h3 className="card-title">
                <div className="justify-content-center d-flex">
                    {!hideTitleText && (
                      <>
                        <span className="card-label font-weight-bolder text-dark d-block">
                            { leadingIcon && (
                                <span className="mr-2">
                                    <i className={`fas fa-${ leadingIcon } text-dark-75`} />
                                </span>
                            )}
                            {title}
                        </span>
                          {(badgeNumber > 0) && (
                              <span
                                  className="label label-md font-weight-bold label-rounded label-danger">{badgeNumber}</span>
                          )}

                          {subtitle && (
                              <span className="text-muted mt-3 font-weight-bold font-size-sm">{subtitle}</span>
                          )}
                      </>
                    )}
                </div>
            </h3>
            <div className="card-toolbar">
                { isLoading && (
                    <div className="px-4 mt-2 mr-4">
                        <Spinner
                            size="sm"
                        />
                    </div>
                )}

                { toolbar }
            </div>
        </div>
    )
}
