import {LoginWrapper} from "./components/LoginWrapper";
import BackButton from "./components/BackButton";
import React, {useState} from "react";
import {Divider} from "@material-ui/core";
import Swall from 'sweetalert2';
import {IRecoverAccount, IRecoverAccountData} from "../../../models/RecoverAccountType";
import {accountRecoverDeleted, searchAccount} from "../../../apis/AuthApi";
import {makeErrorMessage} from "../../../utils/Helpers";
import {useHistory} from "react-router-dom";
import {Alert} from "react-bootstrap";

const RecoverAccount = () => {
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [accounts, setAccoutns] = useState<IRecoverAccountData[]>([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    let timerId: NodeJS.Timeout;

    const onSearch = (e: any) => {
        const searchText: string = e.target.value;
        if (searchText.length > 1) {
            clearTimeout(timerId);

            timerId = setTimeout(() => {
                setSearchQuery(searchText);
                submitSearch(searchText)
            }, 500)
        }
    }

    const submitSearch = (username: string) => {
        setLoading(true);
        searchAccount(username)
            .then(response => {
                const data: IRecoverAccount = response.data;
                setAccoutns(data.accounts);
                setLoading(false);
            }).catch(() => {
                setErrorMessage("No account found");
                setLoading(false);
            });
    }

    const onRecoverClick = (id: number, name: string) => {
        Swall.fire({
            title: "Are you sure?",
            icon: "info",
            text: `Account to Recover "${name}"`,
            showCancelButton: true,
            confirmButtonText: "Recover Account",
            confirmButtonColor: '#2196F3',
            cancelButtonText: "Cancel",
            cancelButtonColor: "#9E9E9E",
            customClass: {
                confirmButton: 'ra-btn-ticket'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                onAccountRecover(id);
            } else {
                // Dismiss modal
            }
        });
    }

    const onAccountRecover = (accountId: number) => {
        Swall.showLoading();
        accountRecoverDeleted(accountId).then(({ data }) => {
            Swall.hideLoading();

            if (data.success) {
                Swall.fire({
                    title: "Success",
                    text: makeErrorMessage(data.messages),
                    icon: "success",
                    confirmButtonText: "OK",
                    showCloseButton: false,
                    showCancelButton: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        // redirect
                        history.replace("/login");
                    } else {
                        // Do nothing
                    }
                });
            } else {
                Swall.hideLoading();
            }
        }).catch(e => {
            Swall.fire(
                "Error",
                makeErrorMessage(e.toString()),
                "error"
            );
            Swall.hideLoading();
        })
    }

    return (
        <LoginWrapper
            title="Recover Account"
            sizeClass="wider"
            autoHeight={ true }
            forceTop={true}>

            <BackButton/>

            <div className="py-2">
                <p style={{ textAlign: 'center' }}>Please enter your Email Address, and we try find your account</p>
            </div>

            <div className="d-flex justify-content-center mt-2">
                <div className="form-group ra-input-wrapper w-75">
                    <label htmlFor="username_email">USERNAME OR EMAIL</label>

                    <div className="d-flex w-100">
                        <div className="form-icon-group d-flex align-items-center pl-4 w-100">
                            <i className="fa fa-search"/>
                            <div className="w-100 ml-4">
                                <input
                                    type="text"
                                    className="form-default"
                                    onChange={onSearch}
                                />
                            </div>
                        </div>
                        <button
                            onClick={() => submitSearch(searchQuery) }
                            className="btn btn-search bg-accent-color">SEARCH
                        </button>
                    </div>
                </div>
            </div>


            <Divider/>

            {errorMessage && (
                <div className="my-4">
                    <Alert variant="danger">{errorMessage}</Alert>
                </div>
            )}

            {loading && <div className="w-100 d-flex justify-content-center" style={{ marginTop: '24px' }}>
                <span className="spinner spinner-darker" />
            </div>}

            { accounts.map((val) => (
                <RecoverSearchItem
                    key={val.id}
                    accountData={ val }
                    onClick={(account) => {
                        onRecoverClick(account.id, account.name);
                    }}
                />
            ))}


        </LoginWrapper>
    )
}

export default RecoverAccount;

interface IRecoverSearchItemProp {
    accountData: IRecoverAccountData;
    onClick: (account: { id: number; name: string; }) => void;
}

const RecoverSearchItem = ({ accountData, onClick }: IRecoverSearchItemProp) => {
    let name = "";
    let image = "";
    let company = "";
    let email = "";
    let address = "";
    let userType = "";

    if (accountData.admin) {
        name        = accountData.admin.fullname;
        image       = accountData.admin.image_url;
        company     = accountData.admin.company ?? "";
        email       = accountData.admin.email;
        address     = accountData.admin.address ?? "";
        userType    = "Administrator";
    } else if (accountData.admin_from) {
        name        = accountData.admin_from.fullname;
        image       = accountData.admin_from.image_url;
        company     = accountData.admin_from.company ?? "";
        email       = accountData.admin_from.email;
        address     = "-";
        userType    = "Administrator?";
    } else if (accountData.admin_temporary) {
        name        = accountData.admin_temporary.fullname;
        image       = accountData.admin_temporary.image_url;
        company     = accountData.admin_temporary.company ?? "";
        email       = accountData.admin_temporary.email;
        address     = "-";
        userType    = "Administrator?";
    } else {
        return (<></>)
    }


    return (
        <div className="ra-item d-flex">
            <div className="ra-image mr-4">
                <img src={ image } alt={name}/>
            </div>

            <div className="ra-desc-wrapper d-sm-flex align-items-center ml-4 justify-content-between flex-grow-1">
                <div className="ra-description mb-4">
                    <div className="ra-user-type">{userType}</div>
                    <div className="ra-user-name">{name}</div>
                    <div className="ra-secondary darken">{company}</div>
                    <div className="ra-secondary">{email}</div>
                    <div className="ra-secondary">{address}</div>
                </div>

                <button
                    onClick={() => {
                        if (onClick) onClick({ id: accountData.id, name });
                    }}
                    className="btn ra-btn-ticket"
                >
                    <i className="fa fa-ticket-alt"/>
                    RECOVER
                </button>
            </div>
        </div>
    )
}
