import { ApiResponse, APIResponse, BaseSearchParameters } from "./BaseAPI";
import axios from "axios";
import { BaseResponse } from "../models/BaseModel/BaseResponse";
import { FormDataType, postToApi } from "../utils/APIHelper";
import { makeErrorMessage } from "../utils/Helpers";
import { InstalledModuleResponse } from "../models/InstalledModules";
import { IAdminProfileResponse } from "../models/IAdminProfileResponse";
import { IAdminProfileModel } from "../models/IAdminModel";
import { IAccountModulesResponseModel } from "../models/IAccountModulesResponseModel";
import { IAccountCheckPricingPlanResponseModel } from "../models/IAccountCheckPricingPlanResponseModel";
import { IAccountAdminPhotoResponseModel } from "../models/IAccountAdminPhotoResponseModel";
import { IAccountExportHistoryResponseModel } from "../models/IAccountExportHistoryResponseModel";
import { IAccountPasscodeResponseModel } from "../models/IAccountPasscodeResponseModel";
import { API } from "../helpers/api";

export function accountProfileAdmin(
  adminId?: number
): ApiResponse<IAdminProfileModel> {
  let formData = new FormData();
  formData.append("admin_id", adminId?.toString() ?? "");

  return postToApi<IAdminProfileModel, IAdminProfileResponse>(
    {
      url: "api/admins/profile",
      data: { admin_id: adminId?.toString() },
    },
    (data) => data.admin
  );
}

export function accountProfileModule(
  dontGetDefaultLimit: boolean = false
): APIResponse<IAccountModulesResponseModel> {
  let formData = new FormData();
  formData.append(
    "dont_get_default_limit",
    dontGetDefaultLimit ? "true" : "false"
  );

  return axios.post("api/modules/all", formData);
}

export function accountProfileCheckPricingPlan(
  adminId: number
): ApiResponse<IAccountCheckPricingPlanResponseModel> {
  return API().post(`check/${adminId}/pricing/plan`);
}

export function accountProfileAdminPhoto(): APIResponse<IAccountAdminPhotoResponseModel> {
  return axios.post("api/admins/photo");
}

// Export history
export function accountExportHistory(
  adminId: number
): APIResponse<IAccountExportHistoryResponseModel> {
  return axios.post(`api/account/export/${adminId}/history`);
}

export function accountExportHistoryData(): ApiResponse</* message */ string> {
  return new Promise((resolve) => {
    axios
      .post<BaseResponse>("api/account/export/execute")
      .then(({ data }) => {
        const msg = makeErrorMessage(data.messages);
        if (data.success) resolve([msg, undefined]);
        else resolve([undefined, msg]);
      })
      .catch((e) => resolve([undefined, e.toString()]));
  });
}

// Passcode
export function accountGeneratePasscode(
  passcode?: string
): APIResponse<IAccountPasscodeResponseModel> {
  let formData = new FormData();
  if (passcode) {
    formData.append("passcode", passcode);
  }
  return axios.post("api/passcode/create", formData);
}

// Profile
export interface ProfileParam {
  firstname: string;
  lastname?: string;
  address?: string;
  email: string;
  suburb?: string;
  postcode?: string;
  state?: string;
  country?: string;
  phone?: string;
  company?: string;
  company_email?: string;
  company_phone_number?: string;
  website?: string;
  description?: string;
  abn?: string;
  username: string;
  password: string;
  duedate?: string;
  account_base?: string;
  userlimit?: string;
  isTrial?: boolean;
  payment_type?: string;
  manual_payment?: string;
  fee_paid?: string;
  avatar?: { file: File };
}

export function accountProfileUpdate(
  accountData: ProfileParam,
  adminId: number
): ApiResponse<string> {
  return postToApi(
    {
      url: `api/admins/${adminId}/edit/profile`,
      data: accountData,
      config: { prefix: "admin" },
    },
    (data, message) => message!
  );
}

export interface AccountChangeLoginAccountData {
  username: string;
  password: string;
  repassword: string;
  current_password?: string;
  _token?: string;
}

interface AccountChangeLoginAccountParam extends FormDataType {
  isItPopUpUsernamePassword?: number;
  user: AccountChangeLoginAccountData;
}

export function accountChangeLoginAccount(
  data: AccountChangeLoginAccountParam,
  adminId: number
): ApiResponse<string> {
  return postToApi(
    {
      url: `api/users/${adminId}/username/password/change`,
      data,
    },
    (data1, message) => message!
  );
}

export interface AccountBankAccountEditData {
  account_name: string;
  bank_name: string;
  bsb_number: string;
  account_number: string;
  isEnable: number;
}

interface AccountBankAccountEditParam extends FormDataType {
  isItPopUpEditBankAccount: number;
  bank_account: AccountBankAccountEditData;
}

// TODO: Remove Validation csrf Token
export function accountBankAccountEdit(
  data: AccountBankAccountEditParam,
  adminId: number,
  token?: String
): ApiResponse<string> {
  return postToApi(
    {
      url: `/api/bank/account/form/${adminId}/edit`,
      data: { ...data, _csrf_token: token },
    },
    (data1, message) => message!
  );
}

export interface AccountModuleAdminUpdate {
  note: string;
  isActive: string;
  _token?: string;
}

export interface AccountModuleUpdateParam {
  isItPopUpSetupModuleAdmin?: "1" | "0";
  module_admin: AccountModuleAdminUpdate;
}

/**
 * Disable or Enable module on Account Settings
 * @param id Id of installed module
 * @param moduleId Id of Module
 * @param enable Enable or disabled module
 * @param token Token is get from Generated CSRF Token
 * @param adminId (Optional) Id of Super Administrator
 */
export function accountModulesUpdate(
  id: number,
  moduleId: number,
  enable: boolean = false,
  token?: string,
  adminId?: number
): ApiResponse</* message */ string> {
  return postToApi(
    {
      url: `/api/modules/admin/enable/disable/module.json`,
      data: {
        admin_id: adminId,
        id: id,
        addon_id: moduleId,
        val: enable ? "1" : "0",
        _csrf_token: token,
      },
    },
    (data, message) => message!
  );
}

export interface AccountCancelParam {
  isItPopUpCancelAccount?: "0";
  account_cancel: {
    admin_from: string;
    detail: string;
    // TODO: Remove CSRF token!
    _token?: string;
  };
}

export function accountCancel(
  param: AccountCancelParam
): ApiResponse</* message */ string> {
  return postToApi(
    {
      url: "cancel/account",
      data: {
        ...param,
        isItPopUpCancelAccount: "0",
      },
    },
    (data, message) => message!
  );
}

interface AccountDeleteParam {
  isItPopUpDeleteAccount?: "0" | "1";
  account_delete: {
    detail: string;
    is_agree: string;
    // TODO: Remove CSRF Token
    _token?: string;
  };
}

export function accountDelete(
  param: AccountDeleteParam
): ApiResponse</* message */ string> {
  return postToApi(
    {
      url: "delete/account",
      data: {
        ...param,
        isItPopUpDeleteAccount: "0",
      },
    },
    (data, message) => message!
  );
}

// Endpoint for editing bank account for Super Administrator
// FIXME: CSRF TOKEN
export function accountBankSuperAdmin(
  data: AccountBankAccountEditData
): ApiResponse<any> {
  return postToApi(
    {
      url: "/bank/account/mypsr/edit",
      data: {
        ...data,
        isItPopUpEditBankAccount: 0,
      },
      config: { prefix: "bank_account" },
    },
    (data) => data
  );
}

export interface AccountChangeUserPassword {
  username: string;
  current_password: string;
  password: string;
  repassword: string;
}

export const accountChangeUserPassword = (
  param: AccountChangeUserPassword,
  userId: number,
  token?: string
): ApiResponse<string> => {
  return postToApi(
    {
      url: `/api/users/${userId}/username/password/change.json`,
      data: {
        user: param,
        _csrf_token: token,
      },
    },
    (data, message) => message
  );
};

/// API for getting installed modules on admin
export const accountAdminInstalledModule = (
  param?: BaseSearchParameters
): ApiResponse<InstalledModuleResponse> => {
  return postToApi<InstalledModuleResponse, InstalledModuleResponse>(
    {
      url: `/api/modules/admin/installed/all`,
      data: {
        ...param,
        dont_get_default_limit: 1,
      },
    },
    (data) => data
  );
};

export const accountPasswordChange = (
  current_password: string,
  new_password: string,
  re_password: string
): ApiResponse<string> => {
  return postToApi<string, BaseResponse>(
    {
      url: "/api/users/password/change",
      data: { current_password, new_password, re_password },
    },
    (_, message) => message ?? ""
  );
};
