import React from "react";
import {useSelector} from "react-redux";
import {Link} from 'react-router-dom';
import {format} from "date-fns";
import {ApplicationState} from "../../../../../../redux/rootReducer";
import {StringHelper} from "../../../../../../helpers/StringHelper";

export function QuickPanel() {

    const {newses} = useSelector((state: ApplicationState) => state.news);

    return (
        <div id="kt_quick_panel" className="offcanvas offcanvas-right pb-10">
            {/*begin::Header*/}
            <div
                className="offcanvas-header bg-mypsr-primary offcanvas-header-navs d-flex align-items-center justify-content-between mb-5 py-4">

                <div className="p-4">
                    <div className="text-white font-size-h4 font-weight-bolder">What's New in myPSR</div>
                </div>

                <div className="offcanvas-close mt-n1 pr-5" style={{position: "absolute", top: "30px", right: "10px"}}>
                    <a
                        href="#"
                        className="btn btn-xs btn-icon btn-light btn-hover-primary"
                        id="kt_quick_panel_close"
                    >
                        <i className="ki ki-close icon-xs text-muted" />
                    </a>
                </div>
            </div>
            {/*end::Header*/}

            {/*begin::Content*/}
            <div className="offcanvas-content px-10">

                <div className="navi navi-icon-circle navi-spacer-x-0">
                    { newses.map((value, index) => (
                        <div key={index} className="navi-item border-bottom">
                            <div className="navi-link rounded">
                                <div className="symbol symbol-50 mr-3">
                                    <div className="symbol-label">
                                        <i className="flaticon-bell text-success icon-lg" />
                                    </div>
                                </div>
                                <div className="navi-text">
                                    <Link to={`/news/${value.id}/detail`}>
                                        <div className="font-weight-bold font-size-lg">
                                            {value.title}
                                        </div>
                                    </Link>
                                    <div className="text-muted mb-2">
                                        {StringHelper.sliceWord(value.strip_tags_details, { start: 0, end: 12}, true)}
                                    </div>
                                    <div className="text-muted font-size-xs">
                                        {format(new Date(value.updated_at), "dd MMMM yyyy HH:mm")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )) }

                </div>

            </div>
            {/*end::Content*/}
        </div>
    );
}
