import React from "react";

interface FormErrorMessageProps {
    errorMessage?: string;
}

export const FormErrorMessage: React.FC<FormErrorMessageProps> = ({ errorMessage }): JSX.Element => {

    return (
        <div className="">
            <span className="text-danger font-size-sm">{errorMessage}</span>
        </div>
    )
}
