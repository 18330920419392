import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ReduxBaseState} from "../../models/ReduxBaseState";
import {put, takeLatest} from "redux-saga/effects";
import * as auth from "./authRedux";
import {Property} from "../../models/SelectOptionPropertyModel";
import {SelectOptionServiceServices} from "../../models/SelectOptionService";
import {Client} from "../../models/ClientSelectModel";
import {OperatorListingData} from "../../models/OperatorListing";

// Create State
export interface OperatorOperatorState extends ReduxBaseState {
    nextOffsetPage: number;
    operators: OperatorListingData[];
    tempSelectedOperator?: OperatorListingData;
}

export interface OperatorClientState extends ReduxBaseState {
    nextOffsetPage: number;
    clients: Client[];
}

export interface OperatorPropertyState extends ReduxBaseState {
    nextOffsetPage: number;
    properties: Property[];
}

export interface OperatorServiceState extends ReduxBaseState {
    services: SelectOptionServiceServices[]
}

export interface OperatorState {
    operator: OperatorOperatorState,
    client: OperatorClientState,
    property: OperatorPropertyState
    services: OperatorServiceState
}

const initialState: OperatorState = {
    operator: {
        nextOffsetPage: 0,
        operators: [],
        status: "init",
        errorMessage: ""
    },
    client: {
        nextOffsetPage: 0,
        clients: [],
        status: "init",
        errorMessage: ""
    },
    property: {
        nextOffsetPage: 0,
        properties: [],
        status: "init",
        errorMessage: ""
    },
    services: {
        services: [],
        status: "init",
        errorMessage: ""
    }
}

export const operatorSlice = createSlice({
    name: "operator",
    initialState,
    reducers: {
        operatorStatus: (state, action: PayloadAction<ReduxBaseState>) => {
            state.operator.status = action.payload.status;
            state.operator.errorMessage = action.payload.errorMessage;
        },
        operatorUpdate: (state, action: PayloadAction<OperatorOperatorState>) => {
            state.operator = action.payload
        },

        operatorAddTemp: (state, action: PayloadAction<OperatorListingData>) => {
            state.operator.tempSelectedOperator = action.payload;
        },


        clientStatus: (state, action: PayloadAction<ReduxBaseState>) => {
            state.client.status = action.payload.status;
            state.client.errorMessage = action.payload.errorMessage;
        },
        clientUpdate: (state, action: PayloadAction<OperatorClientState>) => {
            state.client = action.payload
        },

        // Properties
        propertyStatus: (state, action: PayloadAction<ReduxBaseState>) => {
            state.property.status = action.payload.status;
            state.property.errorMessage = action.payload.errorMessage;
        },
        propertyUpdate: (state, action: PayloadAction<OperatorPropertyState>) => {
            state.property = action.payload;
        },

        serviceStatus: (state, action: PayloadAction<ReduxBaseState>) => {
            state.services.status = action.payload.status;
            state.services.errorMessage = action.payload.errorMessage;
        },
        serviceUpdate: (state, action: PayloadAction<OperatorServiceState>) => {
            state.services = action.payload;
        },

        clearData: () => initialState
    },
});

export const {
    operatorStatus,
    operatorUpdate,
    clientUpdate,
    clientStatus,
    clearData,
    propertyStatus,
    propertyUpdate,
    serviceStatus,
    serviceUpdate,
    operatorAddTemp
} = operatorSlice.actions;

export function* saga() {
    yield takeLatest(auth.logout.type, function* () {
        yield put(clearData());
    })
}
