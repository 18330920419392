import {useSelector} from "react-redux";
import {ApplicationState} from "../../redux/rootReducer";
import Constants from "../../resorces/Constants";

interface UserModuleHelperReturn {
    isCustomiseJobRequestEnabled: boolean
    isSiteTrackerEnabled: boolean
    isSiteAttendanceTrackingEnabled: boolean
    isJobAttendanceTrackingEnabled: boolean
    isMYOBEssentialEnabled: boolean
    isMYOBAccountRightLiveEnabled: boolean
    isQuoteEnabled: boolean
    isAssetEnabled: boolean
    isInventoryEnabled: boolean,
    isAutomateJobRequestEnabled: boolean,
    isMultiOperatorEnabled: boolean,

    MYOBEssentialID?: number
    MYOBAccountingRightLiveID?: number
}

/**
 * Hook for get Modules installed/enabled by User
 */
export const useModuleHelper = (): UserModuleHelperReturn => {
    const {auth: { user }, installedModules: modules} = useSelector((state: ApplicationState) => state)

    let isCustomiseJobRequestEnabled    = false
    let isSiteTrackerEnabled            = false
    let isSiteAttendanceTrackingEnabled = false
    let isJobAttendanceTrackingEnabled  = false
    let isMYOBEssentialEnabled          = false
    let isMYOBAccountRightLiveEnabled   = false
    let isQuoteEnabled                  = false
    let isAssetEnabled                  = false
    let isInventoryEnabled              = false
    let isAutomateJobRequestEnabled     = false
    let isMultiOperatorEnabled          = false


    let MYOBEssentialID             = undefined
    let MYOBAccountingRightLiveID   = undefined

    let addons = undefined
    if (user.admin) {
        if (user.admin.parent) {
            addons = user.admin.parent.addons
        } else {
            addons = user.admin.addons
        }
    }

    if (modules) {
        modules.installed.forEach((addon) => {
            const id = addon.addons_addons.id
            const isActive = addon.is_active ?? false

            if (id === Constants.Addons.CUSTOMISE_JOB_REQUEST_FIELDS && isActive) {
                isCustomiseJobRequestEnabled    = true
            }

            if (id === Constants.Addons.SITE_TRACKER && isActive) {
                isSiteTrackerEnabled = true
            }

            if (id === Constants.Addons.SITE_ATTENDANCE_TRACKING && isActive) {
                isSiteAttendanceTrackingEnabled = true
            }

            if (id === Constants.Addons.JOB_ATTENDANCE_TRACKING && isActive) {
                isJobAttendanceTrackingEnabled = true
            }

            if (id === Constants.Addons.QUOTES && isActive) {
                isQuoteEnabled = true
            }

            if (id === Constants.Addons.ASSET_MANAGEMENT && isActive) {
                isAssetEnabled = true
            }

            if (id === Constants.Addons.INVENTORY_MANAGEMENT && isActive) {
                isInventoryEnabled = true
            }

            if (id === Constants.Addons.MYOB_ESSENTIALS && isActive) {
                isMYOBEssentialEnabled  = true
                MYOBEssentialID         = addon.id
            }

            if (id === Constants.Addons.MULTI_OPERATOR) {
                isMultiOperatorEnabled = isActive;
            }

            if (id === Constants.Addons.AUTOMATE_JOB_REQUEST) {
                isAutomateJobRequestEnabled = isActive;
            }

            if (id === Constants.Addons.MYOB_ACCOUNTING_RIGHT_LIVE && isActive) {
                isMYOBAccountRightLiveEnabled   = true
                MYOBAccountingRightLiveID       = addon.id
            }
        })
    }

    return {
        isCustomiseJobRequestEnabled,
        isSiteTrackerEnabled,
        isSiteAttendanceTrackingEnabled,
        isJobAttendanceTrackingEnabled,
        isMYOBEssentialEnabled,
        isMYOBAccountRightLiveEnabled,
        isQuoteEnabled,
        isAssetEnabled,
        isInventoryEnabled,
        isAutomateJobRequestEnabled,
        isMultiOperatorEnabled,
        MYOBEssentialID,
        MYOBAccountingRightLiveID
    }
}
