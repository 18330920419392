import React from 'react'
import {Spinner} from 'react-bootstrap'

interface AWOverlayLoadingProps {
    show?: boolean
}

export const AWOverlayLoading: React.VFC<AWOverlayLoadingProps> = ({
    show = false
}) => {
    if (show) {
        return (
            <div style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(199, 199, 199, 0.4)',
                display: "flex",
                alignItems: "center",
                justifyContent: 'center',
                borderRadius: 4,
            }}>
                <Spinner animation={"grow"} color={`#DF922Fó`} />
            </div>
        )
    } else {
        return <></>
    }
}
