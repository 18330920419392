import React from "react";
import { TextIcon } from "../../pages/Dashboard/components/TextIcon";
import { IJobRequestExtendedModel } from "../../../models/IJobRequestResponse";

interface IJobRequestDropdownDetailJobRequest {
  jobRequestInfo?: IJobRequestExtendedModel;
}

export const JobRequestDropdownDetailJobRequest: React.VFC<
  IJobRequestDropdownDetailJobRequest
> = (props) => {
  const { jobRequestInfo } = props;

  return (
    <div className="col-lg-3 col-md-12">
      <div className="font-weight-bolder font-size-lg mb-2">Job Request</div>

      <hr />

      {!jobRequestInfo?.assign_to && (
        <>
          <TextIcon
            title={"Is Chargeable?"}
            fontAwesomeIcon={"dollar-sign"}
            textRight={"No"}
            darkIcon
          />

          <TextIcon
            title="Priority"
            textRight={jobRequestInfo?.priority}
            fontAwesomeIcon="bullhorn"
            darkIcon
          />

          <TextIcon
            title="Max Hours"
            textRight=""
            fontAwesomeIcon="clock"
            darkIcon
          />
        </>
      )}

      {jobRequestInfo?.assign_to &&
        jobRequestInfo?.assign_to.jobrequests_fields.map((field, index) => {
          if (field.id === "chargeable") {
            return (
              <TextIcon
                key={index}
                title="Is Chargeable?"
                textRight={jobRequestInfo?.chargeable ? "Yes" : "No"}
                fontAwesomeIcon="dollar-sign"
                darkIcon
              />
            );
          }

          if (field.id === "priority" && field.status) {
            return (
              <TextIcon
                key={index}
                title="Priority"
                textRight={jobRequestInfo?.priority}
                fontAwesomeIcon="bullhorn"
                darkIcon
              />
            );
          }

          if (field.id === "time_taken") {
            return (
              <TextIcon
                key={index}
                title="Hours taken to complete"
                textRight={jobRequestInfo["time_taken"] ?? ""}
                fontAwesomeIcon="clock-o"
                darkIcon
              />
            );
          }

          if (jobRequestInfo?.value && field.id === "value" && field.status) {
            return (
              <TextIcon
                key={index}
                title="Max Value"
                textRight={jobRequestInfo?.value.toString()}
                fontAwesomeIcon="dollar-sign"
                darkIcon
              />
            );
          }

          if (field.id === "hrs" && field.status) {
            let hours = "";
            if (jobRequestInfo?.hrs) {
              hours =
                jobRequestInfo?.hrs > 0 ? jobRequestInfo?.hrs.toString() : "";
            }
            return (
              <TextIcon
                key={index}
                title="Max Hours"
                textRight={hours}
                fontAwesomeIcon="clock"
                darkIcon
              />
            );
          }

          return <div key={index} />;
        })}

      {jobRequestInfo?.jobrequest_fields &&
        jobRequestInfo?.jobrequest_fields.map((field, index) => {
          if (field.jr_fields) {
            const {
              category,
              for_role,
              is_active,
              is_show,
              description,
              title,
              details,
              content_select,
            } = field.jr_fields;
            if (
              category === "Text" &&
              for_role === "Request" &&
              is_active &&
              is_show
            ) {
              return (
                <TextIcon
                  key={field.id}
                  title={title}
                  textRight={details ?? ""}
                  fontAwesomeIcon="ticket-alt"
                  darkIcon
                />
              );
            } else if (
              category === "Choice" &&
              for_role === "Request" &&
              is_active &&
              is_show
            ) {
              return content_select.map((sel) => (
                <TextIcon
                  key={sel.id}
                  title={description}
                  textRight={sel.val ?? ""}
                  fontAwesomeIcon="ticket-alt"
                  darkIcon
                />
              ));
            } else {
              return <div key={index} />;
            }
          } else {
            return <div key={index} />;
          }
        })}
      <TextIcon
        title={"Reference Numer"}
        textRight={jobRequestInfo.ref_no}
        fontAwesomeIcon="ticket-alt"
        darkIcon
      />
    </div>
  );
};
