import {useParams} from "react-router-dom"
import BackButton from "./components/BackButton";
import {LoginWrapper} from "./components/LoginWrapper";
import * as Yup from "yup";
import {useFormik} from "formik";
import {IFormikType} from "../../../models/FormikType";
import {useState} from "react";

const formInitialValue = {
    password: "",
    confirmPassword: ""
}

export const ResetPassword = () => {
    const { resetToken }: { resetToken: string } = useParams();

    const [loading, setLoading] = useState(false);

    const resetPasswordSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, "Minimum 5 Characters")
            .required(),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], "Password does not match")
            .required()
    });

    const formik = useFormik({
        initialValues: formInitialValue,
        validationSchema: resetPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setSubmitting(true);
        }
    })

    const getInputClasses = (fieldName: string) => {
        const touched = formik.touched as IFormikType;
        const errors = formik.errors as IFormikType;
        if (touched[fieldName] && errors[fieldName]) {
            return "is-invalid";
        }

        if (touched[fieldName] && !errors[fieldName]) {
            return "is-valid";
        }

        return "";
    }

    const printErrorMessage = (fieldName: string) => {
        const touched = formik.touched as IFormikType;
        const errors = formik.errors as IFormikType;
        if (touched[fieldName] && errors[fieldName]) {
            return (
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{errors[fieldName]}</div>
                </div>
            );
        }

        return "";
    }

    return (
        <LoginWrapper
            title="RESET PASSWORD"
        >
            <>
                <BackButton />

                <form onSubmit={ formik.handleSubmit }>
                    <div className="form-group">
                        <label htmlFor="username_or_email">Password</label>
                        <input type="password"
                                className={`form-control ${getInputClasses("password")}`}
                                placeholder="Password"
                                {...formik.getFieldProps("password")}
                            />
                        { printErrorMessage("password") }
                    </div>
                    <div className="form-group">
                        <label htmlFor="username_or_email">Confirm New Password</label>
                        <input
                            type="password"
                            className={`form-control ${getInputClasses("confirmPassword")}`}
                            placeholder="Confirm Password"
                            {...formik.getFieldProps("confirmPassword")}
                        />
                        { printErrorMessage("confirmPassword") }
                    </div>
                    <div className="form-group button">
                            { !loading ? (
                                <button
                                    type="submit"
                                    className="bg-accent-color btn w-100 text-light font-weight-bold mb-6"
                                >
                                    RESET PASSWORD
                                </button>
                            ) : (
                                <div className="w-100 d-flex justify-content-center" style={{ marginTop: '24px' }}>
                                    <span className="spinner spinner-darker" />
                                </div>
                            ) }

                        </div>
                </form>
            </>
        </LoginWrapper>
    )
}
