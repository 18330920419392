import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IJobRequestExtendedModel} from "../../models/IJobRequestResponse";

export interface RecurringState {
    recurring?: IJobRequestExtendedModel
}

const initialState: RecurringState = {
    recurring: undefined
};

export const recurringSlice = createSlice({
    name: "JRRecurringSlice",
    initialState,
    reducers: {
        addTempRecurring: (state, action: PayloadAction<IJobRequestExtendedModel>) => {
            state.recurring = action.payload;
        },

        clear: () => initialState
    }
})

export const {
    addTempRecurring,
    clear
} = recurringSlice.actions;
