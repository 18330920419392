import {ReduxBaseState} from "../../../models/ReduxBaseState";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface PropertyLocationReduxState extends ReduxBaseState {
    totalPage: number | string;
    currentPage: number | string;
    totalData: number | string;
    limit: number | string;
    date: any;
    isLoading: boolean;
    searchKeyword: string | any;
    
    isAdvancedSearch: boolean;
    advancedSearchKeyword: string | any;
    clientId: number | string;
    propertyId: number | string;
}

const initialState: PropertyLocationReduxState = {
    status: 'init',
    errorMessage: undefined,
    
    totalPage: 0, // default
    currentPage: 0, // default
    totalData: 0, // default
    limit: 0, // default
    date: new Date(),
    isLoading: false,
    searchKeyword: "", // default

    isAdvancedSearch: false, 
    advancedSearchKeyword: "", // default
    clientId: "", // default
    propertyId: "", // default
}

export const settingsPropertyLocationSlice = createSlice({
    initialState,
    name: 'settingsPropertyLocation',
    reducers: {
        setCurrentPage: (state, action: PayloadAction<{newCurrentPage: number | string}>) => {
            state.currentPage = action.payload.newCurrentPage;
        },
        setIsLoading: (state, action: PayloadAction<{isLoading: boolean}>) => {
            state.isLoading = action.payload.isLoading;
        },
        setSuccessResponsePropertyLocation: (state, action: PayloadAction<{totalData: number | string, limit: number | string}>) => {
            state.totalData = action.payload.totalData;
            state.isLoading = false;
            state.limit = action.payload.limit;
        },
        setDefaultPropertyLocation: (state) => {
            state.totalPage = 0;
            state.currentPage = 0;
            state.totalData = 0;
            state.limit = 0;
            state.searchKeyword = "";
            state.advancedSearchKeyword = "";
            state.clientId = "";
            state.propertyId = "";
        },
        setSearchKeyword: (state, action: PayloadAction<{searchKeyword: string | any}>) => {
            state.searchKeyword = action.payload.searchKeyword;
        },
        setIsAdvancedSearch: (state, action: PayloadAction<{isAdvancedSearch: boolean}>) => {
            state.isAdvancedSearch = action.payload.isAdvancedSearch;
        },
        setAdvancedSearchKeyword: (state, action: PayloadAction<{advancedSearchKeyword: string | any}>) => {
            state.advancedSearchKeyword = action.payload.advancedSearchKeyword;
        },
        setClientId: (state, action: PayloadAction<{clientId: number | string}>) => {
            state.clientId = action.payload.clientId;
            state.propertyId = "";
        },
        setPropertyId: (state, action: PayloadAction<{clientId: number | string, propertyId: number | string}>) => {
            state.clientId = action.payload.clientId;
            state.propertyId = action.payload.propertyId;
        },
        refresh: (state) => {
            state.isLoading = true;
            state.date = new Date();
        },
        clear: () => initialState
    }
})

export const {
    setCurrentPage,
    setIsLoading,
    setSuccessResponsePropertyLocation,
    setDefaultPropertyLocation,
    setSearchKeyword,
    setIsAdvancedSearch,
    setAdvancedSearchKeyword,
    setClientId,
    setPropertyId,
    refresh,
    clear
} = settingsPropertyLocationSlice.actions