import {JobRequestSearchParameters, searchFindJobRequest} from "../../apis/SearchAPI";
import {ActionCreator, Dispatch} from "redux";
import {searchAddParams, searchAddResult, searchError, searchLoading} from "../reducers/searchRedux";
import {ApplicationState} from "../rootReducer";

export const searchFillParametersAction = (param: JobRequestSearchParameters) => (dispatch: Dispatch) => {
    dispatch(searchAddParams(param))
}

export const searchJobRequestAction = (param?: JobRequestSearchParameters) => async (dispatch: Dispatch, getState: ActionCreator<ApplicationState>) => {
    dispatch(searchLoading())

    const [response, error] = await searchFindJobRequest({
        ...getState().search.params,
        ...param
    })
    if (!error) {
        dispatch(searchAddResult({
            result: response!.requests,
            currentPage: param?.offset_page ?? 0,
            totalData: response!.total,
            limit: response!.limit
        }))
    } else {
        dispatch(searchError(error))
    }
}