import {IFormikType} from "../models/FormikType";
import {FormikProps} from "formik";
import {
    SearchToolbarCreatedDateData,
    SearchToolbarDueDateData
} from "../app/components/SearchToolbar/SearchToolbarCreatedDate";
import {CreatedDateSearchParameter, DueDateSearchParameter} from "../apis/BaseAPI";
import ApiConfig from "../api_config";
import {format} from "date-fns";
import {parseInt} from "lodash";

/**
 * Make error text.
 * Because on server sometimes it will be generated string or string of array,
 * make check first before displaying message (for safety purpose)
 *
 * @param {string | string[]} message Message from server
 * @return {string} Appeded Messages
 * */
export const makeErrorMessage = (message: string | string[] | { [key: string]: string }): string => {
    let appendedMessage = "";
    if (Array.isArray(message)) {
        if (message.length > 1) {
            message.forEach((err) => appendedMessage += err + ', \n');
        } else {
            appendedMessage = message[0];
        }
    } else if (typeof message === "object") {
        Object.keys(message).forEach((key) => {
            appendedMessage += message[key] + ", \n";
        })
    } else {
        appendedMessage = message;
    }

    return appendedMessage;
}

/**
 * Adding error class when formik has an error.
 *
 * @param {FormikProps<any>} formik Formik props
 * @param {string} fieldName Field name to display error
 * @param {boolean} hasValid Show green border when validation true/valid (default `false`)
 * @param {boolean} withFormControl Append bootstrap `form-control` classes
 *
 * @return {string} added new error classes or not
 * */
export const formikErrorFormClass = (formik: FormikProps<any>, fieldName: string, hasValid = false, withFormControl = true): string => {
    let classess = withFormControl ? "form-control" : "";
    const touched = formik.touched as IFormikType;
    const errors = formik.errors as IFormikType;
    if (touched[fieldName] && errors[fieldName]) {
        classess += " is-invalid";
    }

    if (hasValid) {
        if (touched[fieldName] && !errors[fieldName]) {
            classess += " is-valid";
        }
    }

    return classess;
};

export const makeFormikErrorClass = (errorMessage?: any, touched?: any): string => {
    let classes = "form-control";
    if (errorMessage && touched) {
        classes += " is-invalid";
    }
    return classes;
}

/**
 * Harcoded day of week from integer to string
 * @param {number} numberOfDays Index of day
 * @return {string} weekday
 */
export const dayOfWeek = (numberOfDays: number): string => {
    switch (numberOfDays) {
        case 1:
            return "Sunday";
        case 2:
            return "Monday";
        case 3:
            return "Tuesday";
        case 4:
            return "Wednesday";
        case 5:
            return "Thursday";
        case 6:
            return "Friday";
        case 7:
            return "Saturday";
        default:
            return "-";
    }
}

export const convertDateFilter = (createdDateFilter?: SearchToolbarCreatedDateData): CreatedDateSearchParameter | undefined => {
    let filter: CreatedDateSearchParameter | undefined;
    if (createdDateFilter) {
        if (createdDateFilter.alias === "within_the_next" || createdDateFilter.alias === "within_the_last") {
            return {
                createdConfig: {
                    id: createdDateFilter.type,
                    value: createdDateFilter.type
                },
                createdNumber: createdDateFilter.within?.total,
                createdDays: {
                    id: createdDateFilter.within?.type,
                    value: createdDateFilter.within?.type

                },
                createdCurrentDate: createdDateFilter?.on,
                createdStartDate: createdDateFilter?.between?.start,
                createdEndDate: createdDateFilter?.between?.end,
            };
        }

        if (createdDateFilter.alias === "on") {
            return {
                createdConfig: {
                    id: 'on',
                    value: 'On'
                },
                createdNumber: "",
                createdDays: "",
                createdCurrentDate: createdDateFilter?.on,
                createdStartDate: createdDateFilter?.between?.start,
                createdEndDate: createdDateFilter?.between?.end,
            };
        }

        if (createdDateFilter.alias === "between") {
            return {
                createdConfig: {
                    id: 'between',
                    value: 'Between'
                },
                createdNumber: "",
                createdDays: "",
                createdCurrentDate: "",
                createdStartDate: createdDateFilter?.between?.start,
                createdEndDate: createdDateFilter?.between?.end,
            };
        }
    }
    return filter;
}

export const convertDueDateFilter = (dueDateFilter?: SearchToolbarDueDateData): DueDateSearchParameter | undefined => {
    let filter: DueDateSearchParameter | undefined;
    if (dueDateFilter) {
        if (dueDateFilter.alias === "within_the_next" || dueDateFilter.alias === "within_the_last") {
            return {
                dueConfig: {
                    id: dueDateFilter.type,
                    value: dueDateFilter.type
                },
                dueNumber: dueDateFilter.within?.total,
                dueDays: {
                    id: dueDateFilter.within?.type,
                    value: dueDateFilter.within?.type

                },
                dueCurrentDate: dueDateFilter?.on,
                dueStartDate: dueDateFilter?.between?.start,
                dueEndDate: dueDateFilter?.between?.end,
            };
        }

        if (dueDateFilter.alias === "on") {
            return {
                dueConfig: {
                    id: 'on',
                    value: 'On'
                },
                dueNumber: "",
                dueDays: "",
                dueCurrentDate: dueDateFilter?.on,
                dueStartDate: dueDateFilter?.between?.start,
                dueEndDate: dueDateFilter?.between?.end,
            };
        }

        if (dueDateFilter.alias === "between") {
            return {
                dueConfig: {
                    id: 'between',
                    value: 'Between'
                },
                dueNumber: "",
                dueDays: "",
                dueCurrentDate: "",
                dueStartDate: dueDateFilter?.between?.start,
                dueEndDate: dueDateFilter?.between?.end,
            };
        }
    }
    return filter;
}

/***
 * Created Date
 * @param data
 */
export const parseCreatedAtFilter = (data: SearchToolbarCreatedDateData): CreatedDateSearchParameter => {
    let _filter: CreatedDateSearchParameter = {};
    if (data.alias === "within_the_next" || data.alias === "within_the_last") {
        _filter = {
            createdConfig: { id: data.type! },
            createdNumber: data.within?.total,
            createdDays: { id: data.within?.type! }
        };
    }

    if (data.alias === "on") {
        _filter = {
            createdConfig: { id: data.type! },
            createdCurrentDate: data?.on
        };
    }

    if (data.alias === "between") {
        _filter = {
            createdConfig: { id: data.type! },
            createdStartDate: data?.between?.start,
            createdEndDate: data?.between?.end
        };
    }
    return _filter
}

/***
 * Due Date
 * @param data
 */
 export const parseDueAtFilter = (data: SearchToolbarCreatedDateData): DueDateSearchParameter => {
    let _filter: DueDateSearchParameter = {};
    if (data.alias === "within_the_next" || data.alias === "within_the_last") {
        _filter = {
            dueConfig: { id: data.type! },
            dueNumber: data.within?.total,
            dueDays: { id: data.within?.type! }
        };
    }

    if (data.alias === "on") {
        _filter = {
            dueConfig: { id: data.type! },
            dueCurrentDate: data?.on
        };
    }

    if (data.alias === "between") {
        _filter = {
            dueConfig: { id: data.type! },
            dueStartDate: data?.between?.start,
            dueEndDate: data?.between?.end
        };
    }
    return _filter
}

/**
 * Convert Blob data to Base64
 * @param blob {Blob} file blob to convert
 * @return {Promise<string>} asynchronous base64 string
 */
export const convertBlobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function () {
            const dataUrl = reader.result;
            if (dataUrl && typeof dataUrl === "string") {
                resolve(dataUrl);
            } else reject("Something wrong, please try again");
        };
        reader.readAsDataURL(blob);
    })
}

export interface ValueFromJobRequestFieldReturn {
    isChargeable: boolean;
    maxValue: string;
}

/**
 * Check the file response path, if the paths is not from `http`
 * then append the base API URL
 * @param url {string} URL to checked
 * @return {string} appended URL
 */
export const redirectUrl = (url?: string): string | undefined => {
    // Try to checking valid URL
    if (url) {
        try {
            const parsedURL = new URL(url)
            return parsedURL.toString()
        } catch (e) {
            return ApiConfig.REACT_APP_BASE_URL + url;
        }
    } else {
        return "error/error-v1"
    }
}

/**
 * Replace all html tags with empty string
 * @param text Text to be replaced
 * @return Replaced text
 */
export const stripHtmlTag = (text: string): string => {
    if (text) {
        return text.replace(/(<([^>]+)>)/gi, "");
    }
    return ""
}

/**
 * Generate Google Maps URL by address
 * @param address
 * @return {string} Full google maps URL
 */
export const constructMapsURL = (address: string): string => {
    let searchParam = new URLSearchParams()
    searchParam.append('api', "1")
    searchParam.append('query', address)

    return `//www.google.com/maps/search/?${searchParam.toString()}`
}

/**
 * Check if the array contains element
 * @param items Array to check
 * @param value value to find
 * @return {boolean} if item available
 */
export function arrayContains<T>(items: T[], value: T): boolean {
    return items.indexOf(value) > -1
}
