import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";

// Create custom date component
interface AWDatePickerProps<T> {
    name?: string;
    selected?: T;
    onChange?: (date: T) => void;
}

type AWDatePickerComponent<T> = React.VFC<AWDatePickerProps<T>> & {
    Date: typeof AWDatePickerDate
}

/**
 * Create custom date picker using timestamp as state value
 * because, when using multiple `DatePicker` when re-creating
 * state, the other date picker status changed
 */
const AWDatePicker: AWDatePickerComponent<number> = ({
    name,
    selected,
    onChange
}): JSX.Element => {

    const [selectedDate, setSelectedDate] = useState<number | undefined>();

    const _onChange = (date: Date) => {
        setSelectedDate(date.getTime());
        if (onChange) onChange(date.getTime())
    };

    useEffect(() => {
        if (selected) setSelectedDate(selected);
    }, [selected]);

    return (
        <DatePicker
            name={name}
            selected={selectedDate ? new Date(selectedDate) : new Date()}
            onChange={_onChange}
            dateFormat="dd-MM-yyyy"
            className="form-control"
        />
    )
}

const AWDatePickerDate: React.VFC<AWDatePickerProps<Date>> = (props): JSX.Element => {

    const {
        name,
        selected,
        onChange
    } = props

    const [selectedDate, setSelectedDate] = useState<Date | undefined>();

    const _onChange = (date: Date) => {
        setSelectedDate(date);
        if (onChange) onChange(date)
    };

    useEffect(() => {
        if (selected) setSelectedDate(selected);
    }, [selected]);

    return (
        <DatePicker
            name={name}
            selected={selectedDate ? new Date(selectedDate) : new Date()}
            onChange={_onChange}
            dateFormat="dd-MM-yyyy"
            className="form-control"
        />
    )
}
AWDatePicker.Date = AWDatePickerDate
export default AWDatePicker;
