import React from "react";
import {Nav, NavDropdown} from "react-bootstrap";

export interface INavToolbarTabNavigation {
    name: string;

    /** Event key for bootstrap */
    eventKey: string;

    /** Font awesome icon suffix */
    fasIcon?: string;

    inner?: INavToolbarTabNavigation[];
}

interface NavToolbarTabProps {
    type?: "pills" | "tabs" | "bs";
    dense?: boolean;
    navigations: INavToolbarTabNavigation[];
}

export function NavToolbarTab({ navigations, type = "pills", dense = false }: NavToolbarTabProps): JSX.Element {

    let navType: "pills" | "tabs" = type === "bs" ? "tabs" : type;

    return (
        <Nav variant={navType}>
            {navigations.map(value => {
                if (value.inner && Array.isArray(value.inner)) {
                    return (
                        <NavDropdown key={value.eventKey} title={(<>
                            {value.fasIcon && (
                                <span className="nav-icon">
                                <i className={`fas fa-${value.fasIcon}`}/>
                            </span>
                            )}
                            <span className="nav-text">{ value.name }</span>
                        </>)} id="nav-dropdown">
                            { value.inner.map((inner) => (
                                <NavDropdown.Item
                                    key={inner.eventKey}
                                    eventKey={inner.eventKey}
                                >
                                    {inner.fasIcon && (
                                        <span className="nav-icon">
                                            <i className={`fas fa-${inner.fasIcon} mr-2`}/>
                                        </span>
                                    )}
                                    {inner.name}
                                </NavDropdown.Item>
                            )) }
                        </NavDropdown>
                    )
                } else {
                    return (
                        <NavToolbarTabChild
                            key={value.eventKey}
                            name={value.name}
                            eventKey={value.eventKey}
                            fasIcon={value.fasIcon}
                            dense={dense}
                        />
                    )
                }
            }) }
        </Nav>
    )
}

const NavToolbarTabChild: React.FunctionComponent<INavToolbarTabNavigation & {dense?: boolean}> = ({
    name,
    eventKey,
    fasIcon,
    dense = false
}): JSX.Element => {
    return (
        <Nav.Item key={eventKey}>
            <Nav.Link eventKey={eventKey} className={`${dense && "px-2 py-3"}`}>
                {fasIcon && (
                    <span className="nav-icon">
                                <i className={`fas fa-${fasIcon}`}/>
                            </span>
                )}
                <span className="nav-text">{ name }</span>
            </Nav.Link>
        </Nav.Item>
    )
}
