import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../redux/rootReducer";
import {propertySelectAction} from "../../../redux/actions/selectActions";
import {useCallback, useEffect, useState} from "react";

export interface PropertySelectItem {
    value: string;
    label: string;
    address: string;
    clientId: string;
}

interface PropertySelectProps {
    clientId?: string | number;
    value?: string | number;
    onSelected?: (selectedId: string, address?: string, title?: string) => void;
    onItemSelected?: (item: PropertySelectItem) => void;
    disabled?: boolean;
    error?: boolean
}

export function SelectProperty({
    clientId,
    value,
    onSelected,
    onItemSelected,
    disabled = false,
    error = false
}: PropertySelectProps) {
    const dispatch = useDispatch();
    const { tempSelectItems, errorMessage, status } = useSelector((state: ApplicationState) => state.select.property);

    const itemNone: PropertySelectItem = {value: "", label: "Select Property", address: "", clientId: ""}

    const selectedItemsWithNone: PropertySelectItem[] = [itemNone, ...tempSelectItems];

    if (process.env.NODE_ENV === "development") {
        if (errorMessage) console.log("SELECT_PROPERTY_ERROR", errorMessage);
    }

    const [ items, setItems ] = useState<PropertySelectItem[]>(selectedItemsWithNone)
    const [ selectedItem, setSelectedItem ] = useState<PropertySelectItem | undefined | null>();

    // Load on clientIdChange
    useEffect(() => {
        if (clientId) {
            const filtered = tempSelectItems.filter(item => item.clientId === clientId.toString())
            // Append None?
            filtered.unshift(itemNone)
            setItems(filtered)
        } else {
            setItems(selectedItemsWithNone)
        }
    }, [clientId, tempSelectItems]);

    // execute on value change
    useEffect(() => {
        _onItemSelected(value?.toString());
    }, [value]);

    const _getPropertySelect = (selectedClientId?: number) => {
        dispatch(propertySelectAction(selectedClientId));
    }

    const _onItemSelected = useCallback((selected?: string | number) => {
        const found = tempSelectItems.find((item) => item.value === selected);
        setSelectedItem(found ?? null);
    }, [tempSelectItems]);

    return (
        <Select
            isLoading={status === "loading"}
            options={items}
            value={selectedItem}
            isDisabled={disabled}
            onChange={selectValue => {
                if (onSelected && selectValue) {
                    onSelected(selectValue.value, selectValue.address, selectValue.label);
                }
                if (onItemSelected && selectValue) {
                    onItemSelected(selectValue)
                }
            }}
            styles={{
                control: (styles) => {
                    return error
                        ? { ...styles, zIndex: 2, border: '1px solid red' }
                        : { ...styles, zIndex: 2 }
                },
                menu: (base) => {
                    return { ...base, zIndex: 3}
                }
            }}
        />
    )
}
