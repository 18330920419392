import {Dropdown} from 'react-bootstrap';
import {IJoinSearchType} from "../../../apis/DashboardApi";
import {DropdownGroupItemTogglerInside} from '../DropdownItem/DropdownGroupItemTogglerInside';

interface SearchToolbarJoinSearchProp {
    title?: string;
    value?: IJoinSearchType[];
    onValueChange?: (arg0: IJoinSearchType[]) => void;
}

export const InitialValueSearchToolbarJoinSearch: IJoinSearchType[] = [
    {
        searchQuery: "",
        type: ""
    }
];

export const SearchToolbarJoinSearch = ({
    value = [],
     onValueChange,
     title = "Search (and/or)"
 }: SearchToolbarJoinSearchProp): JSX.Element => {

    /**
     * Add new item on clicked *Add* Search term
     * append new `IJoinSearchType` with empty data,
     * and make old data (`joinSearch.length - 1`) to OR type search
     */
    const _onAddParamSearch = () => {
        let tempSearch = value;
        const lastIndex = value.length - 1;

        // Add newly search item
        tempSearch.push({
            searchQuery: "",
            type: ""
        });

        // Make default search to `OR` type
        tempSearch[lastIndex].type = "OR"

        // Update state
        setJoinSearch([...tempSearch]);
    }

    /**
     * Remove the search term on selected array index number
     * @param index Index to be deleted
     */
    const _onDeleteSearchItem = (index: number) => {
        let tempJoinSearch: IJoinSearchType[] = [];

        value.forEach((v, i) => {
            if (i !== index) {
                if (value.length - 1 === i) {
                    tempJoinSearch.push({
                        searchQuery: v.searchQuery,
                        type: ""
                    });
                } else {
                    tempJoinSearch.push(v);
                }
            }
        });

        setJoinSearch([...tempJoinSearch]);
    }

    /**
     * Change Joined search type by given array index
     * @param index Index of search term type to be updated
     * @param searchType search Type of joinen search query
     */
    const _onChangeSearchTermType = (index: number, searchType: "AND" | "OR" | "") => {
        // Change item on given index
        let itemToChange    = value[index];
        itemToChange.type   = searchType;

        // Replace item
        let tempJoinSearch      = value;
        tempJoinSearch[index]   = itemToChange;

        setJoinSearch([...tempJoinSearch]);
    }

    /**
     * Update text to search by given index
     * @param index Index to be updated
     * @param searchQuery text to search
     */
    const _onTextChange = (index: number, searchQuery: string) => {
        let tempJoinSearch: IJoinSearchType[] = value;

        let item = tempJoinSearch[index];
        item.searchQuery = searchQuery;

        setJoinSearch([...tempJoinSearch]);
    }


    /**
     * Send trigger to parent whenever data is changed
     */
    const setJoinSearch = (value: IJoinSearchType[]) => {
        if (onValueChange) {
            onValueChange(value)
        }
    }

    return (
        <div className="form-group row">
            <label htmlFor="search_query" className="col-3 col-form-label">
                { title }
            </label>
            <div className="col-9">
                {value.map((value, index) => (
                    <div key={index} className="input-group mb-2">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            value={ value.searchQuery }
                            onChange={(e) => _onTextChange(index, e.target.value) }
                        />
                        <div className="input-group-append">
                            { (value.type !== "") && (
                                <Dropdown

                                >
                                    <Dropdown.Toggle
                                        variant="success"
                                        id="joinSearch"
                                        as={DropdownGroupItemTogglerInside}
                                    >
                                        { value.type }
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={e => e.preventDefault()}
                                            onSelect={() => _onChangeSearchTermType(index, "AND") }
                                        >
                                            AND
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            // as="button"
                                            onClick={e => e.preventDefault()}
                                            onSelect={() => _onChangeSearchTermType(index, "OR") }
                                        >
                                            OR
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            ) }
                            { (value.type !== "") ? (
                                <span
                                    onClick={() => _onDeleteSearchItem(index) }
                                    className="input-group-text">
                                        CLEAR
                                    </span>
                            ) : (
                                <span
                                    onClick={() => _onAddParamSearch() }
                                    className="input-group-text">
                                    ADD
                                </span>
                            )}
                        </div>
                    </div>
                )) }
            </div>
        </div>
    )
}