import React from 'react'

export const JobRequestDropdownDetailDetails: React.VFC<{details: string}> = (props) => {
    return (
        <div className="row mb-4">
            <div className="col-lg-12">
                <div className="font-weight-bolder font-size-lg">Job Request Details</div>
                <hr className="my-3"/>

                <div dangerouslySetInnerHTML={{ __html: props.details }} />
            </div>
        </div>
    )
}