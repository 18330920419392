import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ReduxBaseState} from "../../models/ReduxBaseState";
import {put, takeLatest} from "redux-saga/effects";
import {logout} from "./authRedux";
import {clearData} from "./operatorRedux";
import {IJobRequestExtendedModel} from "../../models/IJobRequestResponse";

interface PaginatedData {
    totalPage:      number;
    currentPage:    number;
    totalData:      number;
    limit:          number;
}

export interface OverdueRequests extends ReduxBaseState, PaginatedData {
    overdue: {
        data: IJobRequestExtendedModel[];
        page: number;
    }[];
    overdues: IJobRequestExtendedModel[];
}

export interface NotInvoicedRequests extends ReduxBaseState, PaginatedData {
    notInvoice: {
        data: IJobRequestExtendedModel[];
        page: number;
    }[];

    /** @deprecated Not Used anymore */
    notInvoiced: IJobRequestExtendedModel[];
}

export interface DueTodayRequests extends ReduxBaseState, PaginatedData {
    dueTodays: {
        data: IJobRequestExtendedModel[];
        page: number;
    }[];

    /** @deprecated Use `dueTodays` */
    dueToday: IJobRequestExtendedModel[];
}

export interface ClosedTodayRequest extends ReduxBaseState, PaginatedData {
    closeToday: {
        data: IJobRequestExtendedModel[];
        page: number;
    }[];

    /** @deprecated use `closeToday` */
    closedToday: IJobRequestExtendedModel[];
}

export interface ClientRequest extends ReduxBaseState, PaginatedData {
    clientRequest: {
        data: IJobRequestExtendedModel[];
        page: number;
    }[];
}

export interface JobRequestsState {
    overdue: OverdueRequests;
    notInvoiced: NotInvoicedRequests;
    dueToday: DueTodayRequests;
    closedToday: ClosedTodayRequest;
    clientRequest: ClientRequest;
}

const initialState: JobRequestsState = {
    overdue: {
        overdue:        [],
        overdues:       [],
        errorMessage:   "",
        status:         "init",
        totalPage:      1,
        currentPage:    0,
        totalData:      1,
        limit:          1
    },
    notInvoiced: {
        notInvoice: [],
        totalPage: 1,
        currentPage: 0,
        totalData:      1,
        limit:          1,

        notInvoiced: [],
        errorMessage: "",
        status: "init"
    },
    dueToday: {
        dueTodays:      [],
        totalPage:      1,
        currentPage:    0,
        totalData:      1,
        limit:          1,

        dueToday: [],
        errorMessage: "",
        status: "init"
    },
    closedToday: {
        closeToday:     [],
        totalPage:      1,
        currentPage:    0,
        totalData:      1,
        limit:          1,

        closedToday: [],
        errorMessage: "",
        status: "init"
    },
    clientRequest: {
        clientRequest: [],
        totalPage:      1,
        currentPage:    0,
        totalData:      1,
        limit:          1,
        errorMessage:   "",
        status:         "init"
    }
}

export const jobSlice = createSlice({
    name: "jobRequests",
    initialState,
    reducers: {
        // Not Invoiced
        notInvoicedUpdate: (state, actions: PayloadAction<NotInvoicedRequests>) => {
            state.notInvoiced.notInvoice    = actions.payload.notInvoice;
            state.notInvoiced.status        = "success";
            state.notInvoiced.errorMessage  = "";
            state.notInvoiced.totalPage     = actions.payload.totalPage;
            state.notInvoiced.currentPage   = actions.payload.currentPage;
            state.notInvoiced.totalData     = actions.payload.totalData;
            state.notInvoiced.limit         = actions.payload.limit
        },
        notInvoicedStatus: (state, action: PayloadAction<ReduxBaseState>) => {
            state.notInvoiced.status        = action.payload.status;
            state.notInvoiced.errorMessage  = action.payload.errorMessage;
        },

        // Overdue
        overduesUpdateStatus: (state, actions: PayloadAction<ReduxBaseState>) => {
            state.overdue.status            = actions.payload.status;
            state.overdue.errorMessage      = actions.payload.errorMessage;
        },
        overduesUpdate: (state, actions: PayloadAction<OverdueRequests>) => {
            state.overdue.overdue           = actions.payload.overdue;
            state.overdue.status            = "success";
            state.overdue.errorMessage      = "";
            state.overdue.totalPage         = actions.payload.totalPage;
            state.overdue.currentPage       = actions.payload.currentPage;
            state.overdue.totalData         = actions.payload.totalData;
            state.overdue.limit             = actions.payload.limit
        },

        // DueToday
        dueTodayStatus: (state, action: PayloadAction<ReduxBaseState>) => {
            state.dueToday.status       = action.payload.status;
            state.dueToday.errorMessage = action.payload.errorMessage;
        },
        dueTodayUpdate: (state, action: PayloadAction<DueTodayRequests>) => {
            state.dueToday.dueTodays    = action.payload.dueTodays;
            state.dueToday.totalPage    = action.payload.totalPage;
            state.dueToday.currentPage  = action.payload.currentPage;
            state.dueToday.totalData    = action.payload.totalData;
            state.dueToday.limit        = action.payload.limit
            state.dueToday.status       = "success";
            state.dueToday.errorMessage = "";
        },

        // Close today
        closeTodayStatus: (state, action: PayloadAction<ReduxBaseState>) => {
            state.closedToday.status        = action.payload.status;
            state.closedToday.errorMessage  = action.payload.errorMessage;
        },
        closeTodayUpdate: (state, action: PayloadAction<ClosedTodayRequest>) => {
            state.closedToday.closeToday   = action.payload.closeToday;
            state.closedToday.totalPage     = action.payload.totalPage;
            state.closedToday.currentPage   = action.payload.currentPage;
            state.closedToday.totalData     = action.payload.totalData;
            state.closedToday.limit         = action.payload.limit
            state.closedToday.status        = "success";
            state.closedToday.errorMessage  = "";
        },

        // Client Request - Accepted Request
        clientRequestStatus: (state, action: PayloadAction<ReduxBaseState>) => {
            state.clientRequest.status        = action.payload.status;
            state.clientRequest.errorMessage  = action.payload.errorMessage;
        },
        clientRequestUpdate: (state, action: PayloadAction<ClientRequest>) => {
            state.clientRequest.clientRequest = action.payload.clientRequest;
            state.clientRequest.totalPage     = action.payload.totalPage;
            state.clientRequest.currentPage   = action.payload.currentPage;
            state.clientRequest.status        = "success";
            state.clientRequest.errorMessage  = "";
            state.clientRequest.totalData     = action.payload.totalData;
            state.clientRequest.limit         = action.payload.limit
        },

        clearData: () => initialState
    }
});

export const {
    notInvoicedUpdate,
    notInvoicedStatus,
    overduesUpdate,
    overduesUpdateStatus,
    dueTodayStatus,
    dueTodayUpdate,
    closeTodayStatus,
    closeTodayUpdate,
    clientRequestStatus,
    clientRequestUpdate
} = jobSlice.actions;

export function* saga() {
    yield takeLatest(logout.type, function* () {
        yield put(clearData());
    })
}
