import {IManagementPropertyData} from "../../../models/ManagementProperty";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ReduxBaseState} from "../../../models/ReduxBaseState";

export interface TempPropertyRedux extends ReduxBaseState {
    properties: IManagementPropertyData[][]
    searchResult: IManagementPropertyData[][]
    searchParam?: {
        totalData: number
        limit: number
        currentPage: number
    },
    totalData: number
    limit: number
    currentPage: number
}

interface ITempPropertyPayload {
    properties: IManagementPropertyData[][],
    param?: {
        totalData: number
        limit: number
        currentPage: number
    }
}

const initialState: TempPropertyRedux = {
    status: 'init',
    errorMessage: '',
    properties: [],
    searchResult: [],
    searchParam: undefined,
    totalData: 0,
    limit: 0,
    currentPage: 0
}

export const propertySlice = createSlice({
    name: 'property',
    initialState,
    reducers: {
        propertiesUpdateStatus: (state, action: PayloadAction<ReduxBaseState>) => {
            state.status = action.payload.status
            state.errorMessage = action.payload.errorMessage
        },

        addProperties: (state, action: PayloadAction<ITempPropertyPayload>) => {
            state.properties = action.payload.properties
            state.currentPage = action.payload.param?.currentPage ?? 0
            state.status = 'success'
            state.limit = action.payload.param?.limit ?? 0
            state.totalData = action.payload.param?.totalData ?? 0
        },

        updateSearch: (state, action: PayloadAction<ITempPropertyPayload>) => {
            state.searchResult = action.payload.properties
            state.searchParam = action.payload.param
        },

        clearProperty: () => initialState
    }
})

export const {
    propertiesUpdateStatus,
    addProperties,
    updateSearch,
    clearProperty
} = propertySlice.actions