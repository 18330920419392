import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../redux/rootReducer";
import React, {useEffect, useState} from "react";
import {SelectItem} from "../../../models/SelectItem";
import {inventorySelectAction} from "../../../redux/actions/selectActions";

interface SelectInventoryProps {
    value?: string;
    onSelect?: (code: string) => void;
    disabled?: boolean;
}

export const SelectInventory: React.FunctionComponent<SelectInventoryProps> = ({
    value,
    onSelect,
    disabled = false
}): JSX.Element => {
    const dispatch = useDispatch();
    const { selectItems, status, errorMessage } = useSelector((state: ApplicationState) => state.select.inventory);

    const [selectedItem, setSelectedItem] = useState<SelectItem | undefined | null>();

    const _selectItemWithEmptyValue: SelectItem[] = [{ value: "", label: "Select Inventory" }, ...selectItems];

    if (process.env.NODE_ENV === "development") {
        if (errorMessage) console.log("SELECT_INVENTORY_ERROR", errorMessage);
    }

    // Effect for getting initial data
    useEffect(() => {
        _getInitialData();
    }, []);

    useEffect(() => {
        _onValueSelected(value);
    }, [value]);

    const _getInitialData = () => {
        dispatch(inventorySelectAction());
    }

    const _onValueSelected = (selectedValue?: string) => {
        const found = selectItems.find(item => item.value === selectedValue);
        setSelectedItem(found ?? null);
    }

    return (
        <Select
            isDisabled={disabled}
            isLoading={status === "loading"}
            options={_selectItemWithEmptyValue}
            value={selectedItem}
            onChange={item => {
                if (onSelect && item) {
                    onSelect(item.value);
                }
            }}
            styles={{
                control: (styles) => {
                    return { ...styles, zIndex: 2 }
                },
                menu: (base) => {
                    return { ...base, zIndex: 3}
                }
            }}
        />
    )
}
