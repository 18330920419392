import React from "react";
import {Link} from "react-router-dom";

interface BackButtonProps {
    /**
     * Wheres page to be navigated. __Default value navigated to `/login`__
     */
    to?: string
}

/**
 * Create back button to navigate to main page Login
 * @returns React.FunctionComponent
 */
const BackButton: React.FunctionComponent<BackButtonProps> = ({
    to = "/login"
}): JSX.Element => {
    return (
        <Link to={ to }>
            <div className="back-button d-flex align-items-center mb-6">
                <div className="mr-4">
                    <i className="fa fa-arrow-left" />
                </div>
                <div className="back-button-text">Back to Login Page</div>
            </div>
        </Link>
    )
}

export default BackButton;
