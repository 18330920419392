import React from "react";
import {SelectService} from "../../Selects";

interface ToolbarServicesProp {
    onServiceSelected?: (serviceId: string) => void;
}

export const ToolbarServices: React.FunctionComponent<ToolbarServicesProp> = ({
    onServiceSelected
}): JSX.Element => {

    return (
        <div className="form-group row">
            <label htmlFor="services" className="col-3 col-form-label">Services</label>
            <div className="col-9">
                <SelectService
                    onSelected={onServiceSelected}
                />
            </div>
        </div>
    )
}
