import {useEffect, useState} from "react";
import {SelectItem} from "../../../models/SelectItem";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../redux/rootReducer";
import {administratorSelectAction} from "../../../redux/actions/selectActions";

type SelectAdministratorSelectType<IsMultiple extends boolean> = IsMultiple extends true ? string[] : string;

interface SelectAdministratorProps<IsMultiple extends boolean = false> {
    value?: SelectAdministratorSelectType<IsMultiple>;
    onSelect?: (administratorId: SelectAdministratorSelectType<IsMultiple>) => void;
    isMultiSelect?: IsMultiple;
    disabled?: boolean;

    /** Placeholder for first selection item */
    placeholder?: string;
}

export function SelectAdministrator<SelectMultiple extends boolean = false>({
    value,
    onSelect,
    isMultiSelect,
    placeholder,
    disabled = false
}: SelectAdministratorProps<SelectMultiple>): JSX.Element {
    const dispatch = useDispatch();
    const { selectItems, status, errorMessage } = useSelector((state: ApplicationState) => state.select.administrator);

    const [selectedItems, setSelectedItems] = useState<SelectItem[]>([]);

    if (process.env.NODE_ENV === "development") {
        if (errorMessage) console.log("SELECT_ADMINISTRATOR_ERROR", errorMessage);
    }

    // Append initial select value
    const selectItemsWithEmptyItem: SelectItem[] = [{value: "", label: "Select Administrator"}, ...selectItems];

    // For initial load
    useEffect(() => {
        _getInitialData();
    }, []);

    // For `value` change
    useEffect(() => {
        _onValueChange(value);
    }, [value]);

    const _getInitialData = () => {
        dispatch(administratorSelectAction());
    }

    const _onValueChange = (selectValue?: SelectAdministratorSelectType<SelectMultiple>) => {
        let _selectedItem: SelectItem[];
        if (isMultiSelect && selectValue && Array.isArray(selectValue)) {
            _selectedItem = selectItems.filter(item => selectValue.includes(item.value));
        } else {
            const found = selectItems.find(item => item.value === selectValue);
            _selectedItem = found ? [found] : [];
        }

        setSelectedItems(_selectedItem);
    }

    return (
        <Select
            isDisabled={disabled}
            isLoading={status === "loading"}
            options={selectItemsWithEmptyItem}
            value={selectedItems}
            isMulti={isMultiSelect}
            styles={{
                // container: (styles) => {
                //     return { ...styles, zIndex: 2 }
                // },
                control: (styles) => {
                    return { ...styles, zIndex: 2 }
                },
                menu: (base) => {
                    return { ...base, zIndex: 3}
                }
            }}
            onChange={(item: any) => {
                if (onSelect && item) {
                    if (Array.isArray(item)) {
                        onSelect(item.map(value => value.value) as SelectAdministratorSelectType<SelectMultiple>);
                    } else {
                        onSelect(item.value);
                    }
                }
            }}
        />
    )
}
