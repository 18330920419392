import axios from "axios";
import { PropertySelectItem } from "../app/components/Selects/SelectProperty";
import { GlobalCategorySelect } from "../models/GlobalCategorySelect";
import { SelectAdministrator } from "../models/SelectAdministrator";
import { SelectArchiveType } from "../models/SelectArchiveType";
import { SelectAsset } from "../models/SelectAsset";
import { SelectAttendancePropertyLocation } from "../models/SelectAttendancePropertyLocation";
import { SelectAttendancePropertyLocationAsset } from "../models/SelectAttendancePropertyLocationAsset";
import { SelectClient } from "../models/SelectClient";
import { SelectInventory } from "../models/SelectInventory";
import { SelectItem, SelectItemPropertyLocation } from "../models/SelectItem";
import { Property, SelectOptionProperty } from "../models/SelectOptionPropertyModel";
import { SelectOptionService } from "../models/SelectOptionService";
import { SelectServiceTypeResponse } from "../models/SelectServiceTypeResponse";
import { SelectVendor } from "../models/SelectVendor";
import { FormDataType, makeFormData, postToApi } from "../utils/APIHelper";
import { ApiResponse, APIResponse, ClientPropertySearchParameter } from "./BaseAPI";

export interface SelectParam extends FormDataType {
    is_infinity?:       boolean;
    offset_page?:       number;
}

export interface SelectParamAdminId extends SelectParam {
    admin_id?: number;
}

export function selectGetProperty(clientId?: number): ApiResponse<{ options: PropertySelectItem[], offsetPage: number, properties: Property[] }> {
    return postToApi<{ options: PropertySelectItem[], offsetPage: number, properties: Property[] }, SelectOptionProperty>({
        url: "api/property/select",
        data: { client_id: clientId, dont_get_default_limit: false }
    }, data => {
        const options: PropertySelectItem[] = data.properties.map(p => {
            return { value: p.id.toString(), label: p.title, address: p.address, clientId: p.client.id.toString() };
        });
        return {options: options, offsetPage: data.next_offset_page, properties: data.properties}
    });
}

export function selectService(): ApiResponse<SelectItem[]> {
    return postToApi<SelectItem[], SelectOptionService>({
        url: "api/service/select"
    }, data => {
        return data.services.map((item) => {
            return { value: item.id.toString(), label: item.name};
        })
    })
}

export function selectGetServices(): APIResponse<SelectOptionService> {
    return axios.post("api/service/select");
}

export function selectAsset(param?: SelectParam): APIResponse<SelectAsset> {
    return axios.post("api/asset/select", makeFormData(param));
}

export function selectCategory(param?: SelectParam): APIResponse<GlobalCategorySelect> {
    return axios.post("api/global/category/select", makeFormData(param));
}

export function selectVendor(param?: SelectParam): ApiResponse<SelectItem[]> {
    return postToApi<SelectItem[], SelectVendor>({
        url: "api/global/vendor/select",
        data: param
    }, data => data.vendors.map(item => {
        return { value: item.id.toString(), label: item.title };
    }))
}

export interface SelectAttendancePropertyLocationParam extends SelectParam, ClientPropertySearchParameter {}

export function selectAttendancePropertyLocation(param?: SelectAttendancePropertyLocationParam)
    : ApiResponse<SelectItemPropertyLocation[]> {
    return postToApi<SelectItemPropertyLocation[], SelectAttendancePropertyLocation>({
        url: "api/attendance/tracking/property/location/site/attendance/register/select",
        data: param,
        config: {
            customErrorMessage: "Can not fetching Property Location data"
        }
    }, data => {
        return data.property_locations.map(item => {
            return {
                value: item.id.toString(),
                label: item.title,
                propertyId: item.property.id,
                clientId: item.property.client.id
            };
        })
    });
}

export function selectAdministrator(param?: SelectParam): ApiResponse<SelectItem[]> {
    return postToApi<SelectItem[], SelectAdministrator>({
        url: "api/admins/all/select",
        data: param,
        config: { customErrorMessage: "Cannot fetching Administrator List" }
    }, data => data.admins.map(item => {
        return { value: item.id.toString(), label: item.fullname_admin };
    }))
}

export function selectArchiveType(param?: SelectParamAdminId): ApiResponse<SelectItem[]> {
    return postToApi<SelectItem[], SelectArchiveType>({
        url: "api/archive/type/select",
        data: param
    }, data => data.archiveTypes.map(item => {
        return { value: item.id.toString(), label: item.name };
    }))
}

export function selectInventory(): ApiResponse<SelectItem[]> {
    return postToApi<SelectItem[], SelectInventory>({
        url: "api/inventory/select"
    }, data => data.inventories.map(item => {
        return { value: item.id.toString(), label: item.title };
    }));
}

export function selectAttendancePropertyLocationAsset(): ApiResponse<SelectItem[]> {
    return postToApi<SelectItem[], SelectAttendancePropertyLocationAsset>({
        url: "api/attendance/tracking/property/location/asset/inventory/select"
    }, data => data.location.map((loc) => {
        return {
            value: loc.id.toString(),
            label: loc.title
        }
    }));
}

// TODO: Select Item filtering
export const getClients = (offset_page?: number): ApiResponse<SelectItem[]> => {
    return postToApi<SelectItem[], SelectClient>({
        url: "api/client/select",
        data: { offset_page }
    }, data => {
        return data.clients.map(item => {
            return { value: item.id.toString(), label: item.name }
        })
    });
}

export function selectStockAsync(param?: SelectParam): ApiResponse<SelectItem[]> {
    const formData = makeFormData({
        is_infinity: param?.isInfinity ?? false,
        offset_page: param?.offsetPage ?? 0
    });

    return postToApi<SelectItem[], SelectAsset>({
        url: "api/asset/select",
        data: formData
    }, (data) => data.assets.map(asset => {
        return { value: asset.id.toString(), label: asset.title };
    }));
}

export function selectServiceType(): ApiResponse<SelectItem[]> {
    return postToApi<SelectItem[], SelectServiceTypeResponse>({
        url: '/api/service/types/select'
    }, data => {
        return data.service_types.map(data => {
            return {value: data.id.toString(), label: data.name}
        })
    })
}