import React, { ReactNode, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";

// Base Interface For Accordin Data (if any)
interface CustomTableAccordionData {
	/** Event key for opening bootstrap accordion */
	eventKey: string;

	accordion: ReactNode;
}

type ColumnSize = "" | "col" | "col-1" | "col-2" | "col-3" | "col-4" | "col-5" | "col-6" | "col-7" | "col-8" | "col-9" | "col-10" | "col-11" | "col-12";

type TableData = string[] | ReactNode;

export interface HeaderColumn {
	columnName: string;
	order: "ASC" | "DESC";
}

interface CustomTableProps {
	/** Name of each columns */
	columnsName: string[];

	/** Bootstrap column class size (col). When empty its default to `col-1` */
	columnSize?: ColumnSize[];

	/** Table Data to be Rendered */
	data: TableData[][];

	/** Add Accordion to table data */
	accordions?: CustomTableAccordionData[];

	/** Add hover effect on table data */
	tableHover?: boolean;

	/** Prop for when its loading, and no data, dont display "No Data" text */
	isLoading?: boolean;

	/** Order column name when clicked */
	orderHeaderColumnName?: string[];

	onHeaderColumnClick?: (nameOrder: HeaderColumn) => void;

	headerOrderColumnValue?: HeaderColumn;

	/** Display `emptyMessage` when theres no data nor is not loading */
	emptyMessage?: string;

	/*
	 * __Default: false__
	 *
	 * Set end of column data not using right alignment. When false is suitable for button action for end of column.
	 * */
	disableAlignRightEndColumn?: boolean;
}

/**
 * Create custom Table using `div`
 */
export function CustomTable({ columnsName, columnSize, data, accordions, tableHover = false, isLoading = false, orderHeaderColumnName, onHeaderColumnClick, headerOrderColumnValue, emptyMessage = "Theres no Data", disableAlignRightEndColumn = false }: CustomTableProps): JSX.Element {
	// Minimum responsive width to collapse content
	const _minResponsiveWidth = 991;

	const [isResponsive, setIsResponsive] = useState(false);

	useEffect(() => {
		function updateWindowSize() {
			setIsResponsive(window.innerWidth <= _minResponsiveWidth);
		}

		window.addEventListener("resize", updateWindowSize);

		return () => {
			window.removeEventListener("resize", updateWindowSize);
		};
	}, []);

	const _onHeaderClick = (position: number) => {
		if (orderHeaderColumnName && orderHeaderColumnName[position]) {
			const columnName = orderHeaderColumnName[position];

			let order: "ASC" | "DESC";
			if (headerOrderColumnValue?.columnName === columnName) {
				// flip order
				order = headerOrderColumnValue.order === "ASC" ? "DESC" : "ASC";
			} else {
				order = "ASC";
			}

			if (onHeaderColumnClick)
				onHeaderColumnClick({
					columnName,
					order,
				});
		}
	};

	return (
		<div className="custom-table">
			{!isResponsive && (
				<div className="row mx-0 bg-gray-200  p-4 align-items-center">
					{columnsName.map((column, index) => {
						let colSize = "col-lg-1";
						if (columnSize && columnSize[index]) {
							colSize = columnSize[index];
						}

						let headClassName = "";

						let isSelected = false;
						let isAscendingOrder = headerOrderColumnValue?.order === "ASC" ?? false;
						if (orderHeaderColumnName && orderHeaderColumnName[index]) {
							isSelected = headerOrderColumnValue?.columnName === orderHeaderColumnName[index];

							headClassName += " cursor-pointer";
						}

						return (
							<div key={`cs-header-${index}`} className={`${colSize} col-xs-12 row px-4 ${headClassName}`} onClick={() => _onHeaderClick(index)}>
								<span className="font-weight-bolder">
									{column.toUpperCase()}
									{isSelected && !isAscendingOrder && <i className="fas fa-caret-down ml-2" />}
									{isSelected && isAscendingOrder && <i className="fas fa-caret-up ml-2" />}
								</span>
							</div>
						);
					})}
				</div>
			)}

			{data.length === 0 && !isLoading && (
				<div className="text-center p-4 border-bottom">
					<span className="font-size-lg font-weight-bolder">{emptyMessage}</span>
				</div>
			)}
			{isLoading && (
				<div className="text-center p-4 border-bottom">
					<span className="font-size-lg font-weight-bolder">{"Please wait..."}</span>
				</div>
			)}

			{!isLoading &&
				data.map((row, index) => {
					if (accordions) {
						return (
							<div key={`ctb-${index}`} className={`${isResponsive ? "border" : "border-bottom"} ${tableHover && "bg-hover-gray-100"}`}>
								<Accordion>
									<CustomTableRow key={`ctb-${index}`} columnsName={columnsName} data={row} columnSize={columnSize} isResponsive={isResponsive} />

									<Accordion.Collapse eventKey={accordions[index].eventKey}>
										<div>{accordions[index].accordion}</div>
									</Accordion.Collapse>
								</Accordion>
							</div>
						);
					} else {
						return (
							<div key={`ctb-${index}`} className={`${isResponsive ? "border" : "border-bottom"} ${tableHover && "bg-hover-gray-100"}`}>
								<CustomTableRow key={`ctb-${index}`} columnsName={columnsName} data={row} columnSize={columnSize} isResponsive={isResponsive} disableAlignRightEndColumn={disableAlignRightEndColumn} />
							</div>
						);
					}
				})}
		</div>
	);
}

interface CustomTableRowProps {
	data: TableData[];
	columnsName: string[];
	columnSize?: ColumnSize[];
	isResponsive?: boolean;
	disableAlignRightEndColumn?: boolean;
}

function CustomTableRow({ data, columnsName, columnSize, isResponsive = false, disableAlignRightEndColumn = false }: CustomTableRowProps): JSX.Element {
	// When is responsive, alter column with `lg`
	const totalData = data.length - 1;

	return (
		<div className="row mx-0 py-4 px-4">
			{data.map((column, index) => {
				let colSize = isResponsive ? "col-lg-1" : "col-1";
				if (columnSize && columnSize[index]) {
					if (isResponsive) {
						const tempColSize = columnSize[index].split("-");
						if (tempColSize[1]) {
							colSize = `col-lg-${tempColSize[1]}`;
						} else {
							colSize = `col-lg-1`;
						}
					} else {
						colSize = columnSize[index];
					}
				}

				let inner: ReactNode = <></>;

				if (typeof column === "string") {
					inner = <div>{column}</div>;
				} else if (React.isValidElement(column)) {
					inner = column;
				} else if (Array.isArray(column)) {
					inner = column.map((value: any) => value);
				}

				let endColumClass = "";
				if (totalData === index && !disableAlignRightEndColumn) {
					endColumClass = "d-inline text-right";
				}

				let notEndColumnClass = "";
				if (totalData !== index) {
					notEndColumnClass = "padding-Right-10";
				}

				return (
					<div key={`rowsingle-${index}`} className={`${colSize} col-xs-12 row px-4 ${isResponsive ? "mb-4" : ""} ${endColumClass}`}>
						<div className={`${notEndColumnClass}`}>
							{isResponsive && <div className="font-weight-bolder">{columnsName[index]}</div>}
							{inner}
						</div>
					</div>
				);
			})}
		</div>
	);
}
