import {Dropdown} from "react-bootstrap";
import {DropdownTopbarItemToggler} from "../../../../_partials/dropdowns";
import React, {useMemo} from "react";
import {useHtmlClassService} from "../../../_core/MetronicLayout";
import objectPath from "object-path";
import {Link} from "react-router-dom";
import {DropdownToggler} from "../../../../../pages/Dashboard/components/DropdownGroupItemToggler";

export function JobRequestDropdown(): JSX.Element {

    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            offcanvas:
                objectPath.get(uiService.config, "extras.notifications.layout") ===
                "offcanvas",
        };
    }, [uiService]);

    const JobMenu = [
        {
            to: "/job-request/new",
            name: "Create New Request",
            icon: "fas fa-plus"
        },
        {
            to: "/job-request/recurring",
            name: "Manage Recurring Requests",
            icon: "fas fa-redo-alt"
        },
        {
            to: "/job-request/area/manager",
            name: "Area Manager",
            icon: "fas fa-users"
        },
        {
            to: "/job-request/status/pending",
            name: "Pending Requests",
            icon: "fas fa-clock"
        },
        {
            to: "/job-request/status/open",
            name: "Open Request",
            icon: "fas fa-flag"
        },
        {
            to: "/job-request/status/declined",
            name: "Declined Requests",
            icon: "fas fa-times"
        },
        {
            to: "/job-request/status/completed",
            name: "Completed Requests",
            icon: "fas fa-check"
        },
        {
            to: "/job-request/status/due-today",
            name: "Request Due Today",
            icon: "fas fa-exclamation-circle"
        },
        {
            to: "/job-request/status/deleted-requests",
            name: "Deleted Requests",
            icon: "fas fa-trash"
        },
        {
            to: "/job-request/recurring/listing",
            name: "Recurring Request",
            icon: "fas fa-redo-alt"
        }
    ]

    return (
        <>
            {layoutProps.offcanvas && (
                <div className="topbar-item">
                    <div
                        className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
                        id="kt_quick_notifications_toggle"
                    >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <i className="fas fa-ticket"/>
            </span>
                    </div>
                </div>
            )}
            {!layoutProps.offcanvas && (
                <Dropdown drop="down">
                    <Dropdown.Toggle
                        as={DropdownTopbarItemToggler}
                        id="kt_quick_notifications_toggle"
                    >
                        <div
                            className="btn btn-clean btn-lg mr-1"
                            id="kt_quick_notifications_toggle"
                        >
                            <i className="fas fa-ticket-alt text-primary"/>
                            <span className="text-muted font-weight-bold py-1">Job Request</span>
                        </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                        className="navi navi-hover py-4"
                    >
                        {
                            JobMenu.map((value, index) => (
                                <Dropdown.Item
                                    key={index}
                                    className="navi-item"
                                    as={DropdownToggler}
                                >
                                    <Link to={value.to} className="navi-link">
                    <span style={{ width: '20px'}} className="symbol symbol-20 mr-3">
                      <i className={`${value.icon}`}/>
                    </span>
                                        <span className="navi-text">
                      {value.name}
                    </span>
                                    </Link>
                                </Dropdown.Item>
                            ))
                        }
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </>
    )
}
