import React from "react";

interface IconTextProps {
    fasIcon?: string;

    bold?: boolean;
    children?: React.ReactNode;
}

/**
 * Text Component with appended `font-awesome` icon
 * */
export const IconText: React.FC<IconTextProps> = ({
    fasIcon,
    bold = false,
    children
}): JSX.Element => {

    return (
        <div className="mb-2">
            <span className={`${ bold ? "font-weight-bolder" : ""}`}>
                <i className={`fas fa fa-${fasIcon} font-size-sm w-15px mr-2`} />
                { children }
            </span>
        </div>
    )

}
