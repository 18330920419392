import {useSelector} from "react-redux";
import {ApplicationState} from "../../redux/rootReducer";
import {useRoleHelper} from "../../resorces/RoleHelper";

interface UserHelperReturn {
    token: string;
    userId: number;
    fullname: string;
    username: string;
    adminId?: string;
    realUserId: number;
}

/**
 * Hooks for getting user Details like: `token`, `userId`, `fullname`, and `username`
 */
export const useUser = (): UserHelperReturn => {
    const {isAdmin, isAdministrator, isOperator, isClient} = useRoleHelper()

    const auth = useSelector((state: ApplicationState) => state.auth)

    let companyAdminId: string | undefined = auth.user.admin?.id.toString()

    // On `ROLE_CLIENT` admin id is on \.auth.user.clientlog.id
    // or \.auth.user.clientlog.admin.user_id
    if (isClient) {
        companyAdminId = auth.user.clientlog?.admin.user_id.toString() ?? ''
    }

    // Sometimes the `userId` is different, create new different UserId
    let realUserId: number = -1
    if (isOperator) {
        realUserId = auth.user.operator!.id
    } else if (isClient) {
        realUserId = auth.user.clientlog!.id
    }

    return {
        token: auth.token,
        userId: auth.user.userId,
        fullname: auth.user.fullname,
        username: auth.user.fullname,
        adminId: companyAdminId,
        realUserId
    }
}