import React, {useEffect, useState} from "react";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../redux/rootReducer";
import {SelectItem} from "../../../models/SelectItem";
import {assetSelectAction} from "../../../redux/actions/selectActions";
import {SelectBaseError} from "./SelectBaseError";

interface SelectAssetProps {
    value?: string;
    onSelect?: (assetId: string) => void;
    disabled?: boolean;
    isError?: boolean
}

export const SelectAsset: React.FC<SelectAssetProps> = ({
    onSelect,
    value,
    disabled = false,
    isError = false
}): JSX.Element => {

    const dispatch = useDispatch();
    const { selectItems, status, errorMessage } = useSelector((state: ApplicationState) => state.select.asset);

    if (process.env.NODE_ENV === "development") {
        if (errorMessage) console.log("SELECT_ASSET_ERROR", errorMessage);
    }

    const [ selectedItem, setSelectedItem ] = useState<SelectItem | undefined | null>();

    const selectItemsWithEmptyItem: SelectItem[] = [{ value: "", label: "Select Asset" }, ...selectItems];

    // Effect for getting data
    useEffect(() => {
        _getInitialData();
    }, []);

    useEffect(() => {
        _onValueChange(value);
    }, [value]);

    const _getInitialData = () => {
        dispatch(assetSelectAction());
    }

    const _onValueChange = (selectedValue?: string) => {
        const found = selectItems.find(item => item.value === selectedValue);
        setSelectedItem(found ?? null);
    }

    return (
        <Select
            isLoading={status === "loading"}
            isDisabled={disabled}
            options={selectItemsWithEmptyItem}
            value={selectedItem}
            styles={SelectBaseError(isError)}
            onChange={item => {
                if (onSelect && item) {
                    onSelect(item.value);
                }
            }}
        />
    )
}
