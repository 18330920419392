import React, {useEffect, useState} from "react";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../redux/rootReducer";
import {SelectItem, SelectItemPropertyLocation} from "../../../models/SelectItem";
import {propertyLocationSelectAction} from "../../../redux/actions/selectActions";
import {parseInt} from "lodash";

interface PropertyLocationSelectProps {
    propertyId?: string;
    clientId?: string;

    value?: string;
    onSelect?: (propertyId: string) => void;
    onItemSelect?: (propertyLocation: SelectItemPropertyLocation) => void;
    disable?: boolean;
}

export function SelectPropertyLocation({
    propertyId,
    clientId,
    value,
    onSelect,
    onItemSelect,
    disable = false
}: PropertyLocationSelectProps): JSX.Element {
    const dispatch = useDispatch();
    const {selectItems, status, errorMessage} = useSelector((state: ApplicationState) => state.select.propertyLocation);

    const itemNone = { value: "", label: "Select Property Location", clientId: -1, propertyId: -1 }

    const _selectedItemWithEmptyValue: SelectItemPropertyLocation[] = [itemNone, ...selectItems];
    const [selectedItem, setSelectedItem] = useState<SelectItemPropertyLocation | undefined | null>();

    const [items, setItems] = useState<SelectItemPropertyLocation[]>(_selectedItemWithEmptyValue)

    if (process.env.NODE_ENV === "development") {
        if (errorMessage) console.log("SELECT_PROPERTY_LOCATION_ERROR", errorMessage);
    }

    // Effect for getting initial value
    useEffect(() => {
        _getInitialData();
    }, []);

    // Effect when the `propertyId` and `clientId` change
    useEffect(() => {
        // Disable for now
        // _getInitialData(propertyId ? parseInt(propertyId) : undefined, clientId ? parseInt(clientId) : undefined);
        
        // When `clientId` change, just filter base on client id, dont fetch from server?
        if (propertyId || clientId) {
            const filtered = selectItems.filter(item => {
                if (propertyId && clientId) {
                    return item.clientId.toString() === clientId && item.propertyId.toString() === propertyId
                } else if (clientId) {
                    return item.clientId.toString() === clientId
                } else if (propertyId) {
                    return item.propertyId.toString() === propertyId
                } else {
                    return false
                }
            })
            setItems([itemNone, ...filtered] ?? null)
        } else {
            setItems(_selectedItemWithEmptyValue)
        }
    }, [propertyId, clientId]);

    // Effect when the `value` change
    useEffect(() => {
        _onValueChange(value);
    }, [value]);

    const _getInitialData = (propertyId?: number, clientId?: number) => {
        dispatch(propertyLocationSelectAction(propertyId, clientId));
    }

    const _onValueChange = (selectedValue?: string) => {
        const found = selectItems.find(item => item.value === selectedValue);
        setSelectedItem(found ?? null);
    }
    return (
        <Select
            isLoading={status === "loading"}
            isDisabled={disable}
            options={items}
            value={selectedItem}
            onChange={item => {
                if (onSelect && item) {
                    onSelect(item.value);
                }
                if (onItemSelect && item) {
                    onItemSelect(item)
                }
            }}
            styles={{
                control: (styles) => {
                    return { ...styles, zIndex: 2 }
                },
                menu: (base) => {
                    return { ...base, zIndex: 3}
                }
            }}
        />
    )
}
