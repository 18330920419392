import React from "react";

interface FormikMessageProps {
  errorMessage?: string | string[] | any;
  touchedField?: boolean | any;
}

export const FormikMessage: React.FunctionComponent<FormikMessageProps> = ({
  errorMessage,
  touchedField,
}): JSX.Element => {
  if (errorMessage && touchedField) {
    let errmsg = "";
    if (Array.isArray(errorMessage)) {
      errorMessage.forEach((e) => (errmsg += e + "\n"));
    } else {
      errmsg = errorMessage;
    }

    return (
      <div className="fv-plugins-message-container">
        <div className="fv-help-block">{errmsg}</div>
      </div>
    );
  } else {
    return <></>;
  }
};
