import React from "react";

/**
 * Create custom table based on [KeenThemes1](https://preview.keenthemes.com/metronic/demo1/features/widgets/advance-tables.html)
 * */
export const BENTableBordered: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }): JSX.Element => {
  // Pass down bordered props for head
  const childrendWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      // @ts-ignore
      return React.cloneElement(child, { bordered: true });
    }
    return child;
  });

  return (
    <table className="table table-head-custom table-vertical-center">
      {childrendWithProps}
    </table>
  );
};
