import React from "react";
import { BENTableBordered } from "./BENTableBordered";
import { BENTableRounded } from "./BENTableRounded";
import { BENTableBody } from "./BENTableBody";
import { BENTableHead } from "./BENTableHead";
import { BENTableEmptyData } from "./BENTableEmptyData";
import { Pagination2 } from "../index";
import { IPagination2PageData } from "../Pagination2";

interface BENTableProp {
  responsive?: boolean;
  pageData?: IPagination2PageData;
  currentPage?: number;

  /**
   * Callback when user selected a page,
   * with `page` is page clicked
   */
  onPageSelected?: (page: number) => void;

  children?: React.ReactNode;
}

type BENTableComponent = React.FunctionComponent<BENTableProp> & {
  Bordered: typeof BENTableBordered;
  Rounded: typeof BENTableRounded;
  Head: typeof BENTableHead;
  Body: typeof BENTableBody;
  Empty: typeof BENTableEmptyData;
};

const BENTable: BENTableComponent = ({
  responsive = true,
  children,
  pageData = { totalData: 1, limit: 1 },
  currentPage = 0,
  onPageSelected,
}): JSX.Element => {
  const totalPageData = pageData
    ? Math.ceil(pageData.totalData / pageData.limit)
    : 0;

  if (responsive) {
    return (
      <div className="table">
        {totalPageData > 0 ? (
          <div className="padding-Left-10">
            <Pagination2
              currentPage={currentPage}
              pageData={pageData}
              onPageClick={onPageSelected}
            />
          </div>
        ) : (
          <></>
        )}

        {children}

        {totalPageData > 0 ? (
          <div className="padding-Left-10">
            <Pagination2
              currentPage={currentPage}
              pageData={pageData}
              onPageClick={onPageSelected}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  } else {
    return (
      <>
        {children}

        {totalPageData > 0 ? (
          <div className="padding-Left-10">
            <Pagination2
              currentPage={currentPage}
              pageData={pageData}
              onPageClick={onPageSelected}
            />
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }
};

BENTable.Rounded = BENTableRounded;
BENTable.Bordered = BENTableBordered;
BENTable.Body = BENTableBody;
BENTable.Head = BENTableHead;
BENTable.Empty = BENTableEmptyData;

export default BENTable;
