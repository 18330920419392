import React from 'react';

export const DropdownGroupItemToggler = React.forwardRef((props: any, ref: any) => {
    return (
        <div
            ref={ref}
            className="btn btn-secondary dropdown-toggle"
            onClick={e => {
                e.preventDefault();
                props.onClick(e);
            }}
        >
            {props.children}
        </div>
    );
});

DropdownGroupItemToggler.displayName = 'DropdownGroupItemToggler';