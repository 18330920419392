import React from "react";
import { toAbsoluteUrl } from "../../../layout/_helpers";
import { Link } from "react-router-dom";
import "../styles/login-wrapper.scss";

interface LoginWrapperProps {
  title: string;

  /**
   * Force `LoginWrapper` to placed on top (not centered x-axis and y-axis)
   * */
  forceTop?: boolean;

  /**
   * Size class of the warpper (in `lg` size classes)
   * - `wider` the content width is `650px`
   * - `wide` for the content width `480px`
   */
  sizeClass?: "wide" | "wider" | "";

  /**
   * Dynamic adjusted height depending on content height
   */
  autoHeight?: boolean;

  children?: React.ReactNode;
}

/**
 * Create Login wrapper for Auth page (back white wrapper)
 */
export const LoginWrapper: React.FunctionComponent<LoginWrapperProps> = ({
  children,
  title = "",
  forceTop = false,
  sizeClass = "",
  autoHeight = true,
}): JSX.Element => {
  return (
    <div
      className={`mypsr-login-wrapper w-100 ${
        autoHeight ? "h-100" : ""
      } d-flex justify-content-center ${forceTop ? "top" : "centered"}`}
    >
      <div id="mypsr-form-container" className={`${sizeClass}`}>
        <div className="text-center">
          <img
            className="my-psr-logo"
            src={toAbsoluteUrl("/media/myPSR-logo-white.png")}
            alt="myPSR Logo"
          />
        </div>
        <div className="mypsr-form-header py-4">
          <div className="w-100">
            <h3 className="text-light text-center w-100">
              {title.toUpperCase()}
            </h3>
          </div>
        </div>

        <div className="mypsr-form-content bg-white w-100 p-8">{children}</div>

        <div className="mypsr-form-footer d-flex justify-content-between position-relative px-8 py-4">
          <Link to={"/forgot-password"}>Forgot Password</Link>

          <Link to={"/recover-account"}>Recover Account</Link>
        </div>
      </div>
    </div>
  );
};
