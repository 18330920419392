import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ReduxBaseState} from "../../models/ReduxBaseState";

export interface IWebConfigRedux extends ReduxBaseState {
    client: {
        enableCreateJobRequest: boolean;
        jobRequestConfig: {
            id?: number;
            title?: string;
            isLoading?: boolean
        }
    }
}

const initialState: IWebConfigRedux = {
    status: "init",
    errorMessage: "",
    client: {
        enableCreateJobRequest: false,
        jobRequestConfig: {
            id: undefined,
            title: '',
            isLoading: false
        },

    }
}

export const webConfigSlice = createSlice({
    name: "webConfig",
    initialState,
    reducers: {
        updateConfigStatus: (state, action: PayloadAction<ReduxBaseState>) => {
            state.status = action.payload.status
            state.errorMessage = action.payload.errorMessage
        },
        updateClientConfig: (state, action: PayloadAction<{enableJobRequest: boolean}>) => {
            state.client.enableCreateJobRequest = action.payload.enableJobRequest
            state.status = "success"
            state.errorMessage = ""
        },
        updateClientCreateJobRequestConfigStatus: (state, action: PayloadAction<{isLoading: boolean}>) => {
            state.client.jobRequestConfig = {
                ...state.client.jobRequestConfig,
                isLoading: action.payload.isLoading
            }
        },
        updateClientCreateJobRequestConfig: (state, action: PayloadAction<{id: number; title: string}>) => {
            state.client.jobRequestConfig = {
                id: action.payload.id,
                title: action.payload.title,
                isLoading: false
            }
            state.status = "success"
            state.errorMessage = ""
        },
        clear: () => initialState
    }
})

export const {
    updateClientConfig,
    clear,
    updateConfigStatus,
    updateClientCreateJobRequestConfig,
    updateClientCreateJobRequestConfigStatus
} = webConfigSlice.actions