import React, {useState} from 'react'
import {IJobRequestDropdownDetail} from "./index";
import {Modal} from "react-bootstrap";
import {SymbolImage} from "../SymbolImage";
import {CustomImage} from "../CustomImage";
import {format} from "date-fns";
import {Link} from "react-router-dom";
import {useRoleHelper} from "../../../resorces/RoleHelper";
import {useUser} from "../../Helpers/UserHelper";
import {IOperatorModel} from "../../../models/IOperatorModel";
import {IJobRequestCommentModel} from "../../../models/IJobRequestResponse";

type IJobRequestDropdownDetailOperator = IJobRequestDropdownDetail & {
    disableOperatorLink?: boolean
}

export const JobRequestDropdownDetailOperator: React.VFC<IJobRequestDropdownDetailOperator> = (props) => {
    const {isOperator} = useRoleHelper()
    const {realUserId} = useUser()

    const {jobRequestInfo, disableOperatorLink = false } = props

    const [showOperatorDetailModal, setShowDetailOperatorModal] = useState(false)
    const [selectedOperatorData, setSelectedOperatorData] = useState<IOperatorModel | undefined>(undefined)
    const [selectedComments, setSelectedComments] = useState<IJobRequestCommentModel[]>([])

    if ((jobRequestInfo?.assign_to) && jobRequestInfo?.assign_to.fullname_operator) {
        return (<>
                <div className="col-lg-4 col-md-12">
                    <div className="font-weight-bolder font-size-lg mb-2">Operator</div>

                    <hr/>

                    {(isOperator && jobRequestInfo?.assign_to.id !== realUserId) ? (
                        <div />
                    ) : (
                        <button
                            type={'button'}
                            className={`bg-none p-0 border-0 mb-2 w-100 text-left ${!disableOperatorLink ? 'text-hover-primary' : ''}`}
                            onClick={() => {
                                if (!disableOperatorLink) {
                                    setShowDetailOperatorModal(true)
                                    setSelectedOperatorData(jobRequestInfo?.assign_to)
                                    setSelectedComments(jobRequestInfo?.comments)
                                }
                            }}
                        >
                            <div className="font-weight-bolder">{jobRequestInfo?.assign_to.fullname_operator}</div>
                        </button>
                    )}

                    {jobRequestInfo?.children.map(child => {
                        // On Operator, only show same operator_id
                        if (isOperator && child.assign_to?.id !== realUserId) {
                            return <div key={child.id} />
                        } else {
                            return (
                                <button
                                    key={child.id}
                                    type={'button'}
                                    className={`bg-none p-0 border-0 mb-2 w-100 text-left ${!disableOperatorLink ? 'text-hover-primary' : ''}`}
                                    onClick={() => {
                                        if (!disableOperatorLink) {
                                            setShowDetailOperatorModal(true)
                                            setSelectedOperatorData(child.assign_to)
                                            setSelectedComments(child.comments)
                                        }
                                    }}
                                >
                                    <div className="font-weight-bolder">{child.assign_to?.fullname_operator ?? ""}</div>
                                </button>
                            )
                        }
                    })}
                </div>
                <OperatorDetailModal
                    show={showOperatorDetailModal}
                    operator={selectedOperatorData}
                    comments={selectedComments}
                    onClose={() => {
                        setShowDetailOperatorModal(!showOperatorDetailModal)
                    }}
                />
            </>
        )
    }

    return <div/>
}

interface IOperatorDetailModalProps {
    operator?: IOperatorModel,
    comments?: IJobRequestCommentModel[],
    show?: boolean
    onClose?: () => void
}

const OperatorDetailModal: React.VFC<IOperatorDetailModalProps> = ({
   operator,
   comments = [],
   show = false,
   onClose
}) => {
    const {isClient, isOperator} = useRoleHelper()
    const disableLink = (isClient || isOperator)

    const _close = () => {
        if (onClose) onClose()
    }

    return (
        <Modal centered show={show} onHide={_close}>
            <Modal.Header>
                <Modal.Title>{operator?.fullname_operator ?? ''}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={'border rounded p-4 mb-4'}>
                    <div className={'font-weight-boldest font-size-lg'}>OPERATOR DETAILS</div>

                    <hr/>

                    {disableLink ? (<>
                        <div className={'font-weight-bolder'}><span>
                            <i className="fas fa-user w-15px icon-nm"/> {operator?.fullname_operator ?? ''}
                        </span></div>

                        <div>
                            <div className={'mb-2'}><span>
                                <i className="fas fa-envelope w-15px icon-nm"/> {operator?.email ?? ''}
                            </span></div>
                        </div>

                        <div>
                            <div className={'mb-2'}><span>
                                <i className="fas fa-phone w-15px icon-nm"/> {operator?.phone ?? ''}
                            </span></div>
                        </div>
                    </>) : (<>
                        <Link
                            to={`/operator/${operator?.id}/show`}
                            className={'font-weight-bolder font-size-lg mb-2 text-dark d-inline-block'}
                        ><span>
                            <i className="fas fa-user w-15px icon-nm"/> {operator?.fullname_operator ?? ''}
                        </span></Link>

                        <a
                            className={'text-dark-50'}
                            href={`mailto:{operator?.email ?? ''}`}>
                            <div className={'mb-2'}><span>
                                <i className="fas fa-envelope w-15px icon-nm"/> {operator?.email ?? ''}
                            </span></div>
                        </a>

                        <a
                            className={'text-dark-50'}
                            href={`tel:${operator?.phone ?? ''}`}
                        >
                            <div className={'mb-2'}><span>
                                <i className="fas fa-phone w-15px icon-nm"/> {operator?.phone ?? ''}
                            </span></div>
                        </a>
                    </>)}
                </div>

                <div>
                    <div className={'font-weight-boldest font-size-lg mb-2'}>COMMENTS</div>

                    {(comments && comments?.length > 0) ? (
                        <div className={'w-100 border border-gray-300 p-4 rounded'}
                             style={{height: '300px', overflowY: 'scroll'}}>
                            {comments.map(comment => (
                                <div
                                    key={comment.id}
                                    className={`d-flex flex-column mb-4 ${!comment.own_logged_user ? 'align-items-start' : 'align-items-end'}`}
                                >
                                    <div className={`d-flex flex-row`}>
                                        {!comment.own_logged_user && (
                                            <div className={'mt-2'}>
                                                <SymbolImage size={'35'}
                                                             className={comment.own_logged_user ? 'ml-4' : 'mr-4'}>
                                                    <CustomImage src={comment.image_url}
                                                    />
                                                </SymbolImage>
                                            </div>
                                        )}
                                        <div style={{maxWidth: '250px'}}>
                                            <div
                                                className={`mt-2 mb-1 rounded p-5 ${!comment.own_logged_user ? 'bg-light-primary' : 'bg-light-success'} text-dark-50 font-size-lg max-w-400px`}
                                            >
                                                <div className={`font-weight-bold`}>{comment.title}</div>
                                                <div dangerouslySetInnerHTML={{ __html: comment.description}} />
                                            </div>
                                            <div className={`${comment.own_logged_user ? 'text-right' : 'text-left'}`}>
                                                <div
                                                    className={`text-muted`}>{format(new Date(comment.created_at), 'dd-MM-yyyy HH:mm')}</div>
                                            </div>
                                        </div>
                                        {comment.own_logged_user && (
                                            <div className={'mt-2'}>
                                                <SymbolImage size={'35'}
                                                             className={comment.own_logged_user ? 'ml-4' : 'mr-4'}>
                                                    <CustomImage src={comment.image_url}
                                                    />
                                                </SymbolImage>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div>
                            <p>There is no comment.</p>
                        </div>
                    )}
                </div>

            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-default"
                    onClick={_close}
                >
                    CLOSE
                </button>
            </Modal.Footer>
        </Modal>
    )
}
