import {ReduxBaseState} from "../../models/ReduxBaseState";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {JobRequestSearchParameters} from "../../apis/SearchAPI";
import {IJobRequestExtendedModel} from "../../models/IJobRequestResponse";

export interface SelectReduxState extends ReduxBaseState {
    params: JobRequestSearchParameters,
    searchResult: IJobRequestExtendedModel[],
    totalPage: number;
    currentPage: number;
    totalData: number;
    limit: number;
}

const initialState: SelectReduxState = {
    params: {
        type: 0
    },
    searchResult: [],
    totalPage: 1,
    currentPage: 0,
    status: 'init',
    errorMessage: undefined,
    totalData: 1,
    limit: 1
}

export const searchSlice = createSlice({
    initialState,
    name: 'search',
    reducers: {
        searchAddParams: (state, action: PayloadAction<JobRequestSearchParameters>) => {
            state.params = action.payload
        },

        searchAddResult: (state, action: PayloadAction<{ result: IJobRequestExtendedModel[], totalData: number, limit: number, currentPage: number }>) => {
            state.searchResult = action.payload.result
            state.totalData = action.payload.totalData
            state.limit = action.payload.limit
            state.currentPage = action.payload.currentPage
            state.status = 'success'
            state.errorMessage = ''
        },

        searchInit: (state) => {
            state.status = 'init'
        },

        searchLoading: (state) => {
            state.status =  'loading'
            state.searchResult = []
            state.errorMessage = ''
        },

        searchError: (state, action: PayloadAction<string>) => {
            state.status = 'error'
            state.errorMessage = action.payload
        },

        clear: () => initialState
    }
})

export const {
    searchAddParams,
    searchAddResult,
    searchLoading,
    searchInit,
    searchError,
    clear
} = searchSlice.actions
