import React from "react";

interface IconFormFieldProps {
  /**
   * Font Awesome class icon name
   */
  iconName: string;
  children?: React.ReactNode;
}

/**
 * Create wrapper for input with icon left side
 */
const IconFormField: React.FunctionComponent<IconFormFieldProps> = ({
  children,
  iconName,
}): JSX.Element => {
  return (
    <div className="form-icon-group d-flex align-items-center pl-4">
      <i className={iconName} />
      <div className="w-100 ml-4">{children}</div>
    </div>
  );
};

export default IconFormField;
