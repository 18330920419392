import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as auth from "../../../redux/reducers/authRedux";
import { ApplicationState } from "../../../redux/rootReducer";
import { LayoutSplashScreen } from "../../layout/layout";
import { Dispatch } from "redux";
import { jobSlice } from "../../../redux/reducers/jobRedux";
import { dashboardSlice } from "../../../redux/reducers/dashboardRedux";
import { newsSlice } from "../../../redux/reducers/newsRedux";
import { operatorSlice } from "../../../redux/reducers/operatorRedux";
import { selectSlice } from "../../../redux/reducers/selectRedux";
import { recurringSlice } from "../../../redux/reducers/recurringRedux";
import { jobRequestFieldSlice } from "../../../redux/reducers/jobRequestFieldRedux";
import * as temp from "../../../redux/reducers/tempRedux";
import { moduleSlice } from "../../../redux/reducers/moduleRedux";
import { searchSlice } from "../../../redux/reducers/searchRedux";
import { clearFieldState } from "../../../redux/reducers/tempRedux/jobRequestFieldRedux";
import { clearCookieFilter } from "../../../helpers/filtering/redux/cookieFilterRedux";

interface ILogoutProp {
  hasAuthToken: boolean;
  dispatch: Dispatch;
}

class Logout extends Component<ILogoutProp> {
  componentDidMount() {
    this.props.dispatch(auth.authSlice.actions.logout());
    this.props.dispatch(jobSlice.actions.clearData());
    this.props.dispatch(dashboardSlice.actions.clearData());
    this.props.dispatch(newsSlice.actions.clear());
    this.props.dispatch(operatorSlice.actions.clearData());
    this.props.dispatch(selectSlice.actions.clear());
    this.props.dispatch(recurringSlice.actions.clear());
    this.props.dispatch(jobRequestFieldSlice.actions.clear());
    this.props.dispatch(moduleSlice.actions.clear());
    this.props.dispatch(searchSlice.actions.clear());
    this.props.dispatch(clearFieldState());
    this.props.dispatch(temp.clear());
    this.props.dispatch(clearCookieFilter());
  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? (
      <LayoutSplashScreen />
    ) : (
      <Redirect to="/auth/login" />
    );
  }
}
export default connect(
  ({ auth }: ApplicationState) => ({
    hasAuthToken: Boolean(auth?.token),
  }),
  null
)(Logout);
