import { Dropdown } from "react-bootstrap";
import React, { FormEvent, useEffect, useState } from "react";
import { FormGroupRow } from "../index";
import { SearchToolbarCreatedDate } from "./SearchToolbarCreatedDate";
import { SearchToolbarJoinSearch } from "./SearchToolbarJoinSearch";
import { SearchToolbarDay } from "./SearchToolbarDay";

// Type for dot syntax
type SearchToolbarSubComponent = {
  CreatedDate: typeof SearchToolbarCreatedDate;
  JoinSearch: typeof SearchToolbarJoinSearch;
  Day: typeof SearchToolbarDay;
};

interface SearchToolbarProps {
  title?: string;

  /** Show search bar inside Dropdown Filter */
  showInnerSearchBar?: boolean;

  buttonSubmitTitle?: string;

  searchQuery?: string;
  onSearchQueryChange?: (searchQuery: string) => void;

  /** Submit search when user entering the primary search box  */
  onSubmitSearch?: () => void;

  /** Button to clear search */
  onClearSearch?: () => void;

  /** Base searchbar width (in px) */
  searchWidth?: number | "full";

  onSearchbarSubmit?: () => void;

  /** When user showing dropdown, disable the main searchbar (outside dropdown) */
  ignoreDisablingSearchOnDropdownShown?: boolean;

  children?: React.ReactNode;
}

/** @deprecated Use `SearchbarToolbar2` */
const SearchToolbar: React.FunctionComponent<SearchToolbarProps> &
  SearchToolbarSubComponent = ({
  title,
  showInnerSearchBar = true,
  buttonSubmitTitle,
  onSubmitSearch,
  onClearSearch,
  searchWidth,

  searchQuery,
  onSearchQueryChange,

  onSearchbarSubmit,

  children,
  ignoreDisablingSearchOnDropdownShown = false,
}): JSX.Element => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [disableSearchQuery, setDisableSearchQuery] = useState(false);

  useEffect(() => {
    if (!ignoreDisablingSearchOnDropdownShown) {
      setDisableSearchQuery(showDropdown);
    }
  }, [showDropdown]);

  const _onSearchSubmit = (e?: FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();
    if (e && onSearchbarSubmit) {
      onSearchbarSubmit();
    } else {
      setShowDropdown(false);
      if (onSubmitSearch) onSubmitSearch();
    }
  };

  return (
    <div className="mr-4">
      <div className="input-group">
        <form onSubmit={_onSearchSubmit}>
          <input
            type="text"
            className="form-control rounded-right-0 py-6"
            placeholder="Search"
            onChange={(e) => {
              if (onSearchQueryChange) onSearchQueryChange(e.target.value);
            }}
            value={searchQuery}
            style={searchWidth ? { width: `${searchWidth}px` } : {}}
            disabled={disableSearchQuery}
          />
        </form>

        <div className="input-group-append">
          <Dropdown
            show={showDropdown}
            className="p-0 btn btn-light border rounded-right-0"
          >
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="btn btn-light"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              Filter
            </Dropdown.Toggle>

            <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xxl">
              {title && (
                <div className="p-8 border-bottom">
                  <h4 className="mb-0">
                    <span className="mr-4">
                      <i className="fas fa-filter" />
                    </span>
                    {title}
                  </h4>
                </div>
              )}

              <div className="p-8">
                {showInnerSearchBar && (
                  <FormGroupRow label="Search">
                    <input
                      type="text"
                      name="search"
                      className="form-control"
                      onChange={(e) => {
                        if (onSearchQueryChange)
                          onSearchQueryChange(e.target.value);
                      }}
                      value={searchQuery}
                    />
                  </FormGroupRow>
                )}

                {children}
              </div>

              <div className="bg-gray-100 px-8 py-3 text-right rounded-bottom">
                <button
                  type="submit"
                  className="btn btn-primary font-weight-bolder"
                  onClick={() => _onSearchSubmit()}
                >
                  {buttonSubmitTitle ?? `Apply to ${title}`}
                </button>
              </div>
            </Dropdown.Menu>
          </Dropdown>

          <button
            className="btn btn-light border"
            onClick={() => {
              if (onClearSearch) onClearSearch();
            }}
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
};

SearchToolbar.CreatedDate = SearchToolbarCreatedDate;
SearchToolbar.JoinSearch = SearchToolbarJoinSearch;
SearchToolbar.Day = SearchToolbarDay;

export default SearchToolbar;
