import React from "react";

interface BENTableRoundedProps {
  isBordered?: boolean;
  children?: React.ReactNode;
}

/**
 * Create custom table based on [KeenThemes1](https://preview.keenthemes.com/metronic/demo1/features/widgets/advance-tables.html)
 * */
export const BENTableRounded: React.FunctionComponent<BENTableRoundedProps> = ({
  isBordered = false,
  children,
}): JSX.Element => {
  // Pass down bordered props for head
  const childrendWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      // @ts-ignore
      return React.cloneElement(child, { rounded: true });
    }
    return child;
  });

  return (
    <table
      className={`table table-responsive-sm table-head-custom table-head-bg ${
        isBordered ? "table-bordered" : "table-borderless"
      } table-vertical-center table-hover`}
    >
      {childrendWithProps}
    </table>
  );
};
