import React, {useEffect, useState} from "react"
import Constants from "../../../resorces/Constants";

export interface SearchToolbarDayProps {
    value?: number;
    onChange?: (days?: number) => void;
}

export const SearchToolbarDay: React.FC<SearchToolbarDayProps> = ({
    value,
    onChange
}): JSX.Element => {

    const [selectedType, setSelectedType] = useState(value ? "day" : "");

    useEffect(() => {
        if ((value ?? -1) > 0)  setSelectedType('day')
    }, [value])

    return (
        <div className="form-group row">
            <label className="col-form-label col-md-3">
                Day
            </label>

            <div className="col-md-3">
                <select
                    name="type"
                    className="form-control"
                    value={selectedType}
                    onChange={(e) => {
                        if (!e.target.value) {
                            if (onChange) onChange(undefined);
                        }
                        setSelectedType(e.target.value);
                    }}
                >
                    <option value="">-- Checked In --</option>
                    <option value="day">Day</option>
                </select>
            </div>
            {selectedType === "day" && (
                <div className="col-md-4">
                    <select
                        name="days"
                        className="form-control"
                        value={value}
                        onChange={(e) => {
                            if (onChange) onChange(parseInt(e.target.value));
                        }}
                    >
                        <option value="-1">None</option>

                        {Constants.Days.map((day, index) => (
                            <option value={(index + 1)}>{ day }</option>
                        ))}
                    </select>
                </div>
            )}
        </div>
    )
}
