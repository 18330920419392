import React, {HTMLAttributes} from 'react'
import {FieldInputProps, FieldMetaProps} from "formik";
import {FormikMessage} from "./FormikMessage";
import {formikErrorFormClass, makeFormikErrorClass} from "../../../utils/Helpers";

type IFormInput = FieldInputProps<any> & HTMLAttributes<any> & FieldMetaProps<any>

const FormInput: React.VFC<IFormInput> = (props) => {

    const {className, error, touched, ...prop} = props

    const newClassName = `form-control form-control-lg form-control-solid ${className} ${makeFormikErrorClass(error, touched)}`

    return (
        <>
            <input
                className={newClassName}
                {...prop}
            />
            <FormikMessage
                errorMessage={props.error}
                touchedField={props.touched}
            />
        </>
    )
}

export default FormInput