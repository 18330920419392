import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Alert, Modal, Spinner} from "react-bootstrap";
import {FormGroupRow} from "./Forms/FormGroupRow";
import {SelectClient, SelectOperator, SelectProperty} from "./Selects";
import {SearchToolbarCreatedDate, SearchToolbarCreatedDateData} from "./SearchToolbar/SearchToolbarCreatedDate";
import {Button} from "./FormFields/Button";
import {Divider} from "@material-ui/core";
import {DATE_RANGE_SEARCH_FILTER_1} from "../../resorces/strings";
import {SearchModalSubmitDue} from "../../apis/SearchAPI";
import {Link, useHistory} from "react-router-dom";
import {debounce} from "lodash";
import {useLocation} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {searchFillParametersAction, searchJobRequestAction} from "../../redux/actions/searchActions";
import {ApplicationState} from "../../redux/rootReducer";
import Constants from "../../resorces/Constants";
import {useRoleHelper} from "../../resorces/RoleHelper";
import { searchInit } from "../../redux/reducers/searchRedux";

/**
 * Create React context for toggling Search Modal globally
 */
export const ShowSearchModalContext = React.createContext({
    show: false,
    toggleShow: (show: boolean) => {
    }
});

export const SearchModal: React.VFC = (): JSX.Element => {
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const {toggleShow} = useContext(ShowSearchModalContext);
    const {status, searchResult, params} = useSelector((state: ApplicationState) => state.search)

    let searchInputText = useRef<HTMLInputElement>(null)

    const [searchText, setSearchText] = useState("");
    const [searchType, setSearchType] = useState(params.type)
    const [tempSearchText, setTempSearchText] = useState(params.keyword ?? '')
    const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
    const [advanceSearchParam, setAdvanceSearchParam] = useState<SearchModalAdvanceCallback | undefined>()

    useEffect(() => {
        searchInputText.current?.focus()

        return () => {
            searchInputText.current?.blur()
        }
    }, [])

    useEffect(() => {
        console.log(searchType)
        dispatch(searchFillParametersAction({
            ...params,
            offset_page: 0,
            type: showAdvanceSearch ? Constants.SearchType.ADVANCED_SEARCH : searchType,
            keyword: searchText,
            client_id: (advanceSearchParam && advanceSearchParam.client) ? parseInt(advanceSearchParam.client) : undefined,
            property_id: (advanceSearchParam && advanceSearchParam.property) ? parseInt(advanceSearchParam.property) : undefined,
            operator_id: (advanceSearchParam && advanceSearchParam.operator) ? parseInt(advanceSearchParam.operator) : undefined,
            status: advanceSearchParam?.status,
            due: advanceSearchParam?.selectedDue?.due,
            due_number: advanceSearchParam?.selectedDue?.due_number,
            due_days: advanceSearchParam?.selectedDue?.due_days,
            due_start: advanceSearchParam?.selectedDue?.due_start,
            due_end: advanceSearchParam?.selectedDue?.due_end,
            due_current: advanceSearchParam?.selectedDue?.due_current
        }))
        if (advanceSearchParam && searchText.length >= 0) {
            dispatch(searchJobRequestAction())
        }
        if (advanceSearchParam === undefined && searchText.length > 1) {
            dispatch(searchJobRequestAction())
        }
    }, [searchText, searchType, advanceSearchParam])

    useEffect(() => {
        if (searchType) {
            dispatch(searchFillParametersAction({
                ...params,
                type: advanceSearchParam ? Constants.SearchType.ADVANCED_SEARCH : searchType,
            }))
        }
    }, [searchType])

    useEffect(() => {
        if (status === 'success' && searchResult.length > 0) {
            //
            if (location.pathname !== '/searching') {
                history.push('/searching')
            }
            toggleShow(false)
        }
    }, [status, searchResult])

    const _onSearchTextChange = useCallback((text: string) => {
        updateSearchText(text)
        setTempSearchText(text)
    }, [])

    const updateSearchText = debounce((text: string) => {
        setSearchText(text);
    }, 300);

    return (
        <ShowSearchModalContext.Consumer>
            {({show, toggleShow}) => (
                <Modal show={show} onHide={() => {
                    dispatch(searchInit())
                    toggleShow(false)
                }}>
                    <Modal.Body className="p-0">
                        <div
                            className="d-flex align-items-center rounded-bottom-0 border border-gray-300 rounded-top-sm">
                            <div className="flex-grow-1">
                                <input
                                    ref={searchInputText}
                                    type="text"
                                    className="w-100 py-8 px-4 form-control border-0 font-size-h4"
                                    placeholder={showAdvanceSearch ? "Contains the word" : "Search ..."}
                                    value={tempSearchText}
                                    onChange={e => _onSearchTextChange(e.target.value)}
                                />
                            </div>
                            {status === 'loading' && (
                                <div className={'px-4'}>
                                    <Spinner animation={'border'}/>
                                </div>
                            )}
                        </div>

                        {(status === 'success' && searchResult.length === 0) && (
                            <div className={'p-4'}>
                                <Alert variant={'warning'}>
                                    <span><i className="fas fa-exclamation-triangle text-white icon-nm mr-2" /> No Job Request Found</span>
                                </Alert>
                            </div>
                        )}


                        <div className="text-right mt-4 mr-4">
                            <div
                                className="text-black-50 bg-hover-gray-100 d-inline-block px-2 py-1 rounded"
                                onClick={() => setShowAdvanceSearch(!showAdvanceSearch)}
                            >
                                <span>
                                    <i className="fas fa-sliders-h font-size-sm mr-2"/>
                                    Advanced Search
                                </span>
                            </div>
                        </div>

                        {showAdvanceSearch ? (
                            <SearchModalAdvance
                                searchKeyword={searchText}
                                onSearchSubmitted={param => {
                                    setAdvanceSearchParam(param)
                                }}
                            />
                        ) : (
                            <SearchModalRegular
                                searchQuery={searchText}
                                onRequestTypeClick={type => {
                                    setSearchType(type)
                                }}
                            />
                        )}
                    </Modal.Body>
                </Modal>
            )}
        </ShowSearchModalContext.Consumer>
    )
}

interface SearchModalRegularProps {
    searchQuery?: string;
    onRequestTypeClick?: (type: number) => void;
}

const SearchModalRegular: React.VFC<SearchModalRegularProps> = ({
    searchQuery = '',
    onRequestTypeClick
}): JSX.Element => {
    const {isAdmin, isAdministrator} = useRoleHelper()
    const isAdminOrAdministrator = isAdministrator || isAdmin
    return (
        <div className="mb-4">
            <ul className="list-group list-unstyled border-between">
                <li className="bg-hover-gray-100 search-list">
                    {isAdminOrAdministrator && (
                        <button
                            type={'button'}
                            className="w-100 border-0 font-weight-bolder font-size-lg py-4 px-4 text-left bg-white"
                            onClick={() => {
                                if (onRequestTypeClick) onRequestTypeClick(Constants.SearchType.JOB_REQUEST_CREATED)
                            }}
                        >
                            <span>
                                <i className="fas fa-ticket-alt w-25px"/>
                                Job Request I've created
                            </span>
                        </button>
                    )}
                </li>
                <li className="bg-hover-gray-100">
                    <Link
                        to={'/job-request/status/open'}
                        className="d-inline-block w-100 border-0 font-weight-bolder font-size-lg py-4 px-4 text-left bg-white"
                    >
                        <span className={'text-dark'}>
                            <i className="fas fa-asterisk text-danger w-25px"/>
                            New Requests
                        </span>
                    </Link>
                </li>
                <li className="bg-hover-gray-100">
                    <Link
                        to={'/job-request/status/pending'}
                        className="d-inline-block w-100 border-0 font-weight-bolder font-size-lg py-4 px-4 text-left bg-white"
                    >
                        <span className={'text-dark'}>
                            <i className="fas fa-hourglass-half text-mypsr-accent w-25px"/>
                            Pending Requests
                        </span>
                    </Link>
                </li>
                <li className="bg-hover-gray-100">
                    <Link
                        to={'/job-request/status/completed'}
                        className="d-inline-block w-100 border-0 font-weight-bolder font-size-lg py-4 px-4 text-left bg-white"
                    >
                        <span className={'text-dark'}>
                            <i className="fas fa-check-circle text-success w-25px"/>
                            Completed Requests
                        </span>
                    </Link>
                </li>
                <li className="bg-hover-gray-100">
                    <Link
                        to={'/job-request/status/declined'}
                        className="d-inline-block w-100 border-0 font-weight-bolder font-size-lg py-4 px-4 text-left bg-white"
                    >
                        <span className={'text-dark'}>
                            <i className="fas fa-times-circle text-danger w-25px"/>
                            Declined Requests
                        </span>
                    </Link>
                </li>
                <li className="bg-hover-gray-100">
                    <button
                        type={'button'}
                        className="w-100 border-0 font-weight-bolder font-size-lg py-4 px-4 text-left bg-white"
                        onClick={() => {
                            if (onRequestTypeClick) onRequestTypeClick(Constants.SearchType.RECENTLY_COMPLETED_JOB_REQUEST)
                        }}
                    >
                        <span>
                            <i className="fas fa-clipboard-check text-success w-25px"/>
                            Recently Completed Job Requests
                        </span>
                    </button>
                </li>
            </ul>
        </div>
    )
}

type JobRequestStatus = 'Pending' | 'Accepted' | 'Completed'

interface SearchModalAdvanceCallback {
    client?: string;
    property?: string;
    operator?: string;
    status?: JobRequestStatus;
    selectedDue?: SearchModalSubmitDue
}

interface SearchModalAdvanceProps {
    searchKeyword?: string;
    onSearchSubmitted?: (searchParam: SearchModalAdvanceCallback) => void;
}

const SearchModalAdvance: React.VFC<SearchModalAdvanceProps> = ({
                                                                    searchKeyword = '',
                                                                    onSearchSubmitted
                                                                }): JSX.Element => {
    const history = useHistory();
    const {isAdmin, isAdministrator} = useRoleHelper()
    const isAdminOrAdministrator = isAdministrator || isAdmin

    const search = useSelector((state: ApplicationState) => state.search)

    const [selectedClient, setSelectedClient] = useState("");
    const [selectedProperty, setSelectedProperty] = useState('')
    const [selectedOperator, setSelectedOperator] = useState("");
    const [selectedStatus, setSelectedStatus] = useState<JobRequestStatus | undefined>();
    const [selectedDue, setSelectedDue] = useState<SearchToolbarCreatedDateData | undefined>();

    useEffect(() => {
        setSelectedClient(search.params.client_id?.toString() ?? "")
        setSelectedProperty(search.params.property_id?.toString() ?? "")
        setSelectedOperator(search.params.operator_id?.toString() ?? "")
        setSelectedStatus(search.params.status as JobRequestStatus)
        setSelectedDue({
            type: search.params.status,
            alias: search.params.due,
            within: { total: search.params.due_number, type: search.params.due },
            between: { start: search.params.due_start, end: search.params.due_end },
            on: search.params.due_current
        })
    }, [search.params])

    const onSearch = useCallback(() => {
        let due: SearchModalSubmitDue = {};
        if (selectedDue) {
            if (selectedDue.alias === "within_the_next" || selectedDue.alias === "within_the_last") {
                due = {
                    due: selectedDue?.type,
                    due_days: selectedDue?.within?.type,
                    due_number: selectedDue?.within?.total
                };
            }

            if (selectedDue.alias === "on") {
                due = {
                    due: selectedDue?.type,
                    due_current: selectedDue.on
                }
            }

            if (selectedDue.alias === "between") {
                due = {
                    due: selectedDue?.type,
                    due_start: selectedDue?.between?.start,
                    due_end: selectedDue?.between?.end
                }
            }
        }

        if (onSearchSubmitted) onSearchSubmitted({
            client: selectedClient,
            property: selectedProperty,
            operator: selectedOperator,
            status: selectedStatus,
            selectedDue: due
        })
    }, [selectedDue, history, searchKeyword, selectedClient, selectedProperty, selectedOperator, selectedStatus]);

    return (
        <div className="m-4">
            {isAdminOrAdministrator && (
                <FormGroupRow label="Client">
                    <SelectClient
                        value={selectedClient}
                        onChange={client => setSelectedClient(client)}
                    />
                </FormGroupRow>
            )}

            {isAdminOrAdministrator && (
                <FormGroupRow label={'Property'}>
                    <SelectProperty
                        clientId={selectedClient ? parseInt(selectedClient) : undefined}
                        value={selectedProperty}
                        onItemSelected={item => {
                            setSelectedProperty(item.value)
                            setSelectedClient(item.clientId)
                        }}
                    />
                </FormGroupRow>
            )}

            {isAdminOrAdministrator && (
                <FormGroupRow label="Assigned to">
                    <SelectOperator
                        value={selectedOperator}
                        onChange={operator => setSelectedOperator(operator)}
                    />
                </FormGroupRow>
            )}

            <FormGroupRow label="Status">
                <div className="btn-group">
                    <div
                        className={`btn ${selectedStatus === 'Pending' ? "btn-primary" : "btn-light"}`}
                        onClick={() => setSelectedStatus('Pending')}
                    >
                        Pending
                    </div>
                    <div
                        className={`btn ${selectedStatus === 'Accepted' ? "btn-primary" : "btn-light"}`}
                        onClick={() => setSelectedStatus('Accepted')}
                    >
                        Open
                    </div>
                    <div
                        className={`btn ${selectedStatus === 'Completed' ? "btn-primary" : "btn-light"}`}
                        onClick={() => setSelectedStatus('Completed')}
                    >
                        Completed
                    </div>
                </div>
            </FormGroupRow>

            <SearchToolbarCreatedDate
                value={selectedDue}
                createdDateSelect={DATE_RANGE_SEARCH_FILTER_1}
                onChange={due => setSelectedDue(due)}
            />

            <Divider/>

            <div className="text-right mt-4">
                <Button
                    className="btn btn-mypsr"
                    isLoading={search.status === 'loading'}
                    onClick={onSearch}
                >
                    <i className="fas fa-search mr-2 icon-nm"/>
                    Search
                </Button>
            </div>
        </div>
    )
}
