import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

interface FormLabelTooltipProps {
    label: string;
    tooltip?: string;
    className?: string
}

export const FormLabelTooltip: React.VFC<FormLabelTooltipProps> = ({label, tooltip, className}): JSX.Element => {

    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {tooltip}
        </Tooltip>
    );

    return (
        <label htmlFor={label} className={className ?? "col-md-2 col-form-label"}>
            <span>
                {label}
                {tooltip && (
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <i className="fas fa-question-circle icon-nm text-mypsr-accent mx-2" />
                    </OverlayTrigger>
                )}
            </span>
        </label>
    )
}
