import {CreateDateItem} from "../app/components/SearchToolbar/SearchToolbarCreatedDate";

export const EMPTY_IMAGE_DEFAULT_PATH = `${window.location.origin}/images/no-image.png`

export const UNIT_ITEMS: {label: string; value: string}[] = [
    {label: 'AMPULES', value: 'AMP'},
    {label: 'BAGS', value: 'BAG'},
    {label: 'BALE', value: 'BAL'},
    {label: 'BUNDLES', value: 'BDL'},
    {label: 'BUCKLES', value: 'BKL'},
    {label: 'BILLIONS OF UNITS', value: 'BOU'},
    {label: 'BOX', value: 'BOX'},
    {label: 'BOTTLES', value: 'BTL'},
    {label: 'BUNCHES', value: 'BUN'},
    {label: 'CANS', value: 'CAN'},
    {label: 'CUBIC METER', value: 'CBM'},
    {label: 'CUBIC CENTIMETER', value: 'CCM'},
    {label: 'CENTIMETER', value: 'CMS'},
    {label: 'CARAT', value: 'CRT'},
    {label: 'CARTONS', value: 'CTN'},
    {label: 'DOZEN', value: 'DOZ'},
    {label: 'DRUM', value: 'DRM'},
    {label: 'FEET', value: 'FTS'},
    {label: 'GREAT GROSS', value: 'GGR'},
    {label: 'GRAMS', value: 'GMS'},
    {label: 'GROSS', value: 'GRS'},
    {label: 'GROSS YARDS', value: 'GYD'},
    {label: 'KILOGRAM ACTIVITY', value: 'KGA'},
    {label: 'KILOGRAM BASE', value: 'KGB'},
    {label: 'KILOGRAMS', value: 'KGS'},
    {label: 'KITS', value: 'KIT'},
    {label: 'KILOLITER', value: 'KLR'},
    {label: 'KILOMETERS', value: 'KME'},
    {label: 'POUNDS', value: 'LBS'},
    {label: 'LITERS', value: 'LTR'},
    {label: 'MILIGRAMS', value: 'MGS'},
    {label: 'MILLION KEASERGEN', value: 'MKU'},
    {label: 'MILLILITER', value: 'MLT'},
    {label: 'MILLIONS OF UNIT', value: 'MOU'},
    {label: 'METER', value: 'MTR'},
    {label: 'METRIC TON', value: 'MTS'},
    {label: 'MILLION UNITS', value: 'MUS'},
    {label: 'NUMBER', value: 'NOS'},
    {label: 'PACKS', value: 'PAC'},
    {label: 'PIECES', value: 'PCS'},
    {label: 'PAIRS', value: 'PRS'},
    {label: 'QUINTAL', value: 'QTL'},
    {label: 'ROLLS', value: 'RLS'},
    {label: 'ROLLS', value: 'ROL'},
    {label: 'SETS', value: 'SET'},
    {label: 'SQUARE FEET', value: 'SQF'},
    {label: 'SQUARE METER', value: 'SQM'},
    {label: 'SQUARE YARDS', value: 'SQY'},
    {label: 'TABLETS', value: 'TBS'},
    {label: 'TEN GRAMS', value: 'TGM'},
    {label: 'THOUSANDS', value: 'THD'},
    {label: 'GREAT BRITAIN TON', value: 'TON'},
    {label: 'TUBES', value: 'TUB'},
    {label: 'US GALLONS', value: 'UGS'},
    {label: 'UNITS', value: 'UNT'},
    {label: 'VIALS', value: 'VLS'},
    {label: 'YARDS', value: 'YDS'}
];

export const DATE_RANGE_SEARCH_FILTER_1: CreateDateItem[] = [
    {value: "", label: ""},
    {value: "next", label: "Within the next", as: "within_the_next"},
    {value: "last", label: "Within the last", as: "within_the_last"},
    {value: "between", label: "Between"},
    {value: "on", label: "On"},
];
