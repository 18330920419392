import React, { FormEvent, ReactNode } from "react";
import { SubHeader } from "../../layout/layout/components/subheader/SubHeader";
import { CardDashboardBody } from "./CardDashboardBody";
import { CardDashboardFooter } from "./CardDashboardFooter";
import {
  CardDashboardHeader,
  CardDashboardHeaderProps,
} from "./CardDashboardHeader";
import { Spinner } from "react-bootstrap";

interface CardDashboardProp extends CardDashboardHeaderProps {
  /** Adding `React.Component` inline of subheader */
  subheaderToolbar?: NonNullable<ReactNode> | null;

  /** Hide top toolbar */
  disableSubheaderToolbar?: boolean;

  /** Wrap card body with card footer with form */
  onFormSubmit?: (e: FormEvent<HTMLFormElement>) => void;

  overlayLoading?: boolean;

  /** Hide only text on title */
  hideTitleText?: boolean;

  children?: React.ReactNode;
}

type CardDashboardComponent<T> = React.FunctionComponent<T> & {
  Body: typeof CardDashboardBody;
  Footer: typeof CardDashboardFooter;
};

const CardDashboard: CardDashboardComponent<CardDashboardProp> = ({
  subheaderToolbar,
  onFormSubmit,
  disableSubheaderToolbar = false,
  children,
  overlayLoading = false,
  ...props
}): JSX.Element => {
  return (
    <>
      {!disableSubheaderToolbar && <SubHeader toolbar={subheaderToolbar} />}

      <div className="card card-custom gutter-b position-relative">
        <CardDashboardHeader {...props} />
        {onFormSubmit ? (
          <form
            onSubmit={(e) => {
              onFormSubmit(e);
            }}
          >
            <div className="px-4 pt-4">{children}</div>
          </form>
        ) : (
          children
        )}

        {overlayLoading && (
          <div className="position-absolute blocked-ui top-0 left-0 right-0 bottom-0 rounded text-center py-8">
            <Spinner animation="border" variant="success" />
          </div>
        )}
      </div>
    </>
  );
};

CardDashboard.Body = CardDashboardBody;
CardDashboard.Footer = CardDashboardFooter;

export default CardDashboard;
