import {useSelector} from "react-redux";
import {ApplicationState} from "../redux/rootReducer";
import Constants from "./Constants";
import {arrayContains} from "../utils/Helpers";

interface RoleHelperReturn {
    isSuperAdmin: boolean
    isAdmin: boolean
    isAdministrator: boolean
    isAdminAdministrator: boolean
    isOperator: boolean
    isClient: boolean
}

export const useRoleHelper = (): RoleHelperReturn => {
    const {user, sandboxedAuth} = useSelector((state: ApplicationState) => state.auth)
    if (sandboxedAuth) {
        return parseRole(sandboxedAuth.user.role)
    } else {

        return parseRole(user.role)
    }
}

export const parseRole = (roles: string[]): RoleHelperReturn => {
    const isSuperAdmin = arrayContains(roles, Constants.Roles.RoleSuperAdmin)
    const isAdmin = arrayContains(roles, Constants.Roles.RoleAdmin)
    const isAdministrator = arrayContains(roles, Constants.Roles.RoleAdministrator)

    const isAdminAdministrator = isSuperAdmin || isAdmin || isAdministrator

    return {
        isSuperAdmin,
        isAdmin,
        isAdministrator,
        isAdminAdministrator,
        isOperator: arrayContains(roles, Constants.Roles.RoleOperator),
        isClient: arrayContains(roles, Constants.Roles.RoleClient)
    }
}
