import axios, { AxiosStatic } from "axios";
import { format } from "date-fns";

interface BaseResponse {
  success: boolean;
  messages: string[] | string;
  message?: string[] | string;
}

interface IAPIConfig {
  formatDate?: string;
  defaultErrorMessage?: string;
}

export const API = (axiosStatic: AxiosStatic = axios) => {
  const defaultConfig: IAPIConfig = {
    formatDate: "dd-MM-yyyy",
    defaultErrorMessage: "Data not found",
  };

  /**
   * Convert object to form data defined array parameter
   *
   * Example (with prefix "hello"):
   * ```
   * _makeFormDataBody({
   *     field1: "value1",
   *     field2: {
   *         subField1: "field2.value1",
   *         subField2: "field2.value2"
   *     },
   *     field3: [
   *         "field3.value1",
   *         "field3.value2"
   *     ],
   * }, "hello");
   * ```
   *
   * Will be generated into
   * ```
   * [
   *  { name: "hello[field1]", value: "value1" },
   *  { name: "hello[field2][subField1]", value: "field2.value1" },
   *  { name: "hello[field2][subField2]", value: "field2.value2" }
   *  { name: "hello[field3][]", value: "field3.value1" }
   *  { name: "hello[field3][]", value: "field3.value2" }
   * ]
   * ```
   *
   * @param {FormDataValueBaseType<FormDataValueType>} data Object data to be generated
   * @param {string?} prefix Prefix for start of string, when not null it will added string to beginning of value
   *                          Example: prefix = start, will be generated into `start[someObject]`
   * @param config
   * @return {FormDataReturnType[]} Generated data to executed to form data
   *
   * @see FormDataConfig
   * */
  const convertObjectAsKeyVal = (
    data: { [key: string]: any },
    prefix?: string,
    config = defaultConfig
  ): { field: string; value: any }[] => {
    let formBody: { field: string; value: any }[] = [];

    Object.keys(data).forEach((key) => {
      const fieldName: string = prefix ? `${prefix}[${key}]` : key;
      const fieldValue = data[key];

      // Checking if the data is an object,
      if (typeof fieldValue === "object") {
        // TODO: Handle for File Later
        if (fieldValue instanceof Date) {
          // If the `object` is instance of date, make it formatted to string
          let dateAsString: string = format(
            fieldValue,
            config?.formatDate ?? "dd-MM-yyyy"
          );

          formBody.push({
            field: fieldName,
            value: dateAsString,
          });
        } else {
          const subFormData = convertObjectAsKeyVal(
            fieldValue,
            fieldName,
            config
          );
          formBody = [...formBody, ...subFormData];
        }
      } else if (Array.isArray(fieldValue)) {
        // Iterate each element
        fieldValue.forEach((value) => {
          formBody.push({
            field: fieldName + "[]",
            value,
          });
        });
      } else if (fieldValue === undefined) {
        // If value is undefined dont append it.
      } else {
        let value = fieldValue?.toString() ?? "";
        formBody.push({
          field: fieldName,
          value: value,
        });
      }
    });

    return formBody;
  };

  const _convertKeyValAsFormData = (
    item: { field: string; value: any }[]
  ): FormData => {
    throw "To Implement Later";
  };

  const _parseMessage = (messages: string | string[] | undefined): string => {
    let errorMessage = "";
    if (Array.isArray(messages)) {
      errorMessage = messages.join(", ");
    } else {
      errorMessage = messages ?? "Something wrong";
    }
    return errorMessage;
  };

  const get = async <T extends BaseResponse>(
    url: string,
    param: { [key: string]: any }
  ): Promise<[T | undefined, string | undefined]> => {
    const { data, status, statusText } = await axiosStatic.get<T>(url);
    if (status === 200) {
      if (data.success) {
        return Promise.resolve([data, undefined]);
      } else {
        return Promise.resolve([
          undefined,
          _parseMessage(data.message ?? data.messages),
        ]);
      }
    } else {
      return Promise.resolve([undefined, "Something Wrong"]);
    }
  };
  const post = async <T extends BaseResponse>(
    url: string,
    param?: { [key: string]: any } | FormData | any,
    apiConfig = defaultConfig
  ): Promise<
    [(T & { parsedMessage: string }) | undefined, string | undefined]
  > => {
    let parsedParam: any | undefined;
    if (param) {
      if (param instanceof FormData) {
        parsedParam = param;
      } else {
        const parsed = convertObjectAsKeyVal(param, "", apiConfig);

        let formData = new FormData();
        parsed.forEach((data) => {
          formData.append(data.field, data.value);
        });
        parsedParam = formData;
      }
    }

    const { data, status, statusText } = await axiosStatic.post<T>(
      url,
      parsedParam
    );
    if (status === 200) {
      let parsedMessage = _parseMessage(data.message ?? data.messages);
      if (data.success) {
        return Promise.resolve([
          {
            ...data,
            parsedMessage: parsedMessage,
          },
          undefined,
        ]);
      } else {
        if (parsedMessage) {
          return Promise.resolve([undefined, parsedMessage]);
        } else {
          return Promise.resolve([undefined, apiConfig.defaultErrorMessage]);
        }
      }
    } else {
      return Promise.resolve([undefined, "Something Wrong"]);
    }
  };

  return { get, post, convertObjectAsKeyVal };
};
