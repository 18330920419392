import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../redux/rootReducer";
import React, {useEffect, useState} from "react";
import {SelectItem} from "../../../models/SelectItem";
import {attendanceTrackingPropertySelectAction} from "../../../redux/actions/selectActions";

interface SelectAttendanceTrackingPropertyProps {
    clientId?: string;

    onChange?: (propertyTrackingId: string) => void;
    value?: string;
    disabled?: boolean;
}

export const SelectAttendanceTrackingProperty: React.FunctionComponent<SelectAttendanceTrackingPropertyProps> = ({
     onChange,
     value,
     clientId,
    disabled = false
 }): JSX.Element => {

    const dispatch = useDispatch();
    const {
        selectItems,
        status,
        errorMessage
    } = useSelector((state: ApplicationState) => state.select.attendanceTrackingProperty);

    const [selectedItem, setSelectedItem] = useState<SelectItem | undefined | null>();

    if (process.env.NODE_ENV === "development") {
        if (errorMessage) console.log("SELECT_ATTENDANCE_PROPERTY_ERROR", errorMessage);
    }

    const _selectItemWithEmptyValue: SelectItem[] = [{ value: "", label: "Select Attendance Tracking Property"}, ...selectItems];

    // Effect for initial data
    useEffect(() => {
        _getInitialData();
    }, []);

    useEffect(() => {
        _getInitialData(clientId);
    }, [clientId]);

    // Effect for value change
    useEffect(() => {
        _onValueChange(value);
    }, [value]);

    const _getInitialData = (clientId?: string) => {
        dispatch(attendanceTrackingPropertySelectAction(clientId));
    }

    const _onValueChange = (selectedValue?: string) => {
        const found = selectItems.find(item => item.value === selectedValue);
        setSelectedItem(found ?? null);
    }

    return (
        <Select
            isLoading={status === "loading"}
            isDisabled={disabled}
            options={_selectItemWithEmptyValue}
            value={selectedItem}
            styles={{
                control: (styles) => {
                    return { ...styles, zIndex: 2 }
                },
                menu: (base) => {
                    return { ...base, zIndex: 3}
                }
            }}
            onChange={item => {
                if (onChange && item) {
                    onChange(item.value);
                }
            }}
        />
    )
}
