import React from "react";
import {AlertPlaceholder, AlertPlaceholderProps} from "../AlertPlaceholder";

interface BENTableEmptyDataProps extends AlertPlaceholderProps {
    colSpan: number;
}

export const BENTableEmptyData: React.FC<BENTableEmptyDataProps> = ({
    placeholderName,
    noResultMessage = false,
    colSpan
}): JSX.Element => {
    return (
        <tr>
            <td colSpan={colSpan}>
                <AlertPlaceholder
                    placeholderName={placeholderName}
                    noResultMessage={noResultMessage}
                />
            </td>
        </tr>

    )
}
