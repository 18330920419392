import React from "react";

interface SpinnerProp {
    /**
     * How large spinner
     * */
    size?: "lg" | "sm"
}

/**
 * Create circular loading indicator
 */
export const Spinner: React.FunctionComponent<SpinnerProp> = ({ size }): JSX.Element => {
    const spinnerSize = size ? `spinner-${size}` : "";
    return (
        <div className="py-4">
            <p style={{ textAlign: 'center' }}><span className={`spinner ${spinnerSize} spinner-dark`} /></p>
        </div>
    )
}
