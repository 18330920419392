import React from 'react'
import {TextIcon} from "../../pages/Dashboard/components/TextIcon";
import {constructMapsURL} from "../../../utils/Helpers";
import {useRoleHelper} from "../../../resorces/RoleHelper";
import {IJobRequestModel} from "../../../models/IJobRequestResponse";

interface IJobRequestDropdownDetailClient {
    jobRequestInfo?: IJobRequestModel
}

export const JobRequestDropdownDetailClient: React.VFC<IJobRequestDropdownDetailClient> = (props) => {
    const {isClient, isOperator} = useRoleHelper()
    const disableLink = (isClient || isOperator)

    const {jobRequestInfo} = props

    return (
        <div className="col-lg-5 col-md-12">
            <div className="font-weight-bolder font-size-lg mb-2">Client</div>

            <hr/>

            <TextIcon
                title={jobRequestInfo?.client.fullname_client ?? ""}
                fontAwesomeIcon="user"
                disableLink={disableLink}
                linkHref={`/clients/${jobRequestInfo?.client.id}/show`}
                bold
                darkIcon
            />

            <TextIcon
                title={jobRequestInfo?.property.title ?? ""}
                fontAwesomeIcon="home"
                disableLink={disableLink}
                linkHref={`/property/${jobRequestInfo?.property.id}/show`}
                bold
                darkIcon
            />

            <TextIcon
                title={jobRequestInfo?.property.address ?? ""}
                fontAwesomeIcon="map-marker-alt"
                disableLink={disableLink}
                href={constructMapsURL(jobRequestInfo?.property.address ?? "")}
                darkIcon
            />

            <TextIcon
                title={jobRequestInfo?.client.email ?? ""}
                fontAwesomeIcon="envelope"
                disableLink={disableLink}
                href={`mailto:${jobRequestInfo?.client.email}`}
                darkIcon
            />

            <TextIcon
                title={jobRequestInfo?.client.phone ?? ""}
                fontAwesomeIcon="phone"
                disableLink={disableLink}
                href={`tel:${jobRequestInfo?.client.phone}`}
                darkIcon
            />
        </div>
    )
}
