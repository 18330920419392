import Select from "react-select";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../redux/rootReducer";
import {SelectItem, SelectItemOperator} from "../../../models/SelectItem";
import {useEffect, useState} from "react";
import {arrayContains} from "../../../utils/Helpers";

type SelectOperatorSelectType<IsMultiple extends boolean> = IsMultiple extends true ? string[] : string;

interface SelectOperatorProps<IsMultiple extends boolean = false> {
    value?:                 SelectOperatorSelectType<IsMultiple>;
    onChange?:              (operatorId: SelectOperatorSelectType<IsMultiple>) => void;
    isMultiSelect?:         IsMultiple;
    disabled?:              boolean;

    /**
     * When this prop is not null, filter the `operatorId`
     * show it not showing on select item
     */
    filterOperatorId?:      string | string[];

    /***
     * Filter data by selected `id` operator from `property_accesses` field
     */
    filterByPropertyId?:    string;
}

export function SelectOperator<Multiple extends boolean = false>({
    value,
    onChange,
    isMultiSelect,
    disabled = false,
    filterOperatorId,
    filterByPropertyId = ''
}: SelectOperatorProps<Multiple>): JSX.Element {

    useEffect(() => {
        _onOperatorValueChange(value);
    }, [value])

    const [selectedOperator, setSelectedOperator] = useState<SelectItem[] | undefined | null>();

    const operatorState = useSelector((state: ApplicationState) => state.select.operator);

    let operatorItems: SelectItemOperator[] = [];

    if (operatorState) {
        operatorItems = operatorState.selectItems;
        if (Array.isArray(filterOperatorId)) {
            let _operatorItems: SelectItem[] = []
            operatorItems.forEach((item) => {
                if (!arrayContains(filterOperatorId, item.value)) {
                    _operatorItems.push(item)
                }
            });
            operatorItems = _operatorItems
        } else if (filterOperatorId) {
            operatorItems = operatorItems.filter(item => item.value !== filterOperatorId)
        }
    }

    operatorItems = [{value: "", label: "All Operators"}, ...operatorItems];

    if (filterByPropertyId) {
        let tempOperatorItems: SelectItemOperator[] = []
        operatorItems.forEach((o) => {
            if (o.propertyAccess) {
                let addToArray = false
                o.propertyAccess.forEach((i) => {
                    if (!addToArray) {
                        addToArray = i.id.toString() === filterByPropertyId
                        tempOperatorItems.push(o)
                    }
                })
            }
        })
        operatorItems = tempOperatorItems
    }

    const _onOperatorValueChange = (operatorId?: SelectOperatorSelectType<Multiple>) => {
        let _defaultValue: SelectItem[] | undefined = [];
        if (operatorId) {
            if (isMultiSelect && Array.isArray(operatorId)) {
                operatorItems.forEach((item) => {
                    operatorId.forEach((op) => {
                        if (item.value === op) _defaultValue!.push(item);
                    })
                })
            } else {
                const found = operatorItems.find(v => v.value === operatorId);
                _defaultValue = found ? [found] : undefined
            }
        }
        setSelectedOperator(_defaultValue);
    }

    return (
        <Select
            options={operatorItems}
            value={selectedOperator}
            isDisabled={disabled}
            isMulti={isMultiSelect}
            onChange={(data: any) => {
                if (onChange && data) {
                    if (Array.isArray(data)) {
                        onChange(data.map((value) => value.value) as SelectOperatorSelectType<Multiple>);
                    } else {
                        onChange(data.value);
                    }
                }
            }}
            styles={{
                control: (styles) => {
                    return { ...styles, zIndex: 2 }
                },
                menu: (base) => {
                    return { ...base, zIndex: 3}
                }
            }}
        />
    )
}

// Create Disable select for operator
export const SelectOperatorDisabled: React.VFC = () => {
    const user = useSelector((state: ApplicationState) => state.auth.user)

    const selectItem = [{value: user.operator!.id.toString(), label: user.operator!.fullname_operator}]

    return (
        <Select
            isDisabled
            options={selectItem}
            value={selectItem[0]}
            styles={{
                control: (styles) => {
                    return { ...styles, zIndex: 2 }
                },
                menu: (base) => {
                    return { ...base, zIndex: 3}
                }
            }}
        />
    )
}
