import React from 'react'
import {TextIcon} from "../../pages/Dashboard/components/TextIcon";
import {IJobRequestRecurringInformationModel} from "../../../models/IJobRequestRecurringInformationModel";

interface IJobRequestDopdownDetailRecurringPattern {
    recurringPattern?: IJobRequestRecurringInformationModel
}

export const JobRequestDopdownDetailRecurringPattern: React.VFC<IJobRequestDopdownDetailRecurringPattern> = (props) => {
    const {
        recurringPattern
    } = props

    if (recurringPattern) {
        return (<>
            <div className="row">
                <div className="col-lg-12">
                    <div className="font-weight-bolder font-size-lg">Recurrence Pattern Details</div>
                    <hr className="my-3"/>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-lg-6 col-xs-12">
                    <TextIcon
                        title="Frequency"
                        textRight={recurringPattern.globaljobrequest_freq_string ?? "-"}
                        fontAwesomeIcon="clock"
                        darkIcon
                    />

                    <TextIcon
                        title="Start"
                        textRight={recurringPattern.globaljobrequest_dtstart_string ?? "-"}
                        fontAwesomeIcon="ticket-alt"
                        darkIcon
                    />

                    <TextIcon
                        title="Until"
                        textRight={recurringPattern.globaljobrequest_until_string ?? "-"}
                        fontAwesomeIcon="ticket-alt"
                        darkIcon
                    />
                </div>
                <div className="col-lg-6 col-xs-12">
                    <TextIcon
                        title="Request Time"
                        textRight={recurringPattern.globaljobrequest_requesttime ?? "-"}
                        fontAwesomeIcon="ticket-alt"
                        darkIcon
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="font-weight-bolder font-size-lg">Estimate Recurring</div>
                    <hr className="my-3"/>

                    { recurringPattern.schedules?.map((schedule) => (
                        <div key={schedule.number} className="bg-gray-400 rounded p-2 mb-2">
                            <span className="font-weight-bolder">
                                Recurring #{schedule.number},
                                Request Time: {schedule.show_requesttime}
                            </span>
                        </div>
                    )) }
                </div>
            </div>
        </>)
    } else {
        return <div />
    }
}
