import axios from "axios";
import {ApiResponse, APIResponse, BaseSearchParameters, ClientPropertySearchParameter} from "./BaseAPI";
import {
    AttendanceTrackingPropertyLocationInventorySelect
} from "../models/AttendanceTrackingPropertyLocationInventorySelect";
import {AttendanceListing} from "../models/AttendanceListing";
import {AttendanceListingRegisterHistory} from "../models/AttendanceListingRegisterHistory";
import {BaseResponse} from "../models/BaseModel/BaseResponse";
import {makeErrorMessage} from "../utils/Helpers";
import {SelectParam} from "./SelectAPI";
import {makeFormData, postToApi} from "../utils/APIHelper";
import {BaseReportRequestFile} from "../models/BaseModel/BaseReportRequestFile";
import {HistoryDate} from "../models/AttendanceRegisterReportHistory";
import {SelectItem} from "../models/SelectItem";

interface AttendanceTrackingPropertyLocationInventorySelectProp extends SelectParam {
    is_own_client?: number;
    client_id?: string;
}

export function attendanceTrackingPropertyLocationInventorySelect(
    param?: AttendanceTrackingPropertyLocationInventorySelectProp,
    isFromApi = false
) : ApiResponse<SelectItem[]> {
    const prefix = isFromApi ? "api/" : "";
    return postToApi<SelectItem[], AttendanceTrackingPropertyLocationInventorySelect>({
        url: prefix + "attendance/tracking/property/location/asset/inventory/select",
        data: param
    }, data => data.location.map(item => {
        return { value: item.id.toString(), label: item.title };
    }));
}

export function attendanceListing(param?: ClientPropertySearchParameter): APIResponse<AttendanceListing> {
    return axios.post("api/attendance/register/property/location/listing", makeFormData({...param}));
}

export interface AttendanceSearch extends ClientPropertySearchParameter, BaseSearchParameters {
    check_in_start?:         string;
    check_in_end?:           string;
    property_location_id?:   string;
    operator_id?:            string;
    administrator_id?:       string;
}

export const makeAttendanceSearch = (search?: AttendanceSearch): FormData => makeFormData({...search})

export function attendanceListingRegisterHistory(search?: AttendanceSearch): ApiResponse<AttendanceListingRegisterHistory> {
    return postToApi<AttendanceListingRegisterHistory, AttendanceListingRegisterHistory>({
        data: search,
        url: "api/attendance/register/history/listings"
    }, data => data)
}

export interface AttendancePropertyCreateParam {
    client_id?:             string;
    property_id?:           string;
    property_location_id?:  number;
    location_title:         string;
    location_type?:         string;
}

export function attendancePropertyCreate(param: AttendancePropertyCreateParam, propertyLocationId?: number): ApiResponse<string> {
    const URL = propertyLocationId
        ? `api/attendance/register/property/location/${propertyLocationId}/update`
        : "api/attendance/register/property/location/new";

    return postToApi({
        url: URL,
        data: param
    }, (data1, message) => message!);
}

export function attendancePropertyLocationDelete(propertyLocationId: number): ApiResponse<{ message: string }> {
    return postToApi({
        url: `api/attendance/register/property/location/${propertyLocationId}/remove`,
        data: { property_location_id: propertyLocationId }
    }, (data, message) => {
        return { message: message! };
    });
}

export interface AttendanceSiteAttendanceTracking {
    property_location_id:   string;
    operator_id:            string;
    administrator_id:       string;
    check_in?:              Date;
    check_out?:             Date;
}

export function attendanceSiteAttendanceTrackingNew(data: AttendanceSiteAttendanceTracking): ApiResponse<string> {
    return new Promise((resolve) => {
        const formData = makeFormData({...data}, "history_site_register", { dateFormat: "dd-MM-yyyy HH:mm"});
        axios.post<BaseResponse>('api/attendance/register/new', formData).then(({ data }) => {
            const msg = makeErrorMessage(data.messages);
            if (data.success) {
                resolve([msg, undefined]);
            } else {
                resolve([undefined, msg]);
            }
        }).catch(e => resolve([undefined, e.toString()]));
    })
}

export function attendanceSiteAttendanceDelete(attendanceId: number): ApiResponse<string> {
    return new Promise((resolve) => {
        const formData = new FormData();
        formData.append("property_location_history_id", attendanceId.toString());
        axios.post<BaseResponse>(`api/attendance/register/${attendanceId}/delete`, formData).then(({ data }) => {
            const msg = makeErrorMessage(data.messages);
            if (data.success) {
                resolve([msg, undefined]);
            } else {
                resolve([undefined, msg]);
            }
        }).catch(e => resolve([undefined, e.toString()]));
    })
}

export function attendanceRegisterReportExcel(filter?: AttendanceSearch): ApiResponse</* file_url */string> {
    return new Promise(resolve => {
        axios.post<BaseReportRequestFile>("api/attendance/register/report/excel", makeFormData({...filter}))
            .then(({ data }) => {
                if (data.success) resolve([data.url, undefined]);
                else resolve([undefined, makeErrorMessage(data.messages)]);
            }).catch(e => resolve([undefined, e.toString()]));
    })
}

export interface AttendanceRegisterReportHistoryParam extends BaseSearchParameters {
    start?:                 Date;
    end?:                   Date;
    operator_id?:           string;
    property_id?:           string;
    property_location_id?:  string;
    current_admin_id?:      string;
    is_both?:               string;
}

export function attendanceRegisterReportHistory(param?: AttendanceRegisterReportHistoryParam): ApiResponse<HistoryDate> {
    return postToApi<HistoryDate, HistoryDate>({
        url: "api/attendance/register/report/history",
        data: param
    }, data => data)
}
