import React, {CSSProperties} from "react";

export interface BENTableHeader {
    columnName: string;
    order: "ASC" | "DESC";
}

interface BENTableHeadProp {
    /** Create column for table head */
    column:             string[];

    selectedColumnName?:    string[];

    onColumnHeaderClick?:   (orderColumn: BENTableHeader) => void;

    columnHeaderValue?:     BENTableHeader;


    /** Add column size for table data head */
    columnMinWidth?:    number[];

    columnMaxWidth?:    number[];

    bordered?:          boolean;

    rounded?:           boolean;
}

export const BENTableHead: React.FunctionComponent<BENTableHeadProp> = ({
    column,
    selectedColumnName,
    columnMinWidth,
    columnMaxWidth,
    bordered = false,
    onColumnHeaderClick,
    columnHeaderValue
}): JSX.Element => {

    const _onColumnClicked = (position: number) => {
        if (selectedColumnName && selectedColumnName[position]) {
            const columnItemName = selectedColumnName[position];

            let columnOrderString: "ASC" | "DESC";
            if (columnHeaderValue?.columnName === columnItemName) {
                columnOrderString = columnHeaderValue.order === "ASC" ? "DESC" : "ASC";
            } else {
                columnOrderString = "ASC";
            }

            if (onColumnHeaderClick) onColumnHeaderClick({
                columnName: columnItemName,
                order: columnOrderString
            });
        }
    }

    return (
        <thead>
            <tr>
                { column.map((value, index) => {
                    let _columnMinWidth: CSSProperties = {};
                    if (columnMinWidth && columnMinWidth[index] && columnMinWidth[index] > 0) {
                        _columnMinWidth = { minWidth: `${columnMinWidth[index]}px` }
                    }

                    let _columnMaxWidth: CSSProperties = {};
                    if (columnMaxWidth && columnMaxWidth[index] && columnMaxWidth[index] > 0) {
                        _columnMaxWidth = { maxWidth: `${columnMaxWidth[index]}px` };
                    }

                    let styles: CSSProperties = { ..._columnMinWidth, ..._columnMaxWidth };

                    let className = (bordered) ? "pl-0" : "";

                    let isSelected = false;
                    let isAscendingOrder = columnHeaderValue?.order === "ASC" ?? false;
                    if (selectedColumnName && selectedColumnName[index]) {
                        isSelected = selectedColumnName[index] === columnHeaderValue?.columnName;

                        className += ` cursor-pointer`;
                    }

                    return (
                        <td
                            key={index.toString()}
                            style={ styles }
                            className={className}
                            onClick={() => _onColumnClicked(index)}
                        >
                            { index === 0 ? (
                                <span className="text-dark-75">{ value }</span>
                            ): (
                                <span>{ value }</span>
                            )}

                            {(isSelected && !isAscendingOrder) && <i className="fas fa-caret-down ml-2" />}
                            {(isSelected && isAscendingOrder) && <i className="fas fa-caret-up ml-2" />}
                        </td>
                    )
                }) }
            </tr>
        </thead>
    )
}
