/**
 * Create Client select dropdown (data from redux)
 * */
import React, {useEffect, useState} from "react";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../redux/rootReducer";
import {SelectItem} from "../../../models/SelectItem";
import {clientSelectAction} from "../../../redux/actions/selectActions";

interface ClientSelectProp {
    value?: string | number;
    onChange?: (clientId: string) => void,
    className?: string;
    disabled?: boolean;
    error?: boolean
}

export const SelectClient: React.FunctionComponent<ClientSelectProp> = ({
    value,
    onChange,
    className,
    disabled = false,
    error = false
}): JSX.Element => {

    const dispatch = useDispatch();
    const { selectItems, status, errorMessage } = useSelector((state: ApplicationState) => state.select.client);

    const [ selectedItem, setSelectedItem ] = useState<SelectItem | undefined | null>();

    // Append Empty value Select Item
    const selectItemWithEmptyValue: SelectItem[] = [{ value: "", label: "Select Client" }, ...selectItems];

    if (process.env.NODE_ENV === "development") {
        if (errorMessage) console.log("SELECT_CLIENT_ERROR", errorMessage);
    }

    // For initial loading state
    useEffect(() => {
        _getInitialData();
    }, []);

    // For when value change
    useEffect(() => {
        _onValueChange(value?.toString());
    }, [value]);

    const _getInitialData = () => {
        dispatch(clientSelectAction());
    }

    const _onValueChange = (selectedValue?: string | number) => {
        const found = selectItems.find(item => item.value === selectedValue);
        setSelectedItem(found ?? null);
    }

    return (
        <Select
            isLoading={status === "loading"}
            isDisabled={disabled}
            options={selectItemWithEmptyValue}
            value={selectedItem}
            styles={{
                control: (styles) => {
                    return error
                        ? { ...styles, zIndex: 2, border: '1px solid red' }
                        : { ...styles, zIndex: 2 }
                },
                menu: (base) => {
                    return { ...base, zIndex: 3}
                }
            }}
            onChange={value1 => {
                if (onChange && value1) {
                    onChange(value1.value);
                }
            }}
        />
    )
}

/**
 * Select Client special for client
 */
export const SelectClientEmpty: React.VFC = () => {
    const user = useSelector((state: ApplicationState) => state.auth.user)

    const item = {value: user.userId.toString(), label: user.clientlog?.fullname_client ?? ""}
    return (
        <Select
            isDisabled
            options={[
                item
            ]}
            value={item}
            styles={{
                control: (styles) => {
                    return { ...styles, zIndex: 2 }
                },
                menu: (base) => {
                    return { ...base, zIndex: 3}
                }
            }}
        />
    )
}
