import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {LoginWrapper} from "./components/LoginWrapper";
import BackButton from "./components/BackButton";
import {Link} from "react-router-dom";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import "./styles/registration.scss";
import {ApplicationState} from "../../../redux/rootReducer";
import {registrationAction} from "../../../redux/actions/authAction";
import {makeFormikErrorClass} from "../../../utils/Helpers";
import {FormikMessage} from "../../components";

const initialValue = {
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    password: '',
    confirmPassword: ''
};

function Registration() {

    const dispatch = useDispatch();

    const {
        status,
        errorMessage
    } = useSelector((state: ApplicationState) => state.auth);

    const RegistrationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required("First Name Required"),
        lastName: Yup.string()
            .required("Las Name Required"),
        email: Yup.string()
            .email("Not valid email")
            .required("Email Required"),
        username: Yup.string()
            .min(5, "Username can not less than 5 character")
            .required("Username Required"),
        password: Yup.string()
            .min(8, "Minimum 8 characters")
            .required("Password Required"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], "Password does not match")
            .required()
    });

    // Use formik to validate form
    const formik = useFormik({
        initialValues: initialValue,
        validationSchema: RegistrationSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            setSubmitting(true);
            dispatch(registrationAction({
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                username: values.username,
                password: values.password
            }))
        }
    });

    return (
        <LoginWrapper
            title="Sign Up"
            sizeClass="wide"
        >
            <>
                <BackButton/>

                <div className="sign-up-form">
                    <div className="signup-header text-center">
                        <h4 className="mb-0">CREATE ACCOUNT</h4>
                        <p>Free Account - 2 Users</p>
                    </div>

                    {status === "success" && (
                        <div
                            className="mb-10 alert alert-custom alert-light-success alert-dismissible d-block text-center">
                            <div className="success-text font-weight-bold mb-2">Registration Success</div>
                            <div className="font-weight-normal">Please Wait, redirecting to Dashboard</div>
                        </div>
                    )}

                    {errorMessage && (
                        <div
                            className="mb-10 alert alert-custom alert-light-danger alert-dismissible d-block text-center">
                            <div className="alert-text font-weight-bold mb-2">{errorMessage}</div>
                        </div>
                    )}

                    <form
                        onSubmit={formik.handleSubmit}>

                        {formik.status && (
                            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                <div className="alert-text font-weight-bold">{formik.status}</div>
                            </div>
                        )}

                        <div className="form-group">
                            <input type="text"
                                   className={makeFormikErrorClass(formik.errors.firstName, formik.touched.firstName)}
                                   placeholder="First Name"
                                   {...formik.getFieldProps("firstName")}
                            />

                            <FormikMessage
                                errorMessage={formik.errors.firstName}
                                touchedField={formik.touched.firstName}
                            />
                        </div>
                        <div className="form-group">
                            <input type="text"
                                   className={makeFormikErrorClass(formik.errors.lastName, formik.touched.lastName)}
                                   placeholder="Last Name"
                                   {...formik.getFieldProps("lastName")}
                            />
                            <FormikMessage
                                errorMessage={formik.errors.lastName}
                                touchedField={formik.touched.lastName}
                            />
                        </div>
                        <div className="form-group">
                            <input type="text"
                                   className={makeFormikErrorClass(formik.errors.email, formik.touched.email)}
                                   placeholder="Email"
                                   {...formik.getFieldProps("email")}
                            />
                            <FormikMessage
                                errorMessage={formik.errors.email}
                                touchedField={formik.touched.email}
                            />
                        </div>
                        <div className="form-group">
                            <input type="text"
                                   className={makeFormikErrorClass(formik.errors.username, formik.touched.username)}
                                   placeholder="Username"
                                   {...formik.getFieldProps("username")}
                            />
                            <FormikMessage
                                errorMessage={formik.errors.username}
                                touchedField={formik.touched.username}
                            />
                        </div>
                        <div className="form-group">
                            <input type="password"
                                   className={makeFormikErrorClass(formik.errors.password, formik.touched.password)}
                                   placeholder="Password"
                                   {...formik.getFieldProps("password")}
                            />
                            <FormikMessage
                                errorMessage={formik.errors.password}
                                touchedField={formik.touched.password}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                className={makeFormikErrorClass(formik.errors.confirmPassword, formik.touched.confirmPassword)}
                                placeholder="Confirm Password"
                                {...formik.getFieldProps("confirmPassword")}
                            />
                            <FormikMessage
                                errorMessage={formik.errors.confirmPassword}
                                touchedField={formik.touched.confirmPassword}
                            />
                        </div>
                        <div className="form-group button">
                            {status === "loading" ? (
                                <div className="w-100 d-flex justify-content-center" style={{marginTop: '24px'}}>
                                    <span className="spinner spinner-darker"/>
                                </div>
                            ) : (
                                <button
                                    type="submit"
                                    className="bg-accent-color btn w-100 text-light font-weight-bold mb-6"
                                >
                                    CREATE ACCOUNT
                                </button>

                            )}

                        </div>
                    </form>

                    <p>By clicking <b>Create Account</b> above, you acknowledge that you have read and agree to
                        the <Link to="/login">Terms and Conditions</Link></p>
                </div>
            </>
        </LoginWrapper>
    );
}

export default Registration;
