import axios from "axios";
import { JobRequestStatusModel } from "../models/JobRequestStatusModel";
import { ApiResponse, APIResponse, BaseApiResponse, BaseSearchParameterForm, BaseSearchParameterOrderBy, SearchParameter } from "./BaseAPI";
import { INotInvoiced } from "../models/NotInvoicedType";
import { IDueToday } from "../models/DueTodayType";
import { IClosedToday } from "../models/ClosedTodayType";
import { ClientRequestAccepted } from "../models/ClientRequestAccepted";
import { JobRequestCompleted } from "../models/JobRequestCompleted";
import { JobRequestPending } from "../models/JobRequestPendingModel";
import { JobRequestRecurringListing } from "../models/JobRequestRecurringListing";
import { JobRequestFieldResponse, JobRequestsFieldData } from "../models/JobRequestFields";
import { JobRequestCustomFields, JobRequestDefaultFields } from "../models/JobRequestCustomFields";
import { JobRequestFieldAll } from "../models/JobRequestFieldAll";
import { JobRequestAdmin } from "../models/JobRequestAdmin";
import { JobRequestAreaManager } from "../models/JobRequestAreaManager";
import { JobRequestDeclined } from "../models/JobRequestDeclined";
import { JobRequestDueToday } from "../models/JobRequestDueToday";
import { JobRequestDeleted } from "../models/JobRequestDeleted";
import { JobRequestSingleRequest } from "../models/JobRequestSingleRequest";
import { JobRequestAddCommentResponse } from "../models/JobRequestAddCommentResponse";
import { JobRequestAddCommentDocumentResponse } from "../models/JobRequestAddCommentDocumentResponse";
import { SimpleResponse } from "../models/BaseModel/SimpleResponse";
import { BaseReportRequestFile } from "../models/BaseModel/BaseReportRequestFile";
import { JRNotificationItem } from "../app/pages/JobRequests/JobRequestWizard/Components/JRNotificationModal";
import { BaseResponse, JobRequestAreaManagerResponse } from "../models/BaseModel/BaseResponse";
import { makeErrorMessage } from "../utils/Helpers";
import { FormDataType, makeFormData, postToApi } from "../utils/APIHelper";
import { JobRequestStatisticGraph } from "../models/JobRequestStatisticGraph";
import { JobRequestApprovalAdmin } from "../models/JobRequestApprovalAdmin";
import { JobRequestFieldMessage, JobRequestNewResponse } from "../models/Response/JobRequestNewResponse";
import { JobRequestMap } from "../models/JobRequestMap";
import { JobRequestReferenceSingle } from "../models/JobRequestReferenceSingle";
import { JobRequestRecurringPatternSingle, Pattern } from "../models/JobRequestRecurringPatternSingle";
import { Operator } from "../models/OperatorSelect";
import { OperatorAllJobRequest } from "../models/OperatorAllJobRequestModel";
import { JobRequestAdminField, JobRequestFields } from "../models/JobRequestAdminField";
import { JobRequestAllField, JobRequestAllFieldsData } from "../models/JobRequestAllFields";
import { HeaderColumn } from "../app/components/CustomTable";
import { JobRequestMarkCompletedResponse } from "../models/JobRequestMarkCompletedResponse";
import { IJobRequestExtendedModel } from "../models/IJobRequestResponse";
import { API } from "../helpers/api";

// Filter
export interface JobRequestFilterParam extends SearchParameter {
	/** Prefered search */
	search_term?: string;
	search_keyword?: string;
}

export interface IJobRequestFilterParam extends SearchParameter {
	search_term?: string;
	search_keyword?: string;
	order?: HeaderColumn;
}

export interface JobRequestSearchParameterWithNoLimit extends JobRequestFilterParam {
	dont_get_default_limit?: boolean;
}

export interface JobRequestSearchParam extends BaseSearchParameterForm {
	isSearch?: boolean;
}
// End

export function jobRequestGetCountStatus(): APIResponse<JobRequestStatusModel> {
	return axios.post("api/job_requests/count/each/status");
}

export function jobRequestGetStatisticGraph() {
	return axios.post("api/job_requests/statistik/graph");
}

export function jobRequestGetNotInvoiced(searchParam?: JobRequestSearchParameterWithNoLimit): APIResponse<INotInvoiced> {
	return axios.post("api/job_requests/not-invoiced", makeFormData({ ...searchParam }));
}

export function jobRequestGetDueToday(searchParam?: JobRequestSearchParameterWithNoLimit): APIResponse<IDueToday> {
	return axios.post("api/job_requests/due_today", makeFormData({ ...searchParam }));
}

export function jobRequestGetClosedToday(searchParam?: JobRequestSearchParameterWithNoLimit): APIResponse<IClosedToday> {
	return axios.post("api/job_requests/closed_today", makeFormData({ ...searchParam }));
}

export function jobRequestGetAccepted(searchParam?: JobRequestFilterParam): APIResponse<ClientRequestAccepted> {
	let param: JobRequestFilterParam = {
		...searchParam,
		search_term: searchParam?.search_keyword,
		orderBy: searchParam?.orderBy ?? ["JobRequest/requesttime/DESC"],
	};
	return axios.post("api/job_requests/accepted", makeFormData({ ...param }));
}

export function jobRequestGetCompleted(searchParam?: JobRequestFilterParam): APIResponse<JobRequestCompleted> {
	let param: JobRequestFilterParam = {
		...searchParam,
		search_term: searchParam?.search_keyword,
		orderBy: searchParam?.orderBy ?? ["JobRequest/completeddate/DESC"],
	};
	return axios.post("api/job_requests/completed", makeFormData({ ...param }));
}

export function jobRequestGetPending(searchParam?: JobRequestFilterParam): APIResponse<JobRequestPending> {
	let param: JobRequestFilterParam = {
		...searchParam,
		search_term: searchParam?.search_keyword,
		orderBy: searchParam?.orderBy ?? ["JobRequest/requesttime/DESC"],
	};

	return axios.post("api/job_requests/pending", makeFormData({ ...param }));
}

export function jobRequestGetRecurringListing(searchParam?: BaseSearchParameterOrderBy, jobReqTemplateOnly: boolean = false): ApiResponse<JobRequestRecurringListing> {
	return postToApi<JobRequestRecurringListing, JobRequestRecurringListing>(
		{
			url: "api/recurring/job-request/all/listing",
			data: {
				...searchParam,
				is_display_job_request_template_only: jobReqTemplateOnly ? 1 : 0,
			},
		},
		(data) => data
	);
}

export function jobRequestFields(jobRequestFieldId: number): ApiResponse<JobRequestsFieldData[]> {
	return postToApi<JobRequestsFieldData[], JobRequestFieldResponse>(
		{
			url: `api/job-request/fields/${jobRequestFieldId}`,
		},
		(data) => data.fields
	);
}

export function jobRequestCustomiseFields(searchParam?: JobRequestSearchParam): APIResponse<JobRequestCustomFields> {
	return axios.post("/api/job-request/fields/customise/fields.json", makeFormData(searchParam));
}

export function jobRequestCustomiseDefaultFields(searchParam?: JobRequestSearchParam): APIResponse<JobRequestDefaultFields> {
	return axios.post("/api/job-request/fields/default/fields.json", makeFormData(searchParam));
}

export function jobRequestFieldsAll(): APIResponse<JobRequestFieldAll> {
	// return axios.post("api/job-request/fields/all-job-request-fields");
	return axios.post(`/api/job-request/fields/all.json`);
}

export function jobRequestSaveOrder(jobRequestFieldIds: string[]): ApiResponse<string> {
	return new Promise<[string | undefined, string | undefined]>((resolve) => {
		const formData = new FormData();
		jobRequestFieldIds.forEach((id) => {
			formData.append("job_request_field[]", id);
		});
		axios
			.post<BaseResponse>("modules/job-request/field/ordering", formData)
			.then(({ data }) => {
				if (data.success) {
					resolve(["Job Request order field saved successfully", undefined]);
				} else {
					resolve([undefined, makeErrorMessage(data.messages)]);
				}
			})
			.catch((e) => resolve([undefined, e.toString()]));
	});
}

export function jobRequestAllByAdmin(param?: JobRequestSearchParam): APIResponse<JobRequestAdmin> {
	return axios.post("api/job_requests/job-requests-all-by-admin", makeFormData(param));
}

export function jobRequestAreaManager(param?: JobRequestFilterParam): ApiResponse<JobRequestAreaManager> {
	const params: JobRequestFilterParam = {
		...param,
		search_term: param?.search_keyword || param?.search_term,
	};

	return postToApi<JobRequestAreaManager, JobRequestAreaManager>(
		{
			url: `api/job_requests/area/manager`,
			data: params,
		},
		(data) => data
	);
}

export function jobRequestDeclined(param?: JobRequestFilterParam): APIResponse<JobRequestDeclined> {
	let params: JobRequestFilterParam = {
		...param,
		search_term: param?.search_keyword,
		orderBy: param?.orderBy ?? ["JobRequest/completeddate/DESC"],
	};

	return axios.post("api/job_requests/declined", makeFormData({ ...params }));
}

export function jobRequestDueToday(param?: JobRequestFilterParam): ApiResponse<JobRequestDueToday> {
	return postToApi<JobRequestDueToday, JobRequestDueToday>(
		{
			url: "api/job_requests/due_today",
			data: param,
		},
		(data) => data
	);
}

export function jobRequestDeleted(param?: JobRequestFilterParam): APIResponse<JobRequestDeleted> {
	let params: JobRequestFilterParam = {
		...param,
		search_term: param?.search_keyword,
		orderBy: param?.orderBy ?? ["JobRequest/requesttime/DESC"],
	};

	return axios.post("api/job_requests/deleted/request", makeFormData({ ...params }));
}

export function jobRequestSingleRequest(jobRequestId: number): APIResponse<JobRequestSingleRequest> {
	return axios.post(`api/job_requests/single_request/${jobRequestId}`);
}

export function jobRequestSingleAsync(jobRequestId: number): ApiResponse<JobRequestSingleRequest> {
	return API().post(`api/job_requests/single_request/${jobRequestId}`);
}

// Add Comment on JobRequest
export interface JobRequestAddCommentParam {
	title: string;
	description: string;
	files?: File[];
}

/**
 * Add Comment to Job Request, if file is not null, then it will automatically uploaded file after completing/success
 * sending comment
 * @param {number} jobRequestId Job Request Detail/Id to be commented
 * @param {JobRequestAddCommentParam} Job request form field
 * @param {number} commentId (Optional) Comment Id to be updated
 * @return {Promise} Response from `response: JobRequestAddCommentResponse`, and when added file `fileResponse?: JobRequestAddCommentDocumentResponse`
 * */
export async function jobRequestAddComment(
	jobRequestId: number,
	{ title, description, files }: JobRequestAddCommentParam,
	commentId?: number
): Promise<{
	response: JobRequestAddCommentResponse;
	fileResponse?: JobRequestAddCommentDocumentResponse;
}> {
	let formData = new FormData();
	formData.append("reason", title);
	formData.append("description", description);

	const URL = commentId ? `api/job_requests/edit_comment/${commentId}/${jobRequestId}` : `api/job_requests/add_comment/${jobRequestId}`;

	const { data } = await axios.post<JobRequestAddCommentResponse>(URL, formData);

	if (data.success) {
		if (files && files.length > 0) {
			const [response] = await jobRequestCommentAddDocument(data.comment_id, files);
			return {
				response: data,
				fileResponse: response,
			};
		}

		return {
			response: data,
		};
	} else {
		throw makeErrorMessage(data.messages);
	}
}

export function jobRequestCommentAddDocument(commentId: number, files: File[]): ApiResponse<JobRequestAddCommentDocumentResponse> {
	let formData = new FormData();
	formData.append("file", files[0]);

	return new Promise<BaseApiResponse<JobRequestAddCommentDocumentResponse>>((resolve) => {
		axios
			.post<JobRequestAddCommentDocumentResponse>(`api/job_requests/upload_documents_for_comment/${commentId}`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then(({ data }) => {
				if (data.success) {
					resolve([data, undefined]);
				} else {
					resolve([undefined, makeErrorMessage(data.messages)]);
				}
			})
			.catch((error) => {
				resolve([undefined, error.toString()]);
			});
	});
}

export function jobRequestDeleteComment(commentId: number): APIResponse<SimpleResponse> {
	return axios.post(`api/job_requests/delete_comment/${commentId}`);
}

export function jobRequestReassignOperator(jobRequestId: number, operatorId: string): ApiResponse<string> {
	return postToApi(
		{
			url: `api/job_requests/reassign_request/${jobRequestId}/${operatorId}`,
		},
		(data, message) => message!
	);
}

export function jobRequestPrint(jobRequestId: number, rootRequestId: number): APIResponse<BaseReportRequestFile> {
	return axios.post(
		"api/reports/get_request_print",
		makeFormData({
			request_id: jobRequestId,
			root_request_id: rootRequestId,
		})
	);
}

// Job request Mark Completed
interface JobRequestMarkCompleteParam extends FormDataType {
	reason?: string;
	description?: string;
	time_taken?: string;
	value_taken?: string;
}

export function jobRequestMarkCompleted(jobRequestId: number, additionalInfo?: JobRequestMarkCompleteParam, file?: File): ApiResponse<string> {
	return new Promise<BaseApiResponse<string>>(async (resolve) => {
		const [jrResponse, error] = await postToApi<{ commentId: number; message: string }, JobRequestMarkCompletedResponse>(
			{
				url: `api/job_requests/mark_completed/${jobRequestId}`,
				data: {
					...additionalInfo,
					is_from_web: "true",
				},
			},
			(data, message) => {
				console.log(message);
				return { commentId: data.comment_id!, message: message! };
			}
		);

		console.log(jrResponse, error);

		if (error) {
			return resolve([undefined, error]);
		}

		if (file && jrResponse) {
			const [, error] = await jobRequestCommentAddDocument(jrResponse.commentId, [file]);
			if (error) {
				// TODO: Job Request Create Successfully but file isn't
			}
		}

		return resolve([jrResponse?.message ?? "Job Request Marked Completed successfully", undefined]);
	});
}

// Save Job Request
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface JobRequestSaveParam {
	notificationReceiver: JRNotificationItem[];
	operatorItems: {
		operatorId: string;
		status: string;
		serviceId: string;
	}[];
	checklists: {
		id: number;
		title: string;
		checked: boolean;
	}[];
	customFields: {
		id: number;
		category: string;
		value: string;
	}[];
}

export interface JobRequestCommentAddParam extends FormDataType {
	reason: string;
	description: string;
	toClient?: boolean;
}

export function jobRequestCommentAdd(jobRequestId: number, data: JobRequestCommentAddParam, attachment?: File): ApiResponse<string> {
	return new Promise((resolve) => {
		axios
			.post<JobRequestAreaManagerResponse>(`api/job_requests/add_comment/${jobRequestId}`, makeFormData(data))
			.then(({ data }) => {
				if (data.success) {
					if (attachment) {
						jobRequestCommentAddFile(data.comment_id, attachment)
							.then(({ data }) => {
								const msg = makeErrorMessage(data.messages);
								if (data.success) {
									resolve(["Comment Added Successfully", undefined]);
								} else {
									resolve([undefined, msg]);
								}
							})
							.catch(() => resolve([undefined, "Cannot upload file"]));
					} else {
						resolve(["Comment Added Successfully", undefined]);
					}
				} else {
					const msg = makeErrorMessage(data.messages);
					resolve([undefined, msg]);
				}
			})
			.catch((e) => resolve([undefined, e.toString()]));
	});
}

export function jobRequestCommentAddFile(jobRequestCommentId: number, attachment: File): APIResponse<BaseResponse> {
	const formData = new FormData();
	formData.append("file", attachment);
	return axios.post(`api/job_requests/upload_documents_for_comment/${jobRequestCommentId}`, formData);
}

export function jobRequestDelete(jobRequestId: number): ApiResponse<string> {
	return postToApi(
		{
			url: `api/job_requests/delete_request/${jobRequestId}`,
		},
		(data, message) => message!
	);
}

export function jobRequestDeactivate(jobRequestId: number, status?: "DEACTIVATE" | "ACTIVATE"): ApiResponse<string> {
	let activateStatus: number = 1;
	switch (status) {
		case "ACTIVATE":
			activateStatus = 0;
			break;
		case "DEACTIVATE":
			activateStatus = 1;
			break;
	}

	return postToApi(
		{
			url: `api/job_requests/deactive/${jobRequestId}/${activateStatus}`,
		},
		(data, message) => message!
	);
}

export function jobRequestSendEmail(jobRequestId: number): ApiResponse<string> {
	return postToApi(
		{
			url: "reminder/overdue/email",
			data: { id: jobRequestId },
		},
		(data, message) => message!
	);
}

interface JobRequestStatisticGraphProps extends FormDataType {
	start: Date;
	end: Date;
}

export function jobRequestStatisticGraph(param: JobRequestStatisticGraphProps): ApiResponse<JobRequestStatisticGraph> {
	return postToApi<JobRequestStatisticGraph, JobRequestStatisticGraph>(
		{
			url: "api/job_requests/statistik/graph",
			data: param,
			config: {
				prefix: "",
				config: { dateFormat: "y-MM-dd" },
			},
		},
		(data) => {
			return data;
		}
	);
}

interface IJobRequestApprovalAdminResponse {
	data: IJobRequestExtendedModel[];
	totalPage: number;
	totalData: number;
	limit: number;
}

export function jobRequestApprovalAdmin(param?: JobRequestFilterParam): ApiResponse<IJobRequestApprovalAdminResponse> {
	return postToApi<IJobRequestApprovalAdminResponse, JobRequestApprovalAdmin>(
		{
			url: "api/job_requests/approval_admin",
			data: param,
		},
		(data) => {
			return {
				data: data.requests,
				totalPage: Math.ceil(data.totals / data.limit),
				totalData: data.totals,
				limit: data.limit,
			};
		}
	);
}

export interface JobRequestNewParam {
	isNewMethod: "0" | "1" | ""; // ?? checkbox
	isSendEmail: string;
	isCreateOnQuote?: string;
	deactive?: string;
	title: string;
	sitecontact: string;
	location: string;
	refNo: string;
	requesttime?: string;
	deadline?: string;
	priority: string;
	chargeable: string;
	client_cost: string; // $ 1.00
	invoice_number: string;
	value: string; // $ 1.00
	hrs: string; // double? 1.00
	contactname: string;
	contactnumber: string;
	details: string;
	property: string; // property_id
	client: string; // client_id
	address: string;
	/// Client Details
	// index [0]=

	assignTo: string[];
	status: string[];
	// service: {
	//     [key: string]: string;
	// }[];
	service: string[][];
	/// End Client Details
	notificationReceiver: {
		id: string;
		firstname: string;
		lastname: string;
		email: string;
	}[];
	// Assign to Operator
	itemsOperator: {
		operator: string; // operatorId
		status: string;
		service: string; // serviceId
		asset?: {
			id: string;
			qty: number;
		}[];
		inventory?: {
			id: string;
			qty: number;
		}[];
		customisedField: {
			id: number;
			value: string | { id: string };
			category: string;
		}[];
	}[];
	// End Assign to Operator
	// Area Manager
	area_managers: string[]; // Admin id
	areaManagers?: string[]; // Admin id
	// end area manager

	// Checklist
	checklist: {
		// Undefined when Create new
		id?: string;
		title: string;
		status: string; // Completed || ""
		isChecked: string; // true or false
	}[];
	// This is used for Job Request Reference.
	checkList?: {
		id?: string;
		title: string;
		status: string; // Completed || ""
		isChecked: string; // true or false
	}[];
	customisedField: {
		id: number;
		category: string;
		// Jika type choice, have an id
		value: string | { id: string };
	}[];

	files?: File[];
}

interface JobRequestNewSuccessResponse {
	message: string;
	redirect?: string;
	jobRequestId: number;
}

interface JobRequestNewErrorResponse {
	fields?: { [key: string]: JobRequestFieldMessage };
	errorMessage: string;
}

export function jobRequestNew(param: JobRequestNewParam, files?: File[], jobRequestId?: string, isRecurring = false): ApiResponse<JobRequestNewSuccessResponse> {
	let URL: string = "";
	let newParam = param;
	if (isRecurring) {
		URL = jobRequestId ? `api/recurring/job-request/template/${jobRequestId}/edit` : "/api/recurring/job-request/template/new";
		newParam = {
			...param,
			checkList: param.checklist,
			areaManagers: param.area_managers,
		};
	} else {
		URL = jobRequestId ? `api/job_requests/${jobRequestId}/edit` : "api/job_requests/new";
	}

	return postToApi<JobRequestNewSuccessResponse, JobRequestNewResponse>(
		{
			url: URL,
			data: {
				isWeb: "1",
				isMobile: "0",
				job_request: newParam,
			},
		},
		(data, message) => {
			return {
				message: message ?? "",
				redirect: data.data?.status.url,
				jobRequestId: data.parent_id,
			};
		}
	);
}

export function jobRequestUploadDocument(jobRequestId: number, file: File, isGlobalRequest = false): ApiResponse</* message */ string> {
	let URL = `api/job_requests/upload_documents_for_request/${jobRequestId}`;
	if (isGlobalRequest) URL = `api/job_requests/upload_documents_for_global_request/${jobRequestId}`;

	return new Promise((resolve) => {
		const formData = new FormData();
		formData.append("file", file);
		axios
			.post<BaseReportRequestFile>(URL, formData)
			.then(({ data }) => {
				resolve([makeErrorMessage(data.messages), undefined]);
			})
			.catch((e) => resolve([undefined, e.toString()]));
	});
}

export const jobRequestMap = (jobRequestId: number): ApiResponse<JobRequestMap> => {
	return postToApi<JobRequestMap, JobRequestMap>(
		{
			url: `api/job_requests/map/${jobRequestId}/google`,
			data: { dont_get_default_limit: true },
		},
		(data) => data
	);
};

export function jobRequestReferenceSingle(jobRequestReferenceId: number): ApiResponse<IJobRequestExtendedModel> {
	return postToApi<IJobRequestExtendedModel, JobRequestReferenceSingle>(
		{
			url: "api/recurring/job-request/global/single",
			data: { id: jobRequestReferenceId },
		},
		(data) => data.jobrequest
	);
}

export function jobRequestRecurringSingle(globalJobRequestId: number) {}

/**
 * Delete Global Job Request (Recurring & Template Job Request)
 * @param jobRequestId {number} Job Request id to be deleted
 * @return {Promise<[string, string]>} response message from server
 */
export function jobRequestGlobalDelete(jobRequestId: number): ApiResponse<string> {
	return postToApi(
		{
			url: `api/recurring/job-request/global-job-request/templates/delete`,
			data: { id: jobRequestId },
		},
		(data, message) => message!
	);
}

export function jobRequestRecurringToggleActivation(jobRequestId: number, isActivated: boolean): ApiResponse<string> {
	return postToApi(
		{
			url: `recurring/${jobRequestId}/active/${isActivated ? "1" : "0"}`,
			data: { id: jobRequestId },
		},
		(data, message) => message!
	);
}

export function jobRequestRecurringDelete(jobRequestId: number): ApiResponse<string> {
	return postToApi(
		{
			url: `recurring/${jobRequestId}/delete`,
			data: { id: jobRequestId },
		},
		(data, message) => message!
	);
}

export function jobRequestAcceptRequest(jobRequestId: number): ApiResponse<string> {
	return postToApi(
		{
			url: `api/job_requests/accept/${jobRequestId}`,
		},
		(data, message) => message!
	);
}

export function jobRequestResendInvitation(jobRequestId: number): ApiResponse<string> {
	return postToApi(
		{
			url: `api/job_requests/invitation/${jobRequestId}`,
		},
		(data, message) => message!
	);
}

export function jobRequestRecurringPatternSingle(patternId: number): ApiResponse<Pattern> {
	return postToApi<Pattern, JobRequestRecurringPatternSingle>(
		{
			url: "api/recurring/job-request/pattern/single",
			data: { pattern_id: patternId },
		},
		(data) => data.pattern
	);
}

export interface JobRequestCreateFromClient {
	title: string;
	property: string;
	address: string;
	priority: string;
	contactname: string;
	contactnumber: string;
	details: string;

	sitecontact?: string;
	location?: string;
	refNo?: string;
	requesttime?: Date;
	deadline?: Date;
	chargeable?: "0" | "1";
	client_cost?: number;
	invoice_number?: string;
	value?: number;
	hrs?: number;
}

/**
 * Create job request for client
 * */
export function jobRequestCreateFromClient(param: JobRequestCreateFromClient): ApiResponse<BaseResponse> {
	return postToApi(
		{
			url: "/customer/client-new-jr",
			data: param,
			config: { prefix: "job_request" },
		},
		(data) => data
	);
}

export function jobRequestCreateAdvanceFromClient() {}

export function jobRequestOperatorAllJobRequest(parentId: number): ApiResponse<Operator[]> {
	return postToApi<Operator[], OperatorAllJobRequest>(
		{
			url: "api/operator/operators-all-job-request",
			data: {
				parentId,
			},
		},
		(data) => data.operators
	);
}

export function jobRequestAllRequestFields(): ApiResponse<JobRequestAllFieldsData[]> {
	return postToApi<JobRequestAllFieldsData[], JobRequestAllField>(
		{
			url: `/api/job-request/fields/all.json`,
		},
		(data) => data.all_job_request_fields
	);
}

export function jobRequestAdminField(adminId: number): ApiResponse<JobRequestFields[]> {
	return postToApi<JobRequestFields[], JobRequestAdminField>(
		{
			url: `/api/job-request/fields/${adminId}.json`,
		},
		(data) => data.fields
	);
}

export function jobRequestDecline(jobRequestId: number): ApiResponse<string> {
	return postToApi<string, BaseResponse>(
		{
			url: `api/job_requests/decline/${jobRequestId}`,
		},
		(_, message) => message ?? "Something went wrong."
	);
}

export interface IJobRequestLocation {
	lat: number | "";
	long: number | "";
}

export type IJobRequestTimerStatus = "start" | "pause";

export function jobRequestMarkStartTime(jobRequestId: number, status: IJobRequestTimerStatus, location: IJobRequestLocation): ApiResponse</* message */ string> {
	return postToApi<string, BaseResponse>(
		{
			url: `api/job_requests/mark_${status}_time/${jobRequestId}`,
			data: location,
		},
		(data, message) => message!
	);
}

export function jobRequestClientChangeFormType(type: 1 | 2): ApiResponse<BaseResponse> {
	return postToApi<BaseResponse, BaseResponse>(
		{
			url: `api/config/client/form`,
			data: { id: type },
		},
		(data) => data
	);
}

export function jobRequestDeleteFile(fileId: number): ApiResponse</* message */ string> {
	return postToApi<string, BaseResponse>(
		{
			url: `api/file/request/delete`,
			data: {
				file_id: fileId,
			},
		},
		(_, message) => message!
	);
}

interface IJobRequestChecklistForm {
	checklist: {
		id: string;
		title: string;
		$$hashKey?: string;
	}[];
	root: string;
}
export const jobRequestUpdateChecklist = (data: IJobRequestChecklistForm): ApiResponse<boolean> => {
	return postToApi(
		{
			url: `api/job_requests/mark_selected_checklist`,
			data,
			config: { prefix: "job_request" },
		},
		(data) => data.success
	);
};
