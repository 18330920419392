import {IJobRequestTimerStatus, jobRequestMarkStartTime} from "../../../../apis/JobRequestAPI";
import toastr from "toastr";

export const TimeTrackerHelper = {
    _updateTracker: async (
        jobRequestId: number,
        status: IJobRequestTimerStatus,
        location?: { lat: number; long: number } | undefined,
        onTrackerUpdated?: () => void,
    ) => {
        const [message, error] = await jobRequestMarkStartTime(
            jobRequestId,
            status,
            location ?? {lat: "", long: ""}
        );
        if (!error) {
            if (onTrackerUpdated) onTrackerUpdated()
            toastr.success(message!, "Success");
        } else {
            toastr.error(error, "Error");
        }
    },
    startTrackTime: async (
        jobRequestId: number,
        status: IJobRequestTimerStatus,
        onTrackerUpdated?: () => void
    ) => {
        // Get current location
        navigator.geolocation.getCurrentPosition(
            (position) => {
                TimeTrackerHelper._updateTracker(
                    jobRequestId,
                    status,
                    {
                        lat: position.coords.latitude,
                        long: position.coords.longitude,
                    },
                    onTrackerUpdated
                ).then();
            },
            () => {
                // When user didn't allow location
                TimeTrackerHelper._updateTracker(
                    jobRequestId,
                    status,
                    undefined,
                    onTrackerUpdated
                ).then();
            }
        );
    }
}