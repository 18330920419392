import React from "react";
import { AsideMenuItemList, MenuItemLink } from "../AsideMenuList";
import { useModuleHelper } from "../../../../../../Helpers/useModuleHelper";

const AsideMenuAdmin = ({ isRoleAdmin = false, isSuperAdmin = false }) => {
  // Check if super administrator is enabling modules
  const {
    isCustomiseJobRequestEnabled,
    isSiteTrackerEnabled,
    isSiteAttendanceTrackingEnabled,
    isJobAttendanceTrackingEnabled,
    isMYOBEssentialEnabled,
    isMYOBAccountRightLiveEnabled,
    isQuoteEnabled,
    isAssetEnabled,
    isInventoryEnabled,
    isAutomateJobRequestEnabled,
    isMultiOperatorEnabled,
    MYOBEssentialID,
    MYOBAccountingRightLiveID,
  } = useModuleHelper();

  const _isJobAttendanceTrackingEnabled = false;
  const _isMYOBEssentialEnabled = false;
  const _isMYOBAccountRightLiveEnabled = false;

  const _utilitiesMenu = () => {
    let menus = [];

    if (isAutomateJobRequestEnabled) {
      menus.push(
        <AsideMenuItemList
          key={"/modules/job-request/reference"}
          url={"/modules/job-request/reference"}
          name={"Automate Job Request"}
          iconType="bullet"
        />
      );
    }

    if (isMultiOperatorEnabled) {
      menus.push(
        <AsideMenuItemList
          key={"/multiple-operator"}
          url={"/multiple-operator"}
          name={"Multi Operator"}
          iconType="bullet"
        />
      );
    }

    if (isCustomiseJobRequestEnabled) {
      menus.push(
        <AsideMenuItemList
          key={"/modules/job-request/field"}
          url={"/modules/job-request/field"}
          name={"Customise Job Request Fields"}
          iconType="bullet"
        />
      );
    }

    if (menus.length > 0) {
      return (
        <AsideMenuItemList
          key={"/properties"}
          url={"/properties"}
          name={"Utilities"}
          icon="cogs"
          iconType="fa"
        >
          {menus.map((m) => m)}
        </AsideMenuItemList>
      );
    } else {
      return <></>;
    }
  };

  const _modulesMenu = () => {
    if (isSuperAdmin) {
      return (
        <AsideMenuItemList
          key={"/modules"}
          url={"/modules"}
          name={"Modules"}
          icon="database"
          iconType="fa"
        />
      );
    } else {
      if (
        isQuoteEnabled ||
        isInventoryEnabled ||
        isAssetEnabled ||
        isMYOBEssentialEnabled ||
        isMYOBAccountRightLiveEnabled ||
        isJobAttendanceTrackingEnabled ||
        isSiteAttendanceTrackingEnabled ||
        isSiteTrackerEnabled
      ) {
        return (
          <AsideMenuItemList
            url={"/modules"}
            name={"Modules"}
            icon="database"
            iconType="fa"
          >
            {isInventoryEnabled && (
              <AsideMenuItemList
                url={"/modules/inventory"}
                name="Inventory Management"
                iconType="bullet"
              />
            )}

            {isAssetEnabled && (
              <AsideMenuItemList
                url={"/modules/home/asset"}
                name="Asset Management"
                iconType="bullet"
              />
            )}

            {isQuoteEnabled && (
              <AsideMenuItemList
                url={"/modules/quote"}
                name="Quotes"
                iconType="bullet"
              />
            )}

            {/* Todo: Get from where register id come from */}
            {_isMYOBAccountRightLiveEnabled && (
              <MenuItemLink
                url={`modules/myob/accountright/register/${MYOBAccountingRightLiveID}`}
                iconType="bullet"
                openNewPage
              >
                MYOB AccountRight Line
              </MenuItemLink>
            )}

            {_isMYOBEssentialEnabled && (
              <MenuItemLink
                url={`modules/myob/essential/register/${MYOBEssentialID}`}
                iconType="bullet"
                openNewPage
              >
                MYOB Essentials
              </MenuItemLink>
            )}

            {_isJobAttendanceTrackingEnabled && (
              <AsideMenuItemList
                url={"/modules/attendance/tracking"}
                name="Job Attendance Tracking"
                iconType="bullet"
              />
            )}

            {isSiteAttendanceTrackingEnabled && (
              <AsideMenuItemList
                url={"/modules/attendance/register"}
                name="Attendance Tracking"
                iconType="bullet"
              />
            )}

            {isSiteTrackerEnabled && (
              <AsideMenuItemList
                url={"/modules/covid-19"}
                name="Traffic Analyser"
                iconType="bullet"
              />
            )}
          </AsideMenuItemList>
        );
      } else {
        return <div />;
      }
    }
  };

  return (
    <>
      <AsideMenuItemList
        url={"/clients"}
        name={"Clients"}
        icon="users"
        iconType="fa"
        appendedClass={"__tour_client"}
      />

      <AsideMenuItemList
        url={"/property"}
        name={"Properties"}
        icon="home"
        iconType="fa"
        appendedClass={"__tour_property"}
      />

      <AsideMenuItemList
        url={"/operator"}
        name={"Operators"}
        icon="headphones"
        iconType="fa"
        appendedClass={"__tour_operator"}
      />

      <AsideMenuItemList
        name="Service"
        icon="flag"
        iconType="fa"
        url={"/service"}
        appendedClass={"__tour_service"}
      >
        <AsideMenuItemList
          url={"/servicetype"}
          name={"Service Type Listings"}
          iconType="bullet"
        />

        <AsideMenuItemList
          url={"/service"}
          name={"Service Listings"}
          iconType="bullet"
        />
      </AsideMenuItemList>

      {isRoleAdmin && (
        <AsideMenuItemList
          url={"/admins"}
          name={"Admins"}
          icon="user-tie"
          iconType="fa"
          appendedClass={"__tour_admin_man"}
        />
      )}

      {_utilitiesMenu()}

      {_modulesMenu()}

      <AsideMenuItemList
        url={"/job-request"}
        name="Job Request"
        icon="ticket-alt"
        iconType="fa"
        appendedClass={"__tour_job_request"}
      >
        <AsideMenuItemList
          url={"/job-request/new"}
          name="Create New Request"
          iconType="bullet"
        />

        <AsideMenuItemList
          url={"/job-request/recurring"}
          name="Manage Recurring Request"
          iconType="bullet"
        />

        <AsideMenuItemList
          url={"/job-request/area/manager"}
          name="Area Manager"
          iconType="bullet"
        />

        <AsideMenuItemList
          url={"/job-request/status/pending"}
          name="Pending Requests"
          iconType="bullet"
        />

        <AsideMenuItemList
          url={"/job-request/status/open"}
          name="Open Requests"
          iconType="bullet"
        />

        <AsideMenuItemList
          url={"/job-request/status/declined"}
          name="Declined Requests"
          iconType="bullet"
        />

        <AsideMenuItemList
          url={"/job-request/status/completed"}
          name="Completed Requests"
          iconType="bullet"
        />

        <AsideMenuItemList
          url={"/job-request/status/due-today"}
          name="Requests Due Today"
          iconType="bullet"
        />

        <AsideMenuItemList
          url={"/job-request/status/deleted-requests"}
          name="Deleted Requests"
          iconType="bullet"
        />

        <AsideMenuItemList
          url={"/job-request/recurring/listing"}
          name="Recurring Requests"
          iconType="bullet"
        />
      </AsideMenuItemList>

      <AsideMenuItemList
        url={"/reports"}
        name="Reports"
        icon="chart-pie"
        iconType="fa"
      >
        {isRoleAdmin && (
          <AsideMenuItemList
            url={"/reports/recent-activity"}
            name="Recent Activity"
            iconType="bullet"
          />
        )}

        <AsideMenuItemList
          url={"/reports/property/home"}
          name="Properties Request"
          iconType="bullet"
        />

        <AsideMenuItemList
          url={"/reports/client/home"}
          name="Client Reports"
          iconType="bullet"
        />

        <AsideMenuItemList
          url={"/reports/operator/home"}
          name="Operator Reports"
          iconType="bullet"
        />

        <AsideMenuItemList
          url={"/reports/request/home"}
          name="Job Request Reports"
          iconType="bullet"
        />

        <AsideMenuItemList
          url={"/reports/service/home"}
          name="Services Reports"
          iconType="bullet"
        />

        <AsideMenuItemList
          url={"/reports/attendance/tracking/home"}
          name="Job Attendance Tracking Reports"
          iconType="bullet"
        />

        <AsideMenuItemList
          url={"/reports/attendance/register/home"}
          name="Attendance Tracking Reports"
          iconType="bullet"
        />

        <AsideMenuItemList
          url={"/reports/covid/19/home"}
          name="Traffice Analyser Reports"
          iconType="bullet"
        />
      </AsideMenuItemList>

      {isSuperAdmin && (
        <AsideMenuItemList
          url={"/news"}
          name="News Management"
          icon="newspaper"
          iconType="fa"
        />
      )}
    </>
  );
};

export default AsideMenuAdmin;
