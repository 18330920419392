import React from "react";

interface PriorityLabelProp {
    label: string;
}

export const PriorityLabel: React.FunctionComponent<PriorityLabelProp> = ({
    label
}): JSX.Element => {

    let labelClases = "primary";
    switch (label) {
        case "High":
            labelClases = "warning";
            break;
        case "Critical":
            labelClases = "danger";
            break;
        case "Medium":
            labelClases = "warning"
            break;
    }

    if (label === "") {
        return (<></>)
    }

    return (
        <span className={`label label-lg label-light-${labelClases} label-inline`}>
            { label }
        </span>
    )
}
