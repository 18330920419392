import React, {FormEvent} from "react";

interface FilterToolbarWrapperProp {
    buttonTitle?: string;
    onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
    children?: React.ReactNode;
}

const FilterToolbarWrapper: React.FunctionComponent<FilterToolbarWrapperProp> = ({
    buttonTitle = "Apply",
    onSubmit,
    children
}): JSX.Element => {
    return (
        <form onSubmit={ onSubmit }>
            <div className="p-8">
                { children }
            </div>
            <div className="bg-gray-100 px-8 py-3 text-right">
                <button
                    type="submit"
                    className="btn btn-primary font-weight-bolder"
                >
                    { buttonTitle }
                </button>
            </div>
        </form>
    )
};

export default FilterToolbarWrapper;
