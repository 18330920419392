import React from "react";

interface ToolbarDueProp {
    title?: string;
    onSelected?: (arg0: string) => void;
}

export const ToolbarDue: React.FunctionComponent<ToolbarDueProp> = ({
    title = "Due Date",
    onSelected
}: ToolbarDueProp): JSX.Element => {

    const _onSelectValue = (e: any) => {
        if (onSelected) {
            onSelected(e.target.value);
        }
    }

    return (
        <div className="form-group row">
            <label htmlFor="search" className="col-3 col-form-label">{ title }</label>
            <div className="col-4">
                <select className="form-control" id="exampleSelect1" onSelect={ _onSelectValue }>
                    <option>-</option>
                    <option value="within_the_week">Within the next</option>
                    <option value="within_the_last">Within the last</option>
                    <option value="between">Between</option>
                    <option value="on">On</option>
                </select>
            </div>
        </div>
    )
}
