import React from 'react'
import {AsideMenuItemList} from "../AsideMenuList";
import {useSelector} from "react-redux";

const AsideMenuClient = () => {
    const clientConfig = useSelector((state) => state.webConfig.client)

    return (
        <>
            <AsideMenuItemList
                url={"/customer/job-request"}
                name="Job Request"
                icon="ticket-alt"
                iconType="fa"
                appendedClass={"__tour_job_request"}
            >
                {clientConfig.enableCreateJobRequest && (
                    <AsideMenuItemList
                        url={"/customer/job-request/new"}
                        name="Create New Request"
                        iconType="bullet"
                    />
                )}

                <AsideMenuItemList
                    url={"/customer/job-request/status/pending"}
                    name="Pending Requests"
                    iconType="bullet"
                />

                <AsideMenuItemList
                    url={"/customer/job-request/status/open"}
                    name="Open Requests"
                    iconType="bullet"
                />

                <AsideMenuItemList
                    url={"/customer/job-request/status/completed"}
                    name="Completed Requests"
                    iconType="bullet"
                />

                <AsideMenuItemList
                    url={"/customer/job-request/status/due-today"}
                    name="Requests Due Today"
                    iconType="bullet"
                />

                <AsideMenuItemList
                    url={"/customer/recurring"}
                    name="Recurring Requests"
                    iconType="bullet"
                />
            </AsideMenuItemList>

            <AsideMenuItemList
                url={'/customer/client-documents'}
                name={'Document Listings'}
                icon={'file'}
                iconType={'fa'}
            />

            <AsideMenuItemList
                url={'/customer/report/request'}
                name={'Job Request Report'}
                icon={'chart-pie'}
                iconType={'fa'}
            />
        </>
    )
}

export default AsideMenuClient