import { AxiosStatic } from "axios";
import { Store } from "redux";
// import { AuthAction } from "../redux/reducers/authRedux";
import { ApplicationState } from "../redux/rootReducer";
import {refreshToken} from "../redux/reducers/authRedux";
import * as auth from "../redux/reducers/authRedux";
import {jobSlice} from "../redux/reducers/jobRedux";
import {dashboardSlice} from "../redux/reducers/dashboardRedux";
import {newsSlice} from "../redux/reducers/newsRedux";
import {operatorSlice} from "../redux/reducers/operatorRedux";
import {selectSlice} from "../redux/reducers/selectRedux";
import {recurringSlice} from "../redux/reducers/recurringRedux";
import {jobRequestFieldSlice} from "../redux/reducers/jobRequestFieldRedux";
import * as temp from "../redux/reducers/tempRedux";

/**
 * Setup interceptor for updating token when user not Authorized (get status code 401),
 * then after refreshing token, update token on redux state
 *
 * @param axios Axios Config
 * @param store Redux Application State
 */
export default function setupAxiosRefreshToken(axios: AxiosStatic, store: Store<ApplicationState>) {
    const interceptors = axios.interceptors.response.use(
        response => response,
        error => {
            // reject promise if usual error
            if (error.response?.status !== 401) {
                return Promise.reject(error);
            }

            axios.interceptors.response.eject(interceptors);

            return axios.get("auth/accesstoken?refresh=true")
                .then(response => {
                    // update token here
                    const token = response.data.data;
                    // store.dispatch<AuthAction>({ type: "[AUTH TOKEN REFRESH] Action", accessToken: token });
                    store.dispatch(refreshToken({ newAccessToken: token }))
                    // update token on header
                    error.response.config.headers["Authorization"] = "Bearer " + token;

                    return axios(error.response.config);
                }).catch(err => {
                    // TODO: when its not authorized or invalid token
                    // make to login
                    store.dispatch(auth.authSlice.actions.logout());
                    store.dispatch(jobSlice.actions.clearData());
                    store.dispatch(dashboardSlice.actions.clearData());
                    store.dispatch(newsSlice.actions.clear());
                    store.dispatch(operatorSlice.actions.clearData());
                    store.dispatch(selectSlice.actions.clear());
                    store.dispatch(recurringSlice.actions.clear());
                    store.dispatch(jobRequestFieldSlice.actions.clear());
                    store.dispatch(temp.clear());

                    return Promise.reject(err);
                }).finally(() => setupAxiosRefreshToken(axios, store));
        }
    )
}
