import React from "react";
import {useSelector} from 'react-redux';
import Select, {OptionTypeBase} from 'react-select';
import {ApplicationState} from '../../../../redux/rootReducer';

interface ToolbarClientProp {
    clientId?: number;
    disable?: boolean;
    onClientSelected?: (selectedClient: number | undefined) => void;
}

export const ToolbarClient: React.FunctionComponent<ToolbarClientProp> = ({
    clientId,
    disable = false,
    onClientSelected
}: ToolbarClientProp): JSX.Element => {


    const clientState = useSelector((state: ApplicationState) => state.operator?.client.clients);

    const clients = clientState?.map((value, index) => {
        return {
            value: value.id,
            label: value.fullname_client
        }
    });

    let selectedClient: OptionTypeBase = {};
    console.log(clients, clientId);
    if (clientId && clients) {
        selectedClient = clients.filter(v => v.value === clientId)[0];
    }

    return (
        <div className="form-group row">
            <label htmlFor="search" className="col-3 col-form-label">Client</label>
            <div className="col-9">
                <Select
                    options={ clients }
                    value={selectedClient}
                    isDisabled={disable}
                    onChange={(e) => {
                        if (onClientSelected) onClientSelected(e?.value);
                    }}
                />
            </div>
        </div>
    );
}
