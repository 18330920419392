import {SearchingResult} from "../models/SearchingResult";
import {postToApi} from "../utils/APIHelper";
import {ApiResponse, BaseSearchParameterOrderBy} from "./BaseAPI";
import {BaseResponse} from "../models/BaseModel/BaseResponse";
import {JobRequestFindResponse} from "../models/JobRequestFindResponse";

export interface SearchFindParam extends BaseSearchParameterOrderBy {
    session?: boolean;
}

export function searchFind(param?: SearchFindParam): ApiResponse<SearchingResult> {
    return postToApi<SearchingResult, SearchingResult>({
        url: "api/job_requests/find",
        data: param
    }, data => data);
}

export interface SearchModalSubmitDue {
    due?:            string;
    due_number?:     string;
    due_days?:       string;
    due_start?:      Date;
    due_end?:        Date;
    due_current?:    Date;
}

interface SearchSessionParam extends SearchModalSubmitDue {
    type?:   string;
    client_id?: string;
    operator_id?: string;
    status?: string;
    search_keyword?: string;
}

export function searchSearching(): ApiResponse<boolean> {
    return postToApi<boolean, BaseResponse>({
        url: "searching"
    }, data => data.success);
}

export interface JobRequestSearchParameters {
    type: number;
    client_id?: number;
    operator_id?: number;
    property_id?: number;
    status?: string;
    due?: string;
    due_number?: string;
    due_days?: string;
    due_start?: Date;
    due_end?: Date;
    due_current?: Date;
    keyword?: string;
    offset_page?: number;
    orderBy?: string[];
}

export function searchSession(param?: SearchSessionParam): ApiResponse<BaseResponse> {
    return postToApi({
        url: "api/searching/register/session",
        data: param,
        config: { config: { dateFormat: 'dd-MM-yyyy' }}
    }, (data) => data);
}

export function searchFindJobRequest(param?: JobRequestSearchParameters): ApiResponse<JobRequestFindResponse> {
    return postToApi<JobRequestFindResponse, JobRequestFindResponse>({
        url: "/api/job_requests/find.json",
        data: {
            ...param,
            search_keyword: param?.keyword
        }
    }, (data) => data);
}
