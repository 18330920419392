import {ClientListingData} from "../../../models/ClientListing";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ReduxBaseState} from "../../../models/ReduxBaseState";

export interface TempClient extends ReduxBaseState {
    client: { data?: ClientListingData } & ReduxBaseState
    selectedClient?: ClientListingData;
    clients: ClientListingData[];
    totalData: number;
    limit: number;
    currentPage: number;
}

const initialState: TempClient = {
    client: { data: undefined, status: "init", errorMessage: "" },
    selectedClient: undefined,
    clients: [],
    errorMessage: undefined,
    status: "init",
    totalData: 0,
    limit: 0,
    currentPage: 0
}

export const clientSlice = createSlice({
    name: "tempClient",
    initialState,
    reducers: {
        selectClient: (state, action: PayloadAction<ClientListingData>) => {
            state.selectedClient = action.payload;
        },

        tempClientUpdateStatus: (state, action: PayloadAction<ReduxBaseState>) => {
            state.status = action.payload.status;
            state.errorMessage = action.payload.errorMessage || "";
        },

        listingClients: (state, action: PayloadAction<{ data: ClientListingData[]; totalData: number; limit: number; currentPage: number}>) => {
            state.clients = action.payload.data;
            state.totalData = action.payload.totalData;
            state.limit = action.payload.limit;
            state.currentPage = action.payload.currentPage;
            state.status = "success";
        },


        updateDetailClient: (state, action: PayloadAction<ClientListingData>) => {
            state.client.data = action.payload
        },

        updateDetailClientStatus: (state, action: PayloadAction<ReduxBaseState>) => {
            state.client.status = action.payload.status
            state.client.errorMessage = action.payload.errorMessage
        },

        clearDetailClient: (state) => {
            state.client.data = undefined
            state.client.status = "init"
            state.client.errorMessage = ""
        },

        clear: () => initialState
    }
});

export const {
    selectClient,
    listingClients,
    tempClientUpdateStatus,
    clear,

    updateDetailClient,
    updateDetailClientStatus,
    clearDetailClient
} = clientSlice.actions;
