import {Addon} from "../../models/UserInfoModel";
import {ReduxBaseState, ReduxBaseStateStatusType} from "../../models/ReduxBaseState";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ModuleInstalled} from "../../models/ModuleUserInstalledModel";

export type ModulesState = ReduxBaseState & { addons: Addon[], installed: ModuleInstalled[] }

const initialState: ModulesState = {
    errorMessage: undefined,
    status: "init",
    addons: [],
    installed: []
}

export const moduleSlice = createSlice({
    initialState,
    name: 'installedModules',
    reducers: {
        moduleStatus: (state, action: PayloadAction<{status: ReduxBaseStateStatusType}>) => {
            state.status = action.payload.status
        },
        addModules: (state, action: PayloadAction<{addons?: Addon[], installed?: ModuleInstalled[]}>) => {
            if (action.payload.addons) state.addons = action.payload.addons
            if (action.payload.installed) state.installed = action.payload.installed
            state.status = 'success'
        },
        updateModules: (state, action: PayloadAction<ModuleInstalled[]>) => {
            state.installed = action.payload
        },
        clear: () => initialState
    }
})

export const {
    moduleStatus,
    addModules,
    updateModules,
    clear
} = moduleSlice.actions
