import {Dispatch} from "redux";
import {getUsers} from "../../apis/AuthApi";
import {clearSandboxState, sandboxStatus, updateSandboxState} from "../reducers/authRedux";

export const SandboxAction = {
    update: (
        accessToken?: string,
        userId?: number
    ) => (dispatch: Dispatch) => {
        dispatch(sandboxStatus("loading"))
        console.log(`update`)
        // TODO: Fetch User
        const accessToken = "MDYzYmI2MjJjNDJkMzZiMTIyMmNkZTY5NDk1MTkwZjZjMjc4ZWI0ZTdjOTc1NjFiNDIyZGZjYjFjZmNmNTk2YQ"

        // Get User info using this token
        getUsers(accessToken)
            .then(({data, status}) => {
                if (status === 200) {
                    dispatch(updateSandboxState({
                        token: accessToken,
                        refreshToken: "",
                        expire: 0,
                        user: {
                            fullname: data.user.username,
                            email: data.user.email,
                            username: data.user.username,
                            userId: data.user.id,
                            adminId: data.user.admin?.id ?? -1,
                            role: data.user.roles,
                            admin: data.user.admin,
                            operator: data.user.operator,
                            clientlog: data.user.clientlog
                        }
                    }))
                    dispatch(sandboxStatus("success"))
                }
            })
            .catch(error => {
                dispatch(sandboxStatus("error"))
                // TOOD: Handler error here!
            })
    },
    clear: () => (dispatch: Dispatch) => {
        dispatch(clearSandboxState())
    }
}
