import {AdminJobRequestField} from "../../models/JobRequestCustomFields";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface JobRequestFieldState {
    selectedJobRequestField?: AdminJobRequestField;
}

const initialState: JobRequestFieldState = {
    selectedJobRequestField: undefined
};

export const jobRequestFieldSlice = createSlice({
    name: "jobRequestField",
    initialState,
    reducers: {
        addSelectedJobRequestField: (state, actions: PayloadAction<AdminJobRequestField>) => {
            state.selectedJobRequestField = actions.payload;
        },

        clear: () => initialState
    }
});

export const { addSelectedJobRequestField, clear } = jobRequestFieldSlice.actions;