import React from 'react'
import {AsideMenuItemList} from "../../../layout/layout/components/aside/aside-menu/AsideMenuList";

export const AsideSuperAdministratorMenu: React.VFC = () => {
    return (
        <>
            {/* @ts-ignore */}
            <AsideMenuItemList
                url={"/customers"}
                name={"myPSR Customer"}
                icon="users"
                iconType="fa"
            >
                {/* @ts-ignore */}
                <AsideMenuItemList
                    url={"/customers/create"}
                    name={"Create a Customer"}
                    iconType="bullet"
                />

                {/* @ts-ignore */}
                <AsideMenuItemList
                    url={"/customers"}
                    name={"myPSR Customer Listing"}
                    iconType="bullet"
                />
            </AsideMenuItemList>

            {/* @ts-ignore */}
            <AsideMenuItemList
                url={"/news"}
                name={"News"}
                icon="news"
                iconType="fa"
            >
                {/* @ts-ignore */}
                <AsideMenuItemList
                    url={"/news/create"}
                    name={"Create a News"}
                    iconType="bullet"
                />

                {/* @ts-ignore */}
                <AsideMenuItemList
                    url={"/news/listing"}
                    name={"News Listing"}
                    iconType="bullet"
                />
            </AsideMenuItemList>
        </>
    )
}