import React from "react";
import { FormLabelTooltip } from "../FormLabelTooltip";

interface FormGroupRowProp {
  /** Text label name */
  label: string;

  /** Column size of children __Default 9__, and __Maximum 9__ */
  contentColSize?: number;

  /** When this set to true, it's means the content column not subtracted with label  */
  fixedColSize?: boolean;

  /** if true the children input wrapper will appended with `col-form-label` class*/
  withLabelClass?: boolean;

  /** If this field is required or not, if its required it will added asterisk (*) after the label */
  required?: boolean;

  labelSize?: number;

  errorMessage?: string;

  tooltip?: string;

  children?: React.ReactNode;
}

/***
 * Create form group row input
 */
export const FormGroupRow: React.FunctionComponent<FormGroupRowProp> = ({
  label,
  contentColSize = 9,
  withLabelClass = false,
  fixedColSize = true,
  required = false,
  labelSize,
  children,
  errorMessage,
  tooltip,
}): JSX.Element => {
  let labelColSize = 12 - contentColSize;
  if (fixedColSize) {
    labelColSize = 3;
  }

  if (labelSize) {
    labelColSize = labelSize;
  }

  return (
    <div className="form-group row">
      {tooltip ? (
        <FormLabelTooltip
          label={label}
          tooltip={tooltip}
          className={`col-form-label col-md-${labelColSize}`}
        />
      ) : (
        <label
          htmlFor={label.toLowerCase()}
          className={`col-form-label col-md-${labelColSize}`}
        >
          {label}
          {required && (
            <span className="text-danger font-weight-bolder">*</span>
          )}
        </label>
      )}

      <div
        className={`col-md-${contentColSize} ${
          withLabelClass ? "col-form-label" : ""
        }`}
      >
        {children}
        {errorMessage && (
          <div className="invalid-feedback d-block">{errorMessage}</div>
        )}
      </div>
    </div>
  );
};

type FormGroupRowTextProp = FormGroupRowProp &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;

export const FormGroupRowText: React.FunctionComponent<
  FormGroupRowTextProp
> = ({
  label,
  contentColSize = 9,
  withLabelClass = false,
  fixedColSize = false,
  required = false,
  placeholder,
  ...prop
}): JSX.Element => {
  let customPlaceholder = placeholder ?? label;

  return (
    <FormGroupRow
      label={label}
      contentColSize={contentColSize}
      withLabelClass={withLabelClass}
      fixedColSize={fixedColSize}
      required={required}
    >
      <input
        type={prop.type ?? "text"}
        placeholder={customPlaceholder}
        className={`form-control ${prop.className ?? ""}`}
        {...prop}
      />
    </FormGroupRow>
  );
};

interface IFormGroupRowRight {
  label: string;
  required?: boolean;
  children?: React.ReactNode;
}

export const FormGroupRowRight: React.FC<IFormGroupRowRight> = (props) => {
  const { label, required = false, children } = props;

  const childrendWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      // @ts-ignore
      return React.cloneElement(child, { placeholder: label });
    }
    return child;
  });

  return (
    <div className="form-group row">
      <label
        htmlFor="a"
        className={"col-form-label col-3 text-lg-right text-left"}
      >
        {label}
        {required && <span className={"pl-1 text-danger"}>*</span>}
      </label>
      <div className="col-9">{childrendWithProps}</div>
    </div>
  );
};
