import React from "react";
import {Link} from "react-router-dom"
import {SymbolImage} from "../SymbolImage";
import {CustomImage} from "..";

interface TableDataWithAvatarProp {
    imageUrl?: string;
    errorImageUrl?: string;
    title: string;
    subtitle?: string;
    to?: string;
}

export const TableDataWithAvatar: React.FunctionComponent<TableDataWithAvatarProp> = ({
    imageUrl,
    errorImageUrl,
    title,
    subtitle,
    to
}): JSX.Element => {

    return (
        <div className="d-flex align-items-center">
            <div className="mr-4">
                <SymbolImage size="60">
                    <CustomImage
                        src={imageUrl ?? ""}
                        alt={ title }
                        errorImageSrc={errorImageUrl}
                    />
                </SymbolImage>
            </div>

            <div>
                { to ? (
                    <Link to={to} className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        <div dangerouslySetInnerHTML={{__html: title}}/>
                        {subtitle && (
                            <span className="text-muted font-weight-bold d-block">{ subtitle }</span>
                        )}
                    </Link>
                ) : (
                    <div className="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                        <div dangerouslySetInnerHTML={{__html: title}}/>
                        {subtitle && (
                            <span className="text-muted font-weight-bold d-block">{ subtitle }</span>
                        )}
                    </div>
                )}

            </div>
        </div>
    )
}
