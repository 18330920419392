import React, {useEffect, useState} from "react";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../redux/rootReducer";
import {SelectItem} from "../../../models/SelectItem";
import {vendorSelectAction} from "../../../redux/actions/selectActions";

interface SelectVendorProps {
    value?: string;
    onSelect?: (vendorId: string) => void;
    disable?: boolean;
}

export const SelectVendor: React.FunctionComponent<SelectVendorProps> = ({
    value,
    onSelect,
    disable = false,
}): JSX.Element => {
    const dispatch = useDispatch();
    const {selectItems, status, errorMessage} = useSelector((state: ApplicationState) => state.select.vendor);

    const [selectedItem, setSelectedItem] = useState<SelectItem | undefined | null>();

    const _selectedItemsWithEmptyValue: SelectItem[] = [{ value: "", label: "Select Vendor" }, ...selectItems];
    if (process.env.NODE_ENV === "development") {
        if (errorMessage) console.log("SELECT_VENDOR_ERROR", errorMessage);
    }

    // Effect for getting initial data
    useEffect(() => {
        _getInitialData();
    }, []);

    // Effect on `value` change
    useEffect(() => {
        _onValueChange(value);
    }, [value]);

    const _getInitialData = () => {
        dispatch(vendorSelectAction());
    }

    const _onValueChange = (selectedValue?: string) => {
        const found = selectItems.find(item => item.value === selectedValue);
        setSelectedItem(found ?? null);
    }

    return (
        <Select
            isLoading={status === "loading"}
            isDisabled={disable}
            options={_selectedItemsWithEmptyValue}
            value={selectedItem}
            onChange={item => {
                if (onSelect && item) {
                    onSelect(item.value);
                }
            }}
            styles={{
                control: (styles) => {
                    return { ...styles, zIndex: 2 }
                },
                menu: (base) => {
                    return { ...base, zIndex: 3}
                }
            }}
        />
    )
}
