import _ from "lodash";
import React, { useEffect, useRef } from "react";
import Configuration from "../../resorces/Configuration";

interface ISearchBar {
  onTextChange?: (text: string) => void;
  onClear?: () => void;
  value?: string;
}

const SearchBar: React.VFC<ISearchBar> = ({ onTextChange, onClear, value }) => {
  const searchRef = useRef<HTMLInputElement>(null);

  const _performSearch = _.debounce((text: string) => {
    if (onTextChange) onTextChange(text);
  }, Configuration.DEFAULT_DEBOUNCE_TIME);

  useEffect(() => {
    searchRef.current!.value = value ?? "";
  }, [value]);

  return (
    <div>
      <div className="input-group">
        <input
          ref={searchRef}
          type="text"
          className="form-control"
          placeholder="Search"
          onChange={(e) => {
            _performSearch(e.target.value);
          }}
        />
        <div className="input-group-append">
          <button
            className="btn btn-light"
            type="button"
            onClick={() => {
              searchRef.current!.value = "";
              if (onClear) onClear();
            }}
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
