import React from "react";

export interface PaginationNavProp {
    /**
     * Total number of page can be navigated
     */
    totalPage: number;

    /**
     * Current Page number
     */
    currentPage: number;

    /**
     * Indicate the page is start from zero (0) or one (1)
     */
    startFromZero?: boolean;

    /**
     * Total of pagination number element to be displayed.
     * Other page number will be truncated
     *
     * __Default value 10__
     */
    totalPageToDisplay?: number;

    /**
     * Callback when user selected a page,
     * with `page` is page clicked
     */
    onPageSelected?: (page: number) => void;
}

/**
 * Create custom Pagination Navigation
 * @deprecated
 */
export const PaginationNav: React.FunctionComponent<PaginationNavProp> = ({
    totalPage,
    currentPage,
    startFromZero = false,
    totalPageToDisplay = 10,
    onPageSelected
}): JSX.Element => {

    let page = currentPage;
    totalPage--
    // if (startFromZero) page = currentPage + 1;

    const _onIncrementPage = () => {
        if (page < totalPage) {
            const currPage = page + 1;
            _navigate(startFromZero ? (currPage - 1) : currPage);
        }
    };

    const _onDecrementPage = () => {
        if (page > 0) {
            const currPage = page - 1;
            _navigate(startFromZero ? (currPage - 1) : currPage);
        }

    };

    /**
     * Goto selected page when user clicked specific page number
     * @param page Number of page selected/clicked
     */
    const _onPageNumberClicked = (page: number) => {
        if (page > 0 && page <= totalPage) {
            _navigate(startFromZero ? (page - 1) : page);
        }
    };

    const _navigate = (page: number) => {
        if (onPageSelected) onPageSelected(page);
    }

    // If total page is 2 or below, just render the page number count
    if (totalPage <= 2) {
        return (
            <div className="d-lg-flex justify-content-between align-items-center">
                <span>
                    Page {page + 1} of {totalPage + 1}
                </span>
                <div className="d-flex justify-content-between align-items-center flex-wrap mt-4">
                    <RenderPage
                        currentPage={page + 1}
                        maxDisplayedPage={totalPageToDisplay}
                        totalPage={totalPage}
                        onClick={page => _onPageNumberClicked(page - 1)}
                    />
                </div>
            </div>
        )
    } else {
        return (
            <div className="d-lg-flex justify-content-between align-items-center">
                <div>
                    Page {page + 1} of {totalPage}
                </div>
                <div className="d-flex justify-content-between align-items-center flex-wrap mt-4">
                    <div className="d-flex flex-wrap py-2 mr-3">
                        <button
                            onClick={() => _onPageNumberClicked(1)}
                            className="btn btn-icon btn-sm btn-light mr-2 my-1">
                            <i className="ki ki-bold-double-arrow-back icon-xs"/>
                        </button>

                        <button
                            onClick={_onDecrementPage}
                            className="btn btn-icon btn-sm btn-light mr-2 my-1">
                            <i className="ki ki-bold-arrow-back icon-xs"/>
                        </button>

                        <RenderPage
                            currentPage={page + 1}
                            maxDisplayedPage={totalPageToDisplay}
                            totalPage={totalPage}
                            onClick={page => _onPageNumberClicked(page)}
                        />

                        <button
                            onClick={_onIncrementPage}
                            className="btn btn-icon btn-sm btn-light mr-2 my-1">
                            <i className="ki ki-bold-arrow-next icon-xs"/>
                        </button>


                        <button
                            onClick={() => _onPageNumberClicked(totalPage)}
                            className="btn btn-icon btn-sm btn-light mr-2 my-1"
                        >
                            <i className="ki ki-bold-double-arrow-next icon-xs"/>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

interface RenderPageProp {
    /**
     * Current Page selected
     */
    currentPage: number;

    /**
     * Maximum page to be displayed
     */
    maxDisplayedPage: number;

    /**
     * Total Page
     */
    totalPage: number;

    /**
     * On Selected Page number
     */
    onClick: (page: number) => void;
}

const RenderPage: React.FunctionComponent<RenderPageProp> = ({
    currentPage,
    maxDisplayedPage,
    totalPage,
    onClick
}): JSX.Element => {


    // if total page is higher than total diplayed page,
    // render with truncated page number
    if (totalPage > maxDisplayedPage) {
        // Create page to be rendered
        let pages = [];

        let startPage = 1;
        let endPage = maxDisplayedPage;

        // get what page need to be started
        if (currentPage >= maxDisplayedPage) {
            // Get the center
            let center = Math.floor(maxDisplayedPage / 2);

            startPage = currentPage - center;

            pages.push(
                <button key="dot-start" className={`btn btn-icon btn-sm border-0 btn-light mr-2 my-1`}>
                    ...
                </button>
            )
        }

        // get where page need to be end and truncated
        if (currentPage >= maxDisplayedPage && currentPage <= (totalPage - maxDisplayedPage)) {
            let center = Math.floor(maxDisplayedPage / 2);

            endPage = currentPage + center;
        }

        if (currentPage >= (totalPage - maxDisplayedPage)) {
            endPage = totalPage;
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={ i }
                    onClick={() => onClick(i)}
                    className={`btn btn-icon btn-sm border-0 btn-light btn-hover-primary mr-2 my-1 ${i === (currentPage) ? 'active' : ""}`}
                >
                    { i }
                </button>
            );
        }

        // if still not on the end page create dot
        if (currentPage < (totalPage - maxDisplayedPage)) {
            pages.push(
                <button key="end-start" className={`btn btn-icon btn-sm border-0 btn-light mr-2 my-1`}>
                    ...
                </button>
            )
        }

        return (
            <>
                { pages.map(value => value)}
            </>
        )
    } else {
        let pages = [];
        for (let i = 1; i <= totalPage; i++) pages.push(i);

        return (
            <>
                {pages.map((value) => (
                    <button
                        key={value.toString()}
                        onClick={() => onClick(value)}
                        className={`btn btn-icon btn-sm border-0 btn-light btn-hover-primary mr-2 my-1 ${value === (currentPage) ? "active" : ""}`}
                    >
                        { value }
                    </button>
                )) }
            </>
        )
    }
}
