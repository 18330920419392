import {Redirect, Switch} from "react-router-dom";
import {ContentRoute} from "../../layout/layout";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import RecoverAccount from "./RecoverAccount";
import Registration from "./Registration";
import React from "react";
import {ResetPassword} from "./ResetPassword";

export const AuthPage = () => {
    return (
        <>
            <div id="mypsr-login-container" className="w-100 h-100">

                <Switch>
                    {/* @ts-ignore */}
                    <ContentRoute path="/login" component={Login} />
                    {/* @ts-ignore */}
                    <ContentRoute path="/forgot-password" component={ForgotPassword} />
                    {/* @ts-ignore */}
                    <ContentRoute path="/recover-account" component={RecoverAccount} />
                    {/* @ts-ignore */}
                    <ContentRoute path="/registration" component={Registration} />

                    {/* @ts-ignore */}
                    <ContentRoute path="/resetting/reset/:resetToken" exact component={ResetPassword} />

                    <Redirect from="/" exact={true} to="/login"/>
                    <Redirect to="/login"/>
                </Switch>


                <div className="footer text-center text-light">
                    <p>Copyright 2021 | <span className="mypsr">myPSR</span> All Right Reserved</p>
                </div>
            </div>
        </>
    )
}
