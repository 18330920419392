import {Dropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import React, {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import objectPath from "object-path";
import {DropdownTopbarItemToggler} from "../../../../_partials/dropdowns";
import {ApplicationState} from "../../../../../../redux/rootReducer";
import {useHtmlClassService} from "../../../_core/MetronicLayout";
import {PasscodeModal} from "../../../../../pages/AccountManagement/PasscodeModal";
import Constants from "../../../../../../resorces/Constants";
import {CustomImage, SymbolImage} from "../../../../../components";
import {useRoleHelper} from "../../../../../../resorces/RoleHelper";
import Api_config from "../../../../../../api_config";

interface LinkItem {
    to?: string;
    name: string;
    icon: string;
    color?: string;
    onClick?: () => void
}

export function UserProfileDropdown(): JSX.Element {

    const {user} = useSelector((state: ApplicationState) => state.auth);
    const {isSuperAdmin, isAdmin, isAdministrator, isOperator, isClient} = useRoleHelper()

    const [showPasscodeModal, setShowPasscodeModal] = useState(false);

    const uiService = useHtmlClassService();
    const _ = useMemo(() => {
        return {
            light:
                objectPath.get(uiService.config, "extras.user.dropdown.style") ===
                "light",
        };
    }, [uiService]);

    let imageProfileUrl: string | undefined
    let userFullName = user.username
    if (isOperator) {
        imageProfileUrl = user.operator?.image_url
        userFullName = user.operator?.firstname ?? ''
    }
    if (isAdministrator || isAdmin) {
        imageProfileUrl = user.admin?.image_url
        userFullName = user.admin?.firstname ?? userFullName
    }
    if (isClient) {
        imageProfileUrl = user.clientlog?.image_url
        userFullName = user.clientlog?.firstname ?? userFullName
    }

    var link: LinkItem[] = []
    if (isSuperAdmin) {
        link = [
            {
                to: "/bank/account/mypsr",
                name: "Bank Account",
                icon: "fas fa-credit-card",
                color: "text-warning",
                // description: "Update Bank Account Information"
            },
            {
                to: '/export/account',
                name: 'Export Data',
                icon: 'fas fa-file-export',
                color: 'text-success'
            },
            {
                name: 'Create New Passcode',
                icon: 'fas fa-key',
                color: 'text-warning',
                onClick: () => {
                    setShowPasscodeModal(true);
                }
            },
            {
                name: 'Change Password',
                icon: 'fas fa-unlock-alt',
                color: 'text-warning'
            },
        ]
    }

    if (isAdministrator) {
        link = [
            {
                to: '/export/account',
                name: 'Export Data',
                icon: 'fas fa-file-export',
                color: 'text-success'
            },
            {
                to: '/edit/profile',
                name: 'Edit Profile',
                icon: 'fas fa-edit',
                color: 'text-primary'
            },
            {
                name: 'Create New Passcode',
                icon: 'fas fa-key',
                color: 'text-warning',
                onClick: () => {
                    setShowPasscodeModal(true);
                }
            },
            {
                name: 'Change Password',
                icon: 'fas fa-unlock-alt',
                color: 'text-warning',
                to: '/profile/change-password'
            },
        ]
    }
    if (isAdmin) {
        link = [
            {
                to: "/admins/account/profile/setup/company",
                name: "My Profile",
                icon: "fas fa-edit",
                color: "text-success",
                // description: "Account information"
            },
            {
                to: "/export/account",
                name: "Export Data",
                icon: "fas fa-file-export",
                // description: "Export User Data"
            },
            {
                to: "/cancel/account",
                name: "Cancel Account",
                icon: "fas fa-ban",
                color: "text-danger",
                // description: "Deactivated User"
            },
            {
                to: "/delete/account",
                name: "Delete Account",
                icon: "fas fa-user-slash",
                color: "text-danger",
                // description: "Delete this account"
            },
            {
                to: "/admins/account/bank/setup/company",
                name: "Bank Account",
                icon: "fas fa-credit-card",
                color: "text-warning",
                // description: "Update Bank Account Information"
            },
            {
                to: "/settings/account/paymenthistory",
                name: "Payment History",
                icon: "fas fa-file-invoice",
                color: "text-warning",
            },
            {
                to: "/admins/account/account/setup/company",
                name: "Setup/Change Payment",
                icon: "fas fa-sync-alt",
                color: "text-warning"
            },
            {
                to: "/admins/account/modules/setup/company",
                name: "Setup Modules",
                icon: "fas fa-cubes",
                // description: "Enable or Disable feature"
            },
            {
                name: "Create New Passcode",
                icon: "fas fa-key",
                color: "text-warning",
                onClick: () => {
                    setShowPasscodeModal(true);
                }
            },
            {
                to: "/profile/change-password",
                name: "Change Password",
                icon: "fas fa-unlock-alt",
                color: "text-warning"
            }
        ]
    }
    if (isOperator) {
        link = [
            {
                to: "/account/profile",
                name: "Edit Profile",
                icon: "fas fa-edit",
                color: "text-success",
                // description: "Account information"
            },
            {
                name: "Create New Passcode",
                icon: "fas fa-key",
                color: "text-warning",
                onClick: () => {
                    setShowPasscodeModal(true);
                }
            },
            {
                to: "/account/change-password",
                name: "Change Password",
                icon: "fas fa-unlock-alt",
                color: "text-warning"
            }
        ]
    }
    if (isClient) {
        link = [
            {
                to: "/profile/email",
                name: "Email Notification Settings",
                icon: "fas fa-envelope",
                color: "text-primary"
            },
            {
                name: "Create New Passcode",
                icon: "fas fa-key",
                color: "text-warning",
                onClick: () => {
                    setShowPasscodeModal(true);
                }
            },
            {
                to: "/profile/change-password",
                name: "Change Password",
                icon: "fas fa-unlock-alt",
                color: "text-warning"
            }
        ]
    }

    return (
        <>
            <Dropdown drop="down" alignRight>
                <Dropdown.Toggle
                    as={DropdownTopbarItemToggler}
                    id="dropdown-toggle-user-profile"
                >
                    <div
                        className={
                            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                        }
                    >
                      <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                        Hi,
                      </span>{" "}
                        <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                        {userFullName}
                      </span>
                        {(imageProfileUrl !== undefined) ? (
                            <SymbolImage>
                                <CustomImage
                                    src={imageProfileUrl}
                                    errorImageSrc={`${Api_config.REACT_APP_BASE_URL}/portal/img/no-image.png?1.2.62_Build_26`}
                                />
                            </SymbolImage>
                        ) : (<span className="symbol symbol-35 symbol-light-success">
                                <span className="symbol-label font-size-h5 font-weight-bold">
                                  {user.fullname[0]}
                                </span>
                          </span>
                        )}
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu
                    className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">

                    <div className="navi navi-spacer-x-0 pt-2">
                        {link.map((value, index) => {
                            if (value.to) {
                                return (
                                    <Link key={index} to={value.to} className="navi-item px-8 cursor-pointer">
                                        <div className="navi-link">
                                            <div className="navi-icon mr-2">
                                                <i className={`${value.icon} ${value.color ?? "text-primary"}`}/>
                                            </div>
                                            <div className="navi-text">
                                                <div className="font-weight-bold cursor-pointer">
                                                    {value.name}
                                                </div>
                                                {/*{value.description && (*/}
                                                {/*    <div className="text-muted">{value.description}</div>)}*/}
                                            </div>
                                        </div>
                                    </Link>
                                )
                            } else {
                                return (
                                    <div key={index} className="navi-item px-8 cursor-pointer">
                                        <div onClick={() => {
                                            if (value.onClick) value.onClick()
                                        }} className="navi-link">
                                            <div className="navi-icon mr-2">
                                                <i className={`${value.icon} ${value.color ?? "text-primary"}`}/>
                                            </div>
                                            <div className="navi-text">
                                                <div className="font-weight-bold cursor-pointer">
                                                    {value.name}
                                                </div>
                                                {/*{value.description && (*/}
                                                {/*    <div className="text-muted">{value.description}</div>)}*/}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })}

                        <div className="navi-separator mt-3"/>

                        <div className="navi-footer  px-8 py-5">
                            <Link
                                to="/logout"
                                className="btn btn-light-danger font-weight-bold"
                            >
                                Sign Out
                            </Link>
                            {(Array.isArray(user.role) && user.role.indexOf(Constants.Roles.RoleAdmin) > -1) && (
                                <Link
                                    to="/admins/account/upgrade/setup/company"
                                    className="btn btn-clean font-weight-bold"
                                >
                                    Upgrade Account
                                </Link>
                            )}
                        </div>
                    </div>
                </Dropdown.Menu>
            </Dropdown>

            <PasscodeModal
                show={showPasscodeModal}
                onClose={() => setShowPasscodeModal(false)}
            />
        </>
    );
}
