import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../redux/rootReducer";
import {SelectItem} from "../../../models/SelectItem";
import {useEffect, useState} from "react";
import {serviceSelectAction} from "../../../redux/actions/selectActions";

interface SelectServiceProps {
    value?: string;
    onSelected?: (serviceId: string) => void;
    disabled?: boolean;
}

export function SelectService({
    value,
    onSelected,
    disabled = false,
}: SelectServiceProps): JSX.Element {
    const dispatch = useDispatch();
    const { selectItems, status, errorMessage } = useSelector((state: ApplicationState) => state.select.service);

    const [selectedItem, setSelectedItem] = useState<SelectItem | undefined | null>();

    if (process.env.NODE_ENV === "development") {
        if (errorMessage) console.log("SELECT_SERVICE_ERROR", errorMessage);
    }

    // Append empty selection
    const selectItemWithEmptyItem: SelectItem[] = [{ value: "", label: "Select Service"}, ...selectItems];

    // Get initial value
    useEffect(() => {
        _getInitialData();
    }, []);

    // Calling when value changing
    useEffect(() => {
        _onValueChange(value);
    }, [value]);

    const _getInitialData = () => {
        dispatch(serviceSelectAction());
    }

    const _onValueChange = (selectedValue?: string) => {
        const found = selectItems.find(item => item.value === selectedValue);
        setSelectedItem(found ?? null);
    }

    return (
        <Select
            isLoading={status === "loading"}
            isDisabled={disabled}
            options={selectItemWithEmptyItem}
            value={selectedItem}
            onChange={item => {
                if (onSelected && item) {
                    onSelected(item.value);
                }
            }}
            styles={{
                control: (styles) => {
                    return { ...styles, zIndex: 2 }
                },
                menu: (base) => {
                    return { ...base, zIndex: 3}
                }
            }}
        />
    )
}
