import React, {useEffect, useState} from 'react'
import {useRoleHelper} from "../../resorces/RoleHelper";
import {Spinner} from "react-bootstrap";
import {IJobRequestTrackTimeModel} from "../../models/IJobRequestResponse";

interface IJobRequestTimeTrackerProps {
    operatorName?: string,
    trackTime?: IJobRequestTrackTimeModel[]
    isLoading?: boolean
    onStartClick?: () => void;
    onPauseClick?: () => void;
    onStopClick?: () => void;
}

const JobRequestTimeTracker: React.VFC<IJobRequestTimeTrackerProps> = ({
    operatorName = "",
    trackTime = [],
    onStartClick,
    onPauseClick,
    onStopClick,
    isLoading = false
}) => {
    const {isAdminAdministrator, isOperator} = useRoleHelper()


    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(isLoading)
    }, [isLoading])
    // console.log(isLoading, loading)

    // Calculate Track Time
    // Get last tracktime
    const lastTrackTime = trackTime[trackTime.length - 1];

    let time = 0
    if (lastTrackTime && lastTrackTime.start_track) {
        time = (new Date()).getTime()
        const lastTrackTimeTimestamp = Date.parse(lastTrackTime.start_track)
        time = time - lastTrackTimeTimestamp
    }

    const timer = useTimer(time, lastTrackTime?.initial_track === "Start")

    const initialTrack: string | undefined = lastTrackTime?.initial_track

    // When Admin,
    if (isAdminAdministrator) {
        return (
            <div className={`py-2 px-4 bg-gray-50 border d-flex justify-content-between`}>
                <div className={`font-weight-bolder`}>{operatorName}</div>
                <div className={'text-danger font-weight-boldest font-size-lg'}>
                    {timer[0]}d {timer[1]}h {timer[2]}m {timer[3]}s
                </div>
            </div>
        )
    }

    // Time Tracker is showed only for Operator
    if (!isOperator) {
        return <div />
    }

    return (<div className={'position-relative p-4 border rounded'}>
        <div className={'text-center'}>
            <div className={'mb-4'}>
                <span className={'font-weight-bolder font-size-h1'}>{timer[0]}d {timer[1]}h {timer[2]}m {timer[3]}s</span>
            </div>
            {!loading ? (<>
                {(initialTrack === "Pause" || trackTime.length === 0) && (
                    <button
                        type={'button'}
                        className={'btn btn-success'}
                        onClick={() => {
                            if (onStartClick) {
                                onStartClick()
                                setLoading(true)
                            }
                        }}
                    >
                        <i className="fas fa-play icon-nm" />
                        Start
                    </button>
                )}

                {(initialTrack !== 'Pause' && trackTime.length > 0) && (
                    <button
                        type={'button'}
                        className={'btn btn-warning mx-4'}
                        onClick={() => {
                            if (onPauseClick) {
                                onPauseClick()
                                setLoading(true)
                            }
                        }}
                    >
                        <i className="fas fa-pause icon-nm" />
                        Pause
                    </button>
                )}

                {(lastTrackTime?.initial_track !== "Pause" && trackTime.length > 0) && (
                    <button
                        type={'button'}
                        className={'btn btn-danger'}
                        onClick={() => {
                            if (onStopClick) {
                                onStopClick()
                                setLoading(true)
                            }
                        }}
                    >
                        <i className="fas fa-stop icon-nm" />
                        Stop
                    </button>
                )}
            </>) : (
                <Spinner animation={'border'} />
            )}
        </div>
    </div>)
}

const useTimer = (timestamp: number, run = true): [number, number, number, number] => {
    const [counter, setCounter] = useState(timestamp)

    useEffect(() => {
        if (timestamp > 0 && run) {
            const interval = setInterval(() => {
                setCounter(timestamp + 1)
            }, 1000)

            return () => clearInterval(interval)
        }
    }, [timestamp])

    return getReturnValues(counter)
}

const getReturnValues = (countDown: number): [number, number, number, number] => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
};

export default JobRequestTimeTracker
