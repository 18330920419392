import React from 'react';
import {useIntl} from "react-intl";

export interface AlertPlaceholderProps {
    placeholderName: string;
    noResultMessage?: boolean;
}

export const AlertPlaceholder: React.FC<AlertPlaceholderProps> = ({
   placeholderName,
   noResultMessage = false,
}): JSX.Element => {

    const {formatMessage: f} = useIntl();

    return (
        <div className="text-center">
            <div className="d-inline-block bg-light-warning p-2 rounded">
                <span className="text-warning font-weight-bolder">
                    <i className="fas fa-exclamation-triangle font-size-sm mr-2 text-warning" />
                    {f({id: noResultMessage ? "DATA.NORESULT": "DATA.EMPTY"}, {name: placeholderName})}
                </span>
            </div>
        </div>
    )
}
