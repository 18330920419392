import React from 'react'
import {AsideMenuItemList} from "../AsideMenuList";

const AsideMenuOperator = () => {
    return (
        <>
            <AsideMenuItemList
                name={'Pending Request'}
                url={'/job-request/status/pending'}
                icon={'clock'}
                iconType={'fa'}
            />

            <AsideMenuItemList
                name={'Open Request'}
                url={'/job-request/status/open'}
                icon={'info-circle'}
                iconType={'fa'}
            />

            <AsideMenuItemList
                name={'Completed Request'}
                url={'/job-request/status/completed'}
                icon={'check-circle'}
                iconType={'fa'}
            />

            <AsideMenuItemList
                name={'Request Due Today'}
                url={'/job-request/status/due-today'}
                icon={'calendar-day'}
                iconType={'fa'}
            />

            <AsideMenuItemList
                name={'Recurring Request'}
                url={'/job-request/recurring/listing'}
                iconType={'fa'}
                icon={'history'}
            />

            <AsideMenuItemList
                name={'Employee Task'}
                url={'/operator/employee/tasks'}
                icon={'tasks'}
                iconType={'fa'}
            />
        </>
    )
}

export default AsideMenuOperator