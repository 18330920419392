import React from "react";
import { Spinner } from "react-bootstrap";

interface CardDashboardBodyProps {
  overlayLoading?: boolean;
  children?: React.ReactNode;
}

export const CardDashboardBody: React.FunctionComponent<
  CardDashboardBodyProps
> = ({ overlayLoading = false, children }): JSX.Element => {
  return (
    <div className="card-body pt-4 px-6 pb-4">
      {children}
      {overlayLoading && (
        <div className="position-absolute blocked-ui top-0 left-0 right-0 bottom-0 rounded text-center py-8">
          <Spinner animation="border" />
        </div>
      )}
    </div>
  );
};
