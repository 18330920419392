import React, { ReactNode } from "react";

interface InputGroupProp {
  prepend?: NonNullable<ReactNode> | null;
  append?: NonNullable<ReactNode> | null;
  children?: React.ReactNode;
}

/**
 * Create Custom input group for Bootstrap, but implemented Differently 😬
 * */
export const InputGroup: React.FunctionComponent<InputGroupProp> = ({
  children,
  prepend,
  append,
}): JSX.Element => {
  // Check if the prepend is type of string
  // if that is string, then wrap it with `.input-group-text` className
  let prepended;
  if (typeof prepend === "string") {
    prepended = <div className="input-group-text">{prepend}</div>;
  } else {
    prepended = prepend;
  }

  let appended = append;
  if (typeof append === "string") {
    appended = <span className="input-group-text">{append}</span>;
  }

  return (
    <div className="input-group">
      {prepend && <div className="input-group-prepend">{prepended}</div>}

      {children}

      {appended && <div className="input-group-append">{appended}</div>}
    </div>
  );
};
