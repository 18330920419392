import {ApiResponse, BaseSearchParameterOrderBy, BaseSearchParameters} from "./BaseAPI";
import {NewsFeed} from "../models/NewsFeed";
import {postToApi} from "../utils/APIHelper";
import {NewsListingData, NewsListingResponse, NewsListingSingleResponse} from "../models/NewsListingResponse";
import {BaseResponse} from "../models/BaseModel/BaseResponse";

export const newsGetAll = (searchParam?: BaseSearchParameterOrderBy): ApiResponse<NewsFeed> =>
    postToApi<NewsFeed, NewsFeed>({
        url: "api/news/publish",
        data: searchParam
    }, data => data)

// For Manage News
export const newsListing = (param?: BaseSearchParameters): ApiResponse<NewsListingResponse> => {
    return postToApi<NewsListingResponse, NewsListingResponse>({
        url: `/api/news/listings.json`,
        data: param
    }, data => data)
}

export const newsDetail = (newsId: string): ApiResponse<NewsListingData> => {
    return postToApi<NewsListingData, NewsListingSingleResponse>({
        url: `/api/news/${newsId}/detail.json`
    }, data => data.news)
}

export interface INewsCreateData {
    title: string,
    details: string,
}

export const newsCreate = (data: INewsCreateData, newsId?: string): ApiResponse</* message */string> => {
    const URL = newsId ? `/api/news/${newsId}/edit.json` : `/api/news/add.json`

    return postToApi<string, BaseResponse>({
        url: URL,
        data: {...data, id: newsId }
    }, (data, message) => message ?? "")
}

export const newsDelete = (newsId: string): ApiResponse</*message*/ string> => {
    return postToApi({
        url: `/api/news/${newsId}/delete`
    }, (data, message) => message!)
}

export const newsPublish = (newsId: string): ApiResponse</*message*/ string> => {
    return postToApi({
        url: `/api/news/${newsId}/publish`
    }, (data, message) => message!)
}