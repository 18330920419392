import React, {MutableRefObject, useCallback, useEffect, useRef, useState} from 'react'
import {InputGroup} from "./InputGroup";
import {Spinner} from "react-bootstrap";
import {arrayContains} from "../../../utils/Helpers";
import _ from "lodash";
import {GMAPGeocodeResult} from "../../../models/GMAPGeocodeResult";
import Configuration from "../../../resorces/Configuration";

interface IAddressSearchData {
    address1: string
    suburb: string
    postcode: string
    state: string
    country: string
}

const GMapsAddressKey = {
    postalCode: "postal_code",
    country: "country",
    state: "administrative_area_level_1",
    suburb: "locality",
    route: "route",
    streetNumber: "street_number"
}

interface IPropertyAddresses {
    full_address: string;
    address: string;
    suburb: string;
    postcode: string;
    state: string;
    country: string
}

interface IAddressSearchField {
    value?: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    onAddressSelected?: (data: IAddressSearchData) => void
    error?: boolean
}

export const AddressSearchField: React.VFC<IAddressSearchField> = (props) => {
    const {value, onAddressSelected, onChange, error} = props

    const wrapperRef = useRef<HTMLDivElement>(null)

    const [showSearchResult, setShowSearchResult] = useState(false)
    const [mapResult, setMapResult] = useState<IPropertyAddresses[]>([])
    const [gmapsLoading, setGmapsLoading] = useState(false)

    useOutsideClickHandler(wrapperRef, () => {
        setShowSearchResult(false)
    })

    const _searchAddress = useCallback((address: string) => {
        _performSearchAddress(address)
    }, [])

    const _performSearchAddress = _.debounce(async (address: string) => {
        setGmapsLoading(true)

        const searchParam = new URLSearchParams()
        searchParam.append('address', address)
        searchParam.append('key', "AIzaSyB0DipBb9eWl9nz_M1TWe4ArJHvPwr6j3A")

        const gmapsUrl = `https://maps.googleapis.com/maps/api/geocode/json?` + searchParam.toString()

        try {
            const responseData = await fetch(gmapsUrl)
            const response: GMAPGeocodeResult = await responseData.json()
            if (response.status === 'OK') {
                // Iterate through response to get data
                const result: IPropertyAddresses[] = response.results.map(item => {
                    let address: string = ''
                    let suburb: string = ''
                    let postcode: string = ''
                    let state: string = ''
                    let country: string = ''
                    let fullAddress = item.formatted_address

                    item.address_components.forEach(addressItem => {
                        if (arrayContains(addressItem.types, GMapsAddressKey.postalCode)) {
                            postcode = addressItem.long_name
                        }
                        if (arrayContains(addressItem.types, GMapsAddressKey.country)) {
                            country = addressItem.long_name
                        }
                        if (arrayContains(addressItem.types, GMapsAddressKey.state)) {
                            state = addressItem.long_name
                        }
                        if (arrayContains(addressItem.types, GMapsAddressKey.suburb)) {
                            suburb = addressItem.long_name
                        }
                        if (arrayContains(addressItem.types, GMapsAddressKey.streetNumber)) {
                            address = addressItem.long_name + " "
                        }
                        if (arrayContains(addressItem.types, GMapsAddressKey.route)) {
                            address += addressItem.long_name
                        }
                    })

                    return { address, suburb, state, country, postcode, full_address: fullAddress }
                })

                setShowSearchResult(true)
                setMapResult(result)
                setGmapsLoading(false)
            } else {
                setGmapsLoading(false)
                console.error(`${response.status}: ${response.error_message}`)
            }
        } catch (e) {
            console.error('Error getting data ' + e)
            setGmapsLoading(false)
        }
    }, Configuration.DEFAULT_DEBOUNCE_TIME)


    return (
        <>
            <InputGroup
                prepend={
                    <>
                        {gmapsLoading && (
                            <div className={'px-4 py-3 bg-gray-100 rounded-bottom-left rounded-top-left'}>
                                <Spinner animation={'border'} size={'sm'} />
                            </div>
                        )}
                    </>
                }
            >
                <input
                    type="text"
                    className={`form-control ${error ? "is-invalid" : "" }`}
                    value={value}
                    onChange={e => {
                        _searchAddress(e.target.value)
                        if (onChange) onChange(e)
                        // formikProps.setFieldValue('address1', e.target.value)
                    }}
                />
            </InputGroup>
            {showSearchResult && (
                <div ref={wrapperRef} style={{
                    position: 'absolute',
                    right: 0,
                    left: 0,
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    zIndex: 1
                }}>
                    <div className={`rounded border border-gray-300 bg-white shadow`}>
                        <ul className={'list-unstyled pb-0'}>
                            {mapResult.map(item => (
                                <li>
                                    <div
                                        className={'p-4 bg-hover-primary-o-13 text-hover-white'}
                                        onClick={() => {
                                            if (onAddressSelected) {
                                                onAddressSelected({
                                                    address1: item.address,
                                                    country: item.suburb,
                                                    postcode: item.postcode,
                                                    state: item.state,
                                                    suburb:item.country,
                                                })
                                            }
                                            setShowSearchResult(false)
                                        }}
                                    >
                                        {item.full_address}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </>
    )
}

type IOutsideClickHandlerCallback = () => void;

const useOutsideClickHandler = (ref: MutableRefObject<any>, onClickOutside: IOutsideClickHandlerCallback) => {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside()
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [ref])
}