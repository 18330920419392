export const SelectBaseError = (isError = false) => {
    return {
        control: (styles: any) => {
            return isError
                ? { ...styles, zIndex: 2, border: '1px solid red' }
                : { ...styles, zIndex: 2 }
        },
        menu: (base: any) => {
            return { ...base, zIndex: 3}
        }
    }
}
