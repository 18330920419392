import React from "react";
import {Link} from "react-router-dom";

interface ITextIconProp {
    title: string,
    textRight?: string,
    fontAwesomeIcon: string,
    bold?: boolean,
    darkIcon?: boolean,
    disableLink?: boolean,
    linkHref?: string,
    href?: string
}

export const TextIcon: React.VFC<ITextIconProp> = (props: ITextIconProp) => {
    const {
        title,
        textRight,
        fontAwesomeIcon,
        bold = false,
        darkIcon = false,
        disableLink = false,
        linkHref = "",
        href = ""
    } = props

    /**
     * Rendering right side of text whenever is has email or phone number, with adding Link
     * and text style when hovering
     *
     * @param text Text to be styled
     * @return {React.ReactNode} returning React component with wrapped in link
     */
    const _renderRightItem = (text: string): React.ReactNode => {
        // Make a link when job request is a Phone number or email
        const phoneRegex = /^\+?[1-9][0-9]{7,14}$/
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

        if (text.match(phoneRegex) && !disableLink) {
            return (
                <a href={`tel:${text}`} className={'text-right font-weight-bolder text-hover-underline'}>{textRight}</a>
            )
        }

        if (text.match(emailRegex) && !disableLink) {
            return (
                <a href={`mailto:${text}`} className={'text-right font-weight-bolder text-hover-underline'}>{textRight}</a>
            )
        }

        if (linkHref && !disableLink) {
            return (
                <Link
                    to={linkHref}
                    className={'text-dark text-hover-primary'}
                >
                    {text}
                </Link>
            )
        }

        if (href && !disableLink) {
            return (
                <a
                    href={href}
                    target={'_blank'}
                    className={'text-dark text-hover-primary'}
                >
                    {text}
                </a>
            )
        }

        return (
            <span className="text-right font-weight-bolder">{ text }</span>
        )
    }

    /**
     * Checking left side of the text, if it's phone or email, to adding a link
     * @param text Text to be checked
     * @return {string|React.ReactNode} returning string if its normal text, or returning
     *                                  a React component when there is email or phone number
     */
    const _renderLeftItem = (text: string) => {
        const phoneRegex = /^\+?[1-9][0-9]{7,14}$/
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

        if (text.match(phoneRegex) && !disableLink) {
            return (
                <a href={`tel:${text}`} className={'text-dark text-hover-underline'}>{text}</a>
            )
        }
        if (text.match(emailRegex) && !disableLink) {
            return (
                <a href={`mailto:${text}`} className={'text-dark text-hover-underline'}>{text}</a>
            )
        }

        if (linkHref && !disableLink) {
            return (
                <Link
                    to={linkHref}
                    className={'text-dark text-hover-primary'}
                >
                    {text}
                </Link>
            )
        }
        if (href && !disableLink) {
            return (
                <a
                    href={href}
                    target={'_blank'}
                    className={'text-dark text-hover-primary'}
                >
                    {text}
                </a>
            )
        }
        return text
    }

    return (
        <span className={`d-flex align-items-start mb-2 ${bold ? "font-weight-bolder" : ""}`}>
            <span className="navi-icon mr-2">
                <i className={`fas fa-${fontAwesomeIcon} font-size-xs ${darkIcon ? "text-dark" : "text-muted"}`}/>
            </span>
            { textRight ? (
                <div className="d-flex">
                    <span className="mr-4">{ title }</span>
                    {_renderRightItem(textRight)}
                </div>
            ) : _renderLeftItem(title)}
        </span>
    )
}