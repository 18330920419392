import {CSSProperties, RefObject, useRef, useState} from "react";
import {EMPTY_IMAGE_DEFAULT_PATH} from "../../resorces/strings";

interface CustomImageProps {
    /** Image source path */
    src: string;

    /** Error fallback image source path */
    errorImageSrc?: string;

    /** Altenative text */
    alt?: string;

    style?: CSSProperties;

    onError?: (ref: RefObject<HTMLImageElement>) => void;
}

/**
 * Basic `<img />` with fallback for error image
 * */
export function CustomImage({
    src = EMPTY_IMAGE_DEFAULT_PATH,
    errorImageSrc = EMPTY_IMAGE_DEFAULT_PATH,
    alt = "",
    style,
    onError
}: CustomImageProps): JSX.Element {

    // State for when error image cannot loaded again,
    // dont make load recursively
    const [errorLoad, setErrorLoad] = useState(false);

    const _imgRef = useRef<HTMLImageElement>(null);

    const _handleError = () => {
        if (onError) {
            onError(_imgRef);
        } else {
            if (_imgRef && errorImageSrc && !errorLoad) {
                _imgRef.current!.src = errorImageSrc;
                setErrorLoad(true)
            }
        }
        return undefined;
    }

    return (
        <div>
            <img
                ref={ _imgRef }
                src={src}
                onError={ _handleError }
                className="align-self-end w-100 h-100"
                alt={ alt }
                style={style}
            />
        </div>
    )
}
