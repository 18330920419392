import {ApiResponse, APIResponse, BaseSearchParameterOrderBy} from "./BaseAPI";
import {AdminListings} from "../models/AdminListings";
import axios from "axios";
import {AdminListingProfile} from "../models/AdminListingProfile";
import {BaseResponse} from "../models/BaseModel/BaseResponse";
import {FormDataType, makeFormData, postToApi} from "../utils/APIHelper";
import { AdminPhoto } from "../models/AdminPhoto";
import {ProfileParam} from "./AccountAPI";

export interface AdminSearchParameter extends BaseSearchParameterOrderBy, FormDataType {}

export function adminsGetListing(searchParam?: AdminSearchParameter): ApiResponse<AdminListings> {

    return postToApi<AdminListings, AdminListings>({
        url: "api/admins/listings/all",
        data: searchParam
    }, data => data)
}

export function adminGetById(adminId: number): ApiResponse<AdminListingProfile> {
    return postToApi<AdminListingProfile, AdminListingProfile>({
        url: "api/admins/profile",
        data: { admin_id: adminId }
    }, data => data)
}

// 
export interface AdminAddParam extends FormDataType {
    firstname: string;
    lastname: string;
    address: string;
    email: string;
    suburb: string;
    postcode: string;
    state: string;
    country: string;
    phone: string;
    company: string;
    company_email: string;
    company_phone_number: string;
    website: string;
    description: string;
    abn: string;
    username: string;
    password: string;
    duedate?: string;
    account_base?: string;
    userlimit?: string;
    isTrial?: number;
    payment_type?: string;
    menual_payment?: string;
    feePaid?: number;
    // This property is for admin
    companyEmail?: string;
    companyPhone?: string
}

export function adminCreateNewEdit(data: AdminAddParam, administratorId?: number): APIResponse<BaseResponse> {
    const make = makeFormData(data, "admin");

    if (administratorId) {
        return axios.post(`api/admins/${administratorId}/edit/profile`, make);
    } else {
        return axios.post("api/admins/new/profile", make);
    }
}

export function adminDelete(administratorId: number): APIResponse<BaseResponse> {
    return axios.post(`admins/${administratorId}/remove`, makeFormData({ id: administratorId.toString() }));
}

export function adminUpdatePhoto(base64Image: string): ApiResponse</* message */ string> {
    return postToApi<string, BaseResponse>({
        url: "api/admins/photo/save",
        data: {imagebase64: base64Image}
    }, (data, message) => message!);
}

/**
 * Get Administrator logo
 * @returns Promise of base64 string image
 */
export function adminPhoto(): ApiResponse</* base64 image */string> {
    return postToApi<string, AdminPhoto>({
        url: "api/admins/photo",
    }, data => data.photo.file_imagebase64);
}

// Update Admin Profile
// TODO: This endpoint is using token to update
export function adminEditProfile(data: ProfileParam, token: string = "") {
    console.log('hello')
    let _data = {
        admin: data,
        _csrf_token: token
    }

    return postToApi<boolean, BaseResponse>({
        url: '/edit/profile',
        data: _data,
    }, data => {
        if (data.success) return true
        else throw new Error('Something went wrong');
    })
}