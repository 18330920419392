import React from "react";
import {Link} from 'react-router-dom';

interface SubheaderButtonProp {
    title: string;

    /**
     * Path navigate to,
     *
     * Default to `/` (root page)
     */
    to?: string;

    /**
     * Font Awesome name
     */
    fasIcon?: string;
}

export const SubheaderButton: React.FunctionComponent<SubheaderButtonProp> = ({
    title,
    to = "/",
    fasIcon
}): JSX.Element => {
    return (
        <Link to={to} className="btn btn-mypsr font-weight-bolder btn-sm ml-4">
            {fasIcon && (
                <i className={`fas fa-${ fasIcon } mr-2`}/>
            )}
            { title }
        </Link>
    )
};
