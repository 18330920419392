import styles from "./timeline.module.css";

interface TimelineData {
    title: string;
    subtitle?: string;
    time?: string;
    type?: string;
}

interface TimelineProps {
    data: TimelineData[];
}

export function Timeline({data}: TimelineProps): JSX.Element {
    return (
        <ul style={{paddingLeft: '0px'}}>
            { data.map((value, index) => (
                <li key={index} style={{ listStyle: 'none', display: 'inline-block', width: '200px', verticalAlign: 'top'}}>
                    <div style={{ float: 'left'}}>
                        <TimelineItem
                            start={(data.length - 1) !== index}
                            end={index > 0}
                            type={value.type}
                        />
                    </div>


                    <div className={styles.textTimelineWrapper} >
                        <div className="font-size-xs text-center">{value.title}</div>
                        <div className="font-size-xs text-center">{value.subtitle} @</div>
                        <div className="font-size-xs text-center text-mypsr-accent">{value.time}</div>
                    </div>
                </li>
            )) }
        </ul>
    );
}

interface TimelineItemProps {
    start?: boolean;
    end?: boolean;
    type?: string;
}

function TimelineItem({
    start = false,
    end = false,
    type = "thumbs-up"
}: TimelineItemProps): JSX.Element {

    let icon = "thumbs-up";
    let color = "";
    if (type === "new_comment" || type === "edit_comment") {
        icon = "comment-dots";
    } else if (type === "delete_comment") {
        icon = "comment-dots";
        color = 'bg-danger';
    } else if (type === "reassigned") {
        icon = "paper-plane";
        color = 'bg-success';
    } else if (type === "completed") {
        icon = "check";
        color = "bg-success";
    } else if (type === "created") {
        icon = "paper-plane";
        color = "bg-mypsr-primary";
    }

    return (
        <>
            <div className={styles.lineWrapper}>
                {start && (<div className={styles.lineWrapperLineStart} />)}
                {end && (<div className={styles.lineWrapperLineEnd} />)}

                <div className={`${styles.lineWrapperCircle} ${color}`}>
                    <i className={`fas fa-${ icon } text-white font-size-lg`} />
                </div>
            </div>
        </>
    )
}