/// Interfaces
import {SelectItem, SelectItemOperator, SelectItemPropertyLocation} from "../../models/SelectItem";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ReduxBaseState} from "../../models/ReduxBaseState";
import {PropertySelectItem} from "../../app/components/Selects/SelectProperty";
import {stat} from "fs";

export interface SelectState {
    administrator:          {
        selectItems:        SelectItem[];
    } & ReduxBaseState;
    archiveType:            {
        selectItems:        SelectItem[];
        selectedAdminId?:   number;
    } & ReduxBaseState;
    asset:                  {
        selectItems:        SelectItem[];
    } & ReduxBaseState;
    attendanceTrackingProperty: {
        selectItems:        SelectItem[];
        selectedClientId?:  string;
    } & ReduxBaseState;
    certificate:            {
        selectItems:        SelectItem[];
    } & ReduxBaseState;
    client:                 {
        selectItems:        SelectItem[];
    } & ReduxBaseState;
    globalCategory:         {
        selectItems:        SelectItem[];
    } & ReduxBaseState;
    inventory:              {
        selectItems:        SelectItem[];
    } & ReduxBaseState;
    operator:               {
        selectItems:        SelectItemOperator[];
    } & ReduxBaseState;
    property:               {
        selectItems:        PropertySelectItem[];
        /** For temporarily saving selected item instead fetching again */
        tempSelectItems:    PropertySelectItem[];
        selectedClientId?:  number;
    } & ReduxBaseState;
    propertyLocation:       {
        selectItems:        SelectItemPropertyLocation[];
        selectedPropertyId?: string;
        selectedClientId?:      string;
    } & ReduxBaseState;
    propertyLocationAdmin:  {
        selectItems:        SelectItem[];
    } & ReduxBaseState;
    service:                {
        selectItems:        SelectItem[];
    } & ReduxBaseState;
    serviceType:            {
        selectItems:        SelectItem[]
    } & ReduxBaseState,
    vendor:                 {
        selectItems:        SelectItem[];
    } & ReduxBaseState;
}

// Init State
const initialState: SelectState = {
    administrator: {
        errorMessage: "", selectItems: [], status: "init"
    },
    archiveType: {
        errorMessage: "", selectItems: [], status: "init"
    },
    asset: {
        errorMessage: "", selectItems: [], status: "init"
    },
    attendanceTrackingProperty: {
        errorMessage: "", selectItems: [], status: "init", selectedClientId: undefined
    },
    certificate: {
        errorMessage: "", selectItems: [], status: "init"
    },
    client: {
        errorMessage: "", selectItems: [], status: "init"
    },
    globalCategory: {
        errorMessage: "", selectItems: [], status: "init"
    },
    inventory: {
        errorMessage: "", selectItems: [], status: "init"
    },
    operator: {
        errorMessage: "", selectItems: [], status: "init"
    },
    property: {
        errorMessage: "", selectItems: [], tempSelectItems: [], status: "init", selectedClientId: undefined
    },
    propertyLocation: {
        errorMessage: "", selectItems: [], status: "init", selectedClientId: undefined, selectedPropertyId: undefined
    },
    propertyLocationAdmin: {
        errorMessage: "", selectItems: [], status: "init"
    },
    service: {
        errorMessage: "", selectItems: [], status: "init"
    },
    serviceType: {
        errorMessage: "", selectItems: [], status: "init"
    },
    vendor: {
        errorMessage: "", selectItems: [], status: "init"
    }
};

/// Redux Slice
export const selectSlice = createSlice({
    name: "selectSlice",
    initialState,
    reducers: {
        addAdministrator: (state, action: PayloadAction<{selectItem: SelectItem[]} & ReduxBaseState>) => {
            state.administrator.status = action.payload.status;
            state.administrator.errorMessage = action.payload.status !== "error" ? "" : action.payload.errorMessage;
            state.administrator.selectItems = action.payload.selectItem;
        },

        addArchiveType: (state, action: PayloadAction<{selectItem: SelectItem[], selectedAdminId?: number} & ReduxBaseState>) => {
            state.archiveType.status = action.payload.status;
            state.archiveType.errorMessage = action.payload.status !== "error" ? "" : action.payload.errorMessage;
            state.archiveType.selectItems = action.payload.selectItem;
            state.archiveType.selectedAdminId = action.payload.selectedAdminId;
        },

        updateArchiveType: (state, action: PayloadAction<{ selectItem: SelectItem[] }>) => {
            state.archiveType.selectItems = action.payload.selectItem;
        },

        addAsset: (state, action: PayloadAction<{selectItem: SelectItem[]} & ReduxBaseState>) => {
            state.asset.status = action.payload.status;
            state.asset.errorMessage = action.payload.status !== "error" ? "" : action.payload.errorMessage;
            state.asset.selectItems = action.payload.selectItem;
        },

        updateAsset: (state, action: PayloadAction<{selectItems: SelectItem[]}>) => {
            state.asset.selectItems = action.payload.selectItems;
        },

        addAttendanceTrackingProperty: (state, action: PayloadAction<{selectItem: SelectItem[], selectedClientId?: string; } & ReduxBaseState>) => {
            state.attendanceTrackingProperty.status = action.payload.status;
            state.attendanceTrackingProperty.errorMessage = action.payload.status !== "error" ? "" : action.payload.errorMessage;
            state.attendanceTrackingProperty.selectItems = action.payload.selectItem;
            state.attendanceTrackingProperty.selectedClientId = action.payload.selectedClientId;
        },

        addCertificate: (state, action: PayloadAction<{selectItem: SelectItem[]} & ReduxBaseState>) => {
            state.certificate.status = action.payload.status;
            state.certificate.errorMessage = action.payload.status !== "error" ? "" : action.payload.errorMessage;
            state.certificate.selectItems = action.payload.selectItem;
        },

        addClient: (state, action: PayloadAction<{selectItem: SelectItem[]} & ReduxBaseState>) => {
            state.client.status = action.payload.status;
            state.client.errorMessage = action.payload.status !== "error" ? "" : action.payload.errorMessage;
            state.client.selectItems = action.payload.selectItem;
        },

        addClientSingle: (state, action: PayloadAction<{ value: string, label: string}>) => {
            state.client.selectItems = [...state.client.selectItems, action.payload];
        },

        addGlobalCategory: (state, action: PayloadAction<{selectItem: SelectItem[]} & ReduxBaseState>) => {
            state.globalCategory.status = action.payload.status;
            state.globalCategory.errorMessage = action.payload.status !== "error" ? "" : action.payload.errorMessage;
            state.globalCategory.selectItems = action.payload.selectItem;
        },

        addInventory: (state, action: PayloadAction<{selectItem: SelectItem[]} & ReduxBaseState>) => {
            state.inventory.status = action.payload.status;
            state.inventory.errorMessage = action.payload.status !== "error" ? "" : action.payload.errorMessage;
            state.inventory.selectItems = action.payload.selectItem;
        },

        updateInventory: (state, action: PayloadAction<{ selectItems: SelectItem[] }>) => {
            state.inventory.selectItems = action.payload.selectItems;
        },

        addOperator: (state, action: PayloadAction<{selectItem: SelectItemOperator[]} & ReduxBaseState>) => {
            state.operator.status = action.payload.status;
            state.operator.errorMessage = action.payload.status !== "error" ? "" : action.payload.errorMessage;
            state.operator.selectItems = action.payload.selectItem;
        },

        updateOperator: (state, action: PayloadAction<{ selectItems: SelectItemOperator[]}>) => {
            state.operator.selectItems = action.payload.selectItems;
        },

        addProperty: (state, action: PayloadAction<{selectItem: PropertySelectItem[], tempSelectItem: PropertySelectItem[], selectedClientId?: number} & ReduxBaseState>) => {
            state.property.status = action.payload.status;
            state.property.errorMessage = action.payload.status !== "error" ? "" : action.payload.errorMessage;
            state.property.selectedClientId = action.payload.selectedClientId;
            state.property.selectItems = action.payload.selectItem;
            state.property.tempSelectItems = action.payload.tempSelectItem;
        },

        updateProperty: (state, action: PayloadAction<{ selectItems: PropertySelectItem[] }>) => {
            state.property.selectItems = action.payload.selectItems;
            state.property.tempSelectItems = action.payload.selectItems;
        },

        addPropertyLocation: (state, action: PayloadAction<{selectItem: SelectItemPropertyLocation[], selectedPropertyId?: string, selectedClientId?: string} & ReduxBaseState>) => {
            state.propertyLocation.status = action.payload.status;
            state.propertyLocation.errorMessage = action.payload.status !== "error" ? "" : action.payload.errorMessage;
            state.propertyLocation.selectItems = action.payload.selectItem;
            state.propertyLocation.selectedPropertyId = action.payload.selectedPropertyId;
            state.propertyLocation.selectedClientId = action.payload.selectedClientId;
        },

        addPropertyLocationAdmin: (state, action: PayloadAction<{selectItem: SelectItem[]} & ReduxBaseState>) => {
            state.propertyLocationAdmin.status = action.payload.status;
            state.propertyLocationAdmin.errorMessage = action.payload.status !== "error" ? "" : action.payload.errorMessage;
            state.propertyLocationAdmin.selectItems = action.payload.selectItem;
        },

        addService: (state, action: PayloadAction<{selectItem: SelectItem[]} & ReduxBaseState>) => {
            state.service.status = action.payload.status;
            state.service.errorMessage = action.payload.status !== "error" ? "" : action.payload.errorMessage;
            state.service.selectItems = action.payload.selectItem;
        },

        addSingleService: (state, action: PayloadAction<{ value: string, label: string }>) => {
            state.service.status = "success";
            state.service.selectItems = [...state.service.selectItems, action.payload];
        },

        // BEGIN Service Type
        addServiceType: (state, action: PayloadAction<{selectItem: SelectItem[]} & ReduxBaseState>) => {
            state.serviceType.status        = action.payload.status;
            state.serviceType.errorMessage  = action.payload.status !== "error" ? "" : action.payload.errorMessage;
            state.serviceType.selectItems   = action.payload.selectItem;
        },

        addServiceTypeSingle: (state, action: PayloadAction<SelectItem>) => {
            state.service.status = "success";
            state.service.selectItems = [...state.service.selectItems, action.payload];
        },

        // END Service Type

        addVendor: (state, action: PayloadAction<{selectItem: SelectItem[]} & ReduxBaseState>) => {
            state.vendor.status = action.payload.status;
            state.vendor.errorMessage = action.payload.status !== "error" ? "" : action.payload.errorMessage;
            state.vendor.selectItems = action.payload.selectItem;
        },

        addVendorSingle: (state, action: PayloadAction<{ selectItems: SelectItem[] }>) => {
            state.vendor.selectItems = action.payload.selectItems;
        },

        clear: () => initialState
    }
});

export const {
    addAdministrator,
    addArchiveType,
    addAsset,
    addAttendanceTrackingProperty,
    addCertificate,
    addClient,
    addGlobalCategory,
    addInventory,
    addOperator,
    addProperty,
    addPropertyLocation,
    addPropertyLocationAdmin,
    addService,
    addServiceType,
    addVendor,
    clear,
    // For adding single
    addSingleService,
    addServiceTypeSingle,
    addClientSingle,
    addVendorSingle,
    // Update
    updateArchiveType,
    updateInventory,
    updateAsset,
    updateProperty,
    updateOperator,
} = selectSlice.actions;
