import {JobRequestStatusModel} from "../../models/JobRequestStatusModel";
import {NotificationData} from "../../models/NotificationWebModel";
import {OperatorEventCalendarModel} from "../../models/OperatorEventCalendar";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {call, put, takeLatest} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {login, logout} from "./authRedux";
import {ReduxBaseState} from "../../models/ReduxBaseState";
import {clearData} from "./operatorRedux";
import {jobRequestGetCountStatus} from "../../apis/JobRequestAPI";

export interface OperatorCalendarData extends ReduxBaseState {
    start?: number,
    end?: number,
    data: OperatorEventCalendarModel[],
    selectedOperator?: string
}

export interface RecentActivitiesData {
    notificationData: NotificationData[];
    pageNumber: number;
}

export interface RecentActivitiesInfo extends ReduxBaseState {
    recentActivitiesData: RecentActivitiesData[];
    totalPage: number;
    currentPage: number;
    notReadNotification: number;
}

// Create Dashboard State
export interface DashboardState {
    statusCount: JobRequestStatusModel;
    notifications: NotificationData[];
    eventCalendar: OperatorCalendarData;
    recentActivities: RecentActivitiesInfo;
}

// Create initial State
const initialState: DashboardState = {
    statusCount: {
        base_url: "",
        code: 0,
        complete_today: 0,
        completed: 0,
        declined: 0,
        deleted: 0,
        due_today: 0,
        not_assign_yet: 0,
        not_invoiced: 0,
        open: 0,
        overdue: 0,
        pending: 0,
        success: false
    },
    notifications: [],
    eventCalendar: {
        status: "init",
        errorMessage: "",
        start: undefined,
        end: undefined,
        data: [],
        selectedOperator: undefined
    },
    recentActivities: {
        recentActivitiesData: [],
        totalPage: 1,
        currentPage: 1,
        status: "init",
        errorMessage: "",
        notReadNotification: 0
    }
}

// Create Slice
export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: initialState,
    reducers: {
        getStatusCount: () => {},
        updateStatusCount: (state, action: PayloadAction<JobRequestStatusModel>) => {
            state.statusCount = action.payload
        },

        updateDueTodayCounter: (state, action: PayloadAction<{total: number}>) => {
            state.statusCount.due_today = action.payload.total;
        },

        updateClosedTodayCounter: (state, action: PayloadAction<{total: number}>) => {
            state.statusCount.complete_today = action.payload.total;
        },

        updateOverdueCounter: (state, action: PayloadAction<{total: number}>) => {
            state.statusCount.overdue = action.payload.total;
        },

        updateClientRequestCounter: (state, action: PayloadAction<{total: number}>) => {
            state.statusCount.not_assign_yet = action.payload.total;
        },

        updateNotInvoicedCounter: (state, action: PayloadAction<{total: number}>) => {
            state.statusCount.not_invoiced = action.payload.total;
        },

        recentActivitiesStatus: (state, action: PayloadAction<ReduxBaseState>) => {
            state.recentActivities.status = action.payload.status;
            state.recentActivities.errorMessage = action.payload.errorMessage;
        },
        recentActivitiesAddData: (state, action: PayloadAction<RecentActivitiesInfo>) => {
            state.recentActivities.recentActivitiesData = action.payload.recentActivitiesData;
            state.recentActivities.totalPage = action.payload.totalPage;
            state.recentActivities.currentPage = action.payload.currentPage;
            state.recentActivities.status = "success";
            state.recentActivities.errorMessage = "";
            state.recentActivities.notReadNotification = action.payload.notReadNotification;
        },

        eventCalendarStatus: (state, action: PayloadAction<ReduxBaseState>) => {
            state.eventCalendar.status = action.payload.status;
            state.eventCalendar.errorMessage = action.payload.errorMessage;
        },
        eventCalendarUpdate: (state, action: PayloadAction<{ calendar: OperatorEventCalendarModel[], start: number, end: number, operator?: string }>) => {
            state.eventCalendar.data = action.payload.calendar;
            state.eventCalendar.start = action.payload.start;
            state.eventCalendar.end = action.payload.end;
            state.eventCalendar.selectedOperator = action.payload.operator;
            state.eventCalendar.status = "success";
            state.eventCalendar.errorMessage = "";
        },

        clearData: () => { return initialState }
    },
});

export const {
    getStatusCount,
    updateStatusCount,
    recentActivitiesStatus,
    recentActivitiesAddData,
    eventCalendarStatus,
    eventCalendarUpdate,

    // Counter actions
    updateDueTodayCounter,
    updateClientRequestCounter,
    updateClosedTodayCounter,
    updateNotInvoicedCounter,
    updateOverdueCounter
} = dashboardSlice.actions;

// Create Redux Saga
export function* saga() {
    yield takeLatest(login.type, function* () {
        try {
            const response: AxiosResponse<JobRequestStatusModel> = yield call(jobRequestGetCountStatus);
            const { data } = response;
            yield put(dashboardSlice.actions.updateStatusCount({ ...data }));
        } catch (e) {
        }
    });

    yield takeLatest(logout.type, function* () {
        yield put(clearData());
    })
}
