import React, {useMemo, useRef} from "react";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {toAbsoluteUrl} from "../../../_helpers";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {QuickUserToggler} from "../extras/QuiclUserToggler";
import clsx from "clsx";
import {SearchResult} from "../extras/dropdowns/search/SearchResult";
import {JobRequestDropdown} from "../extras/dropdowns/JobRequestDropdown";
import {ShowSearchModalContext} from "../../../../components/ShowSearchModalContext";
import AdminSyncDropdown from "../extras/dropdowns/AdminSyncDropdown";
import {useRoleHelper} from "../../../../../resorces/RoleHelper";
import OperatorCalendarSyncDropdown from "../extras/dropdowns/OperatorCalendarSyncDropdown";

export function Topbar() {
    const {isSuperAdmin, isAdmin, isAdministrator, isOperator, isClient} = useRoleHelper()

    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            viewSearchDisplay: objectPath.get(
                uiService.config,
                "extras.search.display"
            ),
            viewNotificationsDisplay: objectPath.get(
                uiService.config,
                "extras.notifications.display"
            ),
            viewQuickActionsDisplay: objectPath.get(
                uiService.config,
                "extras.quick-actions.display"
            ),
            viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
            viewQuickPanelDisplay: objectPath.get(
                uiService.config,
                "extras.quick-panel.display"
            ),
            viewLanguagesDisplay: objectPath.get(
                uiService.config,
                "extras.languages.display"
            ),
            viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
        };
    }, [uiService]);

    let topBarRef = useRef(null)

    return (
        <div className="topbar">
            {/*{layoutProps.viewSearchDisplay && <SearchDropdown />}*/}

            {!isClient && (
                <div
                    id="kt_quick_search_dropdown"
                    className={clsx("quick-search quick-search-dropdown border rounded py-1 px-2 my-3 mr-2", {
                        "quick-search-has-result": 0,
                    })}
                >
                    <form className="quick-search-form">
                        <div className="input-group">
                            <div className={`input-group-prepend`}>
                                <span className="input-group-text">
                                  <span className="svg-icon svg-icon-lg">
                                    <SVG
                                        src={toAbsoluteUrl(
                                            "/media/svg/icons/General/Search.svg"
                                        )}
                                    />
                                  </span>
                                </span>
                            </div>
                            <ShowSearchModalContext.Consumer>
                                {({ show, toggleShow }) => (
                                    <input
                                        ref={topBarRef}
                                        type="text"
                                        placeholder="Search..."
                                        className="form-control"
                                        onClick={() => {
                                            topBarRef.current.blur()
                                            toggleShow(true)
                                        }}
                                    />
                                )}
                            </ShowSearchModalContext.Consumer>

                            <div
                                className={`input-group-append`}
                            >
                        <span className="input-group-text">
                          <i
                              style={{display: 'none'}}
                              onClick={() => {
                              }}
                              className="quick-search-close ki ki-close icon-sm text-muted"
                          />
                        </span>
                            </div>
                        </div>
                    </form>
                    <SearchResult data={[]}/>
                </div>
            )}

            {/*{layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown/>}*/}

            {(isAdministrator || isSuperAdmin) && (
                <AdminSyncDropdown />
            )}

            {(isAdministrator || isAdmin) && (
                <JobRequestDropdown />
            )}

            {(isOperator || isSuperAdmin || isAdmin) && (
                <OperatorCalendarSyncDropdown />
            )}

            {!isClient && (
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="quick-panel-tooltip">Whats New</Tooltip>}
                >
                    <div
                        className="topbar-item"
                        data-toggle="tooltip"
                        title="Quick panel"
                        data-placement="right"
                    >
                        <div
                            className="btn btn-icon btn-clean btn-lg mr-1"
                            id="kt_quick_panel_toggle"
                        >
                          <span className="svg-icon svg-icon-xl svg-icon-primary">
                            <SVG
                                src={toAbsoluteUrl(
                                    "/media/svg/icons/Communication/Mail-notification.svg"
                                )}
                            />
                          </span>
                        </div>
                    </div>
                </OverlayTrigger>
            )}

            {/*{layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown/>}*/}

            {layoutProps.viewUserDisplay && <QuickUserToggler/>}
        </div>
    );
}
