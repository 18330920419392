import {AxiosStatic} from 'axios';
import {Store} from 'redux';
import Config from '../api_config';
import {ApplicationState} from './rootReducer';

export default function setupAxios(axios: AxiosStatic, store: Store<ApplicationState>) {
    axios.interceptors.request.use(
        values => {
            const {
                auth,
            } = store.getState();

            values.baseURL = Config.REACT_APP_API_URL;//process.env["REACT_APP_API_URL"];

            if (auth?.token) {
                if (auth.sandboxedAuth?.token) {
                    values.headers.Authorization = `Bearer ${auth.sandboxedAuth?.token}`;
                } else {
                    values.headers.Authorization = `Bearer ${auth?.token}`;
                }
            }

            values.headers["ngrok-skip-browser-warning"] = "69420";

            return values;
        },
        err => Promise.reject(err)
    );
}
