import React from "react";
import { Spinner } from "react-bootstrap";

interface FloatLoadingProps {
  message?: string;
}

const FloatLoading: React.FunctionComponent<FloatLoadingProps> = (props) => {
  return (
    <div className={"position-absolute zindex-1 p-0 force-centered"}>
      <div
        className={"bg-white shadow-sm border border-gray d-inline p-2 rounded"}
      >
        <Spinner animation={"border"} size={"sm"} />
        <span className={"ml-2"}>{props.message ?? "Loading"}</span>
      </div>
    </div>
  );
};

export default FloatLoading;
