import {ChangeEvent} from "react";

interface CInputFileProp {
    placeholder?: string,
    name?: string,
    accept?: string;
    multiple?: boolean;
    fileName?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function CInputFile({
    placeholder = "Choose File",
    accept = "image/*",
    name,
    multiple = false,
    fileName,
    onChange
}: CInputFileProp): JSX.Element {
    return (
        <div className="custom-file">
            <input
                type="file"
                name={ name }
                accept={accept}
                id={`${name}-ifile`}
                multiple={multiple}
                onChange={onChange}
            />
            <label className="custom-file-label" htmlFor={`${name}-ifile`}>{ fileName ?? placeholder }</label>
        </div>
    )
}