import React, {useState} from "react";
import {IJoinSearchType} from "../../../apis/DashboardApi";
import {FilterToolbarSearchField} from "./FilterToolbarSearchField";
import {ToolbarClient} from "./ToolbarItem/ToolbarClient";
import {ToolbarDue} from './ToolbarItem/ToolbarDue';
import {ToolbarOperator} from "./ToolbarItem/ToolbarOperator";
import {ToolbarProperty} from "./ToolbarItem/ToolbarProperty";
import {ToolbarServices} from "./ToolbarItem/ToolbarServices";

interface FilterToolbarFullSubmit {
    joinSearch: IJoinSearchType[];
    operator?: string;
    client?: number;
    property?: string;
    service?: string;
}

interface FilterToolbarFullProp {
    operatorId?: string;
    clientId?: number;
    propertyId?: string;
    disableOperator?: boolean;
    disableClient?: boolean;
    disableProperty?: boolean;
    onSubmit?: (arg0: FilterToolbarFullSubmit) => void;
    buttonTitle?: string;
}

export const FilterToolbarFull: React.FunctionComponent<FilterToolbarFullProp> = ({
    onSubmit,
    operatorId,
    clientId,
    propertyId,
    disableClient,
    disableOperator,
    disableProperty,
    buttonTitle = "Apply"
}: FilterToolbarFullProp): JSX.Element => {
    const [joinSearch, setJoinSearch] = useState<IJoinSearchType[]>([]);

    const [selectedOperator, setSelectedOperator] = useState<string>();

    const [selectedClient, setSelectedClient] = useState<number | undefined>();

    const [selectedProperty, setSelectedProperty] = useState<string>();

    const [selectedService, setSelectedService] = useState<string>();

    const _onSubmit = (e: any) => {
        e.preventDefault();

        if (onSubmit) {
            onSubmit({
                operator: selectedOperator,
                client: selectedClient,
                joinSearch: joinSearch,
                property: selectedProperty,
                service: selectedService
            });
        }
    }

    return (
        <form onSubmit={ _onSubmit }>
            <div className="px-8 pt-8">
                <FilterToolbarSearchField
                    onValueChange={value => setJoinSearch(value) }
                />

                <ToolbarOperator
                    selectedOperator={operatorId}
                    disable={ disableOperator }
                    onOperatorSelected={operatorId => setSelectedOperator(operatorId)}
                />

                <ToolbarClient
                    clientId={clientId}
                    disable={disableClient}
                    onClientSelected={clientId => setSelectedClient(clientId)}
                />

                <ToolbarProperty
                    propertyId={propertyId}
                    disable={disableProperty}
                    onPropertySelected={propertyId => setSelectedProperty(propertyId)}
                />

                <ToolbarServices
                    onServiceSelected={serviceId => setSelectedService(serviceId)}
                />

                <ToolbarDue

                />
            </div>

            <div className="bg-gray-100 text-right p-4 rounded-bottom">
                <button
                    type="submit"
                    className="btn btn-mypsr-orange font-weight-bolder"
                >
                    {buttonTitle}
                </button>
            </div>
        </form>
    );
};
