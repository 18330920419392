import React, {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useState,
} from "react";

interface RadioValue<T extends string | number> {
  label: string;
  value: T;
  checked?: boolean;
}

interface RadioProp<T extends string | number> {
  name: string;
  initialValue?: string;
  radios: RadioValue<T>[];
  inline?: boolean;
  onRadioChange?: (selected: string) => void;
  onChange?: (e: ChangeEventHandler<HTMLInputElement>) => void;
  disabled?: boolean;
  value?: T;
}

export function Radios<T extends string | number>({
  name,
  radios,
  initialValue,
  onRadioChange,
  onChange,
  inline = true,
  disabled = false,
  value,
}: RadioProp<T>): JSX.Element {
  const [selected, setSelected] = useState<string | undefined>();

  useEffect(() => {
    setSelected(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (value) {
      if (typeof value === "number") {
        setSelected(value.toString());
      } else {
        setSelected(value);
      }
    }
  }, [value]);

  return (
    <div className={inline ? "radio-inline" : "radio-list"}>
      {radios.map((value) => (
        <label
          key={value.value}
          className={`radio ${
            disabled && selected === value.value ? "radio-disabled" : ""
          }`}
        >
          <input
            disabled={disabled}
            type="radio"
            name={name}
            value={value.value}
            onChange={(e: any) => {
              setSelected(e.target.value);
              if (onRadioChange) onRadioChange(e.target.value);

              if (onChange) onChange(e);
            }}
            checked={selected === value.value}
          />
          <span />
          {value.label}
        </label>
      ))}
    </div>
  );
}

interface RadioProps {
  name: string;
  value: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
}

export const Radio: React.FC<RadioProps> = ({
  name,
  value,
  onChange,
  children,
  checked = false,
  disabled = false,
}): JSX.Element => {
  return (
    <label className="radio">
      <input
        type="radio"
        disabled={disabled}
        name={name}
        value={value}
        onChange={(e) => {
          if (onChange) onChange(e);
        }}
        checked={checked}
      />
      <span />
      {children}
    </label>
  );
};
