import React from "react";

interface ILabels {
  theme?: "light" | "outline" | "solid";
  variant?: "success" | "primary" | "danger" | "warning" | "info";
  size?: "sm" | "lg" | "xl";
  children?: React.ReactNode;
}

const Labels: React.FC<ILabels> = (props) => {
  const { children, theme = "solid", variant = "primary" } = props;

  let labelTheme = `label-${theme}-${variant}`;
  if (theme === "solid") {
    labelTheme = `label-${variant}`;
  }

  let labelSize = "label-md";
  if (props.size) {
    labelSize = `label-${props.size}`;
  }

  return (
    <span
      className={`label ${labelSize} label-pill label-inline ${labelTheme}`}
    >
      {children}
    </span>
  );
};

export default Labels;
