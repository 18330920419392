import React from "react";
import {SelectProperty} from "../../Selects";
import {FormGroupRow} from "../../Forms/FormGroupRow";

interface ToolbarPropertyProp {
    propertyId?: string;
    disable?: boolean;
    onPropertySelected?: (operatorId: string) => void
}

export const ToolbarProperty: React.FunctionComponent<ToolbarPropertyProp> = ({
    propertyId,
    onPropertySelected,
    disable = false
}): JSX.Element => {

    return (
        <FormGroupRow label={'Property'}>
            <SelectProperty
                value={propertyId}
                onSelected={onPropertySelected}
                disabled={disable}
            />
        </FormGroupRow>
    )
}
