import React from "react";

interface ICustomGroupButton {
  onClick?: (e: React.MouseEvent) => void;
  children?: React.ReactNode;
}

const CustomGroupButton = React.forwardRef<any, ICustomGroupButton>(
  ({ children, onClick }, ref: any) => (
    <button
      type="button"
      className="btn btn-sm btn btn-primary rounded-right-0"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        if (onClick) onClick(e);
      }}
    >
      {children}
    </button>
  )
);

export default CustomGroupButton;
