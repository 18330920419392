import {format} from "date-fns";
import {ApiResponse} from "../apis/BaseAPI";
import {BaseResponse} from "../models/BaseModel/BaseResponse";
import axios from "axios";
import {makeErrorMessage} from "./Helpers";
import { useEffect, useState } from "react";

type FormDataValueBaseType<T> = {[key: string]: T}
type FormDataValueType = string | string[] | File | FormDataValueBaseType<any> | number | boolean | undefined;

// Combined Form Data Type
export type FormDataType = FormDataValueBaseType<FormDataValueType>;

/**
 * Custom configuration for `makeFormData`
 * */
interface FormDataConfig {
    /**
     * When using `FormDataValueType` as `Date`,
     * override default date format (default: `dd-MM-yyyy`)
     * */
    dateFormat?:        string;

    /**
     * Format date as `ISOString` like `toISOString()`
     * */
    dateAsISOString?:   boolean;

    /**
     * Make `boolean` as integer when it posted
     *
     * ex: `true` = `1` or `false` = `0`
     * */
    booleanAsInteger?:  boolean;

    /**
     * When the `object` has `key` key, its value will not added into sub data
     *
     * Example:
     * ```
     * {
     *     jobRequest: [
     *         {
     *             "1234": {
     *                 key: "1234",
     *                 qty: 1,
     *                 price: 100,
     *             }
     *         }
     *     ]
     * }
     * ```
     *
     * To:
     * ```
     * name: jobRequest[0][1234], value: "1234"
     * name: jobRequest[0][1234][qty], value: 1
     * name: jobRequest[0][1234][price], value: 100
     * ```
     * */
    replaceKeyWithValue?: boolean;
}

interface FormDataReturnType {
    field:      string;
    value:      string | File;
}

/**
 * Convert object to form data defined array parameter
 *
 * Example (with prefix "hello"):
 * ```
 * _makeFormDataBody({
 *     field1: "value1",
 *     field2: {
 *         subField1: "field2.value1",
 *         subField2: "field2.value2"
 *     },
 *     field3: [
 *         "field3.value1",
 *         "field3.value2"
 *     ],
 * }, "hello");
 * ```
 *
 * Will be generated into
 * ```
 * [
 *  { name: "hello[field1]", value: "value1" },
 *  { name: "hello[field2][subField1]", value: "field2.value1" },
 *  { name: "hello[field2][subField2]", value: "field2.value2" }
 *  { name: "hello[field3][]", value: "field3.value1" }
 *  { name: "hello[field3][]", value: "field3.value2" }
 * ]
 * ```
 *
 * @param {FormDataValueBaseType<FormDataValueType>} data Object data to be generated
 * @param {string?} prefix Prefix for start of string, when not null it will added string to beginning of value
 *                          Example: prefix = start, will be generated into `start[someObject]`
 * @param config {FormDataConfig} configuration for make form data
 * @return {FormDataReturnType[]} Generated data to executed to form data
 *
 * @see FormDataConfig
 * */
export const makeFormDataBody = (data: FormDataType, prefix?: string, config?: FormDataConfig): FormDataReturnType[] => {
    let formBody: FormDataReturnType[] = [];

    Object.keys(data).forEach((key) => {
        const fieldName: string = prefix ? `${prefix}[${key}]` : key;
        const fieldValue = data[key];

        // Checking if the data is an object,
        if (typeof fieldValue === "object") {
            if (fieldValue instanceof Date) {
                // If the `object` is instance of date, make it formatted to string
                const dateFormat = config?.dateFormat ?? "dd-MM-yyyy";
                let dateAsString: string = format(fieldValue, dateFormat);

                if (config?.dateAsISOString) {
                    dateAsString = fieldValue.toISOString();
                }

                formBody.push({
                    field: fieldName,
                    value: dateAsString
                })
            } else if (fieldValue instanceof File) {
                formBody.push({
                    field: fieldName,
                    value: fieldValue
                })
            } else {
                const subFormData = makeFormDataBody(fieldValue as FormDataType, fieldName, config);
                formBody = [...formBody, ...subFormData];
            }
        } else if (Array.isArray(fieldValue)) {
            // Iterate each element
            fieldValue.forEach((value) => {
                formBody.push({
                    field: fieldName + "[]",
                    value
                });
            })
        } else if (fieldValue === undefined) {
            // If value is undefined dont append it.
        } else {
            let value = fieldValue?.toString() ?? "";

            if (
                config?.booleanAsInteger &&
                fieldValue &&
                (fieldValue === "true" || fieldValue === "false")
            ) {
                value = (fieldValue === "true") ? "1" : "0";
            }

            if (config?.replaceKeyWithValue) {
                if (key === "key") {
                    formBody.push({
                        field: prefix ?? "",
                        value: value
                    });
                    return;
                }
            }

            formBody.push({
                field: fieldName,
                value: value
            })
        }
    })

    return formBody;
};

const makeAsKeyValue = (data: FormDataReturnType[]): {[key: string]: any} => {
    let items: {[key: string]: any} = {}
    data.forEach((item) => {
        items[item.field] = item.value
    })
    return items
}

/**
 * Make form data from Object <br />
 * For `prefix` parameter, when its not `null`. The field name will added as prefix and
 * the field name from `data` will added to array.
 *
 * @see {@link _makeFormDataBody}
 *
 * @param {[key: string]: any} data Form data Object
 * @param {string} prefix Prefix for custom form field name
 * @param config Form Data Configuration
 * @return {FormData} appended `FormData` from Object
 * */
export const makeFormData = (data?: FormDataType, prefix?: string, config?: FormDataConfig): FormData => {

    let formData = new FormData();

    if (data) {
        const generatedData = makeFormDataBody(data, prefix, config);

        generatedData.forEach((value) => {
            formData.append(value.field, value.value);
        });
    }

    return formData;
};

/**
 * Function form change object for form encoded URL parameter
 * @param data {[key: string]: string} Map/Object yang akan di encoded
 * @return {string} formated parameter Form
 * */
export const makeParameterBuilder = (data: {[key: string]: any}): string => {
    return Object.keys(data).map((key: string) => {
        const inner = data[key]
        if (typeof inner !== 'object') {
            return [key, data[key]].map(encodeURIComponent).join("=");
        }
    }).join("&");
};

interface PostToAPIParam {
    url: string;
    data?: any | FormData;
    config?: {
        prefix?: string;
        config?: FormDataConfig;

        /**
         * Make custom error message when `data.messages` is not defined
         */
        customErrorMessage?: string;

        /**
         * Override `is_success` false from server
         */
        allowNotSuccess?: boolean;

        /**
         * Custom Headers
         */
        headers?: {[key: string]: string}
    }
}

/**
 * Simplifying making calling to POST data and some checking.
 *
 * On `response` it can be returned either the data or message, or processed data from `Return`
 * generic class
 *
 * <b>Example:</b>
 * ```
 * return postToApi<ReturnType, ResponseModelForAxios>({
 *     url: "/path/to/send/data",
 *     data: { "foo": "bar" }
 * }, (data, message) => {
 *     // Can return message or processed data
 *     return data;
 * })
 * ```
 *
 * Note:
 * - `ResponseModelForAxios` model must be extending `BaseResponseModel`
 *   for typechecking with status, and make error message when something goes wrong
 *
 * @param param {PostToAPIParam} URL, data, and config parameter
 * @param response Callback to processing data
 * @return {Promise} Promise resolve
 */
export function postToApi<Return, ResponseModel extends BaseResponse>(
    param: PostToAPIParam,
    response: (data: ResponseModel, message?: string) => Return
): ApiResponse<Return> {
    return new Promise(resolve => {
        axios.post<ResponseModel>(
            param.url,
            param.data ? makeFormData(
                {...param.data, version: 2},
                param.config?.prefix ?? "",
                param.config?.config
            ): {},
            {
                headers: param.config?.headers
            }
        ).then(({ data }) => {
            let msg;
            if (data.messages || data.message) {
                msg = makeErrorMessage(data.messages ?? data.message);
            } else {
                msg = param.config?.customErrorMessage ?? "An error occurred, please contact Administrator";
            }
            if (param.config?.allowNotSuccess) {
                resolve([response(data, msg), undefined]);
            } else {
                if (data.success) {
                    resolve([response(data, msg), undefined]);
                } else resolve([undefined, msg]);
            }
        }).catch(e => resolve([undefined, e.toString()]));
    })
}

export function getFromApi<Return, ResponseModel extends BaseResponse>(
    param: PostToAPIParam,
    response: (data: ResponseModel, message?: string) => Return
): ApiResponse<Return> {
    return new Promise(resolve => {
        axios.get<ResponseModel>(
            param.url,
            { params: {...makeAsKeyValue(makeFormDataBody(param.data)), version: 2, is_web: 1} }
        ).then(({ data }) => {
            let msg;
            if (data.messages || data.message) {
                msg = makeErrorMessage(data.messages ?? data.message);
            } else {
                msg = param.config?.customErrorMessage ?? "An error occurred, please contact Administrator";
            }
            if (data.success) {
                resolve([response(data, msg), undefined]);
            } else resolve([undefined, msg]);
        }).catch(e => resolve([undefined, e.toString()]));
    })
}

/**
 * Custom Hooks for calling API
 * @param func API Function to execute
 * @param deps Same as `useEffect` dependency
 * @returns Array of data
 */
export function useApi<T>(func: ApiResponse<T>, deps: any[] = []): [T | undefined, boolean, string | undefined] {
    const [data, setData] = useState<T | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        setIsLoading(true);

        func.then(([response, error]) => {
            if (!error) {
                setData(response);
            } else {
                setErrorMessage(error);
            }

            setIsLoading(false);
        });
    }, [func, deps]);

    return [data, isLoading, errorMessage];
}
